import React from 'react';
import { connect } from 'react-redux';
import dateFns from 'date-fns';
import Overlay from './Overlay';
import Tooltip from '../Tooltip';
import _ from 'lodash';
import { TSelectableItemProps } from 'react-selectable-fast';
import {ReactComponent as HolidayIcon} from '../../images/holiday.svg';
import {ReactComponent as LockIcon} from '../../images/lock.svg';

import agent from '../../agent';
import {
  SCHEDULE_CREATE,
  SCHEDULE_UPDATE,
  SCHEDULE_REMOVE,
  MONTH_LOADED,
  OPEN_SCHEDULE_OVERLAY,
  CLOSE_SCHEDULE_OVERLAY
} from '../../constants/actionTypes';

const mapStateToProps = (state, ownProps) => (
  {
    ...state,
    activeMonth: state.common.activeMonth,
    overlay: state.schedule.overlay
  }
);

const mapDispatchToProps = dispatch => ({
  updateSchedule: (type, activeMonth, targetMonth, targetDay, work, dates, modal) => {

    if (type === 'CREATE' || type === 'HOLIDAY') {

      if (type === 'HOLIDAY') {

        work = {
          date: targetDay,
          holiday: true
        }

      }

      if (type === 'CREATE') {
        //dispatch({ type: CLIENT_IN_FOCUS, client_id: work.client_id })
      }

      dispatch({ type: SCHEDULE_CREATE, payload: agent.Schedule.create(work) })

    }

    if (type === 'UPDATE') {

      dispatch({ type: SCHEDULE_UPDATE, payload: agent.Schedule.update(work) })

    }

    if (type === 'REMOVE') {

      dispatch({ type: SCHEDULE_REMOVE, payload: agent.Schedule.remove(work.id) })

    }

    if ( dateFns.isSameDay(targetMonth, activeMonth) ) {

      setTimeout(function(){
        let month_name = dateFns.format(targetMonth, 'MMMM').toLowerCase()
        let year = dateFns.format(targetMonth, 'YYYY').toLowerCase()
        dispatch({ type: MONTH_LOADED, month: targetMonth, month_string: month_name + '-' + year, payload: agent.Month.show(month_name, year) })
      }, 500);

    }

  },
  openOverlay: () => {
    dispatch({ type: OPEN_SCHEDULE_OVERLAY });
  },
  closeOverlay: () => {
    dispatch({ type: CLOSE_SCHEDULE_OVERLAY });
  }
});

let dragItem;
let dragItemFrontend;

class ScheduleItem extends React.Component<TSelectableItemProps> {

  constructor(props) {
    super(props);

    this.state = {
      booked: false,
      currency: this.props.common.currentUser.currency,
      overlay: false
    }

  }

  shouldComponentUpdate(nextProps, nextState) {

    if (this.props.classNames !== nextProps.classNames || this.state.overlay !== nextState.overlay || this.props.selectableRef !== nextProps.selectableRef || this.props.isSelecting !== nextProps.isSelecting || this.props.isSelected !== nextProps.isSelected) {
      return true;
    }

    if( !(_.isEqual(_.sortBy(this.props.deleteKeys), _.sortBy(nextProps.deleteKeys))) ) {

      if( _.size(this.props.work) <= 0 )  {
        return false;
      } else {
        return true;
      }

    }

    if( !(_.isEqual(_.sortBy(this.props.work), _.sortBy(nextProps.work))) ) {
      return true;
    }

    return false;

  }

  openOverlay(e, overlay, work = false) {

    e.preventDefault();
    e.stopPropagation();

    if ( e.shiftKey ) {

      if ( e.currentTarget.getAttribute('data-work') ) {

        this.props.deleteWork( e.currentTarget.getAttribute('data-work') )

      } else {

        this.props.addWork( e.currentTarget.getAttribute('data-day') );

      }

    } else {

      this.props.openOverlay()

      this.setState({
        overlay: overlay,
        work: work
      });

    }

  }

  onMouseEnter = (e) => {
    e.stopPropagation();
    e.currentTarget.classList.add('hover');
  }

  onMouseLeave = (e) => {
    e.stopPropagation();
    e.currentTarget.classList.remove('hover');
  }

  onDragEnter = (e) => {
    e.stopPropagation();
    e.currentTarget.classList.add('drop');
  }

  onDragLeave = (e) => {
    e.stopPropagation();
    e.currentTarget.classList.remove('drop');
  }

  onDragOver = (e) => {
    e.preventDefault();
  }

  handleDrop = (e) => {

    let work = {
      ...dragItem.props.work[ dragItemFrontend.getAttribute('data-index') ],
      date: e.currentTarget.getAttribute('data-day')
    }

    this.props.updateSchedule('UPDATE', this.props.activeMonth, this.props.activeMonth, false, work, false)

    e.currentTarget.classList.remove('drop');

    document.querySelector('.schedule__calendar').classList.remove('dragging');

    dragItemFrontend.classList.remove('dragging');

    dragItem = false
    dragItemFrontend = false

  }

  onDragEnd = (e) => {

    e.currentTarget.style.opacity = '1';

  }

  onDragStart = (e) => {

    e.currentTarget.parentNode.parentNode.classList.add('hover');

    dragItem = this;
    dragItemFrontend = e.currentTarget;

    e.stopPropagation();

    document.querySelector('.schedule__calendar').classList.add('dragging');

    setTimeout(function(){
      dragItemFrontend.classList.add('dragging');
    });

  }

  closeOverlay(e) {

    e.stopPropagation();

    this.props.closeOverlay()

    this.setState({
      overlay: false
    });

  }

  handleWorkClick = (e) => {

    e.stopPropagation();

    if ( e.shiftKey || e.currentTarget.classList.contains('checkbox') ) {

      this.props.deleteWork( e.currentTarget.getAttribute('data-work') )

    }

  }

  handleDateEnter = (e) => {

    e.currentTarget.parentNode.querySelector('.day__work div:last-child').classList.add('hover');

  }

  handleDateLeave = (e) => {

    e.currentTarget.parentNode.querySelector('.day__work div:last-child').classList.remove('hover');

  }

  render() {

    const { selectableRef, isSelecting } = this.props

    let content;

    const add = <React.Fragment>
      <div
        className={`add ${ !this.props.schedule && dateFns.isToday(this.props.day) ? 'hover' : '' } ${ this.props.blackout && this.props.blackout.name ? 'blackout' : '' }`}
        data-day={dateFns.format(this.props.day, 'YYYY-MM-DD')}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}>
        <article>
          <span className="title">Book {dateFns.format(this.props.day, 'ddd Do MMMM YYYY')} { this.props.blackout && this.props.blackout.name ? `(${this.props.blackout.name})` : '' }</span>
          <small className="meta">{ this.props.blackout && this.props.blackout.name ? this.props.blackout.name : '' }</small>
        </article>
      </div>
    </React.Fragment>;

    const add_inline = <React.Fragment key={dateFns.format(this.props.day, 'YYYY-MM-DD')}>
      <div
        className="add__quick"
        data-day={dateFns.format(this.props.day, 'YYYY-MM-DD')}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}>
        <small>{this.props.hours} free</small>
      </div>
    </React.Fragment>;

    const clients = this.props.work.map((work, index) =>
      <React.Fragment key={index}>
        <div
          draggable={ (work.state === 'invoiced' || work.state === 'paid') ? false : true }
          data-index={index}
          data-work={work.id}
          data-day={dateFns.format(this.props.day, 'YYYY-MM-DD')}
          onClick={(e) => this.openOverlay(e, work.holiday ? 'holiday' : 'work', work)}
          onDragStart={this.onDragStart}
          onDragEnd={this.onDragEnd}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
          className={ _.includes(this.props.deleteKeys, work.id.toString() ) ? 'delete' : '' }>
          {work.holiday ? (
            <React.Fragment>
              <em />
              <article>
                <span className="title">Holiday <HolidayIcon /></span>
              </article>
            </React.Fragment>
            ) :
            this.props.clients.clients.filter(function (el) {
              return el.id === work.client
            }).map((el) => {

              return (
                <React.Fragment key={el.id}>
                  <Tooltip message={work.unit === 'hours' ? work.unit_value_formatted_short + ' (' + work.price_formatted + ')' : 'Full Day (' + work.price_formatted + ')'} position={'top'} size={'large'}>
                    <em><em style={{backgroundColor: el.hex_colour, height: (work.unit === 'days' ? 100 : work.unit_value * 100 / parseFloat(this.props.common.currentUser.hours_per_day)) + '%'}}></em></em>
                  </Tooltip>
                  <article>
                    <span className="title">
                      { work.project_title ? (
                        <span className="truncate">{work.project_title} ({el.name})</span>
                      ) : (
                        <span className="truncate">{el.name}</span>
                      )}
                      { (work.state === 'invoiced' || work.state === 'paid') && (
                        <Tooltip message={`This item is now locked as it has been marked as ${work.state}.`} position={'top'} size={'large'}>
                          <LockIcon />
                        </Tooltip>
                      ) }
                    </span>
                    {(work.unit === 'hours' || work.note) && (<span className="meta">{work.unit_value_formatted_short}{work.note && ` – ${work.note}`}</span>)}
                  </article>
                  <span className="checkbox" data-work={work.id} onClick={this.handleWorkClick}></span>
                </React.Fragment>
              );
            })
          }
        </div>
      </React.Fragment>
    );

    if (clients.length > 0) {
      content = [clients,add_inline];
    } else {
      content = add;
    }

    return (
      <React.Fragment key={dateFns.format(this.props.day, 'YYYY-MM-DD')}>
        <div
          key={dateFns.format(this.props.day, 'YYYY-MM-DD')}
          className={this.props.classNames}
          ref={selectableRef}
          onClick={(e) => this.openOverlay(e, 'client')}
          onDragEnter={this.onDragEnter}
          onDragLeave={this.onDragLeave}
          onDragOver={this.onDragOver}
          onDrop={this.handleDrop}
          data-day={dateFns.format(this.props.day, 'YYYY-MM-DD')}
          data-select={isSelecting}>
          <span className="day__date"
            onMouseEnter={this.handleDateEnter}
            onMouseLeave={this.handleDateLeave}
            data-day={dateFns.format(this.props.day, 'YYYY-MM-DD')}
            ref={dateFns.isToday(this.props.day) ? this.props.todayRef : undefined}>
            <span className="number">{dateFns.format(this.props.day, 'DD')}</span>
            <span className="bg">{dateFns.format(this.props.day, 'ddd')}</span>
          </span>
          <div className="day__work" data-work={this.props.work.length}>
            {content}
          </div>
        </div>
        {this.state.overlay && (
          <Overlay
            overlay={this.state.overlay}
            dates={[dateFns.format(this.props.day, 'YYYY-MM-DD')]}
            work={this.state.work}
            closeOverlay={this.closeOverlay.bind(this)}></Overlay>
        )}
      </React.Fragment>
    )

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleItem);
