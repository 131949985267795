import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom';
import { get, isEmpty } from 'lodash'
import { InvoicesForm } from '../components/Invoices/InvoicesForm'
import agent from '../agent';
import * as QueryString from "query-string";
import Overlay from '../components/Invoices/Overlay';
import { history } from '../configureStore';

const InvoiceEdit = (props) => {

  const dispatch = useDispatch()

  const params = QueryString.parse(props.location.search);

  const currentUser = useSelector(state => get(state.common, 'currentUser', {}));

  const invoiceId = get(props, "match.params.invoiceId", null);
  const invoices = useSelector(state => get(state.invoices, 'invoices', []));
  const clients = useSelector(state => get(state.clients, 'clients', []));
  
  const [clientId, updateClient] = useState();
  const [dataLoaded, setDataLoaded] = useState(false)
  const [works, setWorks] = useState([])
  const [projects, setProjects] = useState([])
  const [invoice, setInvoice] = useState({})
  
  let client = clients.find(cl => clientId == cl.id) || null;

  const invoiceLineItems = (currency=undefined, lineItems=[]) => {
    currency = currency || currentUser.currency
    const workInCurrency = works.filter((work) => { return work.currency === currency })

    if(!isEmpty(works)){
      lineItems = lineItems.concat(workInCurrency.map((work) => {
          return {description: work.description, subtotal: work.price, total: work.price * 1.2, unit_amount: work.unit_amount, quantity: work.quantity, tax_rate: 20, invoiceable_type: 'WorkDay', invoiceable_id: work.id}
       }))
    }

    if(!isEmpty(projects)){
      lineItems = lineItems.concat(
        projects.map((project) => {
          return {description: project.title, subtotal: project.amount_uninvoiced, unit_amount: project.amount_uninvoiced, total: (project.amount_uninvoiced * 1.2).toFixed(2), quantity: 1, tax_rate: 20, invoiceable_type: 'Project', invoiceable_id: project.id}
        })
      )
    }

    if(isEmpty(lineItems)){
      lineItems.push({
        subtotal: 0, quantity: 1, tax_rate: 20, total: 0
      })
    }
    return lineItems
  }

  useEffect(() => {
    dispatch({ type: 'INVOICES_PAGE_LOADED', payload: agent.Invoices.all() })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    setInvoice(invoices.find(inv => inv.id.toString() === invoiceId) || {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[invoices]);

  useEffect(() => {
    if(!isEmpty(invoice) && !isEmpty(clients)) {
      agent.Clients.invoiceable_works(invoice.client_id).then((res) =>  { setDataLoaded(true); setWorks(res.body); })
      agent.Clients.invoiceable_projects(invoice.client_id).then((res) =>  { setDataLoaded(true); setProjects(res.body) })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[invoice, clients]);

  useEffect(() => {
    if(!isEmpty(invoice)) {
      updateClient(invoice.client_id)
      if(invoice.state !== 'draft') {
        window.location.href = "/invoices";
      }
    }
  },[invoice]);

  if(dataLoaded){
    return (
      <section className="component__route component__route__invoices">
        <header>
          <h2><Link to={'/invoices'}>Invoices</Link> / Edit</h2>
        </header>
        <section>
          <InvoicesForm
            dispatch={dispatch}
            invoice={invoice}
            params={params} />
        </section>
      </section>
    );
  }else{
    return (<div className="loading"><div className="loader"></div></div>)
  }

}

export default InvoiceEdit;
