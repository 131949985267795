import React from 'react';
import agent from '../agent';
import images from '../images';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import dateFns from 'date-fns';
import Select, { components } from 'react-select';
import countryList from 'react-select-country-list';
import update from 'immutability-helper';
import getSymbolFromCurrency from 'currency-symbol-map';

import {
  REGISTER
} from '../constants/actionTypes';

const mapStateToProps = state => (
  {
    errors: state.auth.errors,
    common: state.common
  }
);

const mapDispatchToProps = dispatch => ({
  onSubmitForm: user => {
    // function parse(str) {
    //   const [date, month, year] = str.split('/').map(n => parseInt(n))
    //   return new Date(year, month - 1, date)
    // }
    user.business_attributes.year_end = dateFns.format(dateFns.endOfYear(dateFns.startOfToday()), 'YYYY-MM-DD')
    user.country = user.country.value
    dispatch({ type: REGISTER, payload: agent.Auth.register(user) })
  }
});

const Input = ({ autoComplete, ...props }) => <components.Input {...props} autoComplete="new-password" />;

class RegisterForm extends React.Component {

  constructor(props) {
    super();

    this.countries = countryList().getData()

    this.state = {
      name: props.common.name ? props.common.name : '',
      email: props.common.email ? props.common.email : '',
      password: '',
      standard_hourly_rate: '',
      standard_day_rate: '',
      monthly_revenue_target: '',
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: false,
      sunday: false,
      work_on_public_holidays: false,
      dark_mode: false,
      currency: 'GBP',
      business_attributes: {
        id: null,
        name: '',
        year_end: ''
      },
      country: {
        value: 'US',
        label: countryList().getLabel('US')
      },
      step: 1
    };
  }

  componentWillMount() {
  }

  componentWillReceiveProps(nextProps) {
  }

  errorMessage(field) {
    if(this.props.errors && this.props.errors[field]){
      return this.props.errors[field];
    }
  };

  updateState = field => ev => {

    const [split_field, parent_key] = field.split('.').reverse()
    const value = ev.target.type === 'checkbox' ? ev.target.checked : ev.target.value;
    let newData;

    if (parent_key === undefined) {

      newData = update(this.state, {
        [split_field]: {$set: value}
      })

    } else {

      newData = update(this.state, {
        [parent_key]: {
          [split_field]: {$set: value}
        }
      })

    }

    this.setState(newData);

    // cleanup
    newData = null;

  };

  updateCurrency = (field, value) => ev => {

    const [split_field, parent_key] = field.split('.').reverse()
    let newData;

    if (parent_key === undefined) {

      newData = update(this.state, {
        [split_field]: {$set: value}
      })

    } else {

      newData = update(this.state, {
        [parent_key]: {
          [split_field]: {$set: value}
        }
      })

    }

    this.setState(newData);

    // cleanup
    newData = null;

  };

  updateCountry = value => {
    this.setState({ country: value })
  }

  submitForm(ev) {
    ev.preventDefault();
    this.props.onSubmitForm(this.state);
  }

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false })
  };

  handleChange = (color) => {
    this.setState({
      hex_colour: color.hex
    })
  };

  nextStep = (e) => {
    e.preventDefault();
    this.setState({ step: 2 })
  };

  prevStep = (e) => {
    e.preventDefault();
    this.setState({ step: 1 })
  };

  render() {

    return (
      <form onSubmit={this.submitForm.bind(this)} autoComplete="off" autoComplete="new-password">

        <h1>Start your 30-day free trial.</h1>

        <div className={(this.state.step === 1 || this.props.errors) ? 'show' : 'hide'}>

          <div className="field">
            <label>
              <span>Name {this.errorMessage('name') ? <div className="error">{this.errorMessage('name')}</div> : ''}</span>
              <input
                className="form-control"
                type="text"
                value={this.state.name}
                autoComplete="none"
                autoFocus={true}
                onChange={this.updateState('name')} />
            </label>
          </div>

          <div className="field">
            <label>
              <span>Email {this.errorMessage('email') ? <div className="error">{this.errorMessage('email')}</div> : ''}</span>
              <input
                className="form-control"
                type="text"
                defaultValue={this.state.email}
                autoComplete="new-password"
                onChange={this.updateState('email')} />
            </label>
          </div>

          <div className="field">
            <label>
              <span>Password {this.errorMessage('password') ? <div className="error">{this.errorMessage('password')}</div> : ''}</span>
              <input
                className="form-control"
                type="password"
                value={this.state.password}
                autoComplete="new-password"
                onChange={this.updateState('password')} />
            </label>
            <small>Must be at least 8 characters</small>
          </div>

          <div className="field">

            <label>
              <span>Country</span>
            </label>
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              options={this.countries}
              value={this.state.country}
              onChange={this.updateCountry}
              components={{
                Input
              }}
            />

          </div>

        </div>

        <hr className={(this.props.errors) ? 'show' : 'hide'} />

        <div className={this.state.step === 2 ? 'show' : 'hide'}>

          <h3>Currency Format</h3>

          <div className="field__group">

            <div className="field field__checkbox">
              <label>
                <input
                  className="form-control"
                  type="checkbox"
                  checked={this.state.currency === 'GBP'}
                  onChange={this.updateCurrency('currency', 'GBP')} />
                <span className="currency">£ {this.errorMessage('currency')}</span>
              </label>
            </div>
            <div className="field field__checkbox">
              <label>
                <input
                  className="form-control"
                  type="checkbox"
                  checked={this.state.currency === 'USD'}
                  onChange={this.updateCurrency('currency', 'USD')} />
                <span className="currency">$ {this.errorMessage('currency')}</span>
              </label>
            </div>
            <div className="field field__checkbox">
              <label>
                <input
                  className="form-control"
                  type="checkbox"
                  checked={this.state.currency === 'EUR'}
                  onChange={this.updateCurrency('currency', 'EUR')} />
                <span className="currency">€ {this.errorMessage('currency')}</span>
              </label>
            </div>
            <div className="field field__checkbox">
              <label>
                <input
                  className="form-control"
                  type="checkbox"
                  checked={this.state.currency === 'DKK'}
                  onChange={this.updateCurrency('currency', 'DKK')} />
                <span className="currency">kr. {this.errorMessage('currency')}</span>
              </label>
            </div>
            <div className="field field__checkbox">
              <label>
                <input
                  className="form-control"
                  type="checkbox"
                  checked={this.state.currency === 'BRL'}
                  onChange={this.updateCurrency('currency', 'BRL')} />
                <span className="currency">R$ {this.errorMessage('currency')}</span>
              </label>
            </div>
          </div>

          <div className="field field__helper">
            <label>
              <span>Standard Day Rate {this.errorMessage('standard_day_rate') ? <div className="error">{this.errorMessage('standard_day_rate')}</div> : ''}</span>
              <div className="helper">
                <span>{getSymbolFromCurrency(this.state.currency)}</span>
                <input
                  className="form-control"
                  type="text"
                  value={this.state.standard_day_rate}
                  onChange={this.updateState('standard_day_rate')} />
              </div>
            </label>
          </div>

          <div className="field field__helper">
            <label>
              <span>Standard Hourly Rate {this.errorMessage('standard_hourly_rate') ? <div className="error">{this.errorMessage('standard_hourly_rate')}</div> : ''}</span>
              <div className="helper">
                <span>{getSymbolFromCurrency(this.state.currency)}</span>
                <input
                  className="form-control"
                  type="text"
                  value={this.state.standard_hourly_rate}
                  onChange={this.updateState('standard_hourly_rate')} />
              </div>
            </label>
          </div>

          <div className="field field__helper">
            <label>
              <span>Monthly Revenue Target {this.errorMessage('monthly_revenue_target') ? <div className="error">{this.errorMessage('monthly_revenue_target')}</div> : ''}</span>
              <div className="helper">
                <span>{getSymbolFromCurrency(this.state.currency)}</span>
                <input
                  className="form-control"
                  type="text"
                  value={this.state.monthly_revenue_target}
                  onChange={this.updateState('monthly_revenue_target')} />
              </div>
            </label>
            <small>How much do you need to earn a month to pay your expenses and salary?</small>
          </div>

        </div>

        <div className="field field__buttons">
          { this.state.step === 1 ?
            <React.Fragment>
              <Link to="/login">Sign In</Link>
              <button
                className="btn btn-lg btn-primary pull-xs-right"
                onClick={this.nextStep}
                disabled={this.state.inProgress}>
                Continue
              </button>
            </React.Fragment>
            :
            <React.Fragment>

              <a
                href="#"
                onClick={this.prevStep}>
                Go Back
              </a>
              
              <button
                disabled={this.props.common.loading}
                className={this.props.common.loading ? 'btn btn__loading' : 'btn'}>
                <div className="loader loader__button"></div>
                <span>Create my account</span>
              </button>

            </React.Fragment>
          }
        </div>

        { this.props.errors && <div class="notice">We noticed a few errors...</div>}

      </form>
    );
  }
}

class Register extends React.Component {
  render() {
    return (

    <main>

      <section className="component__route component__route__register">

        <img src={images.logo} alt="Slate" />

        <RegisterForm
          errors={this.props.errors}
          common={this.props.common}
          onSubmitForm={this.props.onSubmitForm} />

        <span>Have any questions? <a href="mailto:hello@heyslate.com?subject=Slate">Email us</a></span>

      </section>

    </main>

    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Register);
