import ListErrors from './ListErrors';
import React from 'react';
import agent from '../agent';
import images from '../images';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import update from 'immutability-helper';
import {
  PASSWORD_RESET,
  PASSWORD_RESET_PAGE_UNLOADED
} from '../constants/actionTypes';

const mapStateToProps = state => ({ ...state.auth });

const mapDispatchToProps = dispatch => ({
  onSubmit: (token, password) =>
    dispatch({ type: PASSWORD_RESET, payload: agent.Password.reset(token, password) }),
  onUnload: () =>
    dispatch({ type: PASSWORD_RESET_PAGE_UNLOADED })
});

class ResetPassword extends React.Component {
  constructor() {
    super();
    this.submitForm = (token, password) => ev => {
      ev.preventDefault();
      this.props.onSubmit(token, password);
    };
    this.state = {
      password: '',
      token: ''
    };
  }

  componentDidMount() {
    const values = queryString.parse(this.props.location.search)
    const token = values.token
    this.updateStatewithValue('token', token)
  }

  updateState = field => ev => {

    let newData = update(this.state, {
      [field]: {$set: ev.target.value}
    })

    this.setState(newData);

    // cleanup
    newData = null;

  };

  updateStatewithValue(field, value) {
    
    let newData = update(this.state, {
      [field]: {$set: value}
    })

    this.setState(newData);
    
    // cleanup
    newData = null;

  };

  componentWillUnmount() {
    this.props.onUnload();
  }

  errorMessage(field) {
    if(this.props.errors && this.props.errors[field]){
      return this.props.errors[field];
    }
  };

  render() {
    return (

      <main>

        <section className="component__route component__route__login">

          <img src={images.logo} alt="Slate" />

          <ListErrors errors={this.props.errors} />

          <form onSubmit={this.submitForm(this.state.token, this.state.password)}>

            <h1>Reset your password.</h1>

            <div>

              <div className="field">

                <label>
                  <span>Password {this.errorMessage('password') ? <div className="error">{this.errorMessage('password')}</div> : ''}</span>
                  <input
                    type="password"
                    value={this.state.password}
                    onChange={this.updateState('password')} />
                </label>

              </div>
            
            </div>

            <div className="field field__buttons">

              <Link to="/login">Sign In</Link>

              <button
                className="btn btn-lg btn-primary pull-xs-right"
                type="submit"
                disabled={this.props.inProgress}>
                Reset Password
              </button>

            </div>

          </form>

        </section>

      </main>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
