import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import dateFns from 'date-fns';
import TaskList from './TaskList';
import ListErrors from '../ListErrors';
import update from 'immutability-helper';
import agent from '../../agent';
import {
  TASKS_PAGE_LOADED,
  TASKS_PAGE_UNLOADED,
  TASK_CREATE,
  TASK_COMPLETE,
  TASK_UPDATE
} from '../../constants/actionTypes';

const mapStateToProps = state => (
  {
    ...state.tasks,
    token: state.common.token,
    errors: state.tasks.errors
  }
);

const mapDispatchToProps = dispatch => ({
  onLoad: (payload) =>
    dispatch({ type: TASKS_PAGE_LOADED, payload: payload }),
  onUnload: () =>
    dispatch({  type: TASKS_PAGE_UNLOADED }),
  onSubmitForm: task =>
    dispatch({ type: TASK_CREATE, payload: agent.Tasks.create(task) }),
  removeTask: task =>
    dispatch({ type: TASK_COMPLETE, payload: agent.Tasks.complete(task.id) }),
  updateTask: task =>
    dispatch({ type: TASK_UPDATE, payload: agent.Tasks.update(task) }),
});

class TaskForm extends React.Component {
  constructor() {
    super();

    this.state = {
      id: '',
      text: ''
    };

    this.updateState = field => ev => {

      let newData = update(this.state, {
        [field]: {$set: ev.target.value}
      })
  
      this.setState(newData);
  
      // cleanup
      newData = null;

    };

    this.submitForm = ev => {
      ev.preventDefault();

      this.props.onSubmitForm(this.state);

      let newData = {
        ...this.state,
        text: ''
      }

      this.setState(newData);

      // cleanup
      newData = null;

    };
  }

  componentWillMount() {
  }

  componentWillReceiveProps(nextProps) {
  }

  render() {
    return (
      <li>
        <span className="new"/>
        <form onSubmit={this.submitForm}>
          <input
            className="form-control"
            type="text"
            placeholder="Create a new reminder"
            value={this.state.text}
            onChange={this.updateState('text')} />
        </form>
      </li>
    );
  }
}

class Tasks extends React.Component {

  componentWillMount() {
    this.props.onLoad(
      agent.Tasks.all()
    );
  }

  componentWillUnmount() {
    this.props.onUnload();
  }

  render() {
    return (
      <React.Fragment>
        <ul>
          {!this.props.clients && (
            <li>
              <span className="new"></span>
              <Link to="/clients">Create your first client</Link>
            </li>
          )}
          {!this.props.amountAgreed && (
            <li>
              <span className="new"></span>
              <Link
                to={{
                  pathname: '/month/' + dateFns.format(this.props.activeMonth, 'MMMM/YYYY').toLowerCase(),
                  state: {
                    trigger: 'click'
                  }
                }}>Add some work to your schedule</Link>
            </li>
          )}
          <TaskList
            removeTask={this.props.removeTask}
            updateTask={this.props.updateTask}
            tasks={this.props.tasks} />
          <ListErrors errors={this.props.errors}></ListErrors>
          <TaskForm
            onSubmitForm={this.props.onSubmitForm} />
        </ul>
      </React.Fragment>
    );

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Tasks);
