import ListErrors from './ListErrors';
import React from 'react';
import agent from '../agent';
import images from '../images';
import { connect } from 'react-redux';
import queryString from 'query-string';
import update from 'immutability-helper';
import {
  UNSUBSCRIBE,
  CHECK_UNSUBSCRIBE_TOKEN,
  UNSUBSCRIBE_UNLOADED
} from '../constants/actionTypes';

const mapStateToProps = state => ({ ...state.auth });

const mapDispatchToProps = dispatch => ({
  onSubmit: (token) =>
    dispatch({ type: UNSUBSCRIBE, payload: agent.Unsubscribe.unsubscribe(token) }),
  onLoad: (token) =>
    dispatch({ type: CHECK_UNSUBSCRIBE_TOKEN, payload: agent.Unsubscribe.get(token) }),
  onUnload: (token) =>
    dispatch({ type: UNSUBSCRIBE_UNLOADED })
});

class Unsubscribe extends React.Component {
  constructor() {
    super();
    this.submitForm = (token) => ev => {
      ev.preventDefault();
      this.props.onSubmit(token);
    };
    this.state = {
      token: '',
      email: ''
    };
  }

  componentDidMount() {
    const values = queryString.parse(this.props.location.search)
    const token = values.token
    this.updateStatewithValue('token', token)
    this.props.onLoad(token);
  }

  updateStatewithValue(field, value) {

    let newData = update(this.state, {
      [field]: {$set: value}
    })

    this.setState(newData);
    
    // cleanup
    newData = null;

  };

  componentWillUnmount() {
    this.props.onUnload();
  }

  errorMessage(field) {
    if(this.props.errors && this.props.errors[field]){
      return this.props.errors[field];
    }
  };

  render() {
    if(this.props.unsubscribe_email === undefined){
      if(this.props.unsubscribed === undefined){
        return (
          <main>

            <section className="component__route component__route__login">

              <img src={images.logo} alt="Slate" />

              <h1>Sorry there is no user linked to the unsubscribe link you clicked on.</h1>

              <p>Please contact hello@heyslate.com and we will be able to help you.</p>

            </section>

          </main>
        );
      }else{
        return (
          <main>

            <section className="component__route component__route__login">

              <img src={images.logo} alt="Slate" />

              <h1>You have been unsubscribed from all Slate emails.</h1>

            </section>

          </main>
        );
      }
    }else{
      return (

        <main>

          <section className="component__route component__route__login">

            <img src={images.logo} alt="Slate" />

            <ListErrors errors={this.props.errors} />

            <form onSubmit={this.submitForm(this.state.token)}>

              <h1>Are you sure you want to unsubscribe { this.props.unsubscribe_email } from all Slate emails?</h1>

              <div className="field field__buttons">

                <button
                  className="btn btn-lg btn-primary pull-xs-right"
                  type="submit"
                  disabled={this.props.inProgress}>
                  Yes, please unsubscribe me from all emails.
                </button>

              </div>

            </form>

          </section>

        </main>
      );
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Unsubscribe);
