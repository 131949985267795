import React from 'react';
import AdminUserItem from './AdminUserItem';

const AdminUserList = props => {

  if (!props.users) {
    return false;
  }

  return (
    <table>
      <thead>
       <tr>
         <th>Name</th>
         <th>Email</th>
         <th>State</th>
         <th>Registered At</th>
         <th>Trial Ends At</th>
         <th>Last Seen At</th>
        </tr>
      </thead>
      <tbody>
        {
          props.users.map(user => {
            return (
              <AdminUserItem user={user} key={user.id} />
            );
          })
        }
      </tbody>
    </table>
    )
};

export default AdminUserList;
