import React from 'react';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import dateFns from 'date-fns';

import agent from '../../agent';
import {
  PAYMENT_SUMMARY,
  DESTROY_PAYMENT_SUMMARY
} from '../../constants/actionTypes';

const mapStateToProps = state => (
  {
    ...state,
    summary: state.invoices.summary
  }
);

const mapDispatchToProps = dispatch => ({
  getSummary: (invoice_id) => {
    dispatch({
      type: PAYMENT_SUMMARY,
      payload: agent.Invoices.payment(invoice_id)
    })
  },
  destroySummary: () => {
    dispatch({ type: DESTROY_PAYMENT_SUMMARY })
  }
});

class InvoiceTime extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      displayTooltip: false
    }
    this.hideTooltip = this.hideTooltip.bind(this)
    this.showTooltip = this.showTooltip.bind(this)
  }

  hideTooltip (event) {

    this.props.destroySummary();

    this.setState({
      displayTooltip: false,
      top: null,
      left: null
    })

  }

  showTooltip (event) {

    this.props.getSummary(
      this.props.invoice_id
    );

    var bounds = event.currentTarget.getBoundingClientRect();
    var y = bounds.top + bounds.height + 8;
    var x = bounds.left;

    this.setState({
      displayTooltip: true,
      top: y,
      left: x
    })

  }

  formatPrice(price, currency) {

    return new Intl.NumberFormat(this.props.common.locale, {
      style: 'currency',
      currency: currency ? currency : this.props.common.currentUser.currency
    }).format(price)

  }

  render() {
    let message = this.props.message
    let position = this.props.position
    let size = this.props.size

    return (
      <>
        {this.state.displayTooltip &&
          (
            ReactDOM.createPortal(
              <div
                className="invoice_summary small"
                style={{top: this.state.top, left: this.state.left}}>
                  {this.props.summary ? (
                    <div className="invoice_summary__item">
                      { this.formatPrice(this.props.summary.gross_total_in_base_currency, this.props.summary.currency) } paid on {dateFns.format(this.props.summary.date_received, 'DD MMM, YYYY')}
                    </div>
                  ) : (
                    <div className="loader loader__small"></div>
                  )}
              </div>,
              document.querySelector('main')
            )
          )
        }
        <span
          className="cursor-default"
          onMouseOver={this.showTooltip}
          onMouseLeave={this.hideTooltip}>
            Paid
        </span>
      </>
    )

  }

}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceTime);
