import React from 'react';
import dateFns from 'date-fns';
import Tooltip from '../Tooltip';
import {ReactComponent as ErrorIcon} from '../../images/error_small.svg';
import {ReactComponent as NoteIcon} from '../../images/note.svg';

export class Item extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.day.id,
      state: props.day.state,
      can_be_invoiced: props.day.can_be_invoiced
    };
  }
  render() {
    const day = this.props.day;
    return (<div className={`item booked invoice ${day.state === 'invoiced' || day.state === 'overdue' ? 'invoiced' : ''}`}>
      <span className="acronym">
        {day.project && (
          <Tooltip message={`${day.project_title} Project`} position={'top'} size={'large'}>{day.project_title.substring(0, 2)}</Tooltip>
        )}
      </span>
      <span className="date">
        {dateFns.format(day.date, 'ddd D MMMM YYYY')} ({day.unit === 'days' ? 'All-day' : `${day.unit_value_formatted}`})
        {day.note && (<Tooltip message={`${day.note}`} position={'top'} size={'large'}><NoteIcon /></Tooltip>)}
      </span>
      <div className="status">
        {(day.fixed_price) ? '' : day.state}
      </div>
    </div>);
  }
}
