import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import dateFns from 'date-fns';
import _ from 'lodash';
import Tooltip from '../Tooltip';
import { Info } from '../Info';

const mapStateToProps = state => (
  {
   token: state.common.token,
   schedule: state.schedule,
   months: state.months,
   activeMonth: state.common.activeMonth,
   currentMonth: state.common.currentMonth,
   nextMonth: state.common.nextMonth,
   prevMonth: state.common.prevMonth,
   currentUser: state.common.currentUser,
   locale: state.common.locale
  }
);

const mapDispatchToProps = dispatch => ({
});

class Month extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      notifications: true,
      tab: 'clients'
    }

  }

  clearNotifications(e) {

    e.stopPropagation();

    this.setState(prevState => ({
      notifications: !prevState.notifications
    }));

  }

  switchTabs(e, tab) {

    e.stopPropagation();

    this.setState({
      ...this.state, // needed?
      tab: tab
    });

  }

  render() {

    if (!this.props.nextMonth || !this.props.months.data) {
      return (
        <div className="loader top-50"></div>
      );
    }

    let in_past = dateFns.isPast(dateFns.endOfMonth(this.props.months.activeMonth));
    let productivity = 0;
    let days_empty = 0;
    let revenue_percentage = 0;
    let revenue_missed_percentage = 0;
    let revenue_target_string;
    let total_earning_potential_string;
    let monthly_revenue_target_string;
    let chart = [];
    let standard_day_rate_string = '£' + (this.props.currentUser.standard_day_rate).toLocaleString(this.props.locale, {style: "decimal", minimumFractionDigits: 1});

    if (this.props.months.data) {

      productivity = this.props.months.data.productivity;
      total_earning_potential_string = this.props.months.data.total_earning_potential_string;
      revenue_percentage = this.props.months.data.revenue_percentage;
      revenue_missed_percentage = this.props.months.data.revenue_missed_percentage;
      monthly_revenue_target_string = this.props.months.data.monthly_revenue_target_string;
      revenue_target_string = this.props.months.data.revenue_target_string;

      if ( productivity >= 100 ) {

        chart['empty'] = '0%';
        chart['available'] = '0%';

      } else {

        days_empty = this.props.months.data.unworked_days

        chart['empty'] = (days_empty) ? _.round((100 * days_empty) / this.props.months.data.maximum_hours) + '%' : '0%';
        chart['available'] = (this.props.months.data.available_hours) ? _.round((100 * this.props.months.data.available_hours) / this.props.months.data.maximum_hours) + '%' : '0%';

      }

      chart['booked'] = productivity + '%';
      chart['holiday'] = (this.props.months.data.holidays) ? _.round((100 * this.props.months.data.holidays) / this.props.months.data.maximum_hours) + '%' : '0%';

      if (revenue_percentage > 100 ) {

        chart['revenue'] = _.round(100/revenue_percentage*100);
        chart['revenue_potential'] = 0;
        chart['revenue_missed'] = 0;
        chart['revenue_exceeded'] = 100 - chart['revenue'];

      } else if (revenue_percentage === 100 ) {

        chart['revenue'] = 100;
        chart['revenue_potential'] = 0;
        chart['revenue_missed'] = 0;
        chart['revenue_exceeded'] = 0;

      } else {

        chart['revenue'] = revenue_percentage;
        chart['revenue_missed'] = revenue_missed_percentage;
        chart['revenue_potential'] = 100 - chart['revenue'] - chart['revenue_missed'];
        chart['revenue_exceeded'] = 0;

      }

    }

    const header =
      <header>
        <h2>{dateFns.format(this.props.activeMonth, 'MMMM ')}
          <Link to={{
            pathname: '/year/' + this.props.months.data.business_year,
            state: {
              trigger: 'click'
            }
          }}>{dateFns.format(this.props.activeMonth, 'YYYY')}</Link>
        </h2>
        <span className="month__prev">
          <Tooltip message={dateFns.format(dateFns.subMonths(this.props.activeMonth, 1), 'MMM YYYY')} position={'bottom'} size={'large'}>
            <Link className="icon" to={{
              pathname: '/month/' + dateFns.format(dateFns.subMonths(this.props.activeMonth, 1), 'MMMM/YYYY').toLowerCase(),
              state: {
                trigger: 'click'
              }
            }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g strokeWidth="2" fill="#e12323" stroke="#e12323"><polyline fill="none" stroke="#e12323" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="11.5,0.5 4,8 11.5,15.5 "></polyline></g></svg>
            </Link>
          </Tooltip>
        </span>
        <span className="month__today">
          <Tooltip message={`Today`} position={'bottom'} size={'large'}>
            <Link className="icon" to={{
              pathname: '/month/' + dateFns.format(this.props.currentMonth, 'MMMM/YYYY').toLowerCase(),
              state: {
                trigger: 'click'
              }
            }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g strokeWidth="2" fill="#e12323" stroke="#e12323"><circle cx="8" cy="8" r="7" fill="none" stroke="#e12323" strokeLinecap="round" strokeLinejoin="round"></circle></g></svg>
            </Link>
          </Tooltip>
        </span>
        <span className="month__next last">
          <Tooltip message={dateFns.format(dateFns.addMonths(this.props.activeMonth, 1), 'MMM YYYY')} position={'bottom'} size={'large'}>
            <Link className="icon" to={{
              pathname: '/month/' + dateFns.format(dateFns.addMonths(this.props.activeMonth, 1), 'MMMM/YYYY').toLowerCase(),
              state: {
                trigger: 'click'
              }
            }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g strokeWidth="2" fill="#e12323" stroke="#e12323"><polyline fill="none" stroke="#e12323" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="4.5,0.5 12,8 4.5,15.5 "></polyline></g></svg>
            </Link>
          </Tooltip>
        </span>
      </header>

    return (
      <React.Fragment>

        { header }

        <section className="component__clients__sidebar">

          <div className="progress">

            <header>
              <h3>Revenue</h3>
              <span>{ (this.props.months.data) ? this.props.months.data.amount_revenue_string : 0 }</span>
            </header>

            <div className="bar">
              <div className="bar__exceeded" style={{width: this.props.months.data.amount_worked_percentage + '%' }}>
                <Tooltip message={`${this.props.months.data.amount_worked_string} earned so far this month`} position={'top'} size={'small'}></Tooltip>
              </div>
              <div className="bar__revenue" style={{width: this.props.months.data.amount_agreed_percentage + '%' }}>
                <Tooltip message={`${this.props.months.data.amount_agreed_string} more expected this month`} position={'top'} size={'small'}></Tooltip>
              </div>
              <div className="bar__empty" style={{width: chart['revenue_potential'] + '%' }}>
                <Tooltip message={`${revenue_target_string} short of your target`} position={'top'} size={'small'}></Tooltip>
              </div>
            </div>

            {this.props.months.data.monthly_revenue_target ?
              <small>{revenue_percentage}% of Monthly Revenue Target <Tooltip message={`${in_past ? 'You earned' : 'You\'re on track to earn' } ${this.props.months.data.amount_revenue_string} of your ${monthly_revenue_target_string} monthly target.`} position={'top'} size={'large'}><Info /></Tooltip></small>
              :
              <small>{revenue_percentage}% of Revenue Potential <Tooltip message={`${in_past ? 'You earned' : 'You\'re on track to earn' } ${this.props.months.data.amount_revenue_string} of ${total_earning_potential_string} potential revenue this month based on your standard day rate of ${standard_day_rate_string}`} position={'top'} size={'large'}><Info /></Tooltip></small>
            }
          </div>

          <div className="progress">

            <header>
              <h3>Productivity</h3>
              <span>{productivity}%</span>
            </header>

            <div className="bar">
              <div className="bar__booked" style={{width: chart['booked'] }}>
                <Tooltip message={`${this.props.months.data.dates_booked_count_string} booked`} position={'top'} size={'small'}></Tooltip>
              </div>
              <div className="bar__holiday" style={{width: chart['holiday'] }}>
                <Tooltip message={`${this.props.months.data.holidays} days holiday`} position={'top'} size={'small'}></Tooltip>
              </div>
              <div className="bar__available" style={{width: chart['available'] }}>
                <Tooltip message={`${this.props.months.data.available_time} free`} position={'top'} size={'small'}></Tooltip>
              </div>
              <div className="bar__empty" style={{width: chart['empty'] }}>
                <Tooltip message={`${this.props.months.data.unworked_days} days not worked`} position={'top'} size={'small'}></Tooltip>
              </div>
            </div>

            {in_past &&
              <small>You worked {this.props.months.data.dates_booked_count_string}  <Tooltip message={`There were ${this.props.months.data.total_workable_days} available days this month`} position={'top'} size={'large'}><Info /></Tooltip></small>
            }
            {!in_past && !_.isEmpty(this.props.months.data.available_time) &&
              <small>You have {this.props.months.data.available_time} free <Tooltip message={`You've booked ${this.props.months.data.dates_booked_count_string} of ${this.props.months.data.total_workable_days} available days this month`} position={'top'} size={'large'}><Info /></Tooltip></small>
            }

          </div>

          {(this.props.months.data.clients.length > 0) && (

            <div className="progress progress__list">

              <header>

              {this.props.months.data.projects.length > 0 ? (
                <div className="options">
                  <div className="list">
                    <button
                      className={this.state.tab === 'clients' ? 'selected' : ''}
                      onClick={(e) => this.switchTabs(e, 'clients')}>Clients</button>
                    <button
                      className={this.state.tab === 'projects' ? 'selected' : ''}
                      onClick={(e) => this.switchTabs(e, 'projects')}>Projects</button>
                  </div>
                </div>
              ) : (

                <h3 className="single">Clients</h3>

              )}

              </header>

              {this.state.tab === 'projects' ? (

                this.props.months.data.projects.map(function(project, i){

                  let next_work_at = project.next_work_at ? `Next working on <br/> ${project.next_work_at}` : 'No future work scheduled';

                  return (
                    <div className="item" key={i}>
                      <div className="item__info">
                        <span className="title">
                          <Link to={`/projects/${project.slug}`}>
                            <Tooltip message={ next_work_at } position={'top'} size={'small'}>{ project.name }</Tooltip>
                          </Link>
                          <small>({project.client_name})</small>
                        </span>
                        <span className="value">{ project.amount_worked } ({ project.revenue }{ (project.revenue != project.revenue_in_base_currency) ? `/${project.revenue_in_base_currency}` : '' })</span>
                      </div>
                    </div>
                  )

                })

              ) : (

                this.props.months.data.clients.map(function(client, i){

                  let next_work_at = client.next_work_at ? `Next working on <br/> ${client.next_work_at}` : 'No future work scheduled';

                  return (
                    <div className="item" key={i}>
                      <div className="item__info">
                        <span className="color" style={{backgroundColor: client.hex_colour }}></span>
                        <span className="title">
                          <Link to={`/clients/${client.slug}`}>
                            <Tooltip message={ next_work_at } position={'top'} size={'small'}>{ client.name }</Tooltip>
                          </Link>
                        </span>
                        <span className="value">{ client.amount_worked } ({ client.revenue }{ (client.revenue != client.revenue_in_base_currency) ? `/${client.revenue_in_base_currency}` : '' })</span>
                      </div>
                    </div>
                  )

                })

              )}

              {this.props.months.data.holidays === -1 &&
                <React.Fragment>
                <hr/>
                <div className="item">
                  <div className="item__info">
                    <span className="color"></span>
                    <span className="title">Holiday</span>
                    <span className="value">{this.props.months.data.holidays} Day{this.props.months.data.holidays > 1 && 's' }</span>
                  </div>
                </div>
                </React.Fragment>
              }

            </div>

          )}

          <div className="notifications">

            { (productivity >= 100) &&
              <div className={this.state.notifications ? 'alert' : 'alert alert__hidden'}>
                <div className="alert__title warning">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="#141414"><path d="M23.707,6.736,17.263.293A1,1,0,0,0,16.556,0H7.444a1,1,0,0,0-.707.293L.293,6.736A1,1,0,0,0,0,7.443v9.114a1,1,0,0,0,.293.707l6.444,6.443A1,1,0,0,0,7.444,24h9.112a1,1,0,0,0,.707-.293l6.444-6.443A1,1,0,0,0,24,16.557V7.443A1,1,0,0,0,23.707,6.736ZM13.645,5,13,14H11l-.608-9ZM12,20a2,2,0,1,1,2-2A2,2,0,0,1,12,20Z" fill="#141414"></path></g></svg>
                  <h3>You're overbooked!</h3>
                  <span onClick={this.clearNotifications.bind(this)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"><path d="M10.707,1.293a1,1,0,0,0-1.414,0L6,4.586,2.707,1.293A1,1,0,0,0,1.293,2.707L4.586,6,1.293,9.293a1,1,0,1,0,1.414,1.414L6,7.414l3.293,3.293a1,1,0,0,0,1.414-1.414L7.414,6l3.293-3.293A1,1,0,0,0,10.707,1.293Z" fill="#ffffff"></path></svg>
                  </span>
                </div>
                <div className="alert__text">
                  <p>If you can, we recommend moving some work to next month to make sure you're not overbooked.</p>
                </div>
              </div>
            }

            { (productivity > 80 && productivity < 100) &&
              <div className={this.state.notifications ? 'alert' : 'alert alert__hidden'}>
                <div className="alert__title warning">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="#141414"><path d="M21.855,18.482A12.85,12.85,0,0,1,20,11.994V8.006a8,8,0,0,0-16,0v3.982a12.851,12.851,0,0,1-1.855,6.494A1,1,0,0,0,3,20H21a1,1,0,0,0,.855-1.518Z" fill="#141414"></path> <path d="M13.007,23.825a3,3,0,0,0,1.819-1.819H9.174A3,3,0,0,0,13.007,23.825Z"></path></g></svg>
                  <h3>On the limit</h3>
                  <span onClick={this.clearNotifications.bind(this)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"><path d="M10.707,1.293a1,1,0,0,0-1.414,0L6,4.586,2.707,1.293A1,1,0,0,0,1.293,2.707L4.586,6,1.293,9.293a1,1,0,1,0,1.414,1.414L6,7.414l3.293,3.293a1,1,0,0,0,1.414-1.414L7.414,6l3.293-3.293A1,1,0,0,0,10.707,1.293Z" fill="#ffffff"></path></svg>
                  </span>
                </div>
                <div className="alert__text">
                  <p>Be careful taking on anymore big projects this month, you're close to overbooking yourself for {dateFns.format(this.props.activeMonth, 'MMMM YYYY')}.</p>
                </div>
              </div>
            }

            { (this.props.months.data.revenue_percentage < 60 && this.props.months.data.revenue_percentage > 1) &&
              <div className={this.state.notifications ? 'alert' : 'alert alert__hidden'}>
                <div className="alert__title">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="#141414"><path d="M23.58.424A1,1,0,0,0,22.819.13C8.791.862,3.609,13.358,3.559,13.484a1,1,0,0,0,.22,1.08l5.657,5.657a1,1,0,0,0,1.085.218c.125-.051,12.554-5.291,13.348-19.253A1,1,0,0,0,23.58.424Zm-8.166,10.99a2,2,0,1,1,0-2.828A2,2,0,0,1,15.414,11.414Z" fill="#141414"></path> <path d="M1.113,18.844a2.844,2.844,0,1,1,4.022,4.022C4.024,23.977,0,24,0,24S0,19.954,1.113,18.844Z"></path> <path id="color-2" d="M10.357,2.341A8.911,8.911,0,0,0,2.522,4.825a9.084,9.084,0,0,0-1.384,1.8,1,1,0,0,0,.155,1.215l1.989,1.99A26.623,26.623,0,0,1,10.357,2.341Z" fill="#141414"></path> <path id="color-3" d="M21.659,13.643a8.911,8.911,0,0,1-2.484,7.835,9.084,9.084,0,0,1-1.8,1.384,1,1,0,0,1-1.215-.155l-1.99-1.989A26.623,26.623,0,0,0,21.659,13.643Z" fill="#141414"></path></g></svg>
                  <h3>Good start {this.props.currentUser.first_name}!</h3>
                  <span onClick={this.clearNotifications.bind(this)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"><path d="M10.707,1.293a1,1,0,0,0-1.414,0L6,4.586,2.707,1.293A1,1,0,0,0,1.293,2.707L4.586,6,1.293,9.293a1,1,0,1,0,1.414,1.414L6,7.414l3.293,3.293a1,1,0,0,0,1.414-1.414L7.414,6l3.293-3.293A1,1,0,0,0,10.707,1.293Z" fill="#ffffff"></path></svg>
                  </span>
                </div>
                <div className="alert__text">
                  <p>You need to earn another {this.props.months.data.target_remaining_formatted} ({this.props.months.data.target_remaining_days_formatted} of work) to meet your monthly revenue target of {this.props.currentUser.monthly_revenue_target_formatted}.</p>
                </div>
              </div>
            }

            { (this.props.months.data.revenue_percentage > 60 && this.props.months.data.revenue_percentage < 100 && this.state.notifications) &&
              <div className={this.state.notifications ? 'alert' : 'alert alert__hidden'}>
                <div className="alert__title">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="#141414"><path fill="#141414" d="M17,16c-2.951,0-5.403-0.639-7-1.712c0,0.746,0,1.238,0,1.712c0,1.657,3.134,3,7,3s7-1.343,7-3 c0-0.474,0-0.966,0-1.712C22.403,15.361,19.951,16,17,16z"></path> <path fill="#141414" d="M17,21c-2.951,0-5.403-0.639-7-1.712c0,0.746,0,1.238,0,1.712c0,1.657,3.134,3,7,3s7-1.343,7-3 c0-0.474,0-0.966,0-1.712C22.403,20.361,19.951,21,17,21z"></path> <ellipse fill="#141414" cx="17" cy="11" rx="7" ry="3"></ellipse> <ellipse cx="7" cy="3" rx="7" ry="3"></ellipse> <path d="M8,17.973C7.673,17.989,7.341,18,7,18c-2.951,0-5.403-0.639-7-1.712C0,17.034,0,17.526,0,18 c0,1.657,3.134,3,7,3c0.34,0,0.673-0.014,1-0.034V17.973z"></path> <path d="M8,12.973C7.673,12.989,7.341,13,7,13c-2.951,0-5.403-0.639-7-1.712C0,12.034,0,12.526,0,13 c0,1.657,3.134,3,7,3c0.34,0,0.673-0.014,1-0.034V12.973z"></path> <path d="M9.92,7.766C9.018,7.916,8.042,8,7,8C4.049,8,1.597,7.361,0,6.288C0,7.034,0,7.526,0,8 c0,1.657,3.134,3,7,3c0.341,0,0.674-0.014,1.003-0.034C8.015,9.703,8.71,8.606,9.92,7.766z"></path></g></svg>
                  <h3>Nearly there {this.props.currentUser.first_name}!</h3>
                  <span onClick={this.clearNotifications.bind(this)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"><path d="M10.707,1.293a1,1,0,0,0-1.414,0L6,4.586,2.707,1.293A1,1,0,0,0,1.293,2.707L4.586,6,1.293,9.293a1,1,0,1,0,1.414,1.414L6,7.414l3.293,3.293a1,1,0,0,0,1.414-1.414L7.414,6l3.293-3.293A1,1,0,0,0,10.707,1.293Z" fill="#ffffff"></path></svg>
                  </span>
                </div>
                <div className="alert__text">
                  <p>You need to earn another {this.props.months.data.target_remaining_formatted} ({this.props.months.data.target_remaining_days_formatted} of work) to meet your monthly revenue target of {this.props.currentUser.monthly_revenue_target_formatted}.</p>
                </div>
              </div>
            }

            { (this.props.months.data.revenue_percentage >= 100 && this.state.notifications) &&
              <div className={this.state.notifications ? 'alert' : 'alert alert__hidden'}>
                <div className="alert__title">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="#141414"><path fill="#141414" d="M6.172,14.561C6.358,14.835,6.668,15,7,15h10c0.332,0,0.642-0.165,0.828-0.439s0.224-0.624,0.101-0.932l-2-5 C15.776,8.249,15.409,8,15,8H9C8.591,8,8.224,8.249,8.071,8.629l-2,5C5.948,13.937,5.986,14.286,6.172,14.561z"></path> <rect y="11" width="4" height="2"></rect> <rect x="3.929" y="2.929" transform="matrix(0.707 -0.7072 0.7072 0.707 -2.0415 4.93)" width="2" height="4"></rect> <rect x="11" width="2" height="4"></rect> <rect x="17.071" y="3.929" transform="matrix(0.7072 -0.707 0.707 0.7072 2.099 14.926)" width="4" height="2"></rect> <rect x="20" y="11" width="4" height="2"></rect> <path fill="#141414" d="M23.929,21.629l-2-5C21.776,16.249,21.409,16,21,16h-6c-0.409,0-0.776,0.249-0.929,0.629l-2,5 C12.023,21.749,12,22,12,22c0-0.125-0.023-0.251-0.071-0.371l-2-5C9.776,16.249,9.409,16,9,16H3c-0.409,0-0.776,0.249-0.929,0.629 l-2,5c-0.123,0.308-0.085,0.657,0.101,0.932S0.668,23,1,23h22c0.332,0,0.642-0.165,0.828-0.439S24.052,21.937,23.929,21.629z"></path></g></svg>
                  <h3>Awesome job {this.props.currentUser.first_name}!</h3>
                  <span onClick={this.clearNotifications.bind(this)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"><path d="M10.707,1.293a1,1,0,0,0-1.414,0L6,4.586,2.707,1.293A1,1,0,0,0,1.293,2.707L4.586,6,1.293,9.293a1,1,0,1,0,1.414,1.414L6,7.414l3.293,3.293a1,1,0,0,0,1.414-1.414L7.414,6l3.293-3.293A1,1,0,0,0,10.707,1.293Z" fill="#ffffff"></path></svg>
                  </span>
                </div>
                <div className="alert__text">
                  { in_past ?
                    <p>You smashed your monthly revenue target for {dateFns.format(this.props.activeMonth, 'MMMM YYYY')} by {this.props.months.data.revenue_exceeded_string}!</p>
                  :
                    <p>You're on target to smash your monthly revenue target for {dateFns.format(this.props.activeMonth, 'MMMM YYYY')} by {this.props.months.data.revenue_exceeded_string}</p>
                  }
                </div>
              </div>
            }

          </div>

        </section>

      </React.Fragment>
    )

  }

};

export default connect(mapStateToProps, mapDispatchToProps)(Month);
