import React from 'react';
export class Landing extends React.Component {
  render() {
    const icon = <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
      <g fill="none" stroke="currentColor" strokeWidth="2">
        <rect x="5" y="5" width="54" height="54" rx="5" />
        <path d="M5 39h17v8h20v-8h17M16 30h16M16 22h32M16 14h32" />
      </g>
    </svg>;
    return (!this.props.match.params.slug && (<div className="empty__list">
      {icon}
      <p>Choose a project...</p>
    </div>));
  }
}
