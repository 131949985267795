import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import React from 'react';
import configureStore, { history } from './configureStore'
import App from './components/App';

const store = configureStore(/* provide initial state if any */)

ReactDOM.render((
  <Provider store={store}>
    <App history={history} />
  </Provider>

), document.getElementById('root'));
