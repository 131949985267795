import React from 'react';
import { connect } from 'react-redux';
import AdminUserList from './AdminUserList';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import { history } from '../../../configureStore';

import agent from '../../../agent';
import {
  REQUEST_ADMIN_USERS,
  ADMIN_USERS_PAGE_LOADED,
  ADMIN_USERS_PAGE_UNLOADED
} from '../../../constants/actionTypes';

const mapStateToProps = state => (
  {
    ...state.admin_users,
    token: state.common.token
  }
);

const mapDispatchToProps = dispatch => ({
  onLoad: (payload) => {
    dispatch({ type: REQUEST_ADMIN_USERS })
    dispatch({ type: ADMIN_USERS_PAGE_LOADED, payload: payload })
  },
  onUnload: () =>
    dispatch({  type: ADMIN_USERS_PAGE_UNLOADED })
});

class Title extends React.Component {

  render() {
    return (
      <header>
        <h2>Admin</h2>
      </header>
    );
  }

}

class Option extends React.Component {

  render() {

    return (
      <li class={this.props.state[this.props.filter] === this.props.link && 'selected'}>
      <Link
        to={`?${this.props.filter}=${this.props.link}`}
        class="option">
          {this.props.children}
      </Link>
      </li>
    );
  }

}

class AdminUsers extends React.Component {

  componentWillMount() {
  }

  componentWillUnmount() {
    this.props.onUnload();
  }

  constructor() {
    super();
    this.submitForm = () => ev => {
      ev.preventDefault();
    };
    this.state = { 'order': 'last_seen_at', 'order_direction': 'DESC' };
  }

  componentDidMount() {
    const values = queryString.parse(this.props.location.search)
    const newState = Object.assign({}, this.state, values);
    this.setState(newState);
    this.props.onLoad(agent.AdminUsers.all(queryString.stringify(newState)));
  }

  componentDidUpdate(prevProps,prevState){

    if(prevProps.location.search !== this.props.location.search) {

      const values = queryString.parse(this.props.location.search)
      const newState = Object.assign({}, this.state, values);
      this.setState(newState);
      this.props.onLoad(agent.AdminUsers.all(queryString.stringify(newState)));

    }

  }

  resetFilter(filter) {

    history.push(`/admin/users?${filter}=`);

    this.setState({
      [filter]: ''
    })

  }

  render() {

    return (
      <React.Fragment>
      <section className="component__projects">
        <Title />
        <h3 className="title">State <span onClick={(e) => this.resetFilter('in_state')}>Clear</span></h3>
        <ul>
          <Option state={this.state} filter="in_state" link="trialing">Trialing</Option>
          <Option state={this.state} filter="in_state" link="purchased">Purchased</Option>
          <Option state={this.state} filter="in_state" link="trial_expired">Trial Expired</Option>
          <Option state={this.state} filter="in_state" link="cancelled">Cancelled</Option>
        </ul>
        <h3 className="title">Last Seen <span onClick={(e) => this.resetFilter('last_seen_within_the_last')}>Clear</span></h3>
        <ul>
          <Option state={this.state} filter="last_seen_within_the_last" link="day">Today</Option>
          <Option state={this.state} filter="last_seen_within_the_last" link="week">This Week</Option>
          <Option state={this.state} filter="last_seen_within_the_last" link="month">This Month</Option>
          <Option state={this.state} filter="last_seen_within_the_last" link="year">This Year</Option>
        </ul>
        <h3 className="title">Trial Expiring <span onClick={(e) => this.resetFilter('trial_ends_within_the_next')}>Clear</span></h3>
        <ul>
          <Option state={this.state} filter="trial_ends_within_the_next" link="day">Today</Option>
          <Option state={this.state} filter="trial_ends_within_the_next" link="week">This Week</Option>
          <Option state={this.state} filter="trial_ends_within_the_next" link="month">This Month</Option>
          <Option state={this.state} filter="trial_ends_within_the_next" link="year">This Year</Option>
        </ul>
        <h3 className="title">Work Scheduled For <span onClick={(e) => this.resetFilter('work_scheduled_within_the_next')}>Clear</span></h3>
        <ul>
          <Option state={this.state} filter="work_scheduled_within_the_next" link="day">Today</Option>
          <Option state={this.state} filter="work_scheduled_within_the_next" link="week">This Week</Option>
          <Option state={this.state} filter="work_scheduled_within_the_next" link="month">This Month</Option>
          <Option state={this.state} filter="work_scheduled_within_the_next" link="year">This Year</Option>
        </ul>
        <h3 className="title">No Work Scheduled For <span onClick={(e) => this.resetFilter('no_work_scheduled_within_the_next')}>Clear</span></h3>
        <ul>
          <Option state={this.state} filter="no_work_scheduled_within_the_next" link="day">Today</Option>
          <Option state={this.state} filter="no_work_scheduled_within_the_next" link="week">This Week</Option>
          <Option state={this.state} filter="no_work_scheduled_within_the_next" link="month">This Month</Option>
          <Option state={this.state} filter="no_work_scheduled_within_the_next" link="year">This Year</Option>
        </ul>
        <h3 className="title">Other Filters<span onClick={(e) => this.resetFilter('no_work')}>Clear</span></h3>
        <ul>
          <Option state={this.state} filter="no_work" link="true">No work ever added</Option>
        </ul>
        <h3 className="title">Order By <span onClick={(e) => this.resetFilter('order')}>Clear</span></h3>
        <ul>
          <Option state={this.state} filter="order" link="name">Name</Option>
          <Option state={this.state} filter="order" link="email">Email</Option>
          <Option state={this.state} filter="order" link="created_at">Registered At</Option>
          <Option state={this.state} filter="order" link="trial_ends_at">Trial Ends At</Option>
          <Option state={this.state} filter="order" link="last_seen_at">Last Seen At</Option>
        </ul>
        <h3 className="title">Order Direction <span onClick={(e) => this.resetFilter('order_direction')}>Clear</span></h3>
        <ul>
          <Option state={this.state} filter="order_direction" link="DESC">DESC</Option>
          <Option state={this.state} filter="order_direction" link="ASC">ASC</Option>
        </ul>
      </section>
      <section className="component__project">

        <section className="component__list">

          {(this.props.isFetching) ? (
              <div className="loading"><div className="loader"></div></div>
          ) : (
            ((typeof this.props.users === 'undefined') || this.props.users.length === 0) ? (
              <div className="empty__list">
                <p>No users found, try another search</p>
              </div>
            ) : (
              <div className="">
                <AdminUserList users={this.props.users} />
              </div>
            )
          )}

        </section>

      </section>
      </React.Fragment>
    )

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminUsers);
