import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux'
import agent from '../../agent';
import { Link } from 'react-router-dom';
import { showNotificationWithTimeout } from '../../components/Notifications';
import { get, isEmpty } from 'lodash';
import Tooltip from '../../components/Tooltip';

const Filter = (props) => {
  const node = useRef();

  const [open, setOpen] = useState(false);
  let loading = useSelector(state => get(state.common, 'loading', false));
  let notification = useSelector(state => get(state.common, 'notification', false));

  const handleScroll = e => {
    setOpen(false);
  }

  const handleChange = (filter, value, persist) => {
    props.onChange(filter,value)
    if (!persist) {
      setOpen(false);
    }
  };

  const handleClickOutside = e => {

    e.stopImmediatePropagation()

    let dropdown = e.composedPath().filter(function (el) {
      return el.id === 'dropdown__menu'
    })

    // inside click
    if (dropdown.length >= 1 || node.current.contains(e.target)) {
      return;
    }

    // outside click
    setOpen(false);

  };

  const onStateTransition = (type, invoice, state, message) => {
    props.dispatch({
      type: type,
      payload: agent.Invoices.transition_state(invoice.id, state).then((res) => {
        if(!res.body.errors) {
          showNotificationWithTimeout(props.dispatch, message);
          setOpen(false);
          if(state === 'submitted') {
            props.openOverlay('email_invoice', invoice)
          }
          if(state === 'deleted') {
            props.updateSummary()
          }
        }
        return res;
      })
    })
  }

  useEffect(() => {

    var element = node.current;

    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
      element.offsetParent.addEventListener("scroll", handleScroll);

      const dropdown_menu = document.querySelector('#dropdown__menu');
      const position_top = (element.offsetHeight + 8);

      // set default position
      dropdown_menu.style.top = position_top + 'px';

      // get bounds now dropdown has default position
      const dropdown_menu_bounds = dropdown_menu.getBoundingClientRect();

      // dropdown outside window, recalculate position
      if ( dropdown_menu_bounds.bottom > window.innerHeight ) {
        const position_recalc = dropdown_menu.offsetHeight + 8;
        dropdown_menu.style.top = -Math.abs(position_recalc) + 'px';
      }

      // finally, show dropdown (to stop position popping effect)
      dropdown_menu.style.visibility = 'visible';

    } else {
      document.removeEventListener("mousedown", handleClickOutside);
      element.offsetParent.removeEventListener("scroll", handleScroll);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      element.offsetParent.removeEventListener("scroll", handleScroll);
    };
  }, [open]);

  const pdfLink = (
    <a href={`/api/invoices/${props.invoice.id}/pdf`} download className="item">
      <span>Download PDF</span>
    </a>
  )

  const renderSwitch = e => {
    switch(props.invoice.state){
      case 'draft':
        return (
          <>
          <Link to={`/invoices/${props.invoice.id}/edit`} className="item">
            Edit Invoice
          </Link>
          { isEmpty(props.invoice.errors_preventing_submission) ? (
            <div onClick={() => {
              onStateTransition('INVOICE_UPDATE', props.invoice, 'submitted', false)
            }} className="item">Issue Invoice</div>
          ) : (
            <Tooltip message={`This invoice needs a few more things before you can mark it as sent.`} position={'top'} size={'large'}>
              <div className="item disabled">
                Issue Invoice
              </div>
            </Tooltip>
          ) }
          {pdfLink}
          <hr />
          <div onClick={() => {
            if (window.confirm("Are you sure you want to delete this invoice? It cannot be undone.")) {
              onStateTransition('INVOICE_REMOVE', props.invoice,'deleted', 'Invoice deleted')
            }
          }} className="item">Delete</div>
          </>
        )
      case 'submitted':
        return (
          <>
          <div onClick={() => {
            props.openOverlay('email_invoice', props.invoice)
          }} className="item">Email Invoice</div>
          <div onClick={() => {
            props.openOverlay('mark_as_paid', props.invoice)
          }} className="item">Mark as paid</div>
          {pdfLink}
          <hr />
          <div onClick={() => {
            if (window.confirm("Are you sure you want to void this invoice? It cannot be undone.")) {
              onStateTransition('INVOICE_UPDATE', props.invoice,'voided', 'Invoice marked as void')
            }
          }} className="item">Void Invoice</div>
          </>
        )
      case 'paid':
      case 'voided':
        return (
          <>
          {pdfLink}
          </>
        )
      default:
        return (
          <div>No Options</div>
        )
    }
  }

  return (
    <div ref={node} className={props.disabled ? 'dropdown disabled' : 'dropdown'}>
      <button className="dropdown-toggler" onClick={e => setOpen(!open)}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
          <path d="M0 0h24v24H0z" stroke="none"/>
          <circle cx="12" cy="12" r="1"/>
          <circle cx="12" cy="19" r="1"/>
          <circle cx="12" cy="5" r="1"/>
        </svg>
      </button>
      {open && (
        ReactDOM.createPortal(
          <div
          style={{position: 'absolute', visibility: 'hidden', right: '1px' }}
            id="dropdown__menu" className={`dropdown__menu ` + props.content}>
            {loading && (
              <div className="loading"><div className="loader loader__small"></div></div>
            )}
            <div className="list">
              {renderSwitch(props.invoice.state)}
            </div>
          </div>, node.current
        )
      )}
      { notification && <div className="success">{notification}</div>}
    </div>
  );
};

export default Filter;
