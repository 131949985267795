import React from 'react';
import { Switch, Route } from 'react-router';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import Overlay from './Overlay';
import Filter from './Filter';
import agent from '../../agent';
import { Landing } from './Landing';
import Project from './Project';
import Tooltip from '../Tooltip';

import {
  PROJECT_UPDATE,
  PROJECT_UNLOADED,
  SCHEDULE_INVOICE,
  SCHEDULE_UNDO_INVOICE,
  OPEN_PROJECTS_OVERLAY,
  CLOSE_PROJECTS_OVERLAY,
  PROJECTS_PAGE_LOADED
} from '../../constants/actionTypes';

const mapStateToProps = state => (
  {
    ...state.projects,
    token: state.common.token,
    overlay: state.projects.overlay,
    clients: state.clients.clients
  }
);

const mapDispatchToProps = dispatch => ({

  transitionState: (project, state) => {
    dispatch({ type: PROJECT_UPDATE, payload: agent.Projects.transition_state(project, state) })
  },

  invoiceWork: (project, day) => {

    dispatch({
      type: SCHEDULE_INVOICE,
      payload: agent.Schedule.transition_state(day, 'invoiced' )
      .then((res) => {
        if(!res.body.errors) {
          dispatch({
            type: PROJECT_UPDATE, payload: agent.Projects.update(project)
          });
        }
        return res;
      })
    })

  },

  rollbackInvoiceWork: (project, day) => {

    dispatch({
      type: SCHEDULE_UNDO_INVOICE,
      payload: agent.Schedule.rollback_transition_state(day)
      .then((res) => {
        if(!res.body.errors) {
          dispatch({
            type: PROJECT_UPDATE, payload: agent.Projects.update(project)
          });
        }
        return res;
      })
    })

  },

  openOverlay: () => {
    dispatch({ type: OPEN_PROJECTS_OVERLAY });
  },

  closeOverlay: () => {
    dispatch({ type: CLOSE_PROJECTS_OVERLAY });
  },

  unsetProject: () => {
    dispatch({ type: PROJECT_UNLOADED });
  },

  loadProjects: () => {
    dispatch({ type: PROJECTS_PAGE_LOADED, payload: agent.Projects.all() })
  }

});

class Projects extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      client: false,
      overlay: false,
      project: false,
      project_state: 'All',
      initialLoad: true
    }

    this.projectKeyPress = this.projectKeyPress.bind(this);
    this.newProjectButtonRef = React.createRef();

  }

  componentDidMount(){
    document.addEventListener("keydown", this.projectKeyPress);

    this.props.loadProjects();

    if (this.state.initialLoad && this.props.clients && this.props.projects && this.props.projects.length === 0) {
      this.newProjectButtonRef.current.click()
      this.setState({
        initialLoad: false
      });
    }

  }

  componentWillUnmount() {
    this.props.unsetProject();
    document.removeEventListener("keydown", this.projectKeyPress);
  }

  projectKeyPress(e){

    // new project shortcut
    if ( e.keyCode === 78 && !this.state.overlay ) {
      this.openOverlay(e, 'client')
    }

  }

  openOverlay(e, overlay, project=false) {

    e.preventDefault();
    e.stopPropagation();

    this.setState({
      overlay: overlay,
      project: project
    });

    this.props.openOverlay()

  }

  closeOverlay(e=false) {

    this.setState({
      overlay: false,
      project: false
    });

    this.props.closeOverlay()

  }

  updateFilter(filter, value) {

    this.setState({
      [filter]: value
    });

  }

  render() {

    if (typeof this.props.projects === 'undefined') {

      return (
        <div className="loading"><div className="loader"></div></div>
      )

    } else {

      let filtered_projects;

      const grouped_projects = _(this.props.projects)
        .groupBy(x => x.state)
        .map((value, key) => ({
          state: key,
          projects: _.orderBy(value, ['id'],['desc'])
        }))
        .sortBy(this.props.projects, function(element){
          var rank = {
            "active": 1,
            "pending": 2,
            "finished": 3,
            "cancelled": 4
          };
          return rank[element.state];
        })
        .value();

      if(this.state.project_state === 'All') {

        filtered_projects = grouped_projects;

      } else {

        filtered_projects = _.orderBy(this.props.projects.filter((el) => {
          return el.state === this.state.project_state.toLowerCase()
        }), ['id'],['desc']);

      }

      const plus =
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
          <g fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M6 1v10M1 6h10"/>
          </g>
        </svg>

      return (
        <React.Fragment>
        <section className="component__projects">
          <header className="filter">
            <h2>Projects</h2>
            <Filter
              border
              disabled={this.props.projects.length === 0 && true}
              filter={this.state.project_state}
              filters={['All','Active','Pending','Finished','Cancelled']}
              onChange={this.updateFilter.bind(this)} />
          </header>
          <ul>
            <li className="new">
              <a
                ref={this.newProjectButtonRef}
                className="option"
                onClick={(e) => this.openOverlay(e, 'client')}>
                  <span>New Project</span>
                  { plus }
              </a>
            </li>
            <li className="divider"></li>
            {this.props.projects.length === 0 && (
              <li className="note">Create your first project above.</li>
            )}
          </ul>

          {this.state.project_state === 'All' ? (

            grouped_projects.map(group => {
              return (
              <React.Fragment key={group.state}>
                <h3 className="title">{ group.state }</h3>
                <ul>
                {group.projects.map(project => {
                  return (
                    <li
                      key={project.id}
                      className={project.slug === this.props.match.params.slug ? 'selected' : ''}>
                      <Link to={`/projects/${project.slug}`} className="option">
                        <div className="title">
                          { project.title }
                          { project.issues.length > 0 && (<span className="issue"></span>) }
                        </div>
                        <span className="acronym">
                          <Tooltip message={project.client_name} position={'top'} size={'large'}>
                            {project.client_name.substring(0, 2)}
                          </Tooltip>
                        </span>
                      </Link>
                    </li>
                  )
                })}
                </ul>
              </React.Fragment>
              )
            })

          ) : (

            <React.Fragment>

              <h3 className="title">{ this.state.project_state }</h3>

              <ul>
              {filtered_projects.length === 0 ? (
                <li className="empty">Not projects found...</li>
              ) : (
                filtered_projects.map(project => {
                  return (
                    <li
                      key={project.id}
                      className={project.slug === this.props.match.params.slug ? 'selected' : ''}>
                      <Link to={`/projects/${project.slug}`} className="option">
                        <div className="title">
                          { project.title }
                          { project.issues.length > 0 && (<span className="issue"></span>) }
                        </div>
                        <span className="acronym">
                          <Tooltip message={project.client_name} position={'top'} size={'large'}>
                            {project.client_name.substring(0, 2)}
                          </Tooltip>
                        </span>
                      </Link>
                    </li>
                  )
                })
              )}
              </ul>

            </React.Fragment>

          )}

          {this.props.overlay && (
            <Overlay
              overlay={this.state.overlay}
              project={this.state.project}
              closeOverlay={(e) => this.closeOverlay(e)}></Overlay>
          )}
        </section>
        <section className="component__project">
          <Switch>
            <Route
              exact
              path={`/projects/`}
              render={(props) => <Landing {...this.props} />} />
            <Route
              exact
              path={`/projects/:slug`}
              render={(props) => <Project {...this.props} openOverlay={this.openOverlay.bind(this)} />} />
          </Switch>
        </section>
        </React.Fragment>
      )

    }

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Projects);
