import React, { useMemo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom';
import { get, isEmpty } from 'lodash'
import { InvoicesForm } from '../components/Invoices/InvoicesForm'
import * as QueryString from "query-string"
import dateFns from 'date-fns';
import agent from '../agent';
import Overlay from '../components/Invoices/Overlay';

const InvoiceNew = (props) => {

  const params = QueryString.parse(props.location.search);

  const dispatch = useDispatch()

  const currentUser = useSelector(state => get(state.common, 'currentUser', {}));

  let currency = params.currency || currentUser.currency;
  const projectId = params.project_id || null;

  const [clientId, updateClient] = useState(params.client_id);
  const clients = useSelector(state => get(state.clients, 'clients', []));
  let client = clients.find(cl => clientId == cl.id) || null;

  const [dataLoaded, setDataLoaded] = useState(false)
  const [works, setWorks] = useState([])
  const [projects, setProjects] = useState([])

  const invoiceLineItems = (currency=undefined) => {
    let lineItems = []
    const workInCurrency = works.filter((work) => { return work.currency === currency && work.project_id == projectId })

    if(!isEmpty(works)){
      lineItems = lineItems.concat(workInCurrency.map((work) => {
          return {description: work.description, subtotal: work.price, total: work.price * 1.2, unit_amount: work.unit_amount, quantity: work.quantity, tax_rate: 20, invoiceable_type: 'WorkDay', invoiceable_id: work.id}
       }))
    }

    const projectsInCurrency = projects.filter((project) => { return project.currency === currency && project.id == projectId })

    if(!isEmpty(projects)){
      lineItems = lineItems.concat(
        projectsInCurrency.map((project) => {
          return {description: project.title, subtotal: project.amount_uninvoiced, unit_amount: project.amount_uninvoiced, total: (project.amount_uninvoiced * 1.2).toFixed(2), quantity: 1, tax_rate: 20, invoiceable_type: 'Project', invoiceable_id: project.id}
        })
      )
    }

    if(isEmpty(lineItems)){
      lineItems.push({
        subtotal: 0, quantity: 1, tax_rate: 20, total: 0
      })
    }
    return lineItems
  }

  const newInvoice = {
    client_id: client ? client.id : null,
    client_name: client ? client.name : null,
    currency: currency,
    invoice_note: get(currentUser.business, 'invoice_note'),
    payment_details: get(currentUser.business, 'payment_details'),
    date: new Date(),
    payment_terms: 30,
    due_date: dateFns.addDays(new Date(), 30),
    invoice_line_items: invoiceLineItems(currency)
  }

  useEffect(() => {
      agent.Clients.invoiceable_works(clientId, projectId).then((res) =>  { setDataLoaded(true); setWorks(res.body) })
      agent.Clients.invoiceable_projects(clientId).then((res) =>  { setDataLoaded(true); setProjects(res.body) })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

    if(dataLoaded){
      return (
        <section className="component__route component__route__invoices">
          <header>
            <h2><Link to={'/invoices'}>Invoices</Link> / New</h2>
          </header>
          <section>
            <InvoicesForm
              dispatch={dispatch}
              invoice={newInvoice}
              params={params} />
          </section>
        </section>
      );
    }else{
      return (<div className="loading"><div className="loader"></div></div>)
    }
  }

export default InvoiceNew;
