import React from 'react';
export class Landing extends React.Component {
  render() {
    const icon = <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
      <g strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" fill="none" stroke="currentColor" strokeMiterlimit="10">
        <path d="M4 62h0c0-15.464 12.536-28 28-28h0c15.464 0 28 12.536 28 28v0" />
        <circle cx="32" cy="18" r="16" />
      </g>
    </svg>;
    return (!this.props.match.params.slug && (<div className="empty__list">
      {icon}
      <p>Choose a client...</p>
    </div>));
  }
}
