import React from 'react';
import { connect } from 'react-redux';
import dateFns from 'date-fns';
import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';
import Tooltip from '../Tooltip';
import { Info } from '../Info';
import _ from 'lodash';
import agent from '../../agent';
import {
  REQUEST_YEAR,
  YEAR_LOADED,
  YEAR_UNLOADED
} from '../../constants/actionTypes';
import {ReactComponent as PriceIcon} from '../../images/icons/price.svg';

const mapStateToProps = (state, ownProps) => ({
    ...state.years,
    ...state.projects,
    year: ownProps.match.params.year,
    currentUser: state.common.currentUser,
    currentMonth: state.common.currentMonth,
    currentYear: state.common.currentUser.current_year,
});

const mapDispatchToProps = dispatch => ({
  onLoad: (payload) => {
    dispatch({ type: REQUEST_YEAR })
    dispatch({ type: YEAR_LOADED, payload: payload })
  },
  onUnload: () =>
    dispatch({  type: YEAR_UNLOADED }),
});

class Year extends React.Component {

  componentWillMount() {

    this.props.onLoad(
      agent.Year.show(this.props.year)
    );

  }

  componentDidUpdate(prevProps,prevState) {

    if(prevProps.location.pathname !== this.props.location.pathname) {

      if(this.props.location.pathname.indexOf('/year/') >= 0) {

        let url_date = this.props.location.pathname.replace('/year/','');

        this.props.onLoad(
          agent.Year.show(url_date.replace('/',''))
        );

      }

    }

  }

  componentWillUnmount() {
    this.props.onUnload();
  }

  formatPrice(price) {

    return new Intl.NumberFormat(this.props.locale, {
      style: 'currency',
      currency: this.props.currentUser.currency
    }).format(price)

  }

  render() {

    if ((typeof this.props.data === 'undefined') || this.props.isFetching) {

      return (
        <div className="loading"><div className="loader"></div></div>
      )

    } else {

      let revenue_percentage = 0;
      let yearly_target = null;
      let yearly_revenue = 0;
      let target_remaining = 0;
      let in_past = dateFns.isPast(dateFns.endOfYear(this.props.year));

      if(this.props.data.amount_revenue && this.props.yearly_target){
        yearly_revenue = this.props.amount_revenue;
        yearly_target = this.props.yearly_target;
        revenue_percentage = Math.round(parseInt(yearly_revenue, 10) / yearly_target * 100);
        target_remaining = this.props.target_remaining_formatted;
      }

      if(this.props.data.name){

        return (
          <React.Fragment>
          <section className="component__route component__route__month">

            <header>
              <h2>{this.props.data.name}</h2>
              <span className="year__prev">
                <Tooltip message={`Prev Year`} position={'bottom'} size={'large'}>
                  <Link className="icon" to={{
                    pathname: '/year/' + this.props.data.previous_year + '/',
                    state: {
                      trigger: 'click'
                    }
                  }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g strokeWidth="2" fill="#e12323" stroke="#e12323"><polyline fill="none" stroke="#e12323" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="11.5,0.5 4,8 11.5,15.5 "></polyline></g></svg>
                  </Link>
                </Tooltip>
              </span>
              <span className="year__today">
                <Tooltip message={`This Year`} position={'bottom'} size={'large'}>
                  <Link className="icon" to={{
                    pathname: '/year/' + this.props.currentYear,
                    state: {
                      trigger: 'click'
                    }
                  }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g strokeWidth="2" fill="#e12323" stroke="#e12323"><circle cx="8" cy="8" r="7" fill="none" stroke="#e12323" strokeLinecap="round" strokeLinejoin="round"></circle></g></svg>
                  </Link>
                </Tooltip>
              </span>
              { this.props.data.next_year && (
                <span className="year__next last">
                  <Tooltip message={`Next Year`} position={'bottom'} size={'large'}>
                    <Link className="icon" to={{
                      pathname: '/year/' + this.props.data.next_year + '/',
                      state: {
                        trigger: 'click'
                      }
                    }}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g strokeWidth="2" fill="#e12323" stroke="#e12323"><polyline fill="none" stroke="#e12323" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="4.5,0.5 12,8 4.5,15.5 "></polyline></g></svg>
                    </Link>
                  </Tooltip>
                </span>
              )}
            </header>

            <section className="component">

              <div className="progress">

                <header>
                  <h3>Revenue</h3>
                  <span>{ this.props.data.amount_revenue_formatted }</span>
                </header>

                <div className="bar">
                  <div className="bar__exceeded" style={{width: this.props.data.amount_worked_percentage + '%' }}>
                    <Tooltip message={`${this.props.data.amount_worked_string} earned this year`} position={'top'} size={'small'}></Tooltip>
                  </div>
                  <div className="bar__revenue" style={{width: this.props.data.amount_agreed_percentage + '%' }}>
                    <Tooltip message={`${this.props.data.amount_agreed_string} more expected this year`} position={'top'} size={'small'}></Tooltip>
                  </div>
                  <div className="bar__empty">
                    <Tooltip message={`${this.props.data.target_remaining_formatted} short of your target`} position={'top'} size={'small'}></Tooltip>
                  </div>
                </div>

                <small>{ this.props.data.monthly_average_revenue_formatted } on average per month</small>

              </div>

              <div className="progress">

                <header>
                  <h3>{ this.props.data.target_remaining_days === 0 ?  'Target smashed by' : 'Target Remaining' }</h3>
                  <span>{ this.props.data.target_remaining_formatted }</span>
                </header>

                <div className="bar">
                  <div style={{width: '100%'}} className={ this.props.data.target_remaining_days === 0 ? 'bar__exceeded' : 'bar__empty' }></div>
                </div>

                <small>{ this.props.data.target_remaining_days === 0 ? 
                in_past ? `You smashed your target of ${this.props.data.yearly_target_formatted}` : `Includes projects you haven't scheduled yet`
                : `${this.props.data.target_remaining_days} days needed to reach this`}{ this.props.data.target_remaining_days > 0 && <Tooltip message={`This is based on your day rate of ${ this.props.currentUser.standard_day_rate_formatted}`} position={'top'} size={'large'}><Info /></Tooltip> }</small>

              </div>

              <div className="progress">

                <header>
                  <h3>Days Worked</h3>
                  <span>{ this.props.data.number_of_days_worked }</span>
                </header>

                <div className="bar">
                <div className="bar__booked" style={{width: ( this.props.data.number_of_days_worked / this.props.data.total_workable_days * 100 ) + '%' }}></div>
                <div className="bar__empty"></div>
                </div>

                <small>Average day rate of { this.props.data.average_day_rate_formatted }</small>

              </div>

              <div className="progress">

                <header>
                  <h3>Holidays</h3>
                  <span>{ this.props.data.number_of_days_holiday }</span>
                </header>

                <div className="bar">
                  <div className="bar__booked" style={{width: ( this.props.data.number_of_days_holiday / this.props.data.total_workable_days * 100 ) + '%' }}></div>
                  <div className="bar__empty"></div>
                </div>

                <small>{this.props.data.number_of_days_unworked_to_date} days not worked <Tooltip message={`These are days you were available but had no work`} position={'top'} size={'large'}><Info /></Tooltip></small>

              </div>

              <div className="progress progress__list">

                <header>

                  <h3>Top Clients</h3>

                </header>

                {this.props.data.client_breakdown.length === 0 && <div className="empty"><p>You haven't added any work for this year.</p></div>}

                {this.props.data.client_breakdown.map(function(client, i){
                  return (
                    <div className="item" key={i}>
                      <div className="item__info">
                        <span className="color" style={{backgroundColor: client.hex_colour }}></span>
                        <span className="title">
                          <Link to={`/clients/${client.client_slug}`}>
                            { client.client_name }
                          </Link>
                        </span>
                        <span className="value">{ client.revenue_formatted }</span>
                      </div>
                    </div>
                  )
                })}

              </div>

              <div className="notifications">

                { (this.props.data.revenue_percentage < 60 && this.props.data.revenue_percentage > 1) &&
                  <div className="alert">
                    <div className="alert__title">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="#141414"><path d="M23.58.424A1,1,0,0,0,22.819.13C8.791.862,3.609,13.358,3.559,13.484a1,1,0,0,0,.22,1.08l5.657,5.657a1,1,0,0,0,1.085.218c.125-.051,12.554-5.291,13.348-19.253A1,1,0,0,0,23.58.424Zm-8.166,10.99a2,2,0,1,1,0-2.828A2,2,0,0,1,15.414,11.414Z" fill="#141414"></path> <path d="M1.113,18.844a2.844,2.844,0,1,1,4.022,4.022C4.024,23.977,0,24,0,24S0,19.954,1.113,18.844Z"></path> <path id="color-2" d="M10.357,2.341A8.911,8.911,0,0,0,2.522,4.825a9.084,9.084,0,0,0-1.384,1.8,1,1,0,0,0,.155,1.215l1.989,1.99A26.623,26.623,0,0,1,10.357,2.341Z" fill="#141414"></path> <path id="color-3" d="M21.659,13.643a8.911,8.911,0,0,1-2.484,7.835,9.084,9.084,0,0,1-1.8,1.384,1,1,0,0,1-1.215-.155l-1.99-1.989A26.623,26.623,0,0,0,21.659,13.643Z" fill="#141414"></path></g></svg>
                      <h3>Good start {this.props.currentUser.first_name}!</h3>
                      <span onClick={this.clearNotifications}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"><path d="M10.707,1.293a1,1,0,0,0-1.414,0L6,4.586,2.707,1.293A1,1,0,0,0,1.293,2.707L4.586,6,1.293,9.293a1,1,0,1,0,1.414,1.414L6,7.414l3.293,3.293a1,1,0,0,0,1.414-1.414L7.414,6l3.293-3.293A1,1,0,0,0,10.707,1.293Z" fill="#ffffff"></path></svg>
                      </span>
                    </div>
                    <div className="alert__text">
                      <p>You need to earn another {this.props.data.target_remaining_formatted} ({this.props.data.target_remaining_days_formatted} of work) to meet your annual revenue target.</p>
                    </div>
                  </div>
                }

                { (this.props.data.revenue_percentage > 60 && this.props.data.revenue_percentage < 100) &&
                  <div className="alert">
                    <div className="alert__title">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="#141414"><path fill="#141414" d="M17,16c-2.951,0-5.403-0.639-7-1.712c0,0.746,0,1.238,0,1.712c0,1.657,3.134,3,7,3s7-1.343,7-3 c0-0.474,0-0.966,0-1.712C22.403,15.361,19.951,16,17,16z"></path> <path fill="#141414" d="M17,21c-2.951,0-5.403-0.639-7-1.712c0,0.746,0,1.238,0,1.712c0,1.657,3.134,3,7,3s7-1.343,7-3 c0-0.474,0-0.966,0-1.712C22.403,20.361,19.951,21,17,21z"></path> <ellipse fill="#141414" cx="17" cy="11" rx="7" ry="3"></ellipse> <ellipse cx="7" cy="3" rx="7" ry="3"></ellipse> <path d="M8,17.973C7.673,17.989,7.341,18,7,18c-2.951,0-5.403-0.639-7-1.712C0,17.034,0,17.526,0,18 c0,1.657,3.134,3,7,3c0.34,0,0.673-0.014,1-0.034V17.973z"></path> <path d="M8,12.973C7.673,12.989,7.341,13,7,13c-2.951,0-5.403-0.639-7-1.712C0,12.034,0,12.526,0,13 c0,1.657,3.134,3,7,3c0.34,0,0.673-0.014,1-0.034V12.973z"></path> <path d="M9.92,7.766C9.018,7.916,8.042,8,7,8C4.049,8,1.597,7.361,0,6.288C0,7.034,0,7.526,0,8 c0,1.657,3.134,3,7,3c0.341,0,0.674-0.014,1.003-0.034C8.015,9.703,8.71,8.606,9.92,7.766z"></path></g></svg>
                      <h3>Nearly there {this.props.currentUser.first_name}!</h3>
                      <span onClick={this.clearNotifications}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"><path d="M10.707,1.293a1,1,0,0,0-1.414,0L6,4.586,2.707,1.293A1,1,0,0,0,1.293,2.707L4.586,6,1.293,9.293a1,1,0,1,0,1.414,1.414L6,7.414l3.293,3.293a1,1,0,0,0,1.414-1.414L7.414,6l3.293-3.293A1,1,0,0,0,10.707,1.293Z" fill="#ffffff"></path></svg>
                      </span>
                    </div>
                    <div className="alert__text">
                      <p>You need to earn another {this.props.data.target_remaining_formatted} ({this.props.data.target_remaining_days_formatted} of work) to meet your annual revenue target.</p>
                    </div>
                  </div>
                }

                { this.props.data.revenue_percentage >= 100 &&
                  <div className="alert">
                    <div className="alert__title">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="#141414"><path fill="#141414" d="M6.172,14.561C6.358,14.835,6.668,15,7,15h10c0.332,0,0.642-0.165,0.828-0.439s0.224-0.624,0.101-0.932l-2-5 C15.776,8.249,15.409,8,15,8H9C8.591,8,8.224,8.249,8.071,8.629l-2,5C5.948,13.937,5.986,14.286,6.172,14.561z"></path> <rect y="11" width="4" height="2"></rect> <rect x="3.929" y="2.929" transform="matrix(0.707 -0.7072 0.7072 0.707 -2.0415 4.93)" width="2" height="4"></rect> <rect x="11" width="2" height="4"></rect> <rect x="17.071" y="3.929" transform="matrix(0.7072 -0.707 0.707 0.7072 2.099 14.926)" width="4" height="2"></rect> <rect x="20" y="11" width="4" height="2"></rect> <path fill="#141414" d="M23.929,21.629l-2-5C21.776,16.249,21.409,16,21,16h-6c-0.409,0-0.776,0.249-0.929,0.629l-2,5 C12.023,21.749,12,22,12,22c0-0.125-0.023-0.251-0.071-0.371l-2-5C9.776,16.249,9.409,16,9,16H3c-0.409,0-0.776,0.249-0.929,0.629 l-2,5c-0.123,0.308-0.085,0.657,0.101,0.932S0.668,23,1,23h22c0.332,0,0.642-0.165,0.828-0.439S24.052,21.937,23.929,21.629z"></path></g></svg>
                      <h3>Awesome job {this.props.currentUser.first_name}!</h3>
                      <span onClick={this.clearNotifications}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"><path d="M10.707,1.293a1,1,0,0,0-1.414,0L6,4.586,2.707,1.293A1,1,0,0,0,1.293,2.707L4.586,6,1.293,9.293a1,1,0,1,0,1.414,1.414L6,7.414l3.293,3.293a1,1,0,0,0,1.414-1.414L7.414,6l3.293-3.293A1,1,0,0,0,10.707,1.293Z" fill="#ffffff"></path></svg>
                      </span>
                    </div>
                    <div className="alert__text">
                      { in_past ?
                        <p>You smashed your annual revenue target for {this.props.data.name} by {this.props.data.target_remaining_formatted}!</p>
                      :
                        <p>You're on target to smash your annual revenue target for {this.props.data.name} by {this.props.data.target_remaining_formatted}</p>
                      }
                    </div>
                  </div>
                }

              </div>

            </section>

          </section>

          <section className="component__schedule">

            <header>

              <h2>Monthly breakdown</h2>

            </header>

            <section className="component__list">

              <div className="blocks blocks__year">

                {this.props.data.month_breakdown.map(hash => {

                  let days = [];
                  let month = dateFns.parse('1 ' + hash.month_name);
                  let day = dateFns.startOfWeek(month, {weekStartsOn: 1});
                  let revenue_percentage = 0;

                  if (hash.revenue >= 1) {
                    revenue_percentage = hash.difference_from_target_width_percentage
                  }

                  let dayClass =  classNames({
                    month: true,
                    empty: revenue_percentage === 0,
                    outside: revenue_percentage <= 40,
                    inside: revenue_percentage > 40,
                    above: hash.difference_from_target > 0,
                    below: hash.difference_from_target < 0
                  })

                  while (day <= dateFns.endOfMonth(month)) {
                    days.push({
                      key: dateFns.format(day, 'DDMMYY'),
                      day: day
                    });
                    day = dateFns.addDays(day, 1);
                  }

                  var headers = ['M','T','W','T','F','S','S']

                  return (
                    <div
                      key={month}
                      className={dayClass}>
                      <h3>
                        <Link to={{
                          pathname: `/month/${hash.month}/${hash.year}`,
                          state: {
                            trigger: 'click'
                          }
                        }}>
                          { hash.month_name}
                        </Link>
                        <span>{hash.revenue_string}</span>
                      </h3>
                      <div className="progress progress__comparison">
                        <div className="bar">
                          <em>No work added</em>
                          {hash.difference_from_target < 0 &&
                            <span>{ hash.difference_from_target_formatted.replace('-','') } { hash.difference_from_target_formatted > 0 ? 'above' : 'below' } target</span>
                          }
                          <div style={{width: hash.difference_from_target_width_percentage + '%' }}></div>
                          {hash.difference_from_target > 0 &&
                            <span>{ hash.difference_from_target_formatted } above target</span>
                          }
                        </div>
                      </div>
                    </div>
                  )

                })}
              </div>

            </section>

          </section>

          </React.Fragment>

        )

      }

    }

  }

}

export default connect(mapStateToProps, mapDispatchToProps)(Year);
