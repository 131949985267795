import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import dateFns from 'date-fns';
import Tooltip from '../Tooltip';
import { Info } from '../Info';
import { ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';

export class Project extends React.Component {

  render() {

    const data = [
      { name: 'worked', value: this.props.project.amount_worked_percentage },
      { name: 'remaining', value: this.props.project.estimate_remaining_percentage }
    ];
    const COLORS = ['#1381FF', '#78b0fd'];

    return (
      <Link to={`/projects/${this.props.project.slug}`} className="option">
        <div className="project">

          <div className="content">

            <h3>{this.props.project.title} <span>({this.props.project.state})</span></h3>

            <p>{this.props.project.billing_type === 'fixed_price' ? `${this.props.project.billing_type_formatted} of ${this.props.project.amount_formatted}` : this.props.project.billing_type_formatted}</p>

            {this.props.project.billing_type === 'fixed_price' ? (
              <p>Earned {this.props.project.amount_revenue_string}
                {this.props.project.state !== 'finished' && (
                  <Tooltip message={`We only track the amount you've scheduled until this project is finished.`} position={'top'} size={'large'}><Info /></Tooltip>
                )}
              </p>
            ) : (
              <p>Earned {this.props.project.amount_revenue_string}</p>
            )}

          </div>

          {this.props.project.billing_type === 'fixed_price' && (
            <Tooltip message={`You've worked ${this.props.project.amount_worked_percentage}% of your estimate`} position={'top'} size={'large'}>
              <ResponsiveContainer width={144} height={72}>
                <PieChart>
                  <Pie
                    isAnimationActive={false}
                    data={data}
                    startAngle={180}
                    endAngle={0}
                    cy={72}
                    innerRadius={56}
                    outerRadius={72}
                    paddingAngle={0}
                    dataKey="value"
                  >
                    {
                      data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} strokeWidth={0} />)
                    }
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </Tooltip>
          )}

        </div>
      </Link>
    );
  }
}
