import {
  REQUEST_INVOICES,
  INVOICE_CREATE,
  INVOICE_LOADED,
  INVOICE_UNLOADED,
  INVOICE_UPDATE,
  INVOICE_REMOVE,
  INVOICE_PAYMENT_CREATE,
  INVOICES_PAGE_LOADED,
  INVOICES_PAGE_UNLOADED,
  OPEN_INVOICES_OVERLAY,
  CLOSE_INVOICES_OVERLAY,
  TIME_SUMMARY,
  DESTROY_TIME_SUMMARY,
  PAYMENT_SUMMARY,
  DESTROY_PAYMENT_SUMMARY
} from '../constants/actionTypes';
import update from 'immutability-helper';

export default (state = { invoices:[] }, action) => {

  switch (action.type) {
    case REQUEST_INVOICES:
      //console.log(action)
      return {
        ...state,
        isFetching: true
      }
    case INVOICES_PAGE_LOADED:
      // console.log(action)
      return {
        ...state,
        invoices: action.payload.body,
        //isFetching: false,
        //errors: false
      };
    case INVOICES_PAGE_UNLOADED:
      //console.log(action)
      return {
        ...state
      };
    case INVOICE_LOADED:
      //console.log(action)
      return {
        ...state,
        invoice: action.payload.body,
        isFetching: false
      };
    case INVOICE_UNLOADED:
      //console.log(action)
      return {
        ...state,
        invoice: false
      };
    case INVOICE_CREATE:
      if(action.error){
        return {
          ...state,
          errors: action.payload.errors
        };
      }else{
        return {
          ...state,
          invoices: [...state.invoices, action.payload.body],
          errors: false
        };
      };
    case INVOICE_UPDATE:
      //console.log(action)
      if(action.error){
        return {
          ...state,
          errors: action.payload.errors
        };
      }else{
        return {
          ...state,
          invoice: action.payload.body,
          invoices: state.invoices.map(function(value){
            return value.id === action.payload.body.id ? action.payload.body : value
          }),
          errors: false
        };
      };
    case INVOICE_REMOVE:
      //console.log(action)
      if(action.error){
        return {
          ...state,
          errors: action.payload.errors
        };
      }else{
        return {
          ...state,
          invoices: [...state.invoices].filter(function(value){
            return value.id !== action.payload.body.id;
          }),
          overlay: false,
          invoice: false,
          errors: false
        };
      };
    case INVOICE_PAYMENT_CREATE:
      let index = state.invoices.findIndex(invoice => invoice.id === action.payload.body.invoice_id);
      let newData = update(state.invoices, {
        [index]: {
          'state': {$set: 'paid'},
        }
      })
      return {
        ...state,
        invoices: newData
      }
    case OPEN_INVOICES_OVERLAY:
      //console.log(action)
      return {
        ...state,
        overlay: true
      }
    case CLOSE_INVOICES_OVERLAY:
      //console.log(action)
      return {
        ...state,
        overlay: false,
        errors: false
      }
    case TIME_SUMMARY:
      //console.log(action)
      return {
        ...state,
        summary: action.payload.body
      };
    case DESTROY_TIME_SUMMARY:
      //console.log(action)
      return {
        ...state,
        summary: false
      };
    case PAYMENT_SUMMARY:
      //console.log(action)
      return {
        ...state,
        summary: action.payload.body
      };
    case DESTROY_PAYMENT_SUMMARY:
      //console.log(action)
      return {
        ...state,
        summary: false
      };
    default:
      return state;
  }
};
