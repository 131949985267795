import {
  MONTH_LOADED,
  MONTH_CACHED
} from '../constants/actionTypes';

const defaultState = {
  loading: false
};

export default (state = defaultState, action) => {

  switch (action.type) {
    case MONTH_LOADED:
      //console.log(action)
      return {
        ...state,
        activeMonth: action.month,
        data: action.payload.body,
        months: {...state.months, [action.month_string]: action.payload.body}
      };
    case MONTH_CACHED:
      //console.log(action)
      return {
        ...state,
        activeMonth: action.month,
        data: action.payload
      };
    default:
      //console.log(action)
      return {
        ...state
      };
  }
};
