import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux'
import agent from '../../agent';
import { Link } from 'react-router-dom';
import { showNotificationWithTimeout } from '../../components/Notifications';
import { get, isEmpty } from 'lodash';
import {ReactComponent as LockIcon} from '../../images/lock.svg';
import Tooltip from '../Tooltip';

const ClientOptions = (props) => {
  const node = useRef();

  const [open, setOpen] = useState(false);
  let loading = useSelector(state => get(state.common, 'loading', false));
  let notification = useSelector(state => get(state.common, 'notification', false));

  const handleScroll = e => {
    setOpen(false);
  }

  const handleChange = (filter, value, persist) => {
    props.onChange(filter,value)
    if (!persist) {
      setOpen(false);
    }
  };

  const handleClickOutside = e => {

    e.stopImmediatePropagation()

    let dropdown = e.composedPath().filter(function (el) {
      return el.id === 'dropdown__menu'
    })

    // inside click
    if (dropdown.length >= 1 || node.current.contains(e.target)) {
      return;
    }

    // outside click
    setOpen(false);

  };

  const onStateTransition = (type, invoice, state, message) => {
    props.dispatch({
      type: type,
      payload: agent.Invoices.transition_state(invoice.id, state).then((res) => {
        if(!res.body.errors) {
          showNotificationWithTimeout(props.dispatch, message);
          setOpen(false);
          if(state === 'submitted') {
            props.openOverlay('email_invoice', invoice)
          }
        }
        return res;
      })
    })
  }

  useEffect(() => {

    var element = node.current;

    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
      element.offsetParent.addEventListener("scroll", handleScroll);

      const dropdown_menu = document.querySelector('#dropdown__menu');
      const position_top = (element.offsetHeight + 8);

      // set default position
      dropdown_menu.style.top = position_top + 'px';

      // get bounds now dropdown has default position
      const dropdown_menu_bounds = dropdown_menu.getBoundingClientRect();

      // dropdown outside window, recalculate position
      if ( dropdown_menu_bounds.bottom > window.innerHeight ) {
        const position_recalc = dropdown_menu.offsetHeight + 8;
        dropdown_menu.style.top = -Math.abs(position_recalc) + 'px';
      }

      // finally, show dropdown (to stop position popping effect)
      dropdown_menu.style.visibility = 'visible';

    } else {
      document.removeEventListener("mousedown", handleClickOutside);
      element.offsetParent.removeEventListener("scroll", handleScroll);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      element.offsetParent.removeEventListener("scroll", handleScroll);
    };
  }, [open]);

  const renderSwitch = e => {
    switch(props.client.can_be_deleted){
      case true:
        return (
          <>
          <div onClick={() => {
            props.deleteClient(props.client)
          }} className="item">Delete Client</div>
          </>
        )
      default:
        return (
          <>
            <Tooltip message={`You cannot delete this client because you have raised invoices for them.`} position={'top'} size={'large'}>
              <div class="disabled">
                <span>Delete Client</span>
                <LockIcon />
              </div>
            </Tooltip>
          </>
        )
    }
  }

  return (
    <div ref={node} className={props.disabled ? 'dropdown disabled' : 'dropdown'}>
      <button className="btn btn__square dropdown-toggler" onClick={e => setOpen(!open)}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
          <path d="M0 0h24v24H0z" stroke="none"/>
          <circle cx="12" cy="12" r="1"/>
          <circle cx="12" cy="19" r="1"/>
          <circle cx="12" cy="5" r="1"/>
        </svg>
      </button>
      {open && (
        ReactDOM.createPortal(
          <div
          style={{position: 'absolute', visibility: 'hidden', right: '1px' }}
            id="dropdown__menu" className={`dropdown__menu ` + props.content}>
            {loading && (
              <div className="loading"><div className="loader loader__small"></div></div>
            )}
            <div className="list">
              {renderSwitch(props.client.state)}
            </div>
          </div>, node.current
        )
      )}
      { notification && <div className="success">{notification}</div>}
    </div>
  );
};

export default ClientOptions;
