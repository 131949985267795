import superagentPromise from 'superagent-promise';
import _superagent from 'superagent';

const superagent = superagentPromise(_superagent, global.Promise);

const API_ROOT = process.env.REACT_APP_RAILS_API;

const responseBody = res => {
  return { body: res.body, headers: res.headers };
}

let token = null;
const tokenPlugin = req => {
  if (token) {
    req.set('authorization', `Token ${token}`);
  }
}

const requests = {
  del: (url, body) =>
    superagent.del(`${API_ROOT}${url}`).send(body).use(tokenPlugin).then(responseBody),
  get: url =>
    superagent.get(`${API_ROOT}${url}`).use(tokenPlugin).then(responseBody),
  put: (url, body) =>
    superagent.put(`${API_ROOT}${url}`, body).use(tokenPlugin).then(responseBody),
  post: (url, body) =>
    superagent.post(`${API_ROOT}${url}`, body).use(tokenPlugin).then(responseBody)
};

const Auth = {
  current: () =>
    requests.get('/user'),
  login: (email, password) =>
    requests.post('/users/login', { user: { email, password } }),
  logout: () =>
    requests.del('/users/sign_out'),
  register: user =>
    requests.post('/user', { user: user }),
  demo_register: user =>
    requests.post('/demo', { user: user }),
  save: user =>
    requests.put('/user', { user })
};

const Password = {
  forgot: (email) =>
    requests.post('/users/password', { email: email }),
  reset: (token, password) =>
    requests.put('/users/password', { reset_password_token: token, password: password }),
};

const Unsubscribe = {
  get: (token) =>
    requests.get('/unsubscriptions?token=' + token),
  unsubscribe: (token) =>
    requests.post('/unsubscriptions', { token: token }),
};

const Clients = {
  all: page =>
    requests.get('/clients'),
  show: id =>
    requests.get(`/clients/${id}`),
  create: client =>
    requests.post('/clients', { client }),
  update: client =>
    requests.put(`/clients/${client.id}`, { client: Object.assign({}, client, { id: undefined } )}),
  remove: (client, prompt) =>
    requests.del(`/clients/${client.id}`, { prompt: prompt }),
  transition_state: (id, state) =>
    requests.post(`/clients/${id}/state`, { state: state }),
  schedule: id =>
    requests.get(`/works/search?by_client_id=${id}`),
  invoiceable_works: (clientId, projectId) =>
    requests.get(`/invoices/works?client_id=${clientId}&project_id=${projectId}`),
  invoiceable_projects: id =>
    requests.get(`/invoices/projects?client_id=${id}`),
}

const Projects = {
  all: page =>
    requests.get('/projects'),
  show: id =>
    requests.get(`/projects/${id}`),
  create: project =>
    requests.post('/projects', { project }),
  update: project =>
    requests.put(`/projects/${project.id}`, { project: Object.assign({}, project, { id: undefined } )}),
  remove: (project, prompt) =>
    requests.del(`/projects/${project.id}`, { prompt: prompt }),
  transition_state: (id, state) =>
    requests.post(`/projects/${id}/state`, { state: state }),
}

const Invoices = {
  all: page =>
    requests.get('/invoices'),
  show: id =>
    requests.get(`/invoices/${id}`),
  create: invoice =>
    requests.post('/invoices', { invoice }),
  update: invoice =>
    requests.put(`/invoices/${invoice.id}`, { invoice: Object.assign({}, invoice, { id: undefined } )}),
  remove: (invoice, prompt) =>
    requests.del(`/invoices/${invoice.id}`, { prompt: prompt }),
  transition_state: (id, state) =>
    requests.post(`/invoices/${id}/state`, { state: state }),
  email: (id, emailInvoice) =>
    requests.post(`/invoices/${id}/email`, emailInvoice),
  summary: () =>
    requests.get(`/invoices/summary`),
  filter: (filters) =>
    requests.get(`/invoices${filters}`),
  time: (client_id, project_id, currency) =>
    project_id ?
      requests.get(`/invoices/works?client_id=${client_id}&project_id=${project_id}&currency=${currency}`)
    :
      requests.get(`/invoices/works?client_id=${client_id}&currency=${currency}`),
  payment: (id) =>
    requests.get(`/invoices/${id}/invoice_payments`),
}

const InvoicePayments = {
  create: (invoiceId, invoice_payment) =>
    requests.post(`/invoices/${invoiceId}/invoice_payments`, { invoice_payment })
}

const Tasks = {
  all: page =>
    requests.get('/tasks'),
  create: task =>
    requests.post('/tasks', { task }),
  update: task =>
    requests.put(`/tasks/${task.id}`, { task: Object.assign({}, task, { id: undefined } )}),
  complete: id =>
    requests.del(`/tasks/${id}`),
}

const Schedule = {
  all: (from, to) =>
    requests.get(`/works?from=${from}&to=${to}`),
  summary: (from, to) =>
    requests.get(`/works/summary?from=${from}&to=${to}`),
  availability: (from, to) =>
    requests.get(`/works/availability?from=${from}&to=${to}`),
  create: work =>
    requests.post('/works', { work }),
  create_multiple: work =>
    requests.post('/works/batches', { works: work }),
  remove: id =>
    requests.del(`/works/${id}`),
  remove_multiple: work =>
    requests.del('/works/batches', { works: work }),
  update: work =>
    requests.put(`/works/${work.id}`, { work: Object.assign({}, work, { id: undefined } )}),
  transition_state: (id, state) =>
    requests.post(`/works/${id}/state`, { state: state }),
  rollback_transition_state: (id) =>
    requests.del(`/works/${id}/state`)
}

const Subscription = {
  create: (token,annual) =>
    requests.post('/subscriptions', { stripeToken: token, annual: annual }),
  remove: id =>
    requests.del(`/subscriptions/${id}`),
  extend: id =>
    requests.put(`/subscriptions/${id}/extend`),
}

const PaymentMethod = {
  create: token =>
    requests.post('/payment_methods', { stripeToken: token })
}

const Month = {
  show: (month, year) =>
    requests.get(`/month/${month}/${year}`)
}

const Year = {
  show: (year) =>
    requests.get(`/year/${year}`)
}

const AdminUsers = {
  all: queryString =>
    requests.get('/admin/users?' + queryString),
  show: id =>
    requests.get(`/admin/users/${id}`),
  masquerade: id =>
    requests.get(`/users/masquerade/${id}`),
  revertMasquerade: () =>
    requests.get(`/users/masquerade/back`)
}

export default {
  Auth,
  Clients,
  Projects,
  Invoices,
  InvoicePayments,
  Schedule,
  Tasks,
  Month,
  Year,
  Password,
  PaymentMethod,
  Subscription,
  Unsubscribe,
  AdminUsers,
  setToken: _token => { token = _token; }
};
