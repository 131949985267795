import agent from '../agent';
import React from 'react';
import { connect } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { history } from '../configureStore';
import AppRoute from '../routes';
import _ from 'lodash';
import images from '../images';
import '../styles/app.scss';

import {
  APP_LOAD,
  REDIRECT,
  CLIENTS_PAGE_LOADED,
  PROJECTS_PAGE_LOADED,
  SUBSCRIPTION_REQUIRED,
  DEMO_LOGOUT
} from '../constants/actionTypes';

const mapStateToProps = state => {
  return {
    appLoaded: state.common.appLoaded,
    appName: state.common.appName,
    currentUser: state.common.currentUser,
    activeMonth: state.common.activeMonth,
    nextMonth: state.common.nextMonth,
    prevMonth: state.common.prevMonth,
    currentMonth: state.common.currentMonth,
    redirectTo: state.common.redirectTo,
    subscription: state.common.subscription,
    clientSize: state.clients.clients,
    projectSize: state.clients.projects
  }};

const mapDispatchToProps = dispatch => ({
  onLoad: (payload, token) =>
    dispatch({ type: APP_LOAD, payload, token, skipTracking: true }),
  onRedirect: () =>
    dispatch({ type: REDIRECT }),
  onClients: (payload) => {
    dispatch({ type: CLIENTS_PAGE_LOADED, payload: payload })
  },
  onProjects: (payload) => {
    dispatch({ type: PROJECTS_PAGE_LOADED, payload: payload })
  },
  checkSubscription: (payload) => {
    dispatch({ type: SUBSCRIPTION_REQUIRED, payload })
  },
  logout: (email, name) => {
    dispatch({ type: DEMO_LOGOUT, payload: agent.Auth.logout(), email: email, name: name })
  }
});

class App extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      newUser: true,
      onboardingStep: 0
    };
  }

  componentWillReceiveProps(nextProps) {

    if (nextProps.redirectTo) {
      history.push(nextProps.redirectTo);
      this.props.onRedirect();
    }

  }

  componentWillMount() {

    const token = window.localStorage.getItem('jwt');
    this.props.onLoad(token ? agent.Auth.current() : null, token);

  }

  componentDidMount() {

    if(this.props.currentUser && (window.location.pathname === '/login' || window.location.pathname === '/register') ) {

      history.push('/');
      this.props.onRedirect();
    }

  }

  componentDidUpdate(prevProps) {

    if(this.props.currentUser) {

      if(prevProps.currentUser !== this.props.currentUser){

        // if(!this.props.currentUser.admin && this.props.currentUser.state !== 'trialing' && this.props.currentUser.state !== 'purchased'){
        //   this.props.checkSubscription(this.props.subscription)
        // }
        this.props.onClients(agent.Clients.all());
        this.props.onProjects(agent.Projects.all());
      }

    }

  }

  nextScreen(e) {

    e.preventDefault();

    if (this.state.onboardingStep === 6) {
      this.setState({ newUser: false })
    } else {
      this.setState(prevState => {
         return {onboardingStep: prevState.onboardingStep + 1}
      })
    }

  }

  register(e) {

    e.preventDefault();

    this.props.logout(
      (this.props.currentUser.email).replace('demo+',''),
      this.props.currentUser.name
    );
    history.push('/register');
    this.props.onRedirect();

  }

  render() {

    if (this.props.appLoaded && this.props.activeMonth && this.props.clientSize !== null) {

      const theme = (this.props.currentUser && this.props.currentUser.dark_mode) ? 'theme--dark' : 'theme--default';

      return (
        <ConnectedRouter history={history}>
          <React.Fragment>
          <div className={`theme ${theme}`}>
            <div id="mobile">Sorry, we're still working on mobile support.</div>
            <AppRoute
              redirectTo={this.props.redirectTo}
              activeMonth={this.props.activeMonth}
              nextMonth={this.props.nextMonth}
              prevMonth={this.props.prevMonth}
              currentMonth={this.props.currentMonth}
              currentUser={this.props.currentUser}
              subscription={this.props.subscription} />
            {((this.props.currentUser && this.props.currentUser.demo) ) && (
              <div className="demo">
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M5.121 17.804A13.937 13.937 0 0 1 12 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 1 1-6 0 3 3 0 0 1 6 0zm6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0z" stroke="#003845" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="none"></path>
                  </svg>
                  Get an account like this for your business.
                </span>
                <button
                  onClick={this.register.bind(this)}
                  className="btn btn__primary">
                    <span>Start your free trial</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                      <path d="M17 8l4 4m0 0l-4 4m4-4H3" stroke="#003845" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                    </svg>
                  </button>
              </div>
            )}
            {( (this.props.currentUser && this.state.newUser && this.props.currentUser.new_user) ) && (
              <div className="c-modal-cover takeover">
                <div className="c-modal">
                  <div className="c-modal__body">

                    <div className={this.state.onboardingStep !== 0 ? 'hidden' : '' }>
                      <h1>Hey { this.props.currentUser.first_name }!</h1>
                      <p>Thanks for giving Slate a try. To help get you started we'd love to show you a things to make the most of your trial.</p>
                    </div>

                    <div className={this.state.onboardingStep !== 1 ? 'hidden' : '' }>
                      <h1>Adding work to a single day</h1>
                      <p>If you want to add some work to a single day, simply find the day in your schedule and click anywhere inside.</p>
                    </div>

                    <div className={this.state.onboardingStep !== 2 ? 'hidden' : '' }>
                      <h1>Adding work to multiple days</h1>
                      <p>If you want to add work to multiple days, simply drag your mouse over the days you want to book.</p>
                    </div>

                    <div className={this.state.onboardingStep !== 3 ? 'hidden' : '' }>
                      <h1>Moving work to another day</h1>
                      <p>If you need to move some work you've already added to your schedule, drag & drop it to another day.</p>
                    </div>

                    <div className={this.state.onboardingStep !== 4 ? 'hidden' : '' }>
                      <h1>Multiple clients on a day</h1>
                      <p>If you're working for multiple clients on a day, we'll tell you how much time is available so you don't overbook yourself.</p>
                    </div>

                    <div className={this.state.onboardingStep !== 5 ? 'hidden' : '' }>
                      <h1>Monthly performance</h1>
                      <p>Everytime you update your schedule, we populate the sidebar with stats and analysis for the month.</p>
                    </div>

                    <div className={this.state.onboardingStep !== 6 ? 'hidden' : '' }>
                      <h1>Yearly performance</h1>
                      <p>We also measure your performance across the year when your schedule is updated, giving you a snapshot of each month.</p>
                    </div>

                    <button onClick={this.nextScreen.bind(this)} className="btn">{ this.state.onboardingStep < 6 ? 'Continue' : 'Start using Slate'}</button>

                  </div>
                  <div>
                    <img src={images.onboarding__00} alt="Step 1" className={this.state.onboardingStep !== 0 ? 'hidden' : '' } />
                    <img src={images.onboarding__01} alt="Step 2" className={this.state.onboardingStep !== 1 ? 'hidden' : '' } />
                    <img src={images.onboarding__02} alt="Step 3" className={this.state.onboardingStep !== 2 ? 'hidden' : '' } />
                    <img src={images.onboarding__03} alt="Step 4" className={this.state.onboardingStep !== 3 ? 'hidden' : '' } />
                    <img src={images.onboarding__04} alt="Step 5" className={this.state.onboardingStep !== 4 ? 'hidden' : '' } />
                    <img src={images.onboarding__05} alt="Step 6" className={this.state.onboardingStep !== 5 ? 'hidden' : '' } />
                    <img src={images.onboarding__06} alt="Step 7" className={this.state.onboardingStep !== 6 ? 'hidden' : '' } />
                  </div>
                </div>
              </div>
            )}
            <div className="overlay"></div>
          </div>
          </React.Fragment>
        </ConnectedRouter>
      );
    }
    return (
      <div className="loading"><div className="loader"></div></div>
    );
  }
}

// App.contextTypes = {
//   router: PropTypes.object.isRequired
// };

export default connect(mapStateToProps, mapDispatchToProps)(App);
