import agent from './agent';
import {
  APP_LOAD,
  ASYNC_START,
  ASYNC_END,
  LOGIN,
  LOGOUT,
  DEMO_LOGOUT,
  REGISTER,
  DEMO_REGISTER,
  PASSWORD_RESET,
  FORBIDDEN,
  SUBSCRIPTION_REQUIRED
} from './constants/actionTypes';

const promiseMiddleware = store => next => action => {
  if (isPromise(action.payload)) {
    store.dispatch({ type: ASYNC_START, subtype: action.type });

    const currentView = store.getState().viewChangeCounter;
    const skipTracking = action.skipTracking;

    action.payload.then(
      res => {
        const currentState = store.getState()
        if (!skipTracking && currentState.viewChangeCounter !== currentView) {
          return
        }
        //console.log('RESULT', res);
        action.payload = res;
        store.dispatch({ type: ASYNC_END, promise: action.payload });
        store.dispatch(action);
      },
      error => {
        if (error.status === 401){
          store.dispatch({ type: LOGOUT });
        }else if (error.status === 403){
          store.dispatch({ type: FORBIDDEN });
        }else if (error.status === 402){
          store.dispatch({ type: SUBSCRIPTION_REQUIRED });
        }

        const currentState = store.getState()
        if (!skipTracking && currentState.viewChangeCounter !== currentView) {
          return
        }
        //console.log('ERROR', error);
        action.error = true;
        action.payload = error.response.body;
        if (!action.skipTracking) {
          store.dispatch({ type: ASYNC_END, promise: action.payload });
        }
        store.dispatch(action);
      }
    );

    return;
  }

  next(action);
};

const localStorageMiddleware = store => next => action => {
  if (action.type === REGISTER || action.type === DEMO_REGISTER || action.type === LOGIN || action.type === PASSWORD_RESET) {
    if (!action.error) {
      var headers = action.payload.headers;
      if(headers.authorization){
        var token = headers.authorization.match(/Bearer (.*)/)[1]
      }
      window.localStorage.setItem('jwt', token);
      agent.setToken(token);
      action.token = token;
      action.payload = action.payload.body;
    }
  } else if (action.type === LOGOUT || action.type === DEMO_LOGOUT) {
    window.localStorage.setItem('jwt', '');
    agent.setToken(null);
  } else if (action.type === APP_LOAD) {
    if(action.error){
      window.localStorage.setItem('jwt', '');
      agent.setToken(null);
    }
  }
  next(action);
};

function isPromise(v) {
  return v && typeof v.then === 'function';
}


export { promiseMiddleware, localStorageMiddleware }
