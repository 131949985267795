import {
  REQUEST_PROJECTS,
  PROJECT_CREATE,
  PROJECT_LOADED,
  PROJECT_UNLOADED,
  PROJECT_UPDATE,
  PROJECT_REMOVE,
  PROJECTS_PAGE_LOADED,
  PROJECTS_PAGE_UNLOADED,
  OPEN_PROJECTS_OVERLAY,
  CLOSE_PROJECTS_OVERLAY
} from '../constants/actionTypes';

export default (state = {}, action) => {

  switch (action.type) {
    case REQUEST_PROJECTS:
      //console.log(action)
      return {
        ...state,
        isFetching: true
      }
    case PROJECTS_PAGE_LOADED:
      //console.log(action)
      return {
        ...state,
        projects: action.payload.body,
        //isFetching: false,
        //errors: false
      };
    case PROJECTS_PAGE_UNLOADED:
      //console.log(action)
      return {
        ...state
      };
    case PROJECT_LOADED:
      //console.log(action)
      return {
        ...state,
        project: action.payload.body,
        isFetching: false
      };
    case PROJECT_UNLOADED:
      //console.log(action)
      return {
        ...state,
        project: false
      };
    case PROJECT_CREATE:
      //console.log(action)
      if(action.error){
        return {
          ...state,
          errors: action.payload.errors
        };
      }else{
        return {
          ...state,
          projects: [...state.projects, action.payload.body],
          errors: false
        };
      };
    case PROJECT_UPDATE:
      //console.log(action)
      if(action.error){
        return {
          ...state,
          errors: action.payload.errors
        };
      }else{
        return {
          ...state,
          project: action.payload.body,
          projects: state.projects.map(function(value){
            return value.id === action.payload.body.id ? action.payload.body : value
          }),
          errors: false
        };
      };
    case PROJECT_REMOVE:
      //console.log(action)
      if(action.error){
        return {
          ...state,
          errors: action.payload.errors
        };
      }else{
        return {
          ...state,
          projects: [...state.projects].filter(function(value){
            return value.id !== action.payload.body.id;
          }),
          overlay: false,
          project: false,
          errors: false
        };
      };
    case OPEN_PROJECTS_OVERLAY:
      //console.log(action)
      return {
        ...state,
        overlay: true
      }
    case CLOSE_PROJECTS_OVERLAY:
      //console.log(action)
      return {
        ...state,
        overlay: false,
        errors: false
      }
    default:
      return state;
  }
};
