import {
  SETTINGS_SAVED,
  SETTINGS_PAGE_UNLOADED,
  SETTINGS_UPDATE,
  OPEN_SETTINGS_OVERLAY,
  CLOSE_SETTINGS_OVERLAY
} from '../constants/actionTypes';

const defaultState = {
  inProgress: false,
  success: false
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SETTINGS_SAVED:
      // console.log(action)
      return {
        ...state,
        inProgress: false,
        success: action.error ? null : true,
        errors: action.error ? action.payload.errors : null
      };
    case SETTINGS_PAGE_UNLOADED:
      // console.log(action)
      return {};
    case SETTINGS_UPDATE:
      // console.log(action)
      return {
        ...state,
        success: false,
        inProgress: true
      };
    case OPEN_SETTINGS_OVERLAY:
      //console.log(action)
      return {
        ...state,
        overlay: true
      }
    case CLOSE_SETTINGS_OVERLAY:
      //console.log(action)
      return {
        ...state,
        overlay: false,
        errors: false
      }
    default:
      // console.log(action)
      return state;
  }
};
