import {
  REQUEST_YEAR,
  YEAR_LOADED
} from '../constants/actionTypes';

const defaultState = {
  isFetching: false
};

export default (state = defaultState, action) => {

  switch (action.type) {
    case REQUEST_YEAR:
      //console.log(action)
      return {
        ...state,
        isFetching: true
      }
    case YEAR_LOADED:
      //console.log(action)
      return {
        ...state,
        data: action.payload.body,
        isFetching: false
      };
    default:
      // console.log(action)
      return {
        ...state
      };
  }
};
