import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {ReactComponent as ErrorIcon} from '../../../images/error.svg';

const mapStateToProps = state => (
  {
    ...state
  }
);

const mapDispatchToProps = dispatch => ({
});

class AdminUserItem extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      name: props.user.name
    }

  }

  render() {

    return (
      <tr>
        <td>{this.props.user.name}</td>
        <td>
          <Link to={{
            pathname: `/admin/users/${this.props.user.id}`,
            state: {
              trigger: 'click'
            }
          }}>
            {this.props.user.email}
          </Link>
        </td>
        <td>{this.props.user.state}</td>
        <td>{this.props.user.created_at}</td>
        <td>{this.props.user.trial_ends_at}</td>
        <td>{this.props.user.last_seen_at}</td>
      </tr>
    );

  }

}

export default connect(mapStateToProps, mapDispatchToProps)(AdminUserItem);
