import {
  APP_LOAD,
  FORBIDDEN,
  REDIRECT,
  LOGOUT,
  DEMO_LOGOUT,
  SETTINGS_SAVED,
  COUNTRY_SAVED,
  LOGIN,
  REGISTER,
  DEMO_REGISTER,
  HOME_PAGE_UNLOADED,
  SETTINGS_PAGE_UNLOADED,
  REGISTER_PAGE_UNLOADED,
  SWITCH_MONTH,
  CHANGE_NEXT_MONTH,
  CHANGE_PREV_MONTH,
  PASSWORD_RESET,
  SUBSCRIPTION_CREATE,
  SUBSCRIPTION_CANCEL,
  SUBSCRIPTION_EXTEND,
  SUBSCRIPTION_REQUIRED,
  PAYMENT_METHOD_CREATE,
  PAYMENT_METHOD_UPDATE,
  ASYNC_START,
  ASYNC_END,
  SET_ERRORS,
  SHOW_NOTIFICATION,
  HIDE_NOTIFICATION,
  MASQUERADE,
  MASQUERADE_REVOKE,
  INVOICE_CREATE,
  OPEN_OVERLAY,
  CLOSE_OVERLAY,
  INVOICE_EMAIL
} from '../constants/actionTypes';
import dateFns from 'date-fns';
import { getUserLocale } from 'get-user-locale';

const defaultState = {
  token: null
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case APP_LOAD: {
      //console.log(action)

      let currentMonth = dateFns.startOfMonth(dateFns.parse(new Date()));
      let activeMonth;

      if(window.location.pathname.indexOf('/month/') >= 0) {

        let months = {
          'january'   : '01',
          'february'  : '02',
          'march'     : '03',
          'april'     : '04',
          'may'       : '05',
          'june'      : '06',
          'july'      : '07',
          'august'    : '08',
          'september' : '09',
          'october'   : '10',
          'november'  : '11',
          'december'  : '12'
        }

        let url_date = window.location.pathname.match(/\/month\/(.*)\/([0-9]{4})/);
        let date = dateFns.parse(url_date[2] + '-' + months[url_date[1]] + '-01');

        activeMonth = dateFns.startOfMonth(date);

      } else {

        activeMonth = currentMonth;

      }

      return {
        ...state,
        token: action ? (action.token || null) : null,
        appLoaded: true,
        currentUser: action ? (action.payload ? action.payload.body : null) : null,
        subscription: action ? (action.payload ? (action.payload.body ? action.payload.body.subscription : null) : null) : null,
        activeMonth: activeMonth,
        nextMonth: dateFns.startOfMonth(dateFns.addMonths(activeMonth,1)),
        prevMonth: dateFns.startOfMonth(dateFns.subMonths(activeMonth,1)),
        currentMonth: currentMonth,
        locale: getUserLocale()
      };
    }
    case REDIRECT:
      return {
        ...state,
        redirectTo: null
      };
    case MASQUERADE:
      //console.log(action)
      return {
        ...state,
        redirectTo: '/',
        currentUser: action.payload.body
      };
    case MASQUERADE_REVOKE:
      //console.log(action)
      return {
        ...state,
        redirectTo: '/',
        currentUser: action.payload.body
      };
    case FORBIDDEN:
      //console.log(action)
      return {
        ...state,
        redirectTo: '/'
      };
    case LOGOUT:
      //console.log(action)
      return { ...state, redirectTo: '/', token: null, currentUser: null };
    case DEMO_LOGOUT:
      //console.log(action)
      return {
        ...state,
        redirectTo: '/register',
        token: null,
        currentUser: null,
        email: action.email,
        name: action.name
      };
    case SETTINGS_SAVED:
      //console.log(action)
      return {
        ...state,
        //redirectTo: action.error ? '/' : '/',
        currentUser: action.error ? state.currentUser : action.payload.body
      };
    case COUNTRY_SAVED:
      //console.log(action)
      return {
        ...state,
        redirectTo: action.error ? '/settings/billing' : '/settings/billing',
        currentUser: action.error ? state.currentUser : action.payload.body
      };
    case PASSWORD_RESET:
    case LOGIN:
      //console.log(action)
      return {
        ...state,
        redirectTo: action.error ? undefined : '/month/' + dateFns.format(state.currentMonth, 'MMMM/YYYY').toLowerCase(),
        token: action.error ? null : action.token,
        currentUser: action.error ? null : action.payload,
        subscription: action.error ? null : action.payload.subscription
      };
    case REGISTER:
      //console.log(action)
      return {
        ...state,
        redirectTo: action.error ? undefined : '/month/' + dateFns.format(state.currentMonth, 'MMMM/YYYY').toLowerCase(),
        //redirectTo: action.error ? undefined : '/settings/billing',
        token: action.error ? null : action.token,
        currentUser: action.error ? null : action.payload,
        subscription: action.error ? null : action.payload.subscription
      };
    case DEMO_REGISTER:
      //console.log(action)
      return {
        ...state,
        //redirectTo: action.error ? undefined : '/month/' + dateFns.format(state.currentMonth, 'MMMM/YYYY').toLowerCase(),
        redirectTo: action.error ? undefined : '/',
        token: action.error ? null : action.token,
        currentUser: action.error ? null : action.payload,
        subscription: action.error ? null : action.payload.subscription
      };
    case SUBSCRIPTION_CANCEL:
      //console.log(action)
      return {
        ...state,
        redirectTo: '/settings',
        subscription: action.error ? state.subscription : null
      };
    case SUBSCRIPTION_EXTEND:
      //console.log(action.payload.body)
      return {
        ...state,
        subscription: action.error ? null : action.payload.body
      };
    case SUBSCRIPTION_CREATE:
    case PAYMENT_METHOD_CREATE:
      return {
        ...state,
        //redirectTo: action.error ? undefined : '/month/' + dateFns.format(state.currentMonth, 'MMMM/YYYY').toLowerCase(),
        redirectTo: action.error ? undefined : '/settings',
        subscription: action.error ? null : action.payload.body
      };
    case PAYMENT_METHOD_UPDATE:
      return {
        ...state,
        redirectTo: action.error ? undefined : '/settings',
        subscription: action.error ? null : action.payload.body
      };
    case SUBSCRIPTION_REQUIRED:
      // console.log(action)
      return {
        ...state,
        redirectTo: action.payload ? undefined : '/settings/billing'
      };
    case HOME_PAGE_UNLOADED:
    case SETTINGS_PAGE_UNLOADED:
    case REGISTER_PAGE_UNLOADED:
    case SWITCH_MONTH:
      //console.log(action)
      return {
        ...state,
        activeMonth: action.month,
        redirectTo: null
      };
    case CHANGE_NEXT_MONTH:
      //console.log(action)
      return {
        ...state,
        nextMonth:  dateFns.addMonths(action.month, 1)
      };
    case CHANGE_PREV_MONTH:
      //console.log(action)
      return {
        ...state,
        prevMonth: dateFns.subMonths(action.month, 1)
      };
    case SET_ERRORS:
      //console.log(action)
      return {
        ...state,
        errors: action.errors
      };
    case SHOW_NOTIFICATION:
      console.log(action)
      return {
        ...state,
        notification: action.payload
      };
    case HIDE_NOTIFICATION:
      //console.log(action)
      return {
        ...state,
        notification: false
      };
    case ASYNC_START:
      //console.log( action )
      return {
        ...state,
        loading: true
      };
    case ASYNC_END:
      //console.log( action )
      return {
        ...state,
        loading: false
      };
    case OPEN_OVERLAY:
      return {
        ...state,
        overlay: true
      };
    case CLOSE_OVERLAY:
      return {
        ...state,
        overlay: false
      };
    case INVOICE_CREATE:
      //console.log( action )
      if(action.error){
        return state;
      }else{
        return {
          ...state,
          redirectTo: '/invoices/' + action.payload.body.id + '/edit'
        };
      }
    case INVOICE_EMAIL:
      if(action.error){
        return state;
      }else{
        return {
          ...state,
          redirectTo: '/invoices'
        };
      }
    default:
      return state;
  }
};
