import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';

let scrollPosition = 0;

const Filter = (props) => {
  const node = useRef();

  const [open, setOpen] = useState(false);

  const handleScroll = e => {
    setOpen(false);
  }

  const handleChange = (filter, value, persist) => {
    props.onChange(filter,value)
    if (!persist) {
      setOpen(false);
    }
  };

  const handleClickOutside = e => {

    e.stopImmediatePropagation()

    let dropdown = e.composedPath().filter(function (el) {
      return el.id === 'dropdown__menu'
    })

    // inside click
    if (dropdown.length >= 1 || node.current.contains(e.target)) {
      return;
    }

    // outside click
    setOpen(false);

  };

  useEffect(() => {
    var element = node.current;
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
      element.offsetParent.addEventListener("scroll", handleScroll);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
      element.offsetParent.removeEventListener("scroll", handleScroll);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      element.offsetParent.removeEventListener("scroll", handleScroll);
    };
  }, [open]);

  return (
    <div ref={node} className={props.disabled ? 'dropdown disabled' : 'dropdown'}>
      <button className="dropdown-toggler" onClick={e => setOpen(!open)}>
        <span>{ props.filter }</span>
        <svg width="9" height="12" xmlns="http://www.w3.org/2000/svg">
          <g transform="translate(-70 -18)" fill="#1282FF" fillRule="evenodd">
            <path fill="currentColor" d="M78.3013153 26.17460431c.15974318.18636704.15830762.45647696.00876349.64041738l-.0629961.06460661-3.5 3c-.16383926.1404336-.39586261.1579879-.57674789.0526626l-.07404349-.0526626-3.5-3c-.20966292-.17971107-.23394369-.49536107-.05423261-.70502399.15974318-.18636704.42689488-.22625929.63154276-.10660658l.07348123.05237397L74.42167794 28.841l3.17461337-2.7206283c.20966292-.17971108.52531292-.15543031.70502399.05423261zm-3.6282761-8.10689523l.07404349.05266262 3.5 3c.20966292.17971107.23394369.49536107.05423261.70502399-.15974318.18636704-.42689488.22625929-.63154276.10660658l-.07348123-.05237397L74.42167794 19.158l-3.17459525 2.7216283c-.18636704.15974318-.45647696.15830762-.64041739.00876349l-.0646066-.0629961c-.15974318-.18636704-.15830762-.45647696-.0087635-.64041738l.06299611-.06460661 3.5-3c.16383926-.14043365.39586261-.15798786.57674789-.05266262z" opacity=".5" fillRule="nonzero"/>
          </g>
        </svg>
      </button>
      {open && (
        ReactDOM.createPortal(
          <div
            style={{position: 'absolute', top: ((node.current.offsetTop + node.current.offsetHeight + 8) - scrollPosition), right: '33px' }}
            id="dropdown__menu" className={`dropdown__menu ` + props.content}>
            <div className="list">
              {props.filters.map(filter => {
                return (
                  <div
                    className={filter === props.filter ? 'item selected' : 'item'}
                    onClick={e => handleChange('client_state', filter )}>
                    { filter }
                  </div>
                )
              })}
            </div>
          </div>, document.querySelector('.filter')
        )
      )}
    </div>
  );
};

export default Filter;
