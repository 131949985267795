import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import dateFns from 'date-fns';
import ScheduleMonth from './ScheduleMonth';
import Overlay from './Overlay';
import { InView } from 'react-intersection-observer';
import 'intersection-observer';
import { history } from '../../configureStore';
import _ from 'lodash';
import { SelectableGroup } from 'react-selectable-fast';

import agent from '../../agent';
import {
  SCHEDULE_REMOVE,
  SCHEDULE_FORWARD,
  SCHEDULE_BACK,
  CHANGE_NEXT_MONTH,
  CHANGE_PREV_MONTH,
  MONTH_LOADED,
  OPEN_SCHEDULE_OVERLAY,
  CLOSE_SCHEDULE_OVERLAY
} from '../../constants/actionTypes';

const mapStateToProps = state => (
  {
    ...state,
    days: state.schedule.days,
    months: state.months,
    clients: state.clients
  }
);

const mapDispatchToProps = dispatch => ({
  onMove: (month, payload) => {
    dispatch({ type: CHANGE_NEXT_MONTH, month: month })
    dispatch({ type: SCHEDULE_FORWARD, month: month, payload: payload })
  },
  onHistory: (month, payload) => {
    dispatch({ type: CHANGE_PREV_MONTH, month: month })
    dispatch({ type: SCHEDULE_BACK, month: month, payload: payload })
  },
  updateSchedule: (type, activeMonth, dates) => {

    if (type === 'REMOVE') {

      var schedule = [];

      dates.forEach(function(item, i){
        schedule.push({id:parseInt(item)})
      })

      dispatch({
        type: SCHEDULE_REMOVE,
        payload: agent.Schedule.remove_multiple(schedule).then((res) => {
          if(!res.body.errors) {
            let month_name = dateFns.format(activeMonth, 'MMMM').toLowerCase()
            let year = dateFns.format(activeMonth, 'YYYY').toLowerCase()
            dispatch({ type: MONTH_LOADED, month: activeMonth, month_string: month_name + '-' + year, payload: agent.Month.show(month_name, year) })
          }
          return res;
        })
      })

      // cleanup
      schedule = null;

    }

  },
  openOverlay: () => {
    dispatch({ type: OPEN_SCHEDULE_OVERLAY });
  },
  closeOverlay: () => {
    dispatch({ type: CLOSE_SCHEDULE_OVERLAY });
  }
});

let isScrolling;
let inViewMonth;
class ScheduleList extends React.Component {

  constructor(props){
    super(props);

    this.todayRef = React.createRef();
    this.scheduleRef = React.createRef();

    this.state = {
      height: 0,
      position: 128,
      scrollUp: false,
      initialLoad: true,
      scrolling: false,
      activeMonth: props.activeMonth,
      multipleDates: [],
      shift: false,
      selectedKeys: [],
      deleteKeys: [],
      selectorEnabled: true,
      overlay: false
    }

    this.handleSelection = this.handleSelection.bind(this);

    this.handleScroll = this.handleScroll.bind(this);
    this.trigger = 'initial'; // the trigger type helps us know whether to scroll the schedule or not

    this.addWork = this.addWork.bind(this);
    this.deleteWork = this.deleteWork.bind(this);

    this.handleDelete = this.handleDelete.bind(this);
    this.handleCancel = this.handleCancel.bind(this);

    this.backListener2 = history.listen(location => {
      if(location.pathname.indexOf('/month/') >= 0) {
        this.trigger = (location.state ? location.state.trigger : 'unknown');
      }
    })

  }

  componentWillUnmount() {
    this.backListener2();
    document.removeEventListener('keydown', this.handleShiftDown.bind(this));
    document.removeEventListener('keyup', this.handleShiftUp.bind(this));
  }

  componentDidMount() {

    let height = ReactDOM.findDOMNode(this).scrollHeight;
    inViewMonth = this.props.currentMonth;

    // ReactDOM.findDOMNode(this).scrollTop = 128;
    // document.querySelector('.schedule__calendar').scrollTop = 128;

    if (dateFns.isSameDay(this.props.activeMonth, this.props.currentMonth)) {

      if (this.todayRef.current) {

        this.todayRef.current.scrollIntoView()

        if( dateFns.isFirstDayOfMonth(new Date()) ) {

          if( dateFns.isMonday(new Date()) ) {
            this.scheduleRef.current.scrollTop -= 104;
          } else {
            this.scheduleRef.current.scrollTop -= 48;
          }

        } else {
          this.scheduleRef.current.scrollTop -= 96;
        }

      }

    } else {

      if(this.scheduleRef.current) {
        this.scheduleRef.current.scrollTop = 48;
      }


    }

    this.setState((state, props) => ({
      height: height,
      initialLoad: false
    }));

    document.addEventListener('keydown', this.handleShiftDown.bind(this));
    document.addEventListener('keyup', this.handleShiftUp.bind(this));

    this.setState((state, props) => ({
      selectorEnabled: true
    }));

  }

  shouldComponentUpdate(nextProps, nextState) {

    if (nextProps.days !== this.props.days || nextProps.activeMonth !== this.props.activeMonth || nextState.multipleDates !== this.state.multipleDates || nextState.shift !== this.state.shift || nextState.selectedKeys !== this.state.selectedKeys || nextState.deleteKeys !== this.state.deleteKeys || nextProps.clients !== this.props.clients || nextState.overlay !== this.state.overlay) {
      return true;
    } else {
      return false;
    }

  }

  componentDidUpdate(prevProps, prevState) {

    if (window.outerWidth < 1024) {
      return false;
    }

    if ( this.props.trigger === 'POP' ) {

      if(this.scheduleRef.current) {
        this.scheduleRef.current.scrollTop = 48;
      }

    } else {

      if ( this.trigger === 'swipe' ) {

        // do nothing

      } else if ( this.trigger === 'history' ) {

        document.querySelectorAll('.schedule__calendar__month')[1].scrollIntoView();

        if(this.scheduleRef.current) {
          this.scheduleRef.current.scrollTop -= 48;
        }

      } else if ( prevProps.activeMonth !== this.props.activeMonth && this.trigger === 'swipe' ) {

        // do nothing

      } else if ( prevProps.activeMonth !== this.props.activeMonth && this.trigger === 'click' ) {

        if (dateFns.isSameDay(this.props.activeMonth, this.props.currentMonth)) {

          if (this.todayRef.current) {

            this.todayRef.current.scrollIntoView()

            if( dateFns.isFirstDayOfMonth(new Date()) ) {

              if( dateFns.isMonday(new Date()) ) {
                this.scheduleRef.current.scrollTop -= 104;
              } else {
                this.scheduleRef.current.scrollTop -= 48;
              }

            } else {
              this.scheduleRef.current.scrollTop -= 96;
            }

          }

        } else {

          if(this.scheduleRef.current) {
            this.scheduleRef.current.scrollTop = 48;
          }

        }

      } else {

        if (this.todayRef.current) {

          if (dateFns.isSameDay(this.props.activeMonth, this.props.currentMonth)) {

            this.todayRef.current.scrollIntoView()

            if( dateFns.isFirstDayOfMonth(new Date()) ) {

              if( dateFns.isMonday(new Date()) ) {
                this.scheduleRef.current.scrollTop -= 104;
              } else {
                this.scheduleRef.current.scrollTop -= 48;
              }

            } else {
              this.scheduleRef.current.scrollTop -= 96;
            }


          } else {

            if(this.scheduleRef.current) {
              this.scheduleRef.current.scrollTop = 48;
            }

          }

        } else {

          if (this.scheduleRef.current) {
            this.scheduleRef.current.scrollTop = 48;
          }

        }

      }

    }

  }

  handleSelection (selectedKeys) {

    if (_.size(selectedKeys) > 0 && !this.state.shift) {

      this.props.openOverlay()

      this.setState({
        overlay: 'client'
      });

    }

  	this.setState({
      selectedKeys: selectedKeys.map(a => a.props.selectableKey)
    });

  }

  goBack(date) {

    if (this.trigger !== 'click') {

      var that = this;

      setTimeout(function(){

        that.trigger = 'history';

        if (document.querySelector('.schedule__calendar')) {
          that.position = document.querySelector('.schedule__calendar').scrollTop;
        }

        let from = dateFns.format(date, 'DD-MM-YYYY');
        let to = dateFns.format(dateFns.endOfMonth(date), 'DD-MM-YYYY');

        that.props.onHistory(
          date,
          agent.Schedule.all(from,to)
        );

      }, 1000);

    }

  }

  goForward(date) {

    if (this.trigger !== 'click') {

      var that = this;

      setTimeout(function(){

        that.trigger = 'swipe';

        let from = dateFns.format(date, 'DD-MM-YYYY');
        let to = dateFns.format(dateFns.endOfMonth(date), 'DD-MM-YYYY');

        that.props.onMove(
          date,
          agent.Schedule.all(from,to)
        );

      }, 1000);

    }

  }


  handleScroll(event) {

    this.trigger = 'swipe';

    window.clearTimeout( isScrolling );
    isScrolling = setTimeout(() => {

      let sticky = document.querySelector('.sticky .month');

      if (sticky) {

        inViewMonth = dateFns.startOfMonth(dateFns.parse(sticky.getAttribute('data-month')));

        if (window.location.pathname === '/' || window.location.pathname.indexOf('month') === 1) {

          let month = dateFns.format(inViewMonth, 'MMMM');
          let year = dateFns.format(inViewMonth, 'YYYY');
          let url = '/month/' + month.toLowerCase() + '/' + year;

          if (this.props.history.location.pathname !== url) {
            history.push('/month/' + month.toLowerCase() + '/' + year, {
              type: 'scroll',
              trigger: 'swipe',
              position: document.querySelector('.schedule__calendar').scrollTop
            } );
          }

        }

      }

      if (!inViewMonth && !this.props.activeMonth) {
        inViewMonth = this.props.currentMonth;
      }

    }, 100);

  }

  addWork(date) {

    this.setState((state, props) => ({
      selectedKeys: _.uniq([...state.selectedKeys, date]).sort()
    }));

  }

  deleteWork(date) {

    if (_.indexOf(this.state.deleteKeys, date) >= 0) {

      this.setState((state, props) => ({
        deleteKeys: _.remove([...state.deleteKeys], function(person){
          return person !== date
        })
      }))

    } else {

      this.setState((state, props) => ({
        deleteKeys: _.uniq([...state.deleteKeys, date.toString()])
      }))

    }

  }

  handleShiftDown(event) {

    if(event.keyCode === 16) {

      this.setState((state, props) => ({
        shift: true
      }));

    }

  }

  handleShiftUp(event) {

    if(event.keyCode === 16 && !this.state.overlay) {

      if (_.size(this.state.selectedKeys) > 0) {
        this.props.openOverlay()
      }

      this.setState((state, props) => ({
        shift: false,
        overlay: _.size(this.state.selectedKeys) > 0 ? 'client' : false
      }));

    }

  }


  handleDelete() {

    this.props.updateSchedule('REMOVE', this.props.activeMonth, this.state.deleteKeys);

    this.setState((state, props) => ({
      deleteKeys: []
    }))

  }

  handleCancel() {

    document.querySelectorAll('.delete').forEach(function(item, i){
      item.classList.remove('delete')
    })

    this.setState((state, props) => ({
      deleteKeys: []
    }))

  }

  closeOverlay(e) {

    document.querySelectorAll('.shift').forEach(function(item, i){
      item.classList.remove('shift')
    })

    e.stopPropagation();

    this.props.closeOverlay()

    this.setState({
      overlay: false,
      selectedKeys: []
    });

  }

  render() {

    let dates = [];

    if (!this.props.days || (_.size(this.props.clients) === 0)) {
      return (
        <section className="schedule__calendar">
          <div className="loading"><div className="loader"></div></div>
          <div className="bottom" data-month={this.props.nextMonth}></div>
        </section>
      );
    }

    return (
      <React.Fragment>
      <section
        className="schedule__calendar"
        ref={this.scheduleRef}
        onScroll={this.handleScroll}>
        <InView threshold={1} onChange={inView => { if(inView && window.outerWidth >= 1024 && this.trigger !== 'initial' ) { this.goBack(this.props.prevMonth) } } }>
          <div className="top" data-month={this.props.prevMonth} data-view={this.state.initialLoad}>
            <div className="loader loader__small"></div>
          </div>
        </InView>
        <div style={{minHeight: '3000px'}}>
        <SelectableGroup
          className="selector"
          onSelectionFinish={this.handleSelection}
          selectOnClick={false}
          tolerance={10}
          resetOnStart={true}
          ignoreList={['[draggable]']}>
        {this.props.days.map((date, index) => {

          dates.push(date);

          if (dateFns.isLastDayOfMonth(date.day)) {
            dates = [];
          }

          if (dateFns.isFirstDayOfMonth(date.day)) {
            return (
              <ScheduleMonth
                key={dates[0].day}
                month={dates[0].day}
                dates={dates}
                activeMonth={this.props.activeMonth}
                addWork={this.addWork}
                deleteWork={this.deleteWork}
                multipleDates={this.state.multipleDates}
                selectedKeys={this.state.selectedKeys}
                deleteKeys={this.state.deleteKeys}
                todayRef={this.todayRef} />
            )
          }

          return null

        })}
        </SelectableGroup>
        </div>
        <InView threshold={1} onChange={inView => { if(inView) { this.goForward(this.props.nextMonth) } } }>
          <div className="bottom" data-month={this.props.nextMonth}>
            <div className="loader loader__small"></div>
          </div>
        </InView>
      </section>

      { (_.size(this.state.deleteKeys) >= 1) &&
        (
          ReactDOM.createPortal(
            <React.Fragment>
              <button onClick={this.handleDelete}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="#e84c85" d="M2,6v8c0,1.1,0.9,2,2,2h8c1.1,0,2-0.9,2-2V6H2z"></path> <path fill="#e84c85" d="M12,3V1c0-0.6-0.4-1-1-1H5C4.4,0,4,0.4,4,1v2H0v2h16V3H12z M10,3H6V2h4V3z"></path></svg>
                <span>Delete</span>
              </button>
              <button onClick={this.handleCancel}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="#e84c85" d="M8,0C3.6,0,0,3.6,0,8s3.6,8,8,8s8-3.6,8-8S12.4,0,8,0z M8,2c1.3,0,2.5,0.4,3.5,1.1l-8.4,8.4 C2.4,10.5,2,9.3,2,8C2,4.7,4.7,2,8,2z M8,14c-1.3,0-2.5-0.4-3.5-1.1l8.4-8.4C13.6,5.5,14,6.7,14,8C14,11.3,11.3,14,8,14z"></path></svg>
                <span>Cancel</span>
              </button>
            </React.Fragment>,
            document.querySelector('span.quick__note__inline span.actions')
          )
        )
      }
      {(this.props.overlay || this.state.overlay) && (
        <Overlay
          overlay="client"
          dates={this.state.selectedKeys}
          closeOverlay={this.closeOverlay.bind(this)}></Overlay>
      )}
      </React.Fragment>
    )

  }

}

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleList);
