import React from 'react';
import { CardElement, injectStripe } from 'react-stripe-elements';
import ReactDOM from 'react-dom';
import dateFns from 'date-fns';

class Overlay extends React.Component {

  constructor(props) {
    super(props);

    this.deleteClientButtonRef = React.createRef();

    this.state = {
      initialLoad: true,
      counter: 1,
      overlay: this.props.overlay,
      overlay_history: ['client'],
      new: false,
      loading: false,
      client: this.props.client
    }

  }

  componentDidMount(){
    document.addEventListener("keydown", this.keyPress.bind(this), false);
  }

  componentWillUnmount(){
    document.removeEventListener("keydown", this.keyPress.bind(this), false);
  }

  keyPress(e, field=false){

    // escape
    // if(e.keyCode === 27) {
    //   this.props.closeOverlay(e)
    // }

    // enter
    if(e.keyCode === 13) {
      if (this.deleteClientButtonRef.current) {
        this.deleteClientButtonRef.current.click()
      }
    }

  }

  confirmPauseSubscription(e) {
    this.props.pause(this.props.subscription.id);
    this.setState({
      pause_loading: true
    })
  }

  confirmCancelSubscription(e) {
    this.props.cancel(this.props.subscription.id);
    this.setState({
      cancel_loading: true
    })
  }

  render() {

    const close =
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
        <g strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" fill="none" stroke="currentColor" strokeMiterlimit="10">
          <path d="M19 5L5 19M19 19L5 5"/>
        </g>
      </svg>

    const extend =
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10">
          <path d="M7.5.5l8 7.5-8 7.5zM5 11.281L.5 15.5V.5L5 4.719"/>
        </g>
      </svg>

    const pause =
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10">
          <path d="M1.5.5h4v15h-4zM10.5.5h4v15h-4z"/>
        </g>
      </svg>

    const icon_delete =
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10">
          <path d="M2.5 6.5v7c0 1.105.895 2 2 2h7c1.105 0 2-.895 2-2v-7M.5 3.5h15M5.5 3.5v-3h5v3"/>
        </g>
      </svg>

    return (
      ReactDOM.createPortal(

      <div className="focus">
        <div className={`overlay__blocks counter__1 initialLoad`}>

          <div className="overlay__block delete">
            <div className="header">
              <h2>What do you want to do?</h2>
              <button
                onClick={(e) => this.props.closeOverlay(e)}>
                {close}
              </button>
            </div>
            <div className="content">
              <div className="padding">
                <div className="option">
                  {this.props.subscription.state === 'trialing' ? (
                    <>
                    <h3>{ extend } Extend trial by 30 days</h3>
                    <p>If you need more time to get the most of your Slate trial, we'll <br/> extend your trial by another 30 days.</p>
                    <button
                      disabled={this.props.loading && this.state.pause_loading}
                      className={(this.props.loading && this.state.pause_loading) ? 'btn btn__loading' : 'btn'}
                      onClick={(e) => this.confirmPauseSubscription(e)}>
                      <div className="loader loader__button"></div>
                      <span>Extend my trial</span>
                    </button>
                    </>
                  ) : (
                    <>
                    <h3>{ pause } Pause my subscription for 3 months</h3>
                    <p>If you need a break, we'll pause payments for the next 3 months. You will still have access to your Slate account during this time.</p>
                    <button
                      disabled={this.props.loading && this.state.pause_loading}
                      className={(this.props.loading && this.state.pause_loading) ? 'btn btn__loading' : 'btn'}
                      onClick={(e) => this.confirmPauseSubscription(e)}>
                      <div className="loader loader__button"></div>
                      <span>Pause my subscription</span>
                    </button>
                    </>
                  )}
                </div>
                <hr />
                <div className="option delete">
                  <h3>{ icon_delete } Cancel my subscription</h3>
                  <p><b>You will lose access to Slate as soon as you do this</b>. You can get <br/>access again by resubscribing at any time.</p>
                  <button
                    disabled={this.props.loading && this.state.cancel_loading}
                    className={(this.props.loading && this.state.cancel_loading) ? 'btn btn__delete btn__loading' : 'btn btn__delete'}
                    onClick={(e) => this.confirmCancelSubscription(e)}>
                    <div className="loader loader__button"></div>
                    <span>Cancel my subscription</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="overlay__bg"
          onClick={(e) => this.props.closeOverlay(e) }></div>

      </div>, document.querySelector('.overlay')
      )
    )


  }

}

class CheckoutForm extends React.Component {

  constructor(props) {
    super();
    this.state = {
      error: false,
      loading: false,
      duration: false // monthly is false, yearly is true
    };
  }

  componentDidUpdate(prevProps,prevState) {

    if( prevProps.subscription !== this.props.subscription) {
      
      this.setState({
        loading: false
      })

    }

  }

  handleSubmit = (e) => {

    e.preventDefault();

    this.props.stripe.createToken({name: this.props.currentUser.name}).then(res => {

      if (res.error) {

        this.setState({
          error: res.error.message,
          loading: false
        })

      } else {

        this._element.clear(); // clears card input

        this.setState({
          error: false,
          loading: true
        })

        this.props.submit(res.token.id, this.state.duration)

      }

    });

  };

  switchDuration(value) {

    this.setState({duration: value})

  }

  closeOverlay = (e) => {

    e.preventDefault();

    this.setState({
      overlay: false,
      loading: false
    })

  }

  render() {

    let color;

    if( !this.props.subscription ) {

      color = 'inherit';

    } else {

      color = this.props.currentUser.dark_mode ? 'white' : 'inherit';

    }

    return (
      <div className="field">

        <div className="checkout">

          {(!this.props.subscription) &&
            <div>
              <label onClick={() => {this.switchDuration(false)}}>
                <input type="radio" name="duration" value="monthly" checked={!this.state.duration ? true : false} />
                Bill Monthly
              </label>

              <label onClick={() => {this.switchDuration(true)}}>
                <input type="radio" name="duration" value="annually" checked={this.state.duration ? true : false} />
                Bill Annually (Save 33%)
              </label>
            </div>
          }

          <form onSubmit={this.handleSubmit}>

            <div>
              <CardElement onReady={(c) => this._element = c} style={{base: {fontSize: '14px', fontWeight: '300', color: color}}} />
            </div>

            {this.state.error &&
              <span className="error">{this.state.error}</span>
            }

            {(!this.props.subscription) &&
            <ul>
              {(this.props.currentUser.available_plans.beta) &&
                <li>20% discount for being part of our early access <span role="img" aria-label="heart">❤️</span></li>
              }
              <li>
                You will be charged {this.state.duration ? this.props.currentUser.available_plans.annual : this.props.currentUser.available_plans.monthly }
              </li>
              {(this.props.currentUser.trial_ends_at && this.props.currentUser.state == 'trialing') &&
                <li>
                  You will not be charged until your trial ends on {dateFns.format(this.props.currentUser.trial_ends_at, 'DD/MM/YYYY')}.
                </li>
              }
              {!this.props.currentUser.trial_ends_at && this.props.currentUser.available_plans.trialable &&
                <li>
                  You will not be charged until your {this.props.currentUser.available_plans.trial_period} trial ends
                </li>
              }
              <li>You can cancel at any time</li>
            </ul>
            }

            <div className="field field__buttons">
              {(this.props.logout) &&
                <a href="#" onClick={this.props.logout}>Logout</a>
              }
              {(!this.props.subscription) &&
                <button className={this.state.loading ? 'btn btn__loading' : 'btn'} disabled={this.state.loading}>
                  <div className="loader loader__button"></div>
                  {(this.props.currentUser.trial_ends_at && this.props.currentUser.state == 'trialing') ? (
                    <span>Add payment details</span>
                  ) : (this.props.currentUser.available_plans.trialable) ? (
                    <span>Start your {this.props.currentUser.available_plans.trial_period} trial</span>
                  ) : (
                    <span>Start Subscription</span>
                  )}
                </button>
              }
              {(this.props.subscription && (this.props.subscription.state === 'active' || this.props.subscription.state === 'trialing')) &&
                <React.Fragment>
                  <button
                    disabled={this.props.loading}
                    className={this.props.loading ? 'btn btn__loading' : 'btn'}>
                    <div className="loader loader__button"></div>
                    <span>Update Payment Method</span>
                  </button>
                  <a href="#" className="btn btn__cancel" onClick={this.props.handleCancel}>Cancel Subscription</a>
                </React.Fragment>
              }
            </div>

          </form>

          {(this.props.subscription && this.props.overlay) && (
            <Overlay
              overlay={this.props.overlay}
              subscription={this.props.subscription}
              currentUser={this.props.currentUser}
              cancel={this.props.cancel}
              pause={this.props.pause}
              loading={this.props.loading}
              closeOverlay={this.props.closeOverlay}></Overlay>
          )}

        </div>

      </div>
    );
  }
}

export default injectStripe(CheckoutForm);
