import logo from './images/logo.svg';
import dashboard from './images/dashboard.svg';
import schedule from './images/schedule.svg';
import calendar from './images/calendar.svg';
import performance from './images/performance.svg';
import tasks from './images/tasks.svg';
import clients from './images/clients.svg';
import settings from './images/settings.svg';
import login from './images/login.svg';
import logout from './images/logout.svg';
import onboarding__00 from './images/onboarding__00.svg';
import onboarding__01 from './images/onboarding__01.svg';
import onboarding__02 from './images/onboarding__02.svg';
import onboarding__03 from './images/onboarding__03.svg';
import onboarding__04 from './images/onboarding__04.svg';
import onboarding__05 from './images/onboarding__05.svg';
import onboarding__06 from './images/onboarding__06.svg';
export default {
    logo,
    dashboard,
    schedule,
    calendar,
    performance,
    tasks,
    clients,
    settings,
    login,
    logout,
    onboarding__00,
    onboarding__01,
    onboarding__02,
    onboarding__03,
    onboarding__04,
    onboarding__05,
    onboarding__06
}
