import React, { useState, useEffect } from 'react';
import { Route, Switch } from 'react-router';
import Nav from './components/Nav';
import Home from './components/Home';
import Clients from './components/Clients';
import AdminUsers from './components/Admin/Users';
import AdminUser from './components/Admin/User';
import Projects from './components/Projects';
import Invoices from './pages/Invoices';
import InvoiceNew from './pages/InvoiceNew';
import InvoiceEdit from './pages/InvoiceEdit';
import Settings from './components/Settings';
import Billings from './components/Billings';
import PaymentMethod from './components/PaymentMethod';
import Schedule from './components/Schedule';
import Year from './components/Year';
import Login from './components/Login';
import Register from './components/Register';
import RegisterDemo from './components/RegisterDemo';
import ForgottenPassword from './components/ForgottenPassword';
import ResetPassword from './components/ResetPassword';
import Unsubscribe from './components/Unsubscribe';
import PrivateRoute from './components/PrivateRoute';
import dateFns from 'date-fns';
import {isNil} from 'lodash'
import { history } from './configureStore';

// const mapStateToProps = state => {
//   return {
//     redirectTo: state.common.redirectTo,
//     currentUser: state.common.currentUser,
//     subscription: state.common.subscription
//   }
// };
//
// const mapDispatchToProps = dispatch => ({
// });

const AppRoute = (props) => {

  const isLoggedIn = window.localStorage.getItem('jwt');

  const billingRequired = (props) => {
    const {currentUser = {}, subscription = null} = props;

    const expiredTrial = (currentUser.state === 'trialing' && dateFns.isPast(currentUser.trial_ends_at) && subscription === null)
    const cancelledAndSubcriptionExpired = (currentUser.state === 'cancelled' && (!isNil(currentUser.paid_subscription_ends_at) && dateFns.isPast(currentUser.paid_subscription_ends_at)))
    const registeredButNoSubscription = (currentUser.state === 'registered' && subscription === null )

    return (
            expiredTrial || cancelledAndSubcriptionExpired || registeredButNoSubscription
          )

  }

  const [path, setPath] = useState( window.location.pathname !== '/' ? window.location.pathname.split('/')[1] : '/' );

  useEffect(() => {
    // Listen for changes to the current location.
    const listen = history.listen((location, action) => {
      if(location.pathname.indexOf('/month/') >= 0) {
        setPath('month');
      } else if(location.pathname.indexOf('/year/') >= 0) {
        setPath('year');
      } else if(location.pathname.indexOf('/clients') >= 0) {
        setPath('clients');
      } else if(location.pathname.indexOf('/projects') >= 0) {
        setPath('projects');
      } else if(location.pathname.indexOf('/invoices') >= 0) {
        setPath('invoices');
      } else if(location.pathname.indexOf('/settings') >= 0) {
        setPath('settings');
      } else if(location.pathname.indexOf('/admin') >= 0) {
        setPath('admin');
      } else {
        setPath('/');
      }
    })
  }, [])

  return (
    isLoggedIn ? (

      // show payment screen if trial is over and no subcription found (excluding admins)
      (
        billingRequired({ currentUser: props.currentUser, subscription: props.subcription}) && (!props.currentUser.admin)
      ) ? (
        <React.Fragment>
          <main>
            <Billings />
          </main>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Nav
            activeMonth={props.activeMonth}
            redirectTo={props.redirectTo}
            currentUser={props.currentUser}
            currentPath={path} />
          <main className={props.currentUser.demo ? 'demo' : 'user'}>
            <Switch>
              <PrivateRoute exact path="/" component={Home}/>
              <PrivateRoute exact path="/month/:month/:year" component={Schedule}/>
              <PrivateRoute exact path="/year/:year" component={Year}/>
              <PrivateRoute exact path="/clients" component={Clients}/>
              <PrivateRoute exact path="/clients/:slug" component={Clients}/>
              <PrivateRoute exact path="/projects" component={Projects}/>
              <PrivateRoute exact path="/projects/:slug" component={Projects}/>
              <PrivateRoute exact path="/invoices" component={Invoices}/>
              <PrivateRoute exact path="/invoices/new" component={InvoiceNew}/>
              <PrivateRoute exact path="/invoices/:invoiceId/edit" component={InvoiceEdit}/>
              <PrivateRoute exact path="/invoices/:slug" component={Invoices}/>
              <PrivateRoute exact path="/settings" component={Settings}/>
              <PrivateRoute exact path="/settings/billing" component={Billings}/>
              <PrivateRoute exact path="/settings/billing/payment" component={PaymentMethod}/>
              <PrivateRoute path="/unsubscribe" component={Unsubscribe} />
              <PrivateRoute path="/admin/users/:slug" component={AdminUser} />
              <PrivateRoute path="/admin/users" component={AdminUsers} />
            </Switch>
            <Route path="/login" component={Login} />
            <Route path="/demo" component={RegisterDemo} />
          </main>
        </React.Fragment>
      )
    ) : (
      <React.Fragment>
        <PrivateRoute exact path="/" component={Home}/>
        <PrivateRoute exact path="/month/:month/:year" component={Schedule}/>
        <PrivateRoute exact path="/year/:year" component={Year}/>
        <PrivateRoute exact path="/clients" component={Clients}/>
        <PrivateRoute exact path="/clients/:slug" component={Clients}/>
        <PrivateRoute exact path="/projects" component={Projects}/>
        <PrivateRoute exact path="/invoices" component={Invoices}/>
        <PrivateRoute exact path="/settings" component={Settings}/>
        <PrivateRoute exact path="/settings/billing" component={Billings}/>
        <Route path="/login" component={Login} />
        <Route path="/demo" component={Register} />
        <Route path="/password/forgot" component={ForgottenPassword} />
        <Route path="/password/reset" component={ResetPassword} />
        <Route path="/register" component={Register} />
        <Route path="/unsubscribe" component={Unsubscribe} />
      </React.Fragment>
    )
  )

}

export default AppRoute
//export default connect(mapStateToProps, mapDispatchToProps)(AppRoute);
