import React from 'react';
import { connect } from 'react-redux';
import dateFns from 'date-fns';
import { Link } from 'react-router-dom';
import ScheduleList from './ScheduleList';
import Month from './Month';
import { history } from '../../configureStore';

import agent from '../../agent';
import {
  SCHEDULE_LOADED,
  SCHEDULE_UNLOADED,
  MONTH_LOADED,
  SWITCH_MONTH,
  CHANGE_PREV_MONTH,
  CHANGE_NEXT_MONTH,
  LOAD_DAYS
} from '../../constants/actionTypes';

const mapStateToProps = state => (
  {
    ...state.schedule,
   token: state.common.token,
   activeMonth: state.common.activeMonth,
   nextMonth: state.common.nextMonth,
   prevMonth: state.common.prevMonth,
   currentMonth: state.common.currentMonth,
   clients: state.clients,
   projects: state.projects
  }
);

const mapDispatchToProps = dispatch => ({
  onLoad: (month,payload) => {
    dispatch({ type: CHANGE_PREV_MONTH, month: month })
    dispatch({ type: CHANGE_NEXT_MONTH, month: dateFns.addMonths(month,1) })
    dispatch({ type: LOAD_DAYS, month: month })
    dispatch({ type: SCHEDULE_LOADED, payload: payload })
    let month_name = dateFns.format(month, 'MMMM').toLowerCase()
    let year = dateFns.format(month, 'YYYY').toLowerCase()
    dispatch({ type: MONTH_LOADED, month: month, month_string: month_name + '-' + year, payload: agent.Month.show(month_name, year) })
  },
  onUnload: () =>
    dispatch({ type: SCHEDULE_UNLOADED }),
  loadDays: (month) => {
    dispatch({ type: LOAD_DAYS, month: month })
    dispatch({ type: CHANGE_PREV_MONTH, month: month })
    dispatch({ type: CHANGE_NEXT_MONTH, month: dateFns.addMonths(month,1) })
  },
  switchMonth: (month) => {
    dispatch({ type: SWITCH_MONTH, month: month })
  },
  loadMonth: (month) => {
    let month_name = dateFns.format(month, 'MMMM').toLowerCase()
    let year = dateFns.format(month, 'YYYY').toLowerCase()
    dispatch({ type: MONTH_LOADED, month: month, month_string: month_name + '-' + year, payload: agent.Month.show(month_name, year) })
  },
  loadSchedule: (payload) => {
    dispatch({ type: SCHEDULE_LOADED, payload: payload })
  }
});

const Title = (props) => {

  let title = 'Schedule';

  return (
    <header>
      <h2>{title}</h2>
      <span className="schedule__reset">
        <Link to={{
          pathname: '/month/' + dateFns.format(props.currentMonth, 'MMMM/YYYY').toLowerCase(),
          state: {
            trigger: 'click'
          }
        }}>
        <button>Go to Today</button></Link>
      </span>
    </header>
  );
}

class Schedule extends React.Component {

  constructor(props){
    super(props);

    this.trigger = 'PUSH';

  }

  componentWillMount() {

    var months = {
      'january'   : '01',
      'february'  : '02',
      'march'     : '03',
      'april'     : '04',
      'may'       : '05',
      'june'      : '06',
      'july'      : '07',
      'august'    : '08',
      'september' : '09',
      'october'   : '10',
      'november'  : '11',
      'december'  : '12'
    }

    let url_date = window.location.pathname.match(/\/month\/(.*)\/([0-9]{4})/);
    let date = dateFns.parse(url_date[2] + '-' + months[url_date[1]] + '-01');
    let nextMonth = dateFns.startOfMonth(dateFns.addMonths(date,1));

    this.props.switchMonth(date);
    this.props.loadMonth(date);
    this.props.loadDays(date);
    this.props.loadSchedule(
      agent.Schedule.all(
        dateFns.format(date, 'DD-MM-YYYY'),
        dateFns.format(dateFns.endOfMonth(nextMonth), 'DD-MM-YYYY')
      )
    );

  }

  componentWillUnmount() {
    this.props.onUnload();
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {

    var months = {
      'january'   : '01',
      'february'  : '02',
      'march'     : '03',
      'april'     : '04',
      'may'       : '05',
      'june'      : '06',
      'july'      : '07',
      'august'    : '08',
      'september' : '09',
      'october'   : '10',
      'november'  : '11',
      'december'  : '12'
    }

    if(prevProps.match.url !== this.props.match.url) {
      
      let url_date = this.props.location.pathname.match(/\/month\/(.*)\/([0-9]{4})/);
      let date = dateFns.parse(url_date[2] + '-' + months[url_date[1]] + '-01');
      let nextMonth = dateFns.startOfMonth(dateFns.addMonths(date,1));

      let trigger = this.props.location.state ? this.props.location.state.trigger : 'unknown';

      if (history.action === 'POP') {

        this.trigger = 'POP';

      } else {

        this.trigger = 'PUSH';

      }

      if (trigger === 'click' || history.action === 'POP') {

        this.props.switchMonth(date);
        this.props.loadMonth(date);
        this.props.loadDays(date);
        this.props.loadSchedule(
          agent.Schedule.all(
            dateFns.format(date, 'DD-MM-YYYY'),
            dateFns.format(dateFns.endOfMonth(nextMonth), 'DD-MM-YYYY')
          )
        );

      } else {

        this.props.switchMonth(date);
        this.props.loadMonth(date);

      }

    }

  }

  shouldComponentUpdate(nextProps, nextState) {

    if (nextProps.match !== this.props.match || nextProps.activeMonth !== this.props.activeMonth || nextProps.nextMonth !== this.props.nextMonth || nextProps.prevMonth !== this.props.prevMonth) {
      return true;
    } else {
      return false;
    }

  }

  render() {
    
    if (!this.props.days || (typeof this.props.clients === 'undefined') || (typeof this.props.projects === 'undefined')) {
      return (
        <section className="schedule__calendar">
          <div className="loading"><div className="loader"></div></div>
          <div className="bottom" data-month={this.props.nextMonth}></div>
        </section>
      );
    }
    return (
      <React.Fragment>
        <section className="component__route component__route__month">
          <Month
            trigger={this.props.trigger} />
        </section>
        <section className="component__schedule">
          <Title
            activeMonth={this.props.activeMonth}
            currentMonth={this.props.currentMonth}
            nextMonth={this.props.nextMonth}
            prevMonth={this.props.prevMonth} />
          <ScheduleList
            history={this.props.history}
            currentMonth={this.props.currentMonth}
            activeMonth={this.props.activeMonth}
            nextMonth={this.props.nextMonth}
            prevMonth={this.props.prevMonth}
            trigger={this.trigger} />
        </section>
      </React.Fragment>
    );

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Schedule);
