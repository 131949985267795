import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import ListErrors from '../ListErrors';
import FocusTrap from 'focus-trap-react';
import { connect } from 'react-redux';
import { _, get } from 'lodash';
import update from 'immutability-helper';
import agent from '../../agent';
import getSymbolFromCurrency from 'currency-symbol-map';
import { components } from 'react-select';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from "react-day-picker/moment";
import { showNotificationWithTimeout } from '../../components/Notifications';
import { validateEmail } from '../../components/Validate';
import dateFns from 'date-fns';

import {
  CLOSE_OVERLAY,
  SETTINGS_SAVED,
  SETTINGS_UPDATE,
  CLIENT_UPDATE,
  INVOICE_PAYMENT_CREATE,
  INVOICE_EMAIL
} from '../../constants/actionTypes';

const Input = ({ autoComplete, ...props }) => <components.Input {...props} autoComplete="new-password" />;
const FORMAT = 'DD/MM/YYYY';

const mapStateToProps = (state, ownProps) => ({
  ...state,
  errors: state.clients.errors
});

const mapDispatchToProps = dispatch => ({

  updateBusiness: user => {
    dispatch({
      type: SETTINGS_SAVED,
      payload: agent.Auth.save(user).then((res) => {
        if(!res.body.errors) {
          showNotificationWithTimeout(dispatch, 'Details updated')
          dispatch({ type: CLOSE_OVERLAY })
        }
        return res;
      })
    })
    dispatch({ type: SETTINGS_UPDATE })
  },

  updateClient: (client, updateSelectedClient) => {
    dispatch({
      type: CLIENT_UPDATE,
      payload: agent.Clients.update(client).then((res) => {
        if(!res.body.errors) {
          // update client in InvoicesForm.js
          updateSelectedClient(res.body)
          showNotificationWithTimeout(dispatch, 'Client updated')
          dispatch({ type: CLOSE_OVERLAY })
        }
        return res;
      })
    })
  },

  createInvoicePayment: (invoiceId, invoicePayment) => {
    dispatch({
      type: INVOICE_PAYMENT_CREATE,
      payload: agent.InvoicePayments.create(invoiceId, invoicePayment).then((res) => {
        if(!res.body.errors) {
          showNotificationWithTimeout(dispatch, 'Invoice paid')
          dispatch({ type: CLOSE_OVERLAY })
        }
        return res;
      })
    })
  },

  emailInvoice: (invoiceId, emailInvoice) => {
    dispatch({
      type: INVOICE_EMAIL,
      payload: agent.Invoices.email(invoiceId, emailInvoice).then((res) => {
        if(!res.body.errors) {
          showNotificationWithTimeout(dispatch, 'Invoice emailed')
          dispatch({ type: CLOSE_OVERLAY })
        }
        return res;
      })
    })
  },

})

export let scrollPosition = 0;

class Overlay extends React.Component {

  constructor(props) {
    super(props);

    this.clientButtonRef = React.createRef();
    this.amountReceived = React.createRef();

    this.state = {
      initialLoad: true,
      counter: 1,
      overlay: this.props.common.overlay,
      overlay_history: [this.props.overlayName],
      new: false,
      loading: false,
      client: this.props.client,
      invoice: this.props.invoice,
      amount_received: '',
      invoicePayment: {
        invoice_id: get(this.props.invoice, 'id', null)
      },
      emailInvoice: {
        invoice_id: get(this.props.invoice, 'id', null),
        recipients: get(this.props.invoice, 'contact_email', null),
        message: 'Hi, please find attached my most recent invoice.'
      },
      currentUser: this.props.currentUser,
      business_attributes: get(this.props.currentUser, 'business', null)
    }

  }

  componentDidMount(){
    document.addEventListener("keydown", this.keyPress.bind(this), false);
  }

  componentWillUnmount(){
    document.removeEventListener("keydown", this.keyPress.bind(this), false);
  }

  componentDidUpdate(prevProps, prevState) {

    if(prevState.loading && this.props.clients.errors) {

      this.setState({
        loading: false
      })

    }

  }

  keyPress(e, field=false){

    // up/down arrow
    if ( field && [38,40].includes( e.keyCode ) ) {

      let value = parseInt(e.target.innerText)

      // down
      if( e.keyCode === 40 ) {

        if (value === 0) {
          return false
        }

        --value

      }

      // up
      if( e.keyCode === 38 ) {
        ++value
      }

      const [split_field, parent_key] = field.split('.').reverse()
      let newData;

      if (parent_key === undefined) {

        newData = update(this.state, {
          [split_field]: {$set: value}
        })

      } else {

        newData = update(this.state, {
          [parent_key]: {
            [split_field]: {$set: value}
          }
        })

      }

      this.setState(newData);

      // cleanup
      newData = null;

    }

    // enter
    if(e.keyCode === 13) {

      if (e.target.nodeName === 'TEXTAREA') {
        return false;
      }

      if (this.clientButtonRef.current) {
        this.clientButtonRef.current.click()
      }

    }

  }

  updateAmountReceived(value) {

    let amount = parseFloat(value).toFixed(2);

    this.updateField('invoicePayment.gross_total_in_base_currency', amount)
    this.setState({
      amount_received: amount
    })
  }

  updateField(field, value) {

    const [split_field, parent_key] = field.split('.').reverse()
    let newData;

    if (parent_key === undefined) {

      newData = update(this.state, {
        [split_field]: {$set: value}
      })

    } else {

      newData = update(this.state, {
        [parent_key]: {
          [split_field]: {$set: value}
        }
      })

    }

    this.setState(newData);

    // cleanup
    newData = null;

  }

  updateBusiness(e, business) {

    let newData = update(this.state.currentUser, {
      business_attributes: { $set: business }
    });

    this.setState(newData);
    this.props.updateBusiness( newData );

    // cleanup
    newData = null;

  }

  updateClient(e, client) {

    if( validateEmail(client.email) ) {
      this.props.updateClient( client, this.props.updateSelectedClient );
    }

  }

  createInvoicePayment(e, invoicePayment) {

    this.props.createInvoicePayment( invoicePayment.invoice_id, invoicePayment );

  }

  emailInvoice(e, emailInvoice) {

    this.props.emailInvoice( emailInvoice.invoice_id, emailInvoice );

  }

  render() {

    const close =
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
        <g strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" fill="none" stroke="currentColor" strokeMiterlimit="10">
          <path d="M19 5L5 19M19 19L5 5"/>
        </g>
      </svg>

    return (
      ReactDOM.createPortal(

      <FocusTrap>
      <div className="focus">
        <div className={`overlay__blocks counter__${this.state.counter} ${this.state.initialLoad && 'initialLoad'}`}>

        {this.state.overlay_history.includes('missing_business') && (
          <div className="overlay__block overflow">
            <div className="header">
              <h2>Before you get started...</h2>
              <button
                onClick={(e) => this.props.closeOverlay(e)}>
                {close}
              </button>
            </div>
            <div className="content" tabIndex="1">
              <div className="padding">
                <div className="field">
                  <p>Before you can setup and send invoices, we need you to provide the name and legal address for your business to include on invoices.</p>
                  <hr />
                </div>
                <div className="field field__helper">
                  <label className="padding border">
                    <span>Business Name</span>
                    <div>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={get(this.state.business_attributes, 'name', '')}
                        onKeyDown={(e) => this.keyPress(e)}
                        onChange={(e) => this.updateField('business_attributes.name', e.target.value)} />
                    </div>
                  </label>
                </div>
                <div className="field">
                  <label className="padding border">
                    <span>Business Address</span>
                    <div>
                      <textarea
                        className="form-control"
                        defaultValue={get(this.state.business_attributes, 'address', '')}
                        onKeyDown={(e) => this.keyPress(e)}
                        onChange={(e) => this.updateField('business_attributes.address', e.target.value)} />
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <div className="actions">
              <button
                ref={this.clientButtonRef}
                disabled={this.props.common.loading || (!this.state.business_attributes.name || !this.state.business_attributes.address)}
                className={this.props.common.loading ? 'btn btn__loading' : 'btn'}
                onClick={(e) => this.updateBusiness(e, this.state.business_attributes)}>
                <div className="loader loader__button"></div>
                <span>Save Details</span>
              </button>
            </div>
          </div>
        )}

        {this.state.overlay_history.includes('email_invoice') && (
          <div className="overlay__block overflow">
            <div className="header">
              <h2>Email Invoice</h2>
              <button
                onClick={(e) => this.props.closeOverlay(e)}>
                {close}
              </button>
            </div>
            <div className="content" tabIndex="1">
              <div className="padding">
                <div className="field">
                  <p>Now you've issued this invoice, you can either email it directly to your client using this form or
                    dowload it and send it to them separately.</p>
                  <hr />
                </div>
                <div className="field">
                  <label className="padding border">
                    <span>Send To</span>
                    <div>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={get(this.state.emailInvoice, 'recipients', '')}
                        onChange={(e) => this.updateField('emailInvoice.recipients', e.target.value)} />
                    </div>
                  </label>
                  <small>Tip: Seperate email addresses using a comma</small>
                </div>
                <div className="field">
                  <label className="padding border">
                    <span>Message</span>
                    <div>
                      <textarea
                        className="form-control tall"
                        defaultValue={get(this.state.emailInvoice, 'message', '')}
                        onChange={(e) => this.updateField('emailInvoice.message', e.target.value)} />
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <div className="actions">
              <div className="buttons tight">
                <a href={`/api/invoices/${this.state.invoice.id}/pdf`} download className="btn btn__outline">
                  <span>Download PDF</span>
                </a>
              </div>
              <button
                ref={this.clientButtonRef}
                disabled={this.props.common.loading}
                className={this.props.common.loading ? 'btn btn__loading' : 'btn'}
                onClick={(e) => this.emailInvoice(e, this.state.emailInvoice)}>
                <div className="loader loader__button"></div>
                <span>Email Invoice</span>
              </button>
            </div>
          </div>
        )}

        {this.state.overlay_history.includes('mark_as_paid') && (
          <div className="overlay__block overflow">
            <div className="header">
              <h2>Mark as Paid</h2>
              <button
                onClick={(e) => this.props.closeOverlay(e)}>
                {close}
              </button>
            </div>
            <div className="content" tabIndex="1">
              <div className="padding">
                <div className="field">
                  <p>The total for invoice { this.state.invoice.invoice_number } was {this.state.invoice.total_formatted} </p>
                  <hr />
                </div>
                <div className="field field__helper">
                  <label className="padding border">
                    <span
                      className="justify">
                      <span>Amount Received</span>
                      <span
                        className="btn btn__tight btn__link"
                        onClick={(e) => this.updateAmountReceived(this.state.invoice.total)}
                      >
                        Set full amount of { this.state.invoice.total_formatted }
                      </span>
                    </span>
                    <div className="helper">
                      <span>{getSymbolFromCurrency(this.props.currency)}</span>
                      <input
                        ref={this.amountReceived}
                        defaultValue={ this.state.amount_received }
                        type="number"
                        className="form-control"
                        onKeyDown={(e) => this.keyPress(e)}
                        onChange={(e) => this.updateField('invoicePayment.gross_total_in_base_currency', e.target.value)} />
                    </div>
                  </label>
                  <small>This is the amount you received in your local currency {this.props.currency} ({getSymbolFromCurrency(this.props.currency)})</small>
                </div>
                <div className="field">
                  <label className="padding border">
                    <span>Date Received</span>
                    <div>
                      <DayPickerInput
                        format={FORMAT}
                        formatDate={formatDate}
                        parseDate={parseDate}
                        placeholder={FORMAT}
                        onDayChange={(e) => this.updateField('invoicePayment.date_received', e)}
                        dayPickerProps={{
                          disabledDays: {
                            before: dateFns.parse(this.state.invoice.date),
                            after: new Date()
                          }
                        }}
                      />
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <div className="actions">
              <button
                ref={this.clientButtonRef}
                disabled={this.props.common.loading}
                className={this.props.common.loading ? 'btn btn__loading' : 'btn'}
                onClick={(e) => this.createInvoicePayment(e, this.state.invoicePayment)}>
                <div className="loader loader__button"></div>
                <span>Save Payment</span>
              </button>
            </div>
          </div>
        )}

        {this.state.overlay_history.includes('client') && (
          <div className="overlay__block">
            <div className="header">
              <h2>We're missing some information</h2>
              <button
                onClick={(e) => this.props.closeOverlay(e)}>
                {close}
              </button>
            </div>

            <div className="content" tabIndex="1">

              <div className="padding">

                <div className="field">
                  <p>Before we can send your invoice we need you to provide some extra information for {this.state.client.name}.</p>
                  <hr />
                </div>

                <div className="field">
                  <label className="padding border">
                    <span>Email Address</span>
                    { this.state.errors }
                    <div
                      className="">
                        <input
                          type="email"
                          className="form-control"
                          value={this.state.client.email}
                          onKeyDown={(e) => this.keyPress(e)}
                          onChange={(e) => this.updateField('client.email', e.target.value)} />
                    </div>
                  </label>
                  <small>Where should we send your invoice to?</small>
                </div>

                <div className="field">
                  <label className="padding">
                    <span>Contact Name</span>
                    <div
                      className="">
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.client.contact_name}
                          onKeyDown={(e) => this.keyPress(e)}
                          onChange={(e) => this.updateField('client.contact_name', e.target.value)} />
                    </div>
                  </label>
                </div>

                <div className="field">
                  <label className="padding">
                    <span>Client Address</span>
                    <textarea
                      type="text"
                      className="form-control"
                      value={this.state.client.address}
                      onKeyDown={(e) => this.keyPress(e)}
                      onChange={(e) => this.updateField('client.address', e.target.value)} />
                  </label>
                </div>

              </div>

            </div>
            <div className="actions">
              <button
                ref={this.clientButtonRef}
                disabled={this.props.common.loading || (!this.state.client.email || !this.state.client.contact_name || !this.state.client.address)}
                className={this.props.common.loading ? 'btn btn__loading' : 'btn'}
                onClick={(e) => this.updateClient(e, this.state.client)}>
                <div className="loader loader__button"></div>
                <span>Update Client</span>
              </button>
            </div>
          </div>

        )}

          </div>

          <div
            className="overlay__bg"
            onClick={(e) => this.props.closeOverlay(e) }
          ></div>

          <ListErrors errors={this.props.errors} />

        </div>
        </FocusTrap>,
        document.querySelector('.overlay')
      )
    )


  }

}

export default connect(mapStateToProps, mapDispatchToProps)(Overlay);
