import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import dateFns from 'date-fns';
import _ from 'lodash';
import { history } from '../../configureStore';
import { Item } from './Item';
import {ReactComponent as ErrorIcon} from '../../images/error.svg';
import Tooltip from '../Tooltip';
import { ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import {ReactComponent as TimeIcon} from '../../images/icons/time.svg';
import {ReactComponent as PriceIcon} from '../../images/icons/price.svg';
import {ReactComponent as InvoiceIcon} from '../../images/icons/invoice.svg';
import {ReactComponent as ToggleIcon} from '../../images/icons/toggle.svg';
import {ReactComponent as CalendarIcon} from '../../images/icons/calendar.svg';

import agent from '../../agent';
import {
  REQUEST_PROJECTS,
  PROJECT_LOADED
} from '../../constants/actionTypes';

const mapStateToProps = (state, ownProps) => (
  {
    loading: state.common.loading
  }
);

const mapDispatchToProps = dispatch => ({

  onLoad: (payload) => {
    dispatch({ type: REQUEST_PROJECTS })
    dispatch({ type: PROJECT_LOADED, payload: payload })
  },

});

class Project extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      tab: 'schedule'
    }

  }

  componentDidMount(){

    this.props.onLoad(
      agent.Projects.show( this.props.match.params.slug )
    );

  }

  componentDidUpdate(prevProps,prevState){

    if(prevProps.match.params.slug !== this.props.match.params.slug) {

      this.props.onLoad(
        agent.Projects.show( this.props.match.params.slug )
      );

    }

  }

  toggleMonth(e, month) {

    e.stopPropagation();
    document.querySelector(`[data-month='${month}']`).classList.toggle('collapsed');

  }

  updateProject(e, id, state, invoice) {

    this.props.transitionState(id, state, invoice);

    if (invoice && this.props.project.billing_type === "fixed_price") {
      this.props.openOverlay(e, 'invoice', this.props.project)
    }

  }

  switchTabs(e, tab) {

    e.stopPropagation();

    this.setState({
      tab: tab
    });

  }

  render() {

    if (this.props.isFetching || !this.props.project) {

      return (
        <div className="loading"><div className="loader"></div></div>
      )

    }

    const issues = <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
      <g stroke-linecap="round" stroke-linejoin="round" stroke-width="2" fill="none" stroke="#c31840" stroke-miterlimit="10">
        <circle cx="32" cy="32" r="29"/>
        <path d="M32 14v25"/>
        <circle cx="32" cy="47" r="1"/>
      </g>
    </svg>;

    const finished = <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
      <g stroke-width="2" strokeLinecap="round" strokeLinejoin="round" fill="none" stroke="currentColor" strokeMiterlimit="10">
        <path d="M2.5 22.5a7 7 0 007-7 7 7 0 007 7M2.5 22.5a7 7 0 017 7 7 7 0 017-7M48.5 22.5a7 7 0 007-7 7 7 0 007 7M48.5 22.5a7 7 0 017 7 7 7 0 017-7M38.283 9.701l-3.996-.581L32.5 5.5l-1.787 3.62-3.996.581 2.891 2.819-.682 3.98 3.574-1.878 3.574 1.878-.682-3.98 2.891-2.819zM59.244 48.701l-3.996-.581-1.787-3.62-1.788 3.62-3.996.581 2.892 2.819-.683 3.98 3.575-1.878 3.574 1.878-.683-3.98 2.892-2.819zM17.283 47.701l-3.996-.581L11.5 43.5l-1.787 3.62-3.996.581 2.891 2.819-.682 3.98 3.574-1.878 3.574 1.878-.682-3.98 2.891-2.819zM47.5 29.5a13.337 13.337 0 00-9 7M17.5 29.5a13.337 13.337 0 019 7M17.5 42.5a11.743 11.743 0 015-.426c4.363.591 10 4.126 10 18.426M47.5 42.5a11.743 11.743 0 00-5-.426c-4.363.591-10 4.126-10 18.426M32.5 22.5v18" />
      </g>
    </svg>;

    const on_track = <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
      <g stroke-width="2" strokeLinecap="round" strokeLinejoin="round" transform="translate(.5 .5)" fill="none" stroke="currentColor" strokeMiterlimit="10">
        <path d="M18 34l8 8 20-20" />
        <circle cx="32" cy="32" r="29" />
      </g>
    </svg>;

    const not_started = <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
      <g stroke-width="2" strokeLinecap="round" strokeLinejoin="round" fill="none" stroke="currentColor" strokeMiterlimit="10">
        <path d="M10.5 6.5h48v32h-48M10.5 22.5h48M26.5 6.5v32M42.5 6.5v32M10.5 2.5v60" />
      </g>
    </svg>;

    const cancelled = <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
      <g stroke-width="2" stroke-linecap="round" stroke-linejoin="round" transform="translate(.5 .5)" fill="none" stroke="currentColor" stroke-miterlimit="10">
        <path d="M52.493 11.507L11.507 52.493" />
        <circle cx="32" cy="32" r="29" />
      </g>
    </svg>;

    // redirect if project not found
    if (!this.props.project) {
      history.push('/projects');
      return false;
    }

    const data = [
      { name: 'worked', value: this.props.project.amount_worked_percentage },
      { name: 'remaining', value: this.props.project.estimate_remaining_percentage }
    ];
    const COLORS = ['#1381FF', '#78b0fd'];

    const work = this.props.project.months_worked.map(hash => {

      var work = _.orderBy(_.values(_.pickBy(this.props.project.work_days,function(value, key){
        return value.month_name === hash.month_name;
      })), ['date'],['desc']);

      if (!work.length) {
        return '';
      }

      return (
      <div className="block" key={hash.month_name} data-month={hash.month_name}>
        <header onClick={(e) => this.toggleMonth(e, hash.month_name)}>
          <h3>
            <Link to={{
              pathname: `/month/${hash.month}/${hash.year}`,
              state: {
                trigger: 'click'
              }
            }}>
              {hash.month_name}
            </Link>
          </h3>
          <div>
            <span>{hash.amount_worked_string} ({hash.revenue})</span>
            <button>
              <ToggleIcon />
            </button>
          </div>
        </header>
        <div className="list">
          {work.map(day => <Item day={day} project={this.props.project} invoiceWork={this.props.invoiceWork} rollbackInvoiceWork={this.props.rollbackInvoiceWork} />)}
        </div>
      </div>
      )

    })

    var you_have_contextual = this.props.project.state == 'finished' ? 'You'  : "You've"

    return (<React.Fragment>
      <div className="summary">

        <div className="data">

          <header>
            <div>
              <span>{this.props.project.client_name}</span>
              <h2>{this.props.project.title}</h2>
            </div>
            {this.props.project.billing_type === 'fixed_price' && (
            <Tooltip message={`You've worked ${this.props.project.amount_worked_percentage}% of your estimate`} position={'bottom'} size={'large'}>
              <PieChart width={96} height={48}>
                <Pie
                  isAnimationActive={false}
                  data={data}
                  startAngle={180}
                  endAngle={0}
                  cy={48}
                  innerRadius={32}
                  outerRadius={48}
                  paddingAngle={0}
                  dataKey="value"
                >
                  {
                    data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} strokeWidth={0} />)
                  }
                </Pie>
              </PieChart>
            </Tooltip>
            )}
          </header>
          {this.props.project.billing_type === 'fixed_price' && (
          <div className="row">
            <div className="title">
              <PriceIcon />
              <h4>
                You're charging a fixed price of {this.props.project.amount_formatted}.
                {this.props.project.amount_formatted !== this.props.project.amount_in_base_currency_formatted && (
                  <span> That's approximately {this.props.project.amount_in_base_currency_formatted}</span>
                )}
              </h4>
            </div>
          </div>
          )}
          {this.props.project.billing_type === 'time_spent' && (
          <div className="row">
            <div className="title">
              <PriceIcon />
              <h4>You're charging {this.props.project.project_rate_value_formatted}/{this.props.project.project_rate_unit_formatted}.
                {this.props.project.project_rate_value_formatted !== this.props.project.project_rate_value_in_base_currency_formatted && (
                  <span> That's approximately {this.props.project.project_rate_value_in_base_currency_formatted}/{this.props.project.project_rate_unit_formatted}</span>
                )}
              </h4>

            </div>
          </div>
          )}
          {this.props.project.amount_invoiced > 0 && (
          <div className="row">
            <div className="title">
              <InvoiceIcon />
              <h4>
                You've invoiced for {this.props.project.amount_invoiced_string} {this.props.project.amount_uninvoiced > 0 && (
                  `and have ${this.props.project.amount_uninvoiced_string} uninvoiced`
                )}.
              </h4>
            </div>
          </div>
          )}
          {this.props.project.deadline && (
            <div className="row">
              <div className="title">
                <CalendarIcon />
                <h4>{ you_have_contextual } set a deadline of {dateFns.format(this.props.project.deadline, 'D MMMM YYYY')} {(dateFns.isAfter(Date.now(), this.props.project.deadline) && this.props.project.state == 'active') && ' (Overdue)'}</h4>
              </div>
            </div>
          )}
        </div>
        <div className="status">
          {this.props.project.state === 'cancelled' && (<React.Fragment>
            <div>
              {cancelled}
              <span>This project has been cancelled.</span>
            </div>

            <button
              ref={this.clientButtonRef}
              className={this.props.loading ? 'btn btn__loading' : 'btn'} disabled={this.props.loading}
              onClick={(e) => this.updateProject(e, this.props.project.id, 'active', false)}>
              <div className="loader loader__button"></div>
              <span>Restart Project</span>
            </button>

            <button className="btn btn__outline" onClick={(e) => this.props.openOverlay(e, 'project', this.props.project)}>Edit Project</button>
          </React.Fragment>)}
          {this.props.project.state === 'pending' && (<React.Fragment>
            <div>
              {not_started}
              <span>Not started yet...</span>
            </div>

            <button
              ref={this.clientButtonRef}
              className={this.state.loading ? 'btn btn__loading' : 'btn'} disabled={this.state.loading}
              onClick={(e) => this.updateProject(e, this.props.project.id, 'active', false)}>
              <div className="loader loader__button"></div>
              <span>Start Project</span>
            </button>

            <button className="btn btn__outline" onClick={(e) => this.props.openOverlay(e, 'project', this.props.project)}>Edit Project</button>
          </React.Fragment>)}
          {this.props.project.state === 'active' && (<React.Fragment>
            <div>
              {this.props.project.issues.length > 0 ? (<React.Fragment>
                {issues}
                <span>Issues Detected</span>
              </React.Fragment>) : (<React.Fragment>
                {on_track}
                <span>This project is on track!</span>
              </React.Fragment>)}
            </div>

            <button
              ref={this.clientButtonRef}
              className={this.props.loading ? 'btn btn__loading' : 'btn'} disabled={this.props.loading}
              onClick={(e) => this.updateProject(e, this.props.project.id, 'finished', true)}>
              <div className="loader loader__button"></div>
              <span>Finish Project</span>
            </button>

            <button className="btn btn__outline" onClick={(e) => this.props.openOverlay(e, 'project', this.props.project)}>Edit Project</button>
          </React.Fragment>)}
          {(this.props.project.state === 'finished' || this.props.project.state === 'invoiced') && (<React.Fragment>
            <div>
              {finished}
              <span>This project has finished!</span>
            </div>

            <button
              ref={this.clientButtonRef}
              className={this.props.loading ? 'btn btn__loading' : 'btn'} disabled={this.props.loading}
              onClick={(e) => this.updateProject(e, this.props.project.id, 'active', false)}>
              <div className="loader loader__button"></div>
              <span>Restart Project</span>
            </button>

            <button className="btn btn__outline" onClick={(e) => this.props.openOverlay(e, 'project', this.props.project)}>Edit Project</button>
          </React.Fragment>)}
        </div>
      </div>

      <div className="options inset">
        <div className="list">
          <button
            className={this.state.tab === 'schedule' ? 'selected' : ''}
            onClick={(e) => this.switchTabs(e, 'schedule')}>Schedule</button>
          <button
            className={this.state.tab === 'statistics' ? 'selected' : ''}
            onClick={(e) => this.switchTabs(e, 'statistics')}>Statistics</button>
          <button
            className={this.state.tab === 'issues' ? 'selected' : ''}
            data-count={this.props.project.issues.length}
            onClick={(e) => this.switchTabs(e, 'issues')}>Issues</button>
        </div>
      </div>

      <div className="analysis">

        {(this.state.tab === 'issues') && (
          this.props.project.issues.length > 0 ? (
            <div className="block">
              <header>
                <h3>We've spotted a few things...</h3>
              </header>
              <div className="list">
                {this.props.project.issues.map(issue => {
                  return (
                  <div className="item">
                    <span className="icon"><ErrorIcon /></span>
                    {issue.title}
                    {(issue.token === 'remaining') && (
                      <button className="btn" onClick={(e) => this.props.openOverlay(e, 'auto-populate', this.props.project)}>Auto-populate</button>
                    )}
                  </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <div className="block">
              <header>
                <h3>No issues with this Project, all good :)</h3>
              </header>
            </div>
          )
        )}

        {(this.state.tab === 'statistics') && (
          <div className="block">
            <header>
              <h3>Here's how you're doing...</h3>
            </header>
            <div className="list">

              {this.props.project.billing_type === 'fixed_price' ? (
                <>
                <div className="item">
                  <span className="icon"><CalendarIcon /></span>
                  You estimated this project would take {this.props.project.estimated_time_formatted}.
                </div>
                <div className="item">
                  <span className="icon"><CalendarIcon /></span>
                  { you_have_contextual } scheduled {this.props.project.amount_scheduled ? this.props.project.amount_scheduled : 'no time yet'}.
                </div>
                <div className="item">
                  <span className="icon"><CalendarIcon /></span>
                  You have {this.props.project.estimate_remaining} remaining before going over your initial estimate.
                </div>
                <div className="item">
                  <span className="icon"><PriceIcon /></span>
                  Your total fixed price for this project was {this.props.project.amount_formatted}.
                </div>
                <div className="item">
                  <span className="icon"><PriceIcon /></span>
                  You're on track to earn {this.props.project.actual_rate}{this.props.project.rate_unit_short} for this project.
                </div>
                </>
              ) : (
                <>
                <div className="item">
                  <span className="icon"><PriceIcon /></span>
                  You're charging {this.props.project.project_rate_value_formatted}/{this.props.project.project_rate_unit_formatted}.
                </div>
                <div className="item">
                  <span className="icon"><CalendarIcon /></span>
                  { you_have_contextual } worked {this.props.project.amount_worked} so far.
                </div>
                <div className="item">
                  <span className="icon"><PriceIcon /></span>
                  { you_have_contextual } earned {this.props.project.amount_revenue_string} so far.
                </div>
                </>
              )}
            </div>
          </div>
        )}

        {(this.state.tab === 'schedule') && (
          <div className="schedule">
            {this.props.project.work_days.length === 0 ? (
              <div className="block">
                <header>
                  <h3>You haven't scheduled any work yet for this project.</h3>
                </header>
              </div>
            ) : (
              work
            )}
          </div>
        )}

      </div>
    </React.Fragment>);

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Project);
