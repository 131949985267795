import React from 'react';
import {Elements, StripeProvider} from 'react-stripe-elements';
import CheckoutForm from './CheckoutForm';
import agent from '../agent';
import { connect } from 'react-redux';
import images from '../images';
import Select, { components } from 'react-select';
import countryList from 'react-select-country-list';
import update from 'immutability-helper';
import {
  LOGOUT,
  SETTINGS_UPDATE,
  COUNTRY_SAVED,
  MASQUERADE,
  SUBSCRIPTION_CANCEL,
  SUBSCRIPTION_CREATE,
} from '../constants/actionTypes';

const mapStateToProps = state => (
  {
    ...state.settings,
    currentUser: state.common.currentUser,
    subscription: state.common.subscription
  }
);

const mapDispatchToProps = dispatch => ({
  createSubscription: (stripe_token_id, type) => {
    dispatch({ type: SUBSCRIPTION_CREATE, payload: agent.Subscription.create(stripe_token_id, type) })
  },
  cancelSubscription: subscription_id => {
    dispatch({ type: SUBSCRIPTION_CANCEL, payload: agent.Subscription.remove(subscription_id) })
  },
  onClickLogout: (currentUser) => {
    if(currentUser.masquerading){
      dispatch({ type: MASQUERADE, payload: agent.AdminUsers.revertMasquerade() })
    }else{
      dispatch({ type: LOGOUT, payload: agent.Auth.logout() })
    }
  },
  onSubmitForm: user => {
    dispatch({ type: SETTINGS_UPDATE })
    dispatch({ type: COUNTRY_SAVED, payload: agent.Auth.save(user) })
  }
});

const Input = ({ autoComplete, ...props }) => <components.Input {...props} autoComplete="new-password" />;

class Billings extends React.Component {

  constructor(props) {
    super();

    this.countries = countryList().getData()

    this.state = {
      country: props.currentUser.country ? {
        value: props.currentUser.country,
        label: countryList().getLabel( props.currentUser.country )
      } : false
    };
  }

  updateCountry = value => {
    this.setState({ country: value })
  }

  submitForm(ev) {

    ev.preventDefault();

    let newData = update(this.state, {
      'country': {$set: this.state.country.value}
    })

    this.props.onSubmitForm(newData);

    // cleanup
    newData = null;

    return false;
  }

  render() {
    return (
      <section className="component__route component__route__billing">

        <img src={images.logo} alt="Slate" />

        <div className="group">

          {!this.props.currentUser.country ?
          <React.Fragment>
            <form onSubmit={this.submitForm.bind(this)} autoComplete="off" autoComplete="new-password">

              <h1>Which country do you live in?</h1>

              <div className="field">

                <Select
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={this.countries}
                  value={this.state.country}
                  onChange={this.updateCountry}
                  components={{
                    Input
                  }}
                />

              </div>

              <div className="field field__buttons">
                <a href="#" onClick={() => { this.props.onClickLogout(this.props.currentUser)} }>Logout</a>
                <button
                  className="btn btn-lg btn-primary pull-xs-right"
                  onClick={this.nextStep}
                  disabled={this.state.inProgress}>
                  Continue
                </button>
              </div>

              { this.props.errors && <div class="notice">We noticed a few errors...</div>}

            </form>
          </React.Fragment>
          :
          <React.Fragment>
            {(this.props.currentUser.state == 'trial_expired') ? (
              <h1>Your trial has expired, please add a payment method.</h1>
            ) : (
              <h1>Add a payment method.</h1>
            )}
            {(!this.props.subscription || this.props.subscription.state === 'canceled') &&
              <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}>
                <Elements>
                  <CheckoutForm
                    showform={true}
                    subscription={this.props.subscription}
                    currentUser={this.props.currentUser}
                    submit={this.props.createSubscription}
                    logout={this.props.onClickLogout} />
                </Elements>
              </StripeProvider>
            }
          </React.Fragment>
          }
        </div>
        <span>Have any questions? <a href="mailto:hello@heyslate.com?subject=Slate">Email us</a></span>
      </section>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Billings);
