import {
  REQUEST_CLIENTS,
  CLIENT_CREATE,
  CLIENT_LOADED,
  CLIENT_UNLOADED,
  CLIENT_UPDATE,
  CLIENT_REMOVE,
  CLIENT_IN_FOCUS,
  CLIENTS_PAGE_LOADED,
  CLIENTS_PAGE_UNLOADED,
  OPEN_CLIENTS_OVERLAY,
  CLOSE_CLIENTS_OVERLAY
} from '../constants/actionTypes';

export default (state = {}, action) => {

  switch (action.type) {
    case REQUEST_CLIENTS:
      //console.log(action)
      return {
        ...state,
        isFetching: true
      }
    case CLIENTS_PAGE_LOADED:
      //console.log(action)
      return {
        ...state,
        clients: action.payload.body,
        //isFetching: false
      };
    case CLIENT_IN_FOCUS:
      //console.log(action)
      return {
        ...state,
        currentClient: action.client_id
      };
    case CLIENTS_PAGE_UNLOADED:
      //console.log(action)
      return {
        ...state
      };
    case CLIENT_LOADED:
      //console.log(action)
      return {
        ...state,
        client: action.payload.body,
        isFetching: false
      };
    case CLIENT_UNLOADED:
      //console.log(action)
      return {
        ...state,
        client: false,
        work: false
      };
    case CLIENT_CREATE:
      //console.log(action)
      if(action.error){
        return {
          ...state,
          errors: action.payload.errors
        };
      }else{
        return {
          ...state,
          clients: [...state.clients, action.payload.body],
        };
      };
    case CLIENT_UPDATE:
      //console.log(action)
      if(action.error){
        return {
          ...state,
          errors: action.payload.errors
        };
      }else{
        return {
          ...state,
          client: action.payload.body,
          clients: state.clients.map(function(value, index, arr){
            return value.id === action.payload.body.id ? action.payload.body : value
          })
        };
      };
    case CLIENT_REMOVE:
      //console.log(action)
      if(action.error){
        return {
          ...state,
          errors: action.payload.errors
        };
      }else{
        return {
          ...state,
          clients: [...state.clients].filter(function(value, index, arr){
            return value.id !== action.payload.body.id;
          }),
          overlay: false,
          project: false,
          errors: false
        };
      };
    case OPEN_CLIENTS_OVERLAY:
      //console.log(action)
      return {
        ...state,
        overlay: true
      }
    case CLOSE_CLIENTS_OVERLAY:
      //console.log(action)
      return {
        ...state,
        overlay: false,
        errors: false
      }
    default:
      //console.log(action);
      return state;
  }
};
