/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux'
import { Form, Field, FormSpy } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'
import agent from '../../agent';
import { _, get, has, remove, isEmpty, isNil, values } from 'lodash'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import Select from 'react-select';
import { history } from '../../configureStore';
import { showNotificationWithTimeout } from '../../components/Notifications';
import {ReactComponent as DeleteIcon} from '../../images/remove.svg';
import {ReactComponent as AddIcon} from '../../images/add.svg';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from "react-day-picker/moment";
import dateFns from 'date-fns';
import getSymbolFromCurrency from 'currency-symbol-map';
import { currencies } from '../../currencies';
import Tooltip from '../Tooltip';
import { Info } from '../Info';
import Overlay from './Overlay';
import { Redirect } from 'react-router';
import {ReactComponent as ErrorIcon} from '../../images/error.svg';

export const InvoicesForm = (props) => {
  const {invoice={}, dispatch, params} = props;

  const overlay = useSelector(state => get(state.common, 'overlay', false));
  const currentUser = useSelector(state => get(state.common, 'currentUser', {}));

  let notification = useSelector(state => get(state.common, 'notification', false));
  let loading = useSelector(state => get(state.common, 'loading', false));
  const clients = useSelector(state => get(state.clients, 'clients', []));

  const [works, setWorks] = useState([])
  const [filteredWorks, setFilteredWorks] = useState([])

  const [projects, setProjects] = useState([])
  const [filteredProjects, setFilteredProjects] = useState([])
  const [selectedClient, changeSelectedClient] = useState(null);

  const [missingInvoiceData, setMissingInvoiceData] = useState(false);
  const [missingClientData, setMissingClientData] = useState(false);

  const [sending, setSending] = useState(false);
  const [overlayName, setOverlayName] = useState();

  const FORMAT = 'DD/MM/YYYY';

  useEffect(() => {
    let client = clients.find(cl => params.client_id == cl.id) || null;
    changeSelectedClient(client)

    if(currentUser && (!currentUser.business.name || !currentUser.business.address)) {
      openOverlay('missing_business');
    }
  }, []);

  useEffect(() => {
    if(invoice.client_id) {
      let client = clients.find(cl => invoice.client_id == cl.id) || null;
      changeSelectedClient(client)
    }
  }, [invoice.client_id]);

  useEffect(() => {
    if(selectedClient) {

      // check invoice details have been provided
      if( props.invoice.errors_preventing_submission && !isEmpty(props.invoice.errors_preventing_submission) ) {
        setMissingInvoiceData(true);
      } else {
        setMissingInvoiceData(false);
      }

      // check client details have been provided
      if( !selectedClient.contact_name || !selectedClient.email || !selectedClient.address ) {
        openOverlay('client');
        setMissingClientData(true);
      } else {
        setMissingClientData(false);
      }

      if( isEmpty(params) ) {
        agent.Clients.invoiceable_works(selectedClient.id).then((res) => setWorks(res.body))
        agent.Clients.invoiceable_projects(selectedClient.id).then((res) => setProjects(res.body))
      }

    }
  },[selectedClient])

  useEffect(() => {

    if(selectedClient) {
      filterWorkByCurrency(selectedClient.currency)
    }

  }, [works, projects]);

  const filterWorkByCurrency = (currency, values=undefined) => {
    setFilteredWorks(
      Object.values(works).filter((work) => {
        return work.currency === currency
      })
    );
    setFilteredProjects(
      Object.values(projects).filter((project) => {
        return project.currency === currency
      })
    );
    if(!isEmpty(values)){
      values.invoice_line_items = values.invoice_line_items.map((line_item)=>{
        if(isNil(line_item.invoiceable_id)){
          return line_item
        }else{
          return {...line_item, ...{'_destroy': true}}
        }
      })
    }
  }

  useEffect(() => {
    //filterWorkByCurrency(invoice.currency)
  }, [invoice.currency, works]);

  const onClientChange = (client_id, values) => {
    if(client_id) {
      let client = clients.find(cl => client_id == cl.id) || null;
      changeSelectedClient(client);
    }
  }

  const WhenFieldChanges = ({ field, set, becomes, to }) => (
    <Field name={set} subscription={{}}>
      {(
        // No subscription. We only use Field to get to the change function
        { input: { onChange } }
      ) => (
        <FormSpy subscription={{}}>
          {({ form }) => (
            <OnChange name={field}>
              {value => {
                onChange(to);
              }}
            </OnChange>
          )}
        </FormSpy>
      )}
    </Field>
  );

  const clientList = Object.values(clients).map((client) => ({
    value: client.id,
    label: client.name
  }))

  const paymentTerms = Object.values([7,15,30,45,60,90]).map((term) => ({
    value: term,
    label: term + ' Days'
  }))

  const onSubmit = (values, send) => {
    values.invoice_line_items_attributes = values.invoice_line_items
    values.client_name = selectedClient.name
    if(values.id){
      dispatch({ type: 'INVOICE_UPDATE',
        payload: agent.Invoices.update(values).then((res) => {
          if(!res.body.errors) {
            if(send === true) {

              setSending(true);
              onStateTransition('INVOICE_UPDATE', props.invoice, 'submitted', false)

            } else {

              if(!isEmpty(res.body.errors_preventing_submission)) {
                setMissingInvoiceData(true)
              } else {
                setMissingInvoiceData(false)
              }

              showNotificationWithTimeout(dispatch, 'Invoice updated')
            }
          }
          return res;
        })
      })
    }else{
      dispatch({ type: 'INVOICE_CREATE',
        payload: agent.Invoices.create(values).then((res) => {
          if(!res.body.errors) {
            showNotificationWithTimeout(dispatch, 'Invoice created')
          }
          return res;
        })
      })

    }

  }

  const invoiceLineItemWithoutDeleted = (values) => {
    return values['invoice_line_items'].filter(li => !li._destroy)
  }

  const invoiceLineItemTotal = (values, key) => {
    return invoiceLineItemWithoutDeleted(values).map(li => Number(li[key])).reduce((a, b) => a + b, 0).toFixed(2)
  }

  const invoiceSubtotal = (values) => {
    return invoiceLineItemTotal(values, 'subtotal')
  }

  const invoiceTotal = (values) => {
    return invoiceLineItemTotal(values, 'total')
  }

  const invoiceTax = (values) => {
    return (invoiceTotal(values) - invoiceSubtotal(values)).toFixed(2)
  }

  const invoiceCurrency = (values) => {
    return getSymbolFromCurrency(
      get(values.currency, 'value', values.currency)
      //values.currency.value ? values.currency.value : values.currency
    );
  }

  const getCurrency = (client_id) => {
    let client = clients.find(cl => client_id == cl.id) || null;
    if( client ) {
      return client.currency;
    }
  }

  const ReactSelectAdapter = ({ input, selected, isDisabled, ...rest }) => {

    const handleChange = (option: ValueType<Option, false>) => {
      input.onChange(option?.value);
    };

    return (
      <Select
        {...input}
        {...rest}
        className="react-select-container"
        classNamePrefix="react-select"
        value={selected}
        isDisabled={isDisabled}
        onChange={handleChange}/>
    )
  }

  const onStateTransition = (type, invoice, state, message) => {
    props.dispatch({
      type: type,
      payload: agent.Invoices.transition_state(invoice.id, state).then((res) => {
        if(!res.body.errors) {
          if(state === 'submitted') {
            history.push('/invoices?invoice=' + invoice.id);
          }
          if(message) {
            showNotificationWithTimeout(props.dispatch, message);
          }
        }
        return res;
      })
    })
  }

  const saveSend = (values) => {
    onSubmit(values, true)
  }

  const updateSelectedClient = (client) => {
    changeSelectedClient(client)
  }

  const openOverlay = (overlay) => {
    if (overlay) {
      setOverlayName(overlay);
    }
    dispatch({ type: 'OPEN_OVERLAY' });
  }

  const closeOverlay = () => {
    dispatch({ type: 'CLOSE_OVERLAY' });
  }

  if(isEmpty(clientList)){
    return 'Loading'
  }

  return (
    <>
    <Form
      onSubmit={onSubmit}
      initialValues={invoice}
      mutators={{
        ...arrayMutators
      }}
      render={({ handleSubmit, form: {
          mutators: { push, pop }
        }, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit}>

          <WhenFieldChanges
            field="client_id"
            set="currency"
            to={getCurrency(values.client_id)}
          />

          <WhenFieldChanges
            field="payment_terms"
            set="due_date"
            to={dateFns.addDays(values.date, (
              values.payment_terms ? (
                values.payment_terms.value ? values.payment_terms.value : values.payment_terms
              ) : 0
            ) )}
          />

          <WhenFieldChanges
            field="date"
            set="due_date"
            to={dateFns.addDays(values.date, (
              values.payment_terms ? (
                values.payment_terms.value ? values.payment_terms.value : values.payment_terms
              ) : 0
            ) )}
          />

          <div className="group">

            <div className="field">
              <label>
                <span>Client</span>
                <Field
                  name="client_id"
                  render={ReactSelectAdapter}
                  options={clientList}
                  isDisabled={(params.client_id || invoice.client_id) ? true : false}
                  selected={clientList.find(client => client.value.toString() == values.client_id)} />
              </label>
              {selectedClient && (
                <>
                { missingClientData && (
                  <small>You need to provide some details for this client</small>
                ) }
                </>
              )}

              {!selectedClient && (
                <div className="message message__issue message__tiny">
                  <ErrorIcon />
                  <div>Select a client to raise a new invoice.</div>
                </div>
              )}

            </div>

            <OnChange name="client_id">
              {(value, previous) => {
                onClientChange(value)
              }}
            </OnChange>

            <hr />

            <div className="inline">

              <div className="field field__grow">
                <label>
                  <span>Currency</span>
                  <Field
                    name="currency"
                    render={ReactSelectAdapter}
                    options={currencies}
                    isDisabled={(invoice.id) ? true : false}
                    selected={currencies.find(currency => currency.value.toString() === values.currency)} />
                </label>
              </div>

              <OnChange name="currency">
                {(value, previous) => {
                  filterWorkByCurrency(value, values)
                }}
              </OnChange>

              <div className="field field__grow">
                <label>
                  <span>Payment Terms</span>
                  <Field
                    name="payment_terms"
                    render={ReactSelectAdapter}
                    options={paymentTerms}
                    selected={paymentTerms.find(client => client.value === values.payment_terms)} />
                </label>
              </div>

              <div className="field field__grow">
                <label>
                  <span>Issued On</span>
                  <Field name="date">
                      {props => (
                        <div>
                          <DayPickerInput
                            value={props.input.value}
                            format={FORMAT}
                            formatDate={formatDate}
                            parseDate={parseDate}
                            placeholder={FORMAT}
                            onDayChange={props.input.onChange}
                            dayPickerProps={{
                              disabledDays: {
                                after: new Date()
                              }
                            }}
                          />
                        </div>
                      )}
                  </Field>
                </label>
              </div>

              <div className="field field__grow">
                <label>
                  <span>Due On</span>
                  <Field name="due_date">
                    {props => (
                      <div>
                        <DayPickerInput
                          inputProps={{ disabled: true }}
                          value={props.input.value}
                          format={FORMAT}
                          formatDate={formatDate}
                          parseDate={parseDate}
                          placeholder={FORMAT}
                          onDayChange={props.input.onChange}
                          dayPickerProps={{
                            disabledDays: {
                              before: new Date(values.date)
                            }
                          }}
                        />
                      </div>
                    )}
                  </Field>
                </label>
              </div>

            </div>

            <div className="inline">

              <div className="field field__grow">

                <label>
                  <span>
                    <span>Invoice Note</span>
                    <Tooltip message={'Add any additional details to your invoice such as tax ID, company registration number or purchase order number'} position={'top'} size={'large'}><Info /></Tooltip>
                  </span>
                  <Field
                    name="invoice_note"
                    component="textarea"
                    className="noResize"
                  />
                </label>

              </div>

              <div className="field field__grow">

                <label>
                  <span>
                    <span>Payment Details</span>
                    <Tooltip message={'Add your payment details here so your clients know how to pay you'} position={'top'} size={'large'}><Info /></Tooltip>
                  </span>
                  <Field
                    name="payment_details"
                    component="textarea"
                    className="noResize"
                  />
                </label>

              </div>

            </div>

          </div>

          {selectedClient && (
          <>

            <div className="group">

              <div className="field__group">
                <div className="field field__grow">Description</div>
                <div className="field field__price">Subtotal</div>
                <div className="field field__tax">Tax</div>
                <div className="field field__price">Total</div>
                <div className="field field__button"></div>
              </div>

              <FieldArray name="invoice_line_items">
              {({ fields }) =>
                fields.map((name, index) => {
                  if(fields.value[index]['_destroy']){
                    return ''
                  }
                  return (
                    <div className="field__group" key={name}>
                      <div className="field field__grow">
                        <label>
                          <Field
                            name={`${name}.description`}
                            component="input"
                            placeholder="Description"
                          />
                        </label>
                      </div>
                      <div className={`field field__price field__helper field__helper-${ values.currency ? values.currency : 'gbp' }`}>
                        <label>
                          {fields.value[index]['invoiceable_type'] === 'WorkDay' ? (
                            <Tooltip message={"You cannot change the amount for days in your schedule."} position={'top'} size={'large'}>
                              <div className="helper">
                                <span>{invoiceCurrency(values)}</span>
                                <Field
                                  disabled={
                                    fields.value[index]['invoiceable_type'] === 'WorkDay' ? true : false
                                  }
                                  name={`${name}.subtotal`}
                                  component="input"
                                  placeholder="0"
                                />
                              </div>
                            </Tooltip>
                          ) : (
                            <div className="helper">
                              <span>{invoiceCurrency(values)}</span>
                              <Field
                                name={`${name}.subtotal`}
                                component="input"
                                placeholder="0"
                              />
                            </div>
                          )}
                          <WhenFieldChanges
                            field={`${name}.subtotal`}
                            set={`${name}.total`}
                            to={
                              !fields.value[index]['tax_rate'] || !fields.value[index]['subtotal']
                              ?
                                fields.value[index]['subtotal'] ? fields.value[index]['subtotal'] : 0
                              :
                                (fields.value[index]['subtotal'] * (1+(fields.value[index]['tax_rate'] / 100))).toFixed(2)
                            }
                          />
                          <WhenFieldChanges
                            field={`${name}.subtotal`}
                            set={`${name}.unit_amount`}
                            to={fields.value[index]['subtotal']}
                          />
                        </label>
                      </div>
                      <div className="field field__tax field__helper field__helper-percentage">
                        <label>
                          <div className="helper">
                            <span>%</span>
                            <Field
                              name={`${name}.tax_rate`}
                              component="input"
                              placeholder="0"
                            />
                          </div>
                          <WhenFieldChanges
                            field={`${name}.tax_rate`}
                            set={`${name}.total`}
                            to={
                              !fields.value[index]['tax_rate'] || !fields.value[index]['subtotal']
                              ?
                                fields.value[index]['subtotal'] ? fields.value[index]['subtotal'] : 0
                              :
                                (fields.value[index]['subtotal'] * (1+(fields.value[index]['tax_rate'] / 100))).toFixed(2)
                            }
                          />
                        </label>
                      </div>
                      <div className={`field field__price field__helper field__helper-${ values.currency ? values.currency : 'gbp' }`}>
                        <label>
                          <div className="helper">
                            <span>{invoiceCurrency(values)}</span>
                            <Field
                              disabled
                              name={`${name}.total`}
                              component="input"
                              placeholder="0"
                            />
                          </div>
                        </label>
                      </div>
                      <div className="field field__button">
                        <Tooltip message={"Remove"} position={'top'} size={'large'}>
                          <div
                            className="btn btn__muted btn__square"
                            onClick={() => {
                              if(fields.value[index].id === undefined) {
                                let field = fields.value[index]
                                let item;
                                if(get(field, 'invoiceable_id')) {
                                  // work day
                                  if(get(field, 'invoiceable_type') === 'WorkDay') {
                                    item = Object.values(filteredWorks).filter((work) => {
                                      return work.id === field.invoiceable_id
                                    })
                                    if(!isEmpty(item)) {
                                      works.push(item[0])
                                    }
                                  }
                                  // project
                                  if(get(field, 'invoiceable_type') === 'Project') {
                                    item = Object.values(filteredProjects).filter((project) => {
                                      return project.id === field.invoiceable_id
                                    })
                                    if(!isEmpty(item)) {
                                      projects.push(item[0])
                                    }
                                  }
                                }
                                fields.remove(index)
                              }else{
                                fields.update(index, {id: fields.value[index].id, '_destroy': true})
                              }
                            }}
                          >
                            <DeleteIcon />
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                  )
                })
              }
              </FieldArray>

              <Tooltip message={"Custom line items don't count towards your monthly and annual revenue targets."} position={'top'} size={'large'}>
                <div
                  className="btn btn__auto btn__outline"
                  onClick={() => push('invoice_line_items', {quantity: 1, subtotal: 0.0, total: 0.00, tax_rate: 20})}
                >
                  <span>Add a custom line item</span>
                </div>
              </Tooltip>

              {( !props.invoice.state && isEmpty(params) && ((!isEmpty(works)) || !isEmpty(projects)) ) && (
                <>
                <hr />
                <div className="message message__issue message__tiny">
                  <ErrorIcon />
                  <div>You have some unbilled work you still need to invoice for this client</div>
                </div>
                </>
              )}

              { filteredWorks.filter((el)=> {
                return !values.invoice_line_items.map(li => `${li.invoiceable_type}:${li.invoiceable_id}`).includes(`WorkDay:${el.id}`)
              }).map((work) => (
                <div className="field__group" key={work}>
                  <div className="field field__grow">
                    <label>
                      <input type="text" value={work.description} disabled />
                    </label>
                  </div>
                  <div class="field field__price field__helper">
                    <label>
                      <div class="helper">
                        <span>{getSymbolFromCurrency(work.currency)}</span>
                        <input value={work.price} disabled />
                      </div>
                    </label>
                  </div>
                  <Tooltip message={"Add"} position={'top'} size={'large'}>
                    <div
                      onClick={() => {
                        push('invoice_line_items', {description: work.description, subtotal: work.price, total: (work.price * 1.2).toFixed(2), unit_amount: work.unit_amount, quantity: work.quantity, tax_rate: 20, invoiceable_type: 'WorkDay', invoiceable_id: work.id})
                        remove(works, (w) => { return w.id === work.id})
                      }}
                      className="btn btn__muted btn__square">
                        <AddIcon />
                    </div>
                  </Tooltip>
                </div>
              ))}

              { filteredProjects.filter((el)=> {
                return !values.invoice_line_items.map(li => `${li.invoiceable_type}:${li.invoiceable_id}`).includes(`Project:${el.id}`)
              }).map((project) => (
                <div className="field__group" key={project}>
                  <div className="field field__grow">
                    <label>
                      <input type="text" value={project.title} disabled />
                    </label>
                  </div>
                  <div class="field field__price field__helper">
                    <label>
                      <div class="helper">
                        <span>{getSymbolFromCurrency(project.currency)}</span>
                        <input value={project.amount_uninvoiced} disabled />
                      </div>
                    </label>
                  </div>
                  <Tooltip message={"Add"} position={'top'} size={'large'}>
                    <div
                      onClick={() => {
                        push('invoice_line_items', {description: project.title, subtotal: project.amount_uninvoiced, total: (project.amount_uninvoiced * 1.2).toFixed(2), quantity: 1, tax_rate: 20, invoiceable_type: 'Project', invoiceable_id: project.id})
                        remove(projects, (w) => { return w.id === project.id})
                      }}
                      className="btn btn__muted btn__square">
                        <AddIcon />
                    </div>
                  </Tooltip>
                </div>
              ))}

              <hr />

              <div className="field__group field__group__summary">
                <div className="field field__summary">
                  <span>Subtotal</span>
                  <h3>{invoiceCurrency(values)}{invoiceSubtotal(values)}</h3>
                </div>
                <div className="field field__summary">
                  <span>Tax</span>
                  <h3>{invoiceCurrency(values)}{invoiceTax(values)}</h3>
                </div>
                <div className="field field__summary">
                  <span>Total</span>
                  <h3>{invoiceCurrency(values)}{invoiceTotal(values)}</h3>
                </div>
              </div>

            </div>

            <div className="field field__fixed">

              { props.invoice.state === 'draft' && (
                <a href={`/api/invoices/${props.invoice.id}/pdf`} download className="btn btn__outline btn__tight">
                  <span>Download PDF</span>
                </a>
              )}

              <div className="button__group">
                <button
                  disabled={sending || loading || !(values.client_id) || !(values.date)}
                  type="Submit"
                  className={
                    props.invoice.state === 'draft' ?
                      'btn btn__outline'
                    :
                      sending || loading ? 'btn btn__loading' : 'btn'
                }>
                  <div className="loader loader__button"></div>
                  <span>Save Invoice</span>
                </button>
                { props.invoice.state === 'draft' && (
                  <>
                  {missingClientData ? (
                    <div
                      onClick={() => { openOverlay('client') }}
                      className="btn disabled">
                        <span>Issue Invoice</span>
                        <Tooltip message={'You need to update this client before you can send this invoice.'} position={'top'} size={'large'}>
                          <Info />
                        </Tooltip>
                    </div>
                  ) : (
                    <div
                      onClick={() => { saveSend(values) }}
                      disabled={sending || loading || missingInvoiceData}
                      className={sending || loading ? 'btn btn__loading' : 'btn'}>
                        <div className="loader loader__button"></div>
                        <span>Issue Invoice</span>
                      </div>
                  )}
                  </>
                )}
              </div>
            </div>
          </>
          )}

          { notification && <div className="success">{notification}</div>}

          { overlay && (
          <Overlay
            overlay={overlay}
            overlayName={overlayName}
            client={selectedClient}
            currentUser={currentUser}
            invoice={invoice ? invoice : null}
            updateSelectedClient={updateSelectedClient}
            closeOverlay={(e) => closeOverlay()}></Overlay>
          )}

        </form>
      )}
    />
    </>
  )
}

