import React from 'react';
import dateFns from 'date-fns';
import { Dropdown } from './Dropdown';
export class OverlayItem extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      date: props.date,
      price: props.price || (this.props.project ? this.props.project.project_rate_value : this.props.client.day_rate),
      unit: props.unit || (this.props.project ? this.props.project.project_rate_unit : 'days'),
      unit_value: props.unit_value || (this.props.project ? this.props.project.project_rate_unit : '1'),
      unit_value_in_time: props.unit_value_in_time || '01:00',
      unit_value_formatted: props.unit_value_formatted || '01h 00m'
    };
  }

  componentDidUpdate(prevProps, prevState) {

    if(
      prevProps.price !== this.props.price || 
      prevProps.unit !== this.props.unit || 
      prevProps.unit_value !== this.props.unit_value || 
      prevProps.unit_value_in_time !== this.props.unit_value_in_time || 
      prevProps.unit_value_formatted !== this.props.unit_value_formatted
    ) {
     
      let newState = {
        ...this.state,
        price: this.props.price,
        unit: this.props.unit,
        unit_value: this.props.unit_value,
        unit_value_in_time: this.props.unit_value_in_time,
        unit_value_formatted: this.props.unit_value_formatted
      };
  
      this.setState(newState);

    }

  }

  incrementDecrementTime = (e) => {
    if ([38, 40].includes(e.keyCode)) {
      let [firstNumber, secondNumber] = String(this.state.unit_value_in_time).split(':');

      // down
      if (e.keyCode === 40) {
        if (parseInt(firstNumber) === 0 && parseInt(secondNumber) === 15) {
          return false;
        }
        secondNumber = parseInt(secondNumber) - 15;
        if (secondNumber < 0) {
          firstNumber = parseInt(firstNumber) - 1;
          firstNumber = (firstNumber < 10 ? '0' : '') + firstNumber;
          secondNumber = '45';
        }
      }
      // up
      if (e.keyCode === 38) {
        if (firstNumber >= 23 && secondNumber >= 45) {
          return false;
        }
        secondNumber = parseInt(secondNumber) + 15;
        if (secondNumber >= 60) {
          firstNumber = parseInt(firstNumber) + 1;
          firstNumber = (firstNumber < 10 ? '0' : '') + firstNumber;
          secondNumber = '00';
        }
      }
      let newState = {
        ...this.state,
        // price: 100, // chris
        unit_value: firstNumber + ':' + secondNumber,
        unit_value_in_time: `${firstNumber}:${secondNumber}`,
        unit_value_formatted: `${firstNumber}h ${secondNumber}m`
      };
      this.setState(newState);
      this.props.updateDate(this.props.date, newState);
    }
  };
  changeTime = (e, time) => {
    let [firstNumber, secondNumber] = String(time).split(':');
    let newState = {
      ...this.state,
      unit_value: time,
      unit_value_in_time: `${firstNumber}:${secondNumber}`,
      unit_value_formatted: `${firstNumber}h ${secondNumber}m`
    };
    this.setState(newState);
    this.props.updateDate(this.props.date, newState);
  };
  changeAllTimes = (e) => {
    this.props.updateAllDates(this.state);
  };
  toggleUnit = (e) => {
    e.stopPropagation();
    let [firstNumber, secondNumber] = String(this.state.unit_value_in_time).split(':');
    let newState = {
      ...this.state,
      unit: this.state.unit === 'days' ? 'hours' : 'days',
      unit_value: this.state.unit === 'days' ? firstNumber + ':' + secondNumber : 1,
      unit_value_in_time: `${firstNumber}:${secondNumber}`,
      unit_value_formatted: `${firstNumber}h ${secondNumber}m`
    };
    this.setState(newState);
    this.props.updateDate(this.props.date, newState);
  };
  render() {
    const dropdown = <svg width="9" height="12" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(-70 -18)" fill="#1282FF" fillRule="evenodd">
        <path fill="#1282FF" d="M78.3013153 26.17460431c.15974318.18636704.15830762.45647696.00876349.64041738l-.0629961.06460661-3.5 3c-.16383926.1404336-.39586261.1579879-.57674789.0526626l-.07404349-.0526626-3.5-3c-.20966292-.17971107-.23394369-.49536107-.05423261-.70502399.15974318-.18636704.42689488-.22625929.63154276-.10660658l.07348123.05237397L74.42167794 28.841l3.17461337-2.7206283c.20966292-.17971108.52531292-.15543031.70502399.05423261zm-3.6282761-8.10689523l.07404349.05266262 3.5 3c.20966292.17971107.23394369.49536107.05423261.70502399-.15974318.18636704-.42689488.22625929-.63154276.10660658l-.07348123-.05237397L74.42167794 19.158l-3.17459525 2.7216283c-.18636704.15974318-.45647696.15830762-.64041739.00876349l-.0646066-.0629961c-.15974318-.18636704-.15830762-.45647696-.0087635-.64041738l.06299611-.06460661 3.5-3c.16383926-.14043365.39586261-.15798786.57674789-.05266262z" opacity=".5" fillRule="nonzero" />
      </g>
    </svg>;
    return (
      <div className="flex padding border rounded">
        <div className="date">{dateFns.format(this.props.date, 'dddd, Do MMMM YYYY')}</div>
        <Dropdown
          disabled={ (this.props.state === 'invoiced' || this.props.state === 'paid') ? true : false }
          content="date"
          unit={this.state.unit}
          unit_value={this.state.unit_value}
          unit_value_in_time={this.state.unit_value_in_time}
          unit_value_formatted={this.state.unit === 'days' ? 'All-day' : this.state.unit_value_formatted}
          toggleUnit={this.toggleUnit}
          onKeyDown={this.incrementDecrementTime}
          onChange={this.changeTime}
          allDates={this.changeAllTimes} />
      </div>
    );
  }
}
