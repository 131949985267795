import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import dateFns from 'date-fns';
import {ReactComponent as ErrorIcon} from '../../../images/error.svg';
import {ReactComponent as TimeIcon} from '../../../images/icons/time.svg';
import {ReactComponent as PriceIcon} from '../../../images/icons/price.svg';
import {ReactComponent as ToggleIcon} from '../../../images/icons/toggle.svg';
import {ReactComponent as CalendarIcon} from '../../../images/icons/calendar.svg';
import {ReactComponent as SettingsIcon} from '../../../images/icons/dashboard.svg';
import {ReactComponent as ClientIcon} from '../../../images/clients.svg';
import {ReactComponent as TaskIcon} from '../../../images/icons/tasks.svg';
import {ReactComponent as ProjectIcon} from '../../../images/icons/projects.svg';

import agent from '../../../agent';

import {
  ADMIN_USER_LOADED,
  ADMIN_USER_UNLOADED,
  MASQUERADE
} from '../../../constants/actionTypes';

const mapStateToProps = (state, ownProps) => ({
    user: state.admin_users.user,
    user_slug: ownProps.match.params.slug
});

const mapDispatchToProps = dispatch => ({
  onLoad: user_slug => {
    dispatch({ type: ADMIN_USER_LOADED, payload: agent.AdminUsers.show(user_slug) })
  },
  masqueradeAsUser: userId => {
    dispatch({ type: MASQUERADE, payload: agent.AdminUsers.masquerade(userId) })
  },
  onUnload: () =>
    dispatch({  type: ADMIN_USER_UNLOADED }),
});

class Title extends React.Component {

  render() {
    return (
      <header>
        <h2>Admin > <Link to="/admin/users" alt="Sign In">Users</Link> > { this.props.email }</h2>
      </header>
    );
  }

}

class AdminUser extends React.Component {

  componentWillMount() {
    this.props.onLoad(this.props.user_slug);
  }

  componentWillUnmount() {
    this.props.onUnload();
  }

  constructor(props) {
    super(props);

    this.state = {
      tab: 'activity'
    }

  }

  switchTabs(e, tab) {

    e.stopPropagation();

    this.setState({
      tab: tab
    });

  }

  render() {

    const issues = <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
      <g strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" fill="none" stroke="#c31840" stroke-miterlimit="10">
        <circle cx="32" cy="32" r="29"/>
        <path d="M32 14v25"/>
        <circle cx="32" cy="47" r="1"/>
      </g>
    </svg>;

    const finished = <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
      <g strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="none" stroke="currentColor" strokeMiterlimit="10">
        <path d="M2.5 22.5a7 7 0 007-7 7 7 0 007 7M2.5 22.5a7 7 0 017 7 7 7 0 017-7M48.5 22.5a7 7 0 007-7 7 7 0 007 7M48.5 22.5a7 7 0 017 7 7 7 0 017-7M38.283 9.701l-3.996-.581L32.5 5.5l-1.787 3.62-3.996.581 2.891 2.819-.682 3.98 3.574-1.878 3.574 1.878-.682-3.98 2.891-2.819zM59.244 48.701l-3.996-.581-1.787-3.62-1.788 3.62-3.996.581 2.892 2.819-.683 3.98 3.575-1.878 3.574 1.878-.683-3.98 2.892-2.819zM17.283 47.701l-3.996-.581L11.5 43.5l-1.787 3.62-3.996.581 2.891 2.819-.682 3.98 3.574-1.878 3.574 1.878-.682-3.98 2.891-2.819zM47.5 29.5a13.337 13.337 0 00-9 7M17.5 29.5a13.337 13.337 0 019 7M17.5 42.5a11.743 11.743 0 015-.426c4.363.591 10 4.126 10 18.426M47.5 42.5a11.743 11.743 0 00-5-.426c-4.363.591-10 4.126-10 18.426M32.5 22.5v18" />
      </g>
    </svg>;

    const on_track = <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
      <g strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" transform="translate(.5 .5)" fill="none" stroke="currentColor" strokeMiterlimit="10">
        <path d="M18 34l8 8 20-20" />
        <circle cx="32" cy="32" r="29" />
      </g>
    </svg>;

    const cancelled = <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
      <g strokeWidth="2" stroke-linecap="round" strokeLinejoin="round" transform="translate(.5 .5)" fill="none" stroke="#c31840" stroke-miterlimit="10">
        <path d="M52.493 11.507L11.507 52.493" />
        <circle cx="32" cy="32" r="29" />
      </g>
    </svg>;

    if(this.props.user) {

      return (
        <React.Fragment>
        <section className="component__project">
          <div className="summary">
             <div className="data">
                <header>
                   <div>
                      <span>{ this.props.user.email ? this.props.user.email : '–' }</span>
                      <h2>{ this.props.user.name ? this.props.user.name : '–' }</h2>
                   </div>
                </header>
                <div className="row">
                   <div className="title">
                      <TimeIcon />
                      <h4>They were last seen at { this.props.user.last_contact_received_at }</h4>
                   </div>
                </div>
                <div className="row">
                   <div className="title">
                      <SettingsIcon />
                      <h4>They are from {this.props.user.country} and using {this.props.user.currency} </h4>
                   </div>
                </div>
                <div className="row">
                   <div className="title">
                      <PriceIcon />
                      <h4>Their default rates are {this.props.user.standard_day_rate_formatted} a day and {this.props.user.standard_hourly_rate_formatted} an hour </h4>
                   </div>
                </div>
                <div className="row">
                   <div className="title">
                      <CalendarIcon />
                      <h4>They've been a user since { this.props.user.created_at }</h4>
                   </div>
                </div>
                <div className="row">
                   <div className="title">
                      { this.props.user.work_count == 0 ? <ErrorIcon /> : <CalendarIcon /> }
                      <h4>They've created { this.props.user.work_count } work with the last one added at { this.props.user.latest_work_added_at }</h4>
                   </div>
                </div>
                <div className="row">
                   <div className="title">
                      <ProjectIcon />
                      <h4>Spread across { this.props.user.clients_count } clients and { this.props.user.project_count } projects.</h4>
                   </div>
                </div>
                <div className="row">
                   <div className="title">
                      <TaskIcon />
                      <h4>Organised themselves with { this.props.user.task_count} tasks.</h4>
                   </div>
                </div>
             </div>
             <div className="status">
                  {this.props.user.state === 'cancelled' && (<React.Fragment>
                      <div>
                        {cancelled}
                        <span>This user has cancelled their subscription</span>
                      </div>
                    </React.Fragment>)}
                  {this.props.user.state === 'trialing' && (<React.Fragment>
                      <div>
                        {on_track}
                        <span>This user is trialing</span>
                        <span>Their trial expires on { this.props.user.trial_ends_at }</span>
                      </div>
                    </React.Fragment>)}
                  {this.props.user.state === 'purchased' && (<React.Fragment>
                      <div>
                        {finished}
                        <span>This user is a paid user on the { this.props.user.subscription_name} plan</span>
                        <a href={this.props.user.stripe_link}>View on Stripe</a>
                      </div>
                    </React.Fragment>)}
                  {this.props.user.state === 'trial_expired' && (<React.Fragment>
                      <div>
                        {issues}
                        <span>Their trial expired at { this.props.user.trial_ends_at } and they chose not to add purchase</span>
                      </div>
                    </React.Fragment>)}
        <button
          onClick={()=> { this.props.masqueradeAsUser(this.props.user.id)} }
          className="btn btn__inline">
          <span>Masquerade as this user</span>
        </button>
             </div>
          </div>
          <div className="options inset">
             <div className="list">
                <button
                  className={this.state.tab === 'activity' ? 'selected' : ''}
                  onClick={(e) => this.switchTabs(e, 'activity')}>Activity</button>
                <button
                  className={this.state.tab === 'schedule' ? 'selected' : ''}
                  onClick={(e) => this.switchTabs(e, 'schedule')}>Schedule</button>
             </div>
          </div>
          <div className="analysis">
            {(this.state.tab === 'activity') && (
              this.props.user.activity.length > 0 ? (
               <div className="schedule">
                  <div className="block" data-month="April 2020">
                     <div className="list">
                        {
                          this.props.user.activity.map(obj => {
                            return (
                              <div className="item booked invoice invoiced">
                                <span className="date">{obj['action']} {obj['type']} on {dateFns.format(obj['date'],'DD/MM/YYYY HH:mm:ss')}</span>
                              </div>
                            );
                          })
                        }
                     </div>
                  </div>
               </div>
              ) : (
                <p>There has been no activity</p>
              )
            )}
            {(this.state.tab === 'schedule') && (
              this.props.user.schedule.works.length > 0 ? (
               <div className="schedule">
                  <div className="block" data-month="April 2020">
                     <div className="list">
                        {
                          this.props.user.schedule.works.map(obj => {
                            return (
                              <div className="item booked invoice invoiced">
                                <span className="date">
                                { dateFns.format(obj['date'], 'ddd D MMMM YYYY') } working on {obj['client_name'] } { obj['project_title'] && (<React.Fragment>( project : {obj['project_title']})</React.Fragment>)} for { obj['unit_value_formatted'] } and charging { obj['price_formatted'] }  </span>
                              </div>
                            );
                          })
                        }
                     </div>
                  </div>
               </div>
              ) : (
                <p>There is no work schedule for the next 30 days</p>
              )
            )}
          </div>
        </section>

        </React.Fragment>
      );

    } else {

      return (<div className="loading"><div className="loader"></div></div>)

    }

  }

}

export default connect(mapStateToProps, mapDispatchToProps)(AdminUser);
