import React from 'react';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import dateFns from 'date-fns';

import agent from '../../agent';
import {
  SCHEDULE_SUMMARY,
  DESTROY_SCHEDULE_SUMMARY
} from '../../constants/actionTypes';

const mapStateToProps = state => (
  {
    ...state,
    summary: state.schedule.summary
  }
);

const mapDispatchToProps = dispatch => ({
  getSummary: (from,to) => {
    dispatch({
      type: SCHEDULE_SUMMARY,
      payload: agent.Schedule.summary(from,to).then((res) => {
        if(!res.body.errors) {
          //dispatch({ type: MONTH_LOADED, month: activeMonth, month_string: month_name + '-' + year, payload: agent.Month.show(month_name, year) })
        }
        return res;
      })
    })
  },
  destroySummary: () => {
    dispatch({ type: DESTROY_SCHEDULE_SUMMARY })
  }
});

class ScheduleWeek extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      displayTooltip: false
    }
    this.hideTooltip = this.hideTooltip.bind(this)
    this.showTooltip = this.showTooltip.bind(this)
  }

  hideTooltip (event) {

    this.props.destroySummary();

    this.setState({
      displayTooltip: false,
      top: null
    })

  }

  showTooltip (event) {

    this.props.getSummary(
      dateFns.format(this.props.date, 'DD-MM-YYYY'),
      dateFns.format(dateFns.endOfWeek(this.props.date, {weekStartsOn: 1}), 'DD-MM-YYYY')
    );

    var bounds = event.currentTarget.getBoundingClientRect();
    var y = bounds.top + bounds.height + 8;

    this.setState({
      displayTooltip: true,
      top: y
    })

  }

  render() {
    let message = this.props.message
    let position = this.props.position
    let size = this.props.size

    return (
      <div className={dateFns.isFirstDayOfMonth(this.props.date) ? 'week monthStart' : 'week'}>
        <div>
          <span>{dateFns.format(this.props.date, 'DD')}–{dateFns.format(dateFns.endOfWeek(this.props.date, {weekStartsOn: 1}), 'DD MMMM')}</span>
          {this.state.displayTooltip &&
            (
              ReactDOM.createPortal(
                <div 
                  className={`schedule_summary`}
                  style={{top: this.state.top}}>
                    {this.props.summary ? (
                      <>
                      {this.props.summary.clients.map((client, index) => {
                        return(
                          <div className="schedule_summary__item">
                            <div>{client.name}</div>
                            <div className="data">{client.total_time_formatted} ({client.total_price_formatted})</div>
                          </div>
                        )
                      })}
                      {this.props.summary.total_time_formatted ? (
                        <div className="schedule_summary__footer">
                          <div>Total</div>
                          <div class="data">{this.props.summary.total_time_formatted} ({this.props.summary.total_price_formatted})</div>
                        </div>
                      ) : (
                        <div className="schedule_summary__item">
                          <div className="empty">No work added</div>
                        </div>
                      )}
                      </>
                    ) : (
                      <div className="loader loader__small"></div>
                    )}
                </div>,
                document.querySelector('main')
              )
            )
          }
          <button
            onMouseOver={this.showTooltip}
            onMouseLeave={this.hideTooltip}>
              <span>Week Summary</span>
            </button>
        </div>
      </div>
    )

  }

}

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleWeek);
