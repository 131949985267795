import React from 'react';
import TaskItem from './TaskItem';
import _ from 'lodash';

const TaskList = props => {
  if (_.isEmpty(props.tasks, true)) {
    return false
  }

  return (
    <React.Fragment>
      {
        props.tasks.map(task => {
          return (
            <TaskItem
              removeTask={props.removeTask}
              updateTask={props.updateTask}
              task={task}
              key={task.id} />
          );
        })
      }
    </React.Fragment>
  );

};

export default TaskList;
