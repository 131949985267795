import React from 'react';
import agent from '../agent';
import images from '../images';
import { connect } from 'react-redux';
import update from 'immutability-helper';
import {
  DEMO_REGISTER
} from '../constants/actionTypes';

const mapStateToProps = state => (
  {
    errors: state.auth.errors
  }
);

const mapDispatchToProps = dispatch => ({
  onSubmit: (user) =>
    dispatch({ type: DEMO_REGISTER, payload: agent.Auth.demo_register(user) })
});

class RegisterDemo extends React.Component {
  constructor() {
    super();

    this.state = {
      loading: false,
      email: '',
      name: '',
      clients: '1'
    };

  }

  componentDidUpdate(prevProps, prevState) {

    if (this.props.errors && prevState.loading) {
      this.setState({
        loading: false
      })
    }

  }

  submitForm = (ev) => {
    ev.preventDefault();

    this.setState({
      loading: true
    })

    this.props.onSubmit(this.state);

  };

  updateState = field => ev => {

    let newData = update(this.state, {
      [field]: {$set: ev.target.value}
    })

    this.setState(newData);

    // cleanup
    newData = null;

  };

  errorMessage(field) {
    if(this.props.errors && this.props.errors[field]){
      return this.props.errors[field];
    }
  };

  render() {
    return (

      <main>

        <section className="component__route component__route__register">

          <img src={images.logo} alt="Slate" />

          <form onSubmit={this.submitForm.bind(this)} autoComplete="off" autoComplete="new-password">

            <h1>Build your custom Slate demo.</h1>

            {this.props.message &&
              <p class="notice">{this.props.message}</p>
            }
            <div className="field">

              <label>
                <div>What's your name? {this.errorMessage('name') ? <div className="error">{this.errorMessage('name')}</div> : ''}</div>
                <input
                  type="text"
                  value={this.state.name}
                  onChange={this.updateState('name')} />
              </label>

            </div>

            <div className="field">

              <label>
                <div>What's your email address? {this.errorMessage('email') ? <div className="error">{this.errorMessage('email')}</div> : ''}</div>
                <input
                  type="text"
                  value={this.state.email}
                  onChange={this.updateState('email')} />
              </label>

            </div>

            <h3>How many clients do your normally work for in a day?</h3>

            <div className="field__group">

              <div className="field field__checkbox">
                <label>
                  <input
                    className="form-control"
                    type="checkbox"
                    checked={this.state.clients === '1'}
                    value="1"
                    onChange={this.updateState('clients')} />
                  <span className="currency">1</span>
                </label>
              </div>
              <div className="field field__checkbox">
                <label>
                  <input
                    className="form-control"
                    type="checkbox"
                    checked={this.state.clients === '2'}
                    value="2"
                    onChange={this.updateState('clients')} />
                  <span className="currency">2</span>
                </label>
              </div>
              <div className="field field__checkbox">
                <label>
                  <input
                    className="form-control"
                    type="checkbox"
                    checked={this.state.clients === '3'}
                    value="3"
                    onChange={this.updateState('clients')} />
                  <span className="currency">3</span>
                </label>
              </div>
              <div className="field field__checkbox">
                <label>
                  <input
                    className="form-control"
                    type="checkbox"
                    checked={this.state.clients === '3+'}
                    value="3+"
                    onChange={this.updateState('clients')} />
                  <span className="currency">3+</span>
                </label>
              </div>
            </div>

            <div className="field field__buttons">

              {this.state.loading && <span>Building your demo, please wait...</span>}

              <button className={this.state.loading ? 'btn btn__loading' : 'btn'} disabled={this.state.loading}>
                <div className="loader loader__button"></div>
                <span>Start Demo</span>
              </button>

            </div>

          </form>

          <span>This is a temporary demo account just for you so feel free to <br/> add, edit and delete to your hearts content.</span>

        </section>

      </main>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterDemo);
