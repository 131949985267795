import React from 'react';
import update from 'immutability-helper';

class TaskItem extends React.Component {

  constructor(props) {
    super();

    this.state = {
      id: props.task ? props.task.id : '',
      text: props.task ? props.task.text : ''
    };

  }

  pushChange = change => {
    this.props.updateTask(change);
  }

  updateState = field => ev => {

    let newData = update(this.state, {
      [field]: {$set: ev.target.value}
    })

    this.pushChange(newData)

    // cleanup
    newData = null;

  };

  render() {

    return (
      <li >
        <span className="remove" onClick={() => {this.props.removeTask(this.props.task)}}></span>
        <input
          className="form-control"
          type="text"
          defaultValue={this.props.task.text}
          onKeyUp={this.updateState('text')} />
      </li>
    );
  }
}

export default TaskItem;
