import React from 'react';
import dateFns from 'date-fns';
import Tooltip from '../Tooltip';
import {ReactComponent as NoteIcon} from '../../images/note.svg';

export class Item extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.day.id,
      state: props.day.state
    };
  }
  invoiceWork(project, day) {
    this.props.invoiceWork(project, day);
  }
  rollbackInvoiceWork(project, day) {
    this.props.rollbackInvoiceWork(project, day);
  }
  render() {
    const day = this.props.day;
    return (
      <div className={`item booked invoice ${day.state === 'invoiced' ? 'invoiced' : ''}`}>
        <span className="date">
          {dateFns.format(day.date, 'ddd D MMMM YYYY')} ({day.unit === 'days' ? 'All-day' : `${day.unit_value_formatted}`})
          {day.note && (<Tooltip message={`${day.note}`} position={'top'} size={'large'}><NoteIcon /></Tooltip>)}
        </span>
        <div className="status">
          {(this.props.project.billing_type == 'fixed_price') ? '' : day.state}
        </div>
      </div>
    );
  }
}
