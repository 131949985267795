import React from 'react';
import { Link } from 'react-router-dom';
import agent from '../agent';
import { connect } from 'react-redux';
import images from '../images';
import dateFns from 'date-fns';
import Tooltip from './Tooltip';

import {
  LOGOUT,
  MASQUERADE
} from '../constants/actionTypes';

const mapStateToProps = state => ({
  ...state.settings,
  currentUser: state.common.currentUser,
  subscription: state.common.subscription,
  activeMonth: state.common.activeMonth,
  redirectTo: state.common.redirectTo
});

const mapDispatchToProps = dispatch => ({
  onClickLogout: (currentUser) => {
    if(currentUser.masquerading){
      dispatch({ type: MASQUERADE, payload: agent.AdminUsers.revertMasquerade() })
    }else{
      dispatch({ type: LOGOUT, payload: agent.Auth.logout() })
    }
  }
});

const LoggedOutView = props => {
  if (!props.currentUser) {
    return (
      <Link to="/login" alt="Sign In">
        <img src={images.login} alt="Sign In"/>
      </Link>
    );
  }
  return null;
};

const LoggedInView = props => {

  if (props.currentUser) {

    let trial_days = 30;
    let trial_remaining;
    let trial_remaining_percentage;

    if (props.currentUser && !props.currentUser.admin && props.currentUser.state === 'trialing' && props.currentUser.trial_ends_at) {
      trial_remaining = dateFns.differenceInDays(props.currentUser.trial_ends_at, dateFns.parse(Date.now()));
      trial_remaining_percentage = Math.ceil((trial_remaining / trial_days * 100) / 10) * 10;

      if(trial_remaining < 0 ) {
        trial_remaining = 0;
      }
    }

    return (
      <React.Fragment>
      <figure>
        <img src={images.logo} alt="Slate"/>
      </figure>
      <div className="menu">
        <Link
          className={props.currentPath === '/' ? 'active' : null}
          to={{
            pathname: '/',
            state: {
              trigger: 'click'
            }
          }}>
          <Tooltip message={'Dashboard'} position={'right'} size={'large'}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path fill="currentColor" d="M20,12 C20.5128358,12 20.9355072,12.3860402 20.9932723,12.8833789 L21,13 L21,23 C21,23.5128358 20.6139598,23.9355072 20.1166211,23.9932723 L20,24 L4,24 C3.48716416,24 3.06449284,23.6139598 3.00672773,23.1166211 L3,23 L3,13 C3,12.4477153 3.44771525,12 4,12 C4.51283584,12 4.93550716,12.3860402 4.99327227,12.8833789 L5,13 L5,22 L8.99996988,22 L9,17 C9,16.4871642 9.38604019,16.0644928 9.88337887,16.0067277 L10,16 L14,16 C14.5128358,16 14.9355072,16.3860402 14.9932723,16.8833789 L15,17 L14.9999699,22 L19,22 L19,13 C19,12.4871642 19.3860402,12.0644928 19.8833789,12.0067277 L20,12 Z M13,18 L11,18 L10.9999699,22 L12.9999699,22 L13,18 Z M13,10 C13.5522847,10 14,10.4477153 14,11 L14,11 L14,13 C14,13.5522847 13.5522847,14 13,14 L13,14 L11,14 C10.4477153,14 10,13.5522847 10,13 L10,13 L10,11 C10,10.4477153 10.4477153,10 11,10 L11,10 Z M12.4182465,1.09153795 L12.5290158,1.15131784 L12.6332378,1.2260427 L23.6332378,10.2260427 C24.0606826,10.5757703 24.1236849,11.205793 23.7739573,11.6332378 C23.4511318,12.0278022 22.889475,12.1118358 22.4687796,11.8473591 L22.3667622,11.7739573 L12,3.291 L1.63323779,11.7739573 C1.23867335,12.0967828 0.671498159,12.0679331 0.31150493,11.7253875 L0.226042701,11.6332378 C-0.0967827527,11.2386733 -0.0679330721,10.6714982 0.274612472,10.3115049 L0.36676221,10.2260427 L11.3667622,1.2260427 C11.6681525,0.979450664 12.0775726,0.934615748 12.4182465,1.09153795 Z"/>
            </svg>
          </Tooltip>
          <span>Dashboard</span>
        </Link>
        <Link
          className={props.currentPath === 'month' ? 'active' : null}
          to={{
            pathname: '/month/' + dateFns.format(props.activeMonth, 'MMMM/YYYY').toLowerCase(),
            state: {
              trigger: 'click'
            }
          }}>
          <Tooltip message={'Schedule'} position={'right'} size={'large'}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <g fill="currentColor">
                <path d="M23 6h-2c-.553 0-1-.447-1-1s.447-1 1-1h2c.553 0 1 .447 1 1s-.447 1-1 1zM17 6H1c-.553 0-1-.447-1-1s.447-1 1-1h16c.553 0 1 .447 1 1s-.447 1-1 1zM3 13H1c-.553 0-1-.447-1-1s.447-1 1-1h2c.553 0 1 .447 1 1s-.447 1-1 1zM23 13H7c-.553 0-1-.447-1-1s.447-1 1-1h16c.553 0 1 .447 1 1s-.447 1-1 1zM23 20h-2c-.553 0-1-.447-1-1s.447-1 1-1h2c.553 0 1 .447 1 1s-.447 1-1 1zM17 20H1c-.553 0-1-.447-1-1s.447-1 1-1h16c.553 0 1 .447 1 1s-.447 1-1 1z"/>
              </g>
            </svg>
          </Tooltip>
          <span>Schedule</span>
        </Link>
        <Link
          className={props.currentPath === 'year' ? 'active' : null}
          to={{
            pathname: '/year/' + props.currentUser.current_year,
            state: {
              trigger: 'click'
            }
          }}>
          <Tooltip message={'Performance'} position={'right'} size={'large'}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path fill="currentColor" d="M12,24 C18.627417,24 24,18.627417 24,12 C24,5.372583 18.627417,0 12,0 C5.372583,0 0,5.372583 0,12 C0,18.627417 5.372583,24 12,24 Z M12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 Z M9.82025637,9.24322334 L13.0715233,17.3713907 C13.3786568,18.1392244 14.4320141,18.2236272 14.8574929,17.5144958 L17.5661904,13 L23,13 C23.5522847,13 24,12.5522847 24,12 C24,11.4477153 23.5522847,11 23,11 L17,11 C16.6487371,11 16.3232303,11.1842988 16.1425071,11.4855042 L14.1797436,14.7567767 L10.9284767,6.62860932 C10.6213432,5.86077555 9.56798595,5.77637279 9.14250707,6.48550424 L6.43380962,11 L1,11 C0.44771525,11 0,11.4477153 0,12 C0,12.5522847 0.44771525,13 1,13 L7,13 C7.35126285,13 7.67676968,12.8157012 7.85749293,12.5144958 L9.82025637,9.24322334 Z"/>
            </svg>
          </Tooltip>
          <span>Performance</span>
        </Link>
        <Link
          className={props.currentPath === 'clients' ? 'active' : null}
          to="/clients">
          <Tooltip message={'Clients'} position={'right'} size={'large'}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path fill="currentColor" d="M12 0c3.8659932 0 7 3.13400675 7 7 0 2.236293-1.0486611 4.2276581-2.6810619 5.5091739C20.7717334 13.9165889 24 18.0811818 24 23c0 .5522847-.4477153 1-1 1s-1-.4477153-1-1c0-4.8819525-3.8866666-8.8557968-8.7346299-8.996163L13 14h-2c-4.97071525 0-9 4.0292847-9 9 0 .5522847-.44771525 1-1 1s-1-.4477153-1-1c0-4.9190528 3.22857447-9.0838082 7.68234406-10.4908871C6.04909277 11.2284777 5 9.23675324 5 7c0-3.86599325 3.13400675-7 7-7zm0 2C9.23857625 2 7 4.23857625 7 7s2.23857625 5 5 5c2.7614237 0 5-2.23857625 5-5s-2.2385763-5-5-5z"/>
            </svg>
          </Tooltip>
          <span>Clients</span>
        </Link>
        <Link
          className={props.currentPath === 'projects' ? 'active' : null}
          to="/projects">
          <Tooltip message={'Projects'} position={'right'} size={'large'}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path fill="currentColor" d="M20.7272727 0C22.5347501 0 24 1.46524991 24 3.27272727V20.7272727C24 22.5347501 22.5347501 24 20.7272727 24H3.27272727C1.46524991 24 0 22.5347501 0 20.7272727V3.27272727C0 1.46524991 1.46524991 0 3.27272727 0zM2 20.8888889C2 21.5025386 2.49746139 22 3.11111111 22H20.8888889C21.5025386 22 22 21.5025386 22 20.8888889V15h-5v2c0 .5128358-.3860402.9355072-.8833789.9932723L16 18H8c-.51283584 0-.93550716-.3860402-.99327227-.8833789L7 17v-2H2zM20.8888889 2H3.11111111C2.49746139 2 2 2.49746139 2 3.11111111V13h6c.51283584 0 .93550716.3860402.99327227.8833789L9 14v2h6v-2c0-.5128358.3860402-.9355072.8833789-.9932723L16 13h6V3.11111111C22 2.49746139 21.5025386 2 20.8888889 2z"/>
          </svg>
          </Tooltip>
          <span>Projects</span>
        </Link>
        <Link
          className={props.currentPath === 'invoices' ? 'active' : null}
          to="/invoices">
          <Tooltip message={'Invoices'} position={'right'} size={'large'}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path fill="currentColor" d="M20 0c.5128358 0 .9355072.38604019.9932723.88337888L21 1v15c0 .5522847-.4477153 1-1 1-.5128358 0-.9355072-.3860402-.9932723-.8833789L19 16V2H2v18.5c0 .7799673.59463286 1.4204806 1.35549495 1.4931362L3.5 22h.93l.061097-.0074159c.15872778-.0266995.37963462-.0870923.60654937-.189877l.17043488-.085173C5.75004509 21.452454 6 21.0900194 6 20.5V19c0-.5522847.44771525-1 1-1h16c.5522847 0 1 .4477153 1 1v1.5c0 1.9332847-1.5667153 3.5-3.5 3.5h-17C1.63115808 24 .10485498 22.5359916.00517793 20.6920621L0 20.5V1C0 .48716416.38604019.06449284.88337888.00672773L1 0h19zm2 20H8v.5c0 .536173-.10398058 1.0146062-.29461802 1.4340729L7.672 22H20.5c.7799673 0 1.4204806-.5946329 1.4931362-1.3554949L22 20.5V20zm-8-7c.5522847 0 1 .4477153 1 1 0 .5128358-.3860402.9355072-.8833789.9932723L14 15H7c-.55228475 0-1-.4477153-1-1 0-.5128358.38604019-.9355072.88337887-.9932723L7 13h7zm0-4c.5522847 0 1 .44771525 1 1 0 .5128358-.3860402.9355072-.8833789.9932723L14 11H7c-.55228475 0-1-.4477153-1-1 0-.51283584.38604019-.93550716.88337887-.99327227L7 9h7zm0-4c.5522847 0 1 .44771525 1 1 0 .51283584-.3860402.93550716-.8833789.99327227L14 7H7c-.55228475 0-1-.44771525-1-1 0-.51283584.38604019-.93550716.88337887-.99327227L7 5h7z"/>
          </svg>
          </Tooltip>
          <span>Invoices</span>
        </Link>
        <Link
          className={props.currentPath === 'settings' ? 'active' : null}
          to="/settings">
          <Tooltip message={'Settings'} position={'right'} size={'large'}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path fill="currentColor" d="M17.5 13c.102275 0 .2009638.0153537.2938875.043882C18.0244356 13.0153173 18.260429 13 18.5 13c3.0375661 0 5.5 2.4624339 5.5 5.5S21.5375661 24 18.5 24c-.239571 0-.4755644-.0153173-.7070544-.0450259-.0561398.0186684-.1153969.0312215-.1763245.0382982L17.5 24h-12C2.44771525 24 0 21.5522847 0 18.5c0-2.9778388 2.32977061-5.3802396 5.27776778-5.4956584L5.5 13h12zM13 18.5c0-1.3294241.4716721-2.5486833 1.2567987-3.49956L5.5 15C3.55228475 15 2 16.5522847 2 18.5c0 1.8827914 1.45052386 3.396075 3.30658942 3.4948686L5.5 22l8.7576141.0005473C13.4719987 21.0495273 13 19.8298843 13 18.5zm5.5-3.5c-1.9329966 0-3.5 1.5670034-3.5 3.5s1.5670034 3.5 3.5 3.5 3.5-1.5670034 3.5-3.5-1.5670034-3.5-3.5-3.5zm0-15C21.5522847 0 24 2.44771525 24 5.5c0 2.97783878-2.3297706 5.3802396-5.2777678 5.4956584L18.5 11h-12c-.10227495 0-.20096385-.0153537-.29388748-.043882C5.97556441 10.9846827 5.73957098 11 5.5 11 2.46243388 11 0 8.53756612 0 5.5S2.46243388 0 5.5 0c.23957098 0 .47556441.01531727.7070544.04502592.05613978-.0186684.11539694-.03122155.17632447-.03829819L6.5 0h12zm0 2l-8.75679874.00044003C10.5283279 2.95131671 11 4.17057589 11 5.5c0 1.32988427-.4719987 2.54952735-1.25761415 3.50054728L18.5 9C20.4477153 9 22 7.44771525 22 5.5c0-1.88279141-1.4505239-3.39607498-3.3065894-3.49486856L18.5 2zm-13 0C3.56700338 2 2 3.56700338 2 5.5S3.56700338 9 5.5 9 9 7.43299662 9 5.5 7.43299662 2 5.5 2z"/>
          </svg>
          </Tooltip>
          <span>Settings</span>
        </Link>
        { props.currentUser.admin && (
          <Link
            className={props.currentPath === 'admin' ? 'bottom active' : 'bottom'}
            to="/admin/users">
            <Tooltip message={'Admin'} position={'right'} size={'large'}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                <path fill="currentColor" fillRule="nonzero" d="M12 0c6.627417 0 12 5.372583 12 12s-5.372583 12-12 12S0 18.627417 0 12 5.372583 0 12 0zm0 2C6.4771525 2 2 6.4771525 2 12s4.4771525 10 10 10 10-4.4771525 10-10S17.5228475 2 12 2zM7.61289944 6.20970461l.09420734.08318861 3.00016442 2.99914927C11.0987325 9.10482952 11.5371241 9 12 9c1.6568542 0 3 1.3431458 3 3s-1.3431458 3-3 3-3-1.3431458-3-3c0-.4628759.10482952-.9012675.29204249-1.2927288L6.29289322 7.70710678c-.39052429-.39052429-.39052429-1.02368927 0-1.41421356.36048396-.36048396.92771502-.3882135 1.32000622-.08318861zM12 11c-.2559368 0-.489417.0961482-.6662909.254295-.0098813.0153232-.0230051.0292146-.0366023.0428118l-.0428118.0366023C11.0961482 11.510583 11 11.7440632 11 12c0 .5522847.4477153 1 1 1s1-.4477153 1-1-.4477153-1-1-1zm7 0c.5522847 0 1 .4477153 1 1 0 .5128358-.3860402.9355072-.8833789.9932723L19 13h-1c-.5522847 0-1-.4477153-1-1 0-.5128358.3860402-.9355072.8833789-.9932723L18 11h1zM6 11c.55228475 0 1 .4477153 1 1 0 .5128358-.38604019.9355072-.88337887.9932723L6 13H5c-.55228475 0-1-.4477153-1-1 0-.5128358.38604019-.9355072.88337887-.9932723L5 11h1zm11.6571068-4.65710678c.3604839.36048396.3882135.92771502.0831886 1.32000622l-.0831886.09420734-.707.707c-.3905243.39052429-1.0236893.39052429-1.4142136 0-.3604839-.36048396-.3882135-.92771502-.0831886-1.32000622l.0831886-.09420734.707-.707c.3905243-.39052429 1.0236893-.39052429 1.4142136 0zM12 4c.5128358 0 .9355072.38604019.9932723.88337887L13 5v1c0 .55228475-.4477153 1-1 1-.5128358 0-.9355072-.38604019-.9932723-.88337887L11 6V5c0-.55228475.4477153-1 1-1z"/>
              </svg>
            </Tooltip>
            <span>Admin</span>
          </Link>
        )}
        {trial_remaining >= 0 && (
          <Link
            className={`trial_remaining bottom x-${trial_remaining}`}
            to="/settings">
            <Tooltip message={`${ trial_remaining } days of trial left`} position={'right'} size={'large'}>
              <span>{ trial_remaining }</span>
            </Tooltip>
          </Link>
        )}
        <a href="https://heyslate.com/docs" className="bottom">
          <Tooltip message={'Help & Support'} position={'right'} size={'large'}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path fill="currentColor" d="M3.5146 3.5148c4.68624656-4.68624656 12.2841534-4.68624656 16.9704 0 4.6862466 4.68624656 4.6862466 12.2841534 0 16.9704-4.6862466 4.6862466-12.28415344 4.6862466-16.9704 0-4.68624656-4.6862466-4.68624656-12.28415344 0-16.9704zm11.962058 13.3762273c-2.0746556 1.4785535-4.8790603 1.4785535-6.95371596 0L5.67113579 19.743451c3.67240706 3.0087784 8.98574961 3.0086041 12.65796651-.0005228zM4.25634895 5.67133579C1.24784396 9.34340922 1.24774489 14.65612 4.25605175 18.3283014l2.8527209-2.8514434c-1.47855348-2.0746556-1.47855348-4.8790603 0-6.95371596zm15.48690205 0l-2.8524237 2.85180625c1.4785535 2.07465566 1.4785535 4.87906036 0 6.95371596l2.852721 2.8514434c3.0083068-3.6721814 3.0082077-8.98489218-.0002973-12.65696561zM14.8282 9.1716c-1.5620822-1.56208218-4.0947178-1.56208218-5.6568 0-1.56208218 1.5620822-1.56208218 4.0947178 0 5.6568 1.5620822 1.5620822 4.0947178 1.5620822 5.6568 0 1.5620822-1.5620822 1.5620822-4.0947178 0-5.6568zm3.4999014-4.91534825c-3.6721814-3.00830686-8.98489218-3.00820779-12.65696561.0002972l2.85180625 2.8524237c2.07465566-1.47855348 4.87906036-1.47855348 6.95371596 0z"/>
          </svg>
          </Tooltip>
        </a>
        <button
          onClick={()=> { props.onClickLogout(props.currentUser)} }
          className="bottom">
          <Tooltip message={'Logout'} position={'right'} size={'large'}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path fill="currentColor" d="M4.41421356,13 L7.70710678,16.2928932 C8.09763107,16.6834175 8.09763107,17.3165825 7.70710678,17.7071068 C7.31658249,18.0976311 6.68341751,18.0976311 6.29289322,17.7071068 L1.29289322,12.7071068 C0.902368927,12.3165825 0.902368927,11.6834175 1.29289322,11.2928932 L6.29289322,6.29289322 C6.68341751,5.90236893 7.31658249,5.90236893 7.70710678,6.29289322 C8.09763107,6.68341751 8.09763107,7.31658249 7.70710678,7.70710678 L4.41421356,11 L17,11 C17.5522847,11 18,11.4477153 18,12 C18,12.5522847 17.5522847,13 17,13 L4.41421356,13 Z M21,2 L10,2 C9.44771525,2 9,1.55228475 9,1 C9,0.44771525 9.44771525,0 10,0 L22,0 C22.5522847,0 23,0.44771525 23,1 L23,23 C23,23.5522847 22.5522847,24 22,24 L10,24 C9.44771525,24 9,23.5522847 9,23 C9,22.4477153 9.44771525,22 10,22 L21,22 L21,2 Z"/>
            </svg>
          </Tooltip>
          <span>Logout</span>
        </button>
      </div>
      </React.Fragment>
    );
  }

  return null;
};

class Nav extends React.Component {

  render() {

    if(this.props.currentUser) {
      return (
        <nav
          className={this.props.currentUser.demo ? 'demo' : 'user'}>
          <LoggedInView
            onClickLogout={this.props.onClickLogout}
            redirectTo={this.props.redirectTo}
            activeMonth={this.props.activeMonth}
            currentMonth={this.props.currentMonth}
            currentUser={this.props.currentUser}
            subscription={this.props.subscription}
            currentPath={this.props.currentPath} />
        </nav>
      )
    }

    return (
      <nav>
        <LoggedOutView
          currentUser={this.props.currentUser} />
      </nav>
    )

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Nav);
