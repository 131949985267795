import {
  LOGIN,
  REGISTER,
  DEMO_REGISTER,
  LOGIN_PAGE_UNLOADED,
  REGISTER_PAGE_UNLOADED,
  PASSWORD_FORGET,
  PASSWORD_RESET,
  UPDATE_FIELD_AUTH,
  CHECK_UNSUBSCRIBE_TOKEN,
  UNSUBSCRIBE_UNLOADED,
  UNSUBSCRIBE
} from '../constants/actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case LOGIN:
      //console.log(action);
      if(action.error){
        return {
          ...state,
          errors: action.payload.errors
        };
      }else{
        return {
          ...state,
          inProgress: false
        };
      }
    case REGISTER:
      //console.log(action);
      return {
        ...state,
        inProgress: false,
        errors: action.error ? action.payload.errors : null
      };
    case DEMO_REGISTER:
      //console.log(action);
      return {
        ...state,
        inProgress: false,
        errors: action.error ? action.payload.errors : null
      };
    case PASSWORD_FORGET:
      //console.log(action);
      return {
        ...state,
        inProgress: false,
        message: action.error ? null : 'Thanks! Please check your email account for a link to reset your password.',
        errors: action.error ? action.payload.errors : null
      };
    case PASSWORD_RESET:
      //console.log(action);
      return {
        ...state,
        inProgress: false,
        errors: action.error ? action.payload.errors : null
      };
    case LOGIN_PAGE_UNLOADED:
      //console.log(action);
    case REGISTER_PAGE_UNLOADED:
      //console.log(action);
      return {};
    case UPDATE_FIELD_AUTH:
      //console.log(action);
      return { ...state, [action.key]: action.value };
    case CHECK_UNSUBSCRIBE_TOKEN:
      //console.log(action);
      return {
        ...state,
        inProgress: false,
        unsubscribe_email: action.error ? undefined : action.payload.body.email,
        unsubscribed: undefined
      };
    case UNSUBSCRIBE:
      //console.log(action);
      return {
        ...state,
        inProgress: false,
        unsubscribe_email: action.error ? state.unsubscribe_email : undefined,
        unsubscribed: action.error ? true : false,
        errors: action.error ? ['Sorry there was a problem unsubscribing, please contact hello@heyslate.com and we will be able to help you.'] : null
      };
    case UNSUBSCRIBE_UNLOADED:
      //console.log(action);
      return {
        ...state,
        inProgress: false,
        unsubscribe_email: undefined,
        unsubscribed: undefined
      };
    default:
      //console.log(action);
      return state;
  }

  return state;
};
