import React from 'react';
import ReactDOM from 'react-dom';
import Tooltip from '../Tooltip';
import { Info } from '../Info';
import ListErrors from '../ListErrors';
import ContentEditable from 'react-contenteditable';
import FocusTrap from 'focus-trap-react';
import { connect } from 'react-redux';
import { _ , isEmpty } from 'lodash';
import { history } from '../../configureStore';
import { TwitterPicker } from 'react-color';
import update from 'immutability-helper';
import agent from '../../agent';
import { Dropdown } from './Dropdown';
import ProjectOptions from './ProjectOptions';
import {ReactComponent as PriceIcon} from '../../images/price_small.svg';
import dateFns from 'date-fns';
import { AvailabilityDateBuilder } from '../../services/AvailabilityDateBuilder';
import getSymbolFromCurrency from 'currency-symbol-map';
import { components } from 'react-select';
import Select from 'react-select';
import { currencies } from '../../currencies';
import { validateEmail } from '../../components/Validate';
import { Link } from 'react-router-dom';

import {
  SCHEDULE_CREATE,
  CLIENT_CREATE,
  PROJECT_CREATE,
  PROJECT_UPDATE,
  PROJECT_REMOVE,
  CLOSE_PROJECTS_OVERLAY,
  SCHEDULE_AVAILABILITY,
  PROJECTS_PAGE_LOADED
} from '../../constants/actionTypes';

const Input = ({ autoComplete, ...props }) => <components.Input {...props} autoComplete="new-password" />;
const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <svg width="9" height="12" xmlns="http://www.w3.org/2000/svg"><g transform="translate(-70 -18)" fill="#1282FF" fill-rule="evenodd"><path fill="currentColor" d="M78.3013153 26.17460431c.15974318.18636704.15830762.45647696.00876349.64041738l-.0629961.06460661-3.5 3c-.16383926.1404336-.39586261.1579879-.57674789.0526626l-.07404349-.0526626-3.5-3c-.20966292-.17971107-.23394369-.49536107-.05423261-.70502399.15974318-.18636704.42689488-.22625929.63154276-.10660658l.07348123.05237397L74.42167794 28.841l3.17461337-2.7206283c.20966292-.17971108.52531292-.15543031.70502399.05423261zm-3.6282761-8.10689523l.07404349.05266262 3.5 3c.20966292.17971107.23394369.49536107.05423261.70502399-.15974318.18636704-.42689488.22625929-.63154276.10660658l-.07348123-.05237397L74.42167794 19.158l-3.17459525 2.7216283c-.18636704.15974318-.45647696.15830762-.64041739.00876349l-.0646066-.0629961c-.15974318-.18636704-.15830762-.45647696-.0087635-.64041738l.06299611-.06460661 3.5-3c.16383926-.14043365.39586261-.15798786.57674789-.05266262z" opacity=".5" fill-rule="nonzero"></path></g></svg>
    </components.DropdownIndicator>
  )
}

const mapStateToProps = (state, ownProps) => ({
  ...state,
  errors: state.projects.errors,
  dates: state.dates
});

const mapDispatchToProps = dispatch => ({

  newClient: client => {
    dispatch({ type: CLIENT_CREATE, payload: agent.Clients.create(client) })
  },

  newProject: project => {
    dispatch({ type: PROJECT_CREATE, payload: agent.Projects.create(project) })
  },

  getAvailability: (from,to) => {
    dispatch({
      type: SCHEDULE_AVAILABILITY,
      payload: agent.Schedule.availability(from,to)
    })

  },

  autoPopulateSchedule: (project, dates) => {

    dispatch({
      type: SCHEDULE_CREATE,
      payload: agent.Schedule.create_multiple(dates).then((res) => {
        if(!res.body.errors) {
          dispatch({ type: PROJECT_UPDATE, payload: agent.Projects.update(project) })
          dispatch({ type: CLOSE_PROJECTS_OVERLAY });
        }
        return res;
      })
    })

  },

  updateProject: project => {
    dispatch({
      type: PROJECT_UPDATE,
      payload: agent.Projects.update(project).then((res) => {
        if(!res.body.errors) {
          dispatch({ type: CLOSE_PROJECTS_OVERLAY });
          // check if project name has changed
          if (history.location.pathname !== '/projects/' + res.body.slug) {
            history.push('/projects/')
          }
        }
        return res;
      })
    })
  },

  transitionState: (project, state) => {
    dispatch({
      type: PROJECT_UPDATE,
      payload: agent.Projects.transition_state(project.id, state).then((res) => {
        if(!res.body.errors) {
          dispatch({ type: CLOSE_PROJECTS_OVERLAY });
        }
        return res;
      })
    })
  },

  deleteProject: (project, prompt) => {
    dispatch({
      type: PROJECT_REMOVE,
      payload: agent.Projects.remove(project, prompt).then((res) => {
        if(!res.body.errors) {
          history.push('/projects/')
        }
        return res;
      })
    })
  },

})

export let scrollPosition = 0;

class Overlay extends React.Component {

  constructor(props) {
    super(props);

    this.searchClientsRef = React.createRef();
    this.clientTitleRef = React.createRef();
    this.projectTitleRef = React.createRef();
    this.clientButtonRef = React.createRef();
    this.projectButtonRef = React.createRef();
    this.deleteProjectButtonRef = React.createRef();

    if (this.props.project) {

      let client = this.props.clients.clients.filter((el) => {
        return el.id === this.props.project.client_id
      });

      this.state = {
        initialLoad: true,
        counter: 1,
        overlay: this.props.overlay,
        overlay_history: [this.props.overlay],
        new: false,
        loading: false,
        project: this.props.project,
        client: client[0]
      }

    } else {

      this.state = {
        initialLoad: true,
        counter: 1,
        overlay: this.props.overlay,
        overlay_history: ['client'],
        clients: this.props.clients.clients.filter((el) => {
          return el.state === 'active'
        }),
        keyword: false,
        new: true,
        loading: false
      }

    }

  }

  componentDidMount(){
    document.addEventListener("keydown", this.keyPress.bind(this), false);

    if(this.state.overlay === 'auto-populate') {
      this.getAvailability(this.state.project);
    }

    if(this.state.overlay === 'client') {
      this.searchClientsRef.current.focus()
    }

    if(this.state.overlay === 'new_client') {
      this.clientTitleRef.current.focus()
    }

    if(this.state.overlay === 'new_project') {
      this.projectTitleRef.current.focus()
    }

    if(this.state.overlay === 'project') {
      let estimated = this.state.project.amount / this.state.project.estimated_time_value

      this.setState({
        estimated: estimated
      })
    }

  }

  componentWillUnmount(){
    document.removeEventListener("keydown", this.keyPress.bind(this), false);
    scrollPosition = 0
  }

  componentDidUpdate(prevProps, prevState) {

    if(prevState.loading && this.props.projects.errors) {

      this.setState({
        loading: false
      })

    }

    if((this.state.overlay === 'new_project' || this.state.overlay === 'project') && prevState.project !== this.state.project) {

      let estimated = this.state.project.amount / this.state.project.estimated_time_value

      this.setState({
        estimated: estimated
      })

    }

    if(this.state.overlay === 'client') {
      this.searchClientsRef.current.focus()
    }

    if(this.state.overlay === 'new_client' && prevState.overlay !== 'new_client') {
      this.clientTitleRef.current.focus()
    }

    if(this.state.overlay === 'new_project' && prevState.overlay !== 'new_project') {
      this.projectTitleRef.current.focus()
    }


  }

  keyPress(e, field=false){

    // up/down arrow
    if ( field && [38,40].includes( e.keyCode ) ) {

      let value = parseInt(e.target.innerText)

      // down
      if( e.keyCode === 40 ) {

        if (value === 0) {
          return false
        }

        --value

      }

      // up
      if( e.keyCode === 38 ) {
        ++value
      }

      const [split_field, parent_key] = field.split('.').reverse()
      let newData;

      if (parent_key === undefined) {

        newData = update(this.state, {
          [split_field]: {$set: value}
        })

      } else {

        newData = update(this.state, {
          [parent_key]: {
            [split_field]: {$set: value}
          }
        })

      }

      this.setState(newData);

      // cleanup
      newData = null;

    }

    // escape
    // if(e.keyCode === 27) {
    //   this.props.closeOverlay(e)
    // }

    // enter
    if(e.keyCode === 13) {

      const overlay = this.state.overlay;

      if (e.target.nodeName === 'TEXTAREA') {
        return false;
      }

      if (overlay === 'new_client' && this.clientButtonRef.current) {
        this.clientButtonRef.current.click()
      }

      if ((overlay === 'project' || overlay === 'new_project') && this.projectButtonRef.current) {
        this.projectButtonRef.current.click()
      }

      if (overlay === 'delete' && this.deleteProjectButtonRef.current) {
        this.deleteProjectButtonRef.current.click()
      }

    }

  }

  formatPrice(price, currency=undefined) {

    return new Intl.NumberFormat(this.props.common.locale, {
        style: 'currency',
        currency: currency || this.props.common.currentUser.currency
    }).format(price)

  }

  numericOnly(value){
    return value.replace(/[^0-9.]/g, '')
  }

  setOverlay(e, overlay, direction) {

    e.stopPropagation();

    let overlay_history;

    if (direction === 'back') {
      let array = this.state.overlay_history
      overlay_history = array.splice(0, array.length-1)
    } else {
      overlay_history = this.state.overlay_history.concat(overlay);
    }

    if(overlay === 'client') {
      this.searchClientsRef.current.value = ''
    }

    this.setState({
      ...this.state, // needed?
      initialLoad: false,
      overlay: overlay,
      overlay_history: overlay_history,
      counter: overlay === 'client' ? 1 : (direction === 'forward' ? this.state.counter + 1 : this.state.counter - 1),
      //|| overlay === 'project') ? false : this.state.keyword,
      results: false,
      keyword: false
    });

  }

  search(e, type) {

    let search = e.currentTarget.value;
    let array = {
      'clients': this.props.clients.clients
    }

    if (search.length > 1) {

      let list = _.filter(array[type], _.flow(
        _.values,
        _.partialRight(_.some, _.method('match', new RegExp(search, 'i')))
      ));

      this.setState({
        ...this.state, // needed?
        results: list,
        keyword: search
      })

      if (list.length === 0 && e.keyCode === 13) {
        this.setupNewClient()
      }

    }

    if (search.length === 0) {

      this.setState({
        ...this.state, // needed?
        results: false,
        keyword: false
      })

    }

  }

  updateField(field, value) {

    const [split_field, parent_key] = field.split('.').reverse()
    let newData;

    if (parent_key === undefined) {

      newData = update(this.state, {
        [split_field]: {$set: value}
      })

    } else {

      newData = update(this.state, {
        [parent_key]: {
          [split_field]: {$set: value}
        }
      })

    }

    // get correct rate for new projects
    if(this.state.new && this.state.client && field === 'project.project_rate_unit') {

      let rate;

      if (value === 'hours') {
        rate = this.state.client.hourly_rate ? this.state.client.hourly_rate : this.props.common.currentUser.standard_hourly_rate;
      }

      if (value === 'days') {
        rate = this.state.client.day_rate ? this.state.client.day_rate : this.props.common.currentUser.standard_day_rate;
      }

      newData = update(this.state, {
        'project': {
          'project_rate_value': {$set: rate},
          'project_rate_unit': {$set: value}
        }
      })

    }

    this.setState(newData);

    // cleanup
    newData = null;

  }

  updateColour(color) {
    this.updateField('client.hex_colour', color.hex)

    this.setState({
      displayColorPicker: false
    })

  }

  setClient(e, client) {

    e.stopPropagation();

    this.setState({
      ...this.state,
      overlay: 'new_project',
      overlay_history: this.state.overlay_history.concat('new_project'),
      counter: this.state.counter + 1,
      projects: this.state.projects,
      client: client,
      project: {
        title: '',
        billing_type: 'fixed_price',
        client_id: client.id,
        amount: '',
        estimated_time_unit: 'days',
        estimated_time_value: 1,
        project_rate_unit: 'days',
        project_rate_value: client.day_rate
      },
      estimated: 0,
      results: false,
      keyword: false
    })

    this.searchClientsRef.current.value = ''
    this.searchClientsRef.current.blur()

  }

  setupNewClient(e) {

    var r = ((Math.floor(Math.random() * 255) + 255) / 2);
    var g = ((Math.floor(Math.random() * 255) + 255) / 2);
    var b = ((Math.floor(Math.random() * 255) + 255) / 2);
    var random_colour = 'rgb(' + r + ', ' + g + ', ' + b + ')';

    this.setState({
      ...this.state,
      overlay: 'new_client',
      overlay_history: this.state.overlay_history.concat('new_client'),
      counter: this.state.counter + 1,
      client: {
        name: this.state.keyword ? this.state.keyword : '',
        hex_colour: random_colour,
        day_rate: this.props.common.currentUser.standard_day_rate,
        hourly_rate: this.props.common.currentUser.standard_hourly_rate,
        preferred_billing_unit: 'days',
        address_line_1: '',
        address_line_2: '',
        city: '',
        country: '',
        postal_code: '',
        payment_terms: 30,
        invoice_schedule: 'end_of_month',
        currency: this.props.common.currentUser.currency
      },
      results: false,
      keyword: false
    })

    this.searchClientsRef.current.value = ''
    this.searchClientsRef.current.blur()

  }

  createClient(e, clientName) {

    // validate email if provided
    if( this.state.client.email && !validateEmail(this.state.client.email) ) {
      return false;
    }

    let newData;

    // pro-rata day rate
    if (this.state.client.preferred_billing_unit === 'hours') {

      newData = update(this.state.client, {
        day_rate: {$set: this.state.client.hourly_rate * this.props.common.currentUser.hours_per_day }
      })

    }

    // pro-rata hourly rate
    if (this.state.client.preferred_billing_unit === 'days') {
      newData = update(this.state.client, {
        hourly_rate: {$set: this.state.client.day_rate / this.props.common.currentUser.hours_per_day }
      })

    }

    // create the client
    this.props.newClient( newData );

    this.setState({
      newData,
      loading: true
    })

    let that = this;
    let timer = setInterval(function() {

      let new_client = that.props.clients.clients.filter(function (el) {
        return el.name === clientName
      });

      if (new_client[0]) {

        clearInterval(timer);

        let client = new_client[0]

        let overlay_history = that.state.overlay_history;

        let newState = {
          ...that.state,
          overlay: 'new_project',
          overlay_history: overlay_history.splice(0, overlay_history.length-1).concat('new_project'),
          project: {
            title: '',
            billing_type: 'fixed_price',
            client_id: client.id,
            amount: '',
            estimated_time_unit: 'days',
            estimated_time_value: 1,
            project_rate_unit: 'days',
            project_rate_value: client.day_rate
          },
          estimated: 0,
          keyword: false
        }

        that.setState(newState);

      }

    }, 100);

    // cleanup
    newData = null;

  }

  setProject(e, project) {

    e.stopPropagation();

    this.setState({
      overlay: 'schedule',
      overlay_history: this.state.overlay_history.concat('schedule'),
      counter: this.state.counter + 1,
      project: project,
      project_id: project.id,
      results: false,
      keyword: false
    });

  }

  createProject(e, projectName) {

    this.setState({
      loading: true
    })

    // create the project
    this.props.newProject( this.state.project );

    let that = this;
    let timer = setInterval(function() {

      let new_project = that.props.projects.projects.filter(function (el) {
        return el.title === projectName
      });

      if (new_project[0]) {

        clearInterval(timer);

        if( new_project[0].billing_type === 'fixed_price') {

          that.getAvailability(new_project[0]);

        } else {

          history.push('/projects/' + new_project[0].slug);

          that.props.closeOverlay(e)

        }

      }

    }, 100);

  }

  getAvailability(project) {

    // get availability
    this.props.getAvailability(
      dateFns.format(new Date(), 'DD-MM-YYYY'),
      dateFns.format( dateFns.addDays(new Date(), 90), 'DD-MM-YYYY')
    );

    let overlay_history = this.state.overlay_history;

    let that = this

    let timer = setInterval(function() {

      if (that.props.schedule.availability) {

        clearInterval(timer);

        let dates = AvailabilityDateBuilder(project, that.props.schedule.availability.dates, that.props.common.currentUser, undefined)

        let newState = {
          ...that.state,
          project: project,
          overlay: 'auto-populate',
          overlay_history: overlay_history.splice(0, overlay_history.length-1).concat('auto-populate'),
          dates: dates,
          loading: false
        }

        that.setState(newState);

      }

    });

  }

  closeAvailability(e, project) {
    history.push('/projects/' + project.slug);
    this.props.closeOverlay(e)
  }

  updateProject(e, project) {
    this.props.updateProject( this.state.project );
  }

  deleteProject = () => {

    this.setState({
      overlay: 'delete',
      overlay_history: this.state.overlay_history.concat('delete'),
      counter: this.state.counter + 1,
    });

  }

  confirmCancelProject = (project) => {

    this.props.transitionState( this.state.project, 'cancelled' );

  }

  confirmDeleteProject = (e, project) => {

    e.stopPropagation();

    this.props.deleteProject( this.state.project, this.state.prompt );

  }

  autoPopulateSchedule(e, project, dates) {

    e.stopPropagation();

    let newData = update(project, {
      state: {$set: 'active' }
    })

    this.props.autoPopulateSchedule(newData, dates);

    this.setState({
      loading: true
    })

  }

  checkProjectName(e, name) {

    e.stopPropagation();

    if (name === this.state.project.title) {

      this.setState({
        can_delete: true,
        prompt: name
      });

    } else {

      this.setState({
        can_delete: false,
        prompt: name
      });

    }

  }

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false })
  };

  render() {

    const close =
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
        <g strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" fill="none" stroke="currentColor" strokeMiterlimit="10">
          <path d="M19 5L5 19M19 19L5 5"/>
        </g>
      </svg>

    const price =
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
        <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
          <circle cx="11" cy="10" r="1.5"/><path d="M.5 2.5v11a2 2 0 002 2h13v-11h-13a2 2 0 01-2-2h0a2 2 0 012-2h9v2"/>
        </g>
      </svg>

    const arrow =
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
        <g strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" fill="none" stroke="currentColor" strokeMiterlimit="10">
          <path d="M2 12h20M15 5l7 7-7 7"/>
        </g>
      </svg>

    const client =
      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48">
        <g strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" fill="currentColor" stroke="currentColor">
        <path fill="none" strokeMiterlimit="10" d="M24 2C11.85 2 2 11.85 2 24v18s6 4 22 4 22-4 22-4V24c0-12.15-9.85-22-22-22z"/>
        <path fill="none" strokeMiterlimit="10" d="M28.577 8.673c-5.07 5.049-11.822 8.409-19.344 9.164C8.441 19.734 8 21.815 8 24c0 8.837 7.163 16 16 16s16-7.163 16-16c0-7.245-4.818-13.357-11.423-15.327z"/>
        <circle fill="none" strokeMiterlimit="10" cx="17" cy="25" r="1"/>
        <circle fill="none" strokeMiterlimit="10" cx="31" cy="25" r="1"/>
        <circle data-stroke="none" cx="17" cy="25" r="1" stroke="none"/>
        <circle data-stroke="none" cx="31" cy="25" r="1" stroke="none"/>
        </g>
      </svg>

    const cancel =
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10">
          <circle cx="8" cy="8" r="7.5"/>
          <path d="M2.697 13.303L13.303 2.697"/>
        </g>
      </svg>

    const icon_delete =
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10">
          <path d="M2.5 6.5v7c0 1.105.895 2 2 2h7c1.105 0 2-.895 2-2v-7M.5 3.5h15M5.5 3.5v-3h5v3"/>
        </g>
      </svg>

    return (
      ReactDOM.createPortal(

      <FocusTrap>
      <div className="focus">
        <div className={`overlay__blocks counter__${this.state.counter} ${this.state.initialLoad && 'initialLoad'}`}>

          {this.state.overlay_history.includes('client') && (
            <div className={this.props.clients.clients.length === 0 ? 'overlay__block empty' : 'overlay__block client'}>
              {this.props.clients.clients.length === 0 && (
                <div className="empty">
                  { client }
                  <span>Add your first client.</span>
                </div>
              )}
              <div className="header">
                <input
                  autoFocus={true}
                  ref={this.searchClientsRef}
                  onKeyUp={(e) => this.search(e, 'clients')}
                  type="text"
                  placeholder={ this.props.clients.clients.length === 0 ? 'Client Name' : 'Search Clients...'} />
                <button
                  onClick={(e) => this.props.closeOverlay(e)}>
                  {close}
                </button>
              </div>
              <div className="content">
                {(this.state.results ? this.state.results : this.state.clients).map((el) => {
                  return (
                    <div
                      key={el.name}
                      className="item padding border rounded"
                      onClick={(e) => this.setClient(e, el)}>{el.name}</div>
                  )
                })}
                {this.state.keyword.length > 1 ? (
                  <React.Fragment>
                  <div className="divider padding"></div>
                  <div
                    className="new padding border rounded"
                    onClick={(e) => this.setupNewClient(e)}>Create a client called &shy;<b>{this.state.keyword}</b> { arrow }</div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                  <div className="divider padding"></div>
                  <div
                    className="new padding border rounded"
                    onClick={(e) => this.setupNewClient(e)}>Create a new client { arrow }</div>
                  </React.Fragment>
                )}
              </div>
            </div>
          )}

          {this.state.overlay_history.includes('new_client') && (
            <div className="overlay__block new_client overflow">
              <div className="header">
                <input
                  type="text"
                  ref={this.clientTitleRef}
                  value={this.state.client.name}
                  placeholder="Client Name..."
                  onChange={(e) => this.updateField('client.name', e.target.value)} />
                <button
                  onClick={(e) => this.setOverlay(e, 'client', 'back')}>
                  {close}
                </button>
              </div>
              <div className="content">
                <div className="flex">
                  <textarea
                    type="text"
                    placeholder="Address..."
                    value={this.state.client.address}
                    onKeyDown={(e) => this.keyPress(e)}
                    onChange={(e) => this.updateField('client.address', e.target.value)} />
                </div>
                <label className="flex padding">
                  <div className="date">Contact Name</div>
                  <div
                    className="input text">
                      <input
                        type="text"
                        placeholder="Optional"
                        value={this.state.client.contact_name}
                        onKeyDown={(e) => this.keyPress(e)}
                        onChange={(e) => this.updateField('client.contact_name', e.target.value)} />
                  </div>
                </label>
                <label className="flex padding border">
                  <div className="date">Email Address</div>
                  <div
                    className="input text">
                      <input
                        type="text"
                        placeholder="Optional"
                        value={this.state.client.email}
                        onKeyDown={(e) => this.keyPress(e)}
                        onChange={(e) => this.updateField('client.email', e.target.value)} />
                  </div>
                </label>
                <label className="flex padding border">
                  <div className="date">Colour</div>
                  <div className="picker">
                    <div
                      className="preview"
                      style={{backgroundColor: this.state.client.hex_colour}}
                      onClick={ this.handleClick }>
                    </div>
                    { this.state.displayColorPicker && <div className="popover">
                      <div className="popover__cover" onClick={ this.handleClose }/>
                      <TwitterPicker
                        color={ this.state.client.hex_colour }
                        onChangeComplete={this.updateColour.bind(this)}/>
                      </div>}
                  </div>
                </label>
                <div className="flex padding border">
                  <div className="date">Currency</div>
                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={currencies}
                    defaultValue={currencies.filter((el) => { return el.value === this.state.client.currency})}
                    menuPlacement="auto"
                    onChange={(e) => this.updateField('client.currency', e.value)}
                    components={{ Input, DropdownIndicator }}
                  />
                </div>
                <div className="flex padding border">
                  <div className="date">How do you want to bill {this.state.client.name}?</div>
                  <Dropdown
                    client={this.state.client}
                    currency={this.props.common.currentUser.currency}
                    content="preferred_billing_unit"
                    onChange={this.updateField.bind(this)} />
                </div>
                {this.state.client.preferred_billing_unit !== 'projects' && (
                  this.state.client.preferred_billing_unit === 'hours' ? (
                    <div className="flex padding border">
                      <div className="date">How much do you charge per hour?</div>
                      <div
                        className={`input currency`}
                        onClick={(e) => e.currentTarget.querySelector('[contentEditable]').focus() }>
                        <span className="helper">{getSymbolFromCurrency( this.state.client ? this.state.client.currency : this.props.common.currentUser.currency )}</span>
                        <ContentEditable
                          innerRef={ React.createRef() }
                          html={this.state.client.hourly_rate.toString()}
                          onKeyDown={(e) => this.keyPress(e)}
                          onChange={(e) => this.updateField('client.hourly_rate', this.numericOnly(e.target.value))} />
                      </div>
                    </div>
                  ) : (
                    <div className="flex padding border">
                      <div className="date">How much do you charge per day?</div>
                      <div
                        className={`input currency`}
                        onClick={(e) => e.currentTarget.querySelector('[contentEditable]').focus() }>
                        <span className="helper">{getSymbolFromCurrency( this.state.client ? this.state.client.currency : this.props.common.currentUser.currency )}</span>
                        <ContentEditable
                          innerRef={ React.createRef() }
                          html={this.state.client.day_rate.toString()}
                          onKeyDown={(e) => this.keyPress(e)}
                          onChange={(e) => this.updateField('client.day_rate', this.numericOnly(e.target.value))} />
                      </div>
                    </div>
                  )
                )}
              </div>
              <div className="actions">
                <button
                  ref={this.clientButtonRef}
                  className={this.state.loading ? 'btn btn__loading' : 'btn'}
                  disabled={this.state.loading || isEmpty(this.state.client.name)}
                  onClick={(e) => this.createClient(e, this.state.client.name)}>
                  <div className="loader loader__button"></div>
                  <span>Save & Continue</span>
                </button>
              </div>
            </div>
          )}

          {(this.state.overlay_history.includes('project') || this.state.overlay_history.includes('new_project')) && (
            <div className="overlay__block new_project overflow">
              <div className="header">
                <input
                  autoFocus={true}
                  ref={this.projectTitleRef}
                  className="form-control"
                  type="text"
                  placeholder="Project Name..."
                  value={this.state.project.title}
                  onChange={(e) => this.updateField('project.title', e.target.value)} />
                {this.state.overlay_history.includes('new_project') ? (
                  <button
                    onClick={(e) => this.setOverlay(e, 'client', 'back')}>
                    {close}
                  </button>
                ) : (
                  <button
                    onClick={(e) => this.props.closeOverlay(e)}>
                    {close}
                  </button>
                )}
              </div>
              <div className="content">
                <div className="flex">
                  <textarea
                    className="form-control"
                    type="text"
                    placeholder="Description..."
                    value={this.state.project.description ? this.state.project.description : ''}
                    onChange={(e) => this.updateField('project.description', e.target.value)}></textarea>
                </div>

                <div className="tabs">

                  <div className="title">I want to bill this project by...</div>

                  <div className="options tabs">
                    <button
                      className={this.state.project.billing_type === 'time_spent' ? 'selected' : ''}
                      onClick={(e) => this.updateField('project.billing_type', 'time_spent')}>Time Spent</button>
                    <button
                      className={this.state.project.billing_type === 'fixed_price' ? 'selected' : ''}
                      onClick={(e) => this.updateField('project.billing_type', 'fixed_price')}>Fixed Price</button>
                  </div>

                </div>

                {this.state.project.billing_type === 'time_spent' ? (
                <React.Fragment>
                  <div className="flex padding border">
                    <div className="date">How do you want to bill this project?</div>
                    <Dropdown
                      project={this.state.project}
                      content="project_rate_unit"
                      onChange={this.updateField.bind(this)} />
                  </div>
                  <div className="flex padding border">
                    <div className="date">How much do you charge per {this.state.project.project_rate_unit === 'hours' ? 'hour' : 'day'}?</div>
                    <div
                      className={`input currency`}
                      onClick={(e) => e.currentTarget.querySelector('[contentEditable]').focus() }>
                      <span className="helper">{getSymbolFromCurrency( this.state.client ? this.state.client.currency : this.props.common.currentUser.currency )}</span>
                      <ContentEditable
                        innerRef={ React.createRef() }
                        html={this.state.project.project_rate_value.toString()}
                        onKeyDown={(e) => this.keyPress(e)}
                        onChange={(e) => this.updateField('project.project_rate_value', this.numericOnly(e.target.value))} />
                    </div>
                  </div>
                </React.Fragment>
                ) : (
                <React.Fragment>
                  <div className="flex padding border">
                    <div className="date">What is your fixed price?</div>
                    <div
                      className={`input currency`}
                      onClick={(e) => e.currentTarget.querySelector('[contentEditable]').focus() }>
                      <span className="helper">{getSymbolFromCurrency( this.state.client ? this.state.client.currency : this.props.common.currentUser.currency )}</span>
                      <ContentEditable
                        className="fixed_price"
                        innerRef={this.fixedPriceRef}
                        html={this.state.project.amount.toString()}
                        onKeyDown={(e) => this.keyPress(e)}
                        onChange={(e) => this.updateField('project.amount', this.numericOnly(e.target.value))} />
                    </div>
                  </div>
                  <div className="flex padding border">
                    <div className="date">How long do you think this will take?</div>
                    <div className="double">
                      <div
                        className="input number"
                        onClick={(e) => e.currentTarget.querySelector('[contentEditable]').focus() }>
                        <ContentEditable
                          innerRef={ React.createRef() }
                          html={this.state.project.estimated_time_value.toString()}
                          onKeyDown={(e) => this.keyPress(e, 'project.estimated_time_value')}
                          onChange={(e) => this.updateField('project.estimated_time_value', this.numericOnly(e.target.value))} />
                      </div>
                      <Dropdown
                        project={this.state.project}
                        content="estimated_time_unit"
                        onChange={this.updateField.bind(this)} />
                    </div>
                  </div>
                </React.Fragment>
                )}
                <div className="flex padding border">
                  <div className="date">Do you have a deadline?</div>
                  <Dropdown
                    project={this.state.project}
                    content="deadline"
                    onChange={this.updateField.bind(this)} />
                </div>
              </div>
              <div className="actions">
                { this.state.project.billing_type === 'fixed_price' && (
                  <div className="totals">
                    <div>
                      <PriceIcon />
                      { this.formatPrice(this.state.estimated, this.state.client ? this.state.client.currency : this.props.common.currentUser.currency) }/{this.state.project.estimated_time_unit.slice(0, -1)} <Tooltip message={`This is an estimate based on your fixed price and the length of time you expect this project to take.`} position={'top'} size={'large'}><Info /></Tooltip>
                    </div>
                  </div>
                )}
                {this.state.overlay_history.includes('new_project') ? (
                  <button
                    ref={this.projectButtonRef}
                    disabled={this.props.common.loading}
                    className={this.props.common.loading ? 'btn btn__loading' : 'btn'}
                    onClick={(e) => this.createProject(e, this.state.project.title)}>
                    <div className="loader loader__button"></div>
                    <span>Create Project</span>
                  </button>
                ) : (
                  <div className="buttons">
                    <ProjectOptions
                      content="small"
                      project={this.state.project}
                      confirmCancelProject={this.confirmCancelProject}
                      deleteProject={this.deleteProject} />
                    <button
                      ref={this.projectButtonRef}
                      disabled={this.props.common.loading}
                      className={this.props.common.loading ? 'btn btn__loading' : 'btn'}
                      onClick={(e) => this.updateProject(e, this.state.project)}>
                      <div className="loader loader__button"></div>
                      <span>Update</span>
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}

          {this.state.overlay_history.includes('auto-populate') && (
            <div className="overlay__block">
              <div className="header">
                <h2>Auto-populate Schedule</h2>
                <button
                  disabled={this.state.loading}
                  onClick={(e) => this.closeAvailability(e, this.state.project)}>
                  {close}
                </button>
              </div>
              {!this.state.dates ? (
                <div className="content">
                  <div className="padding">
                    <div className="loading"><div className="loader"></div></div>
                  </div>
                </div>
              ) : (
                <React.Fragment>
                <div className="content">
                  <div className="padding">
                    <p>You still need to schedule {this.state.project.estimate_remaining} for this project. We've found some free dates in your schedule,
                    do you want to set them aside for this project?</p>
                    <ul className="dates">
                    {this.state.dates.map((el) => {

                      var time_formatted;

                      if (this.state.project.estimated_time_unit === 'hours') {
                        var calc = String(el.unit_value_formatted).split('.')
                        var calc_hours = parseFloat(calc[0])
                        var calc_minutes = parseFloat('0.'+calc[1]) * 60
                        time_formatted = calc_hours + 'h ' + calc_minutes.toFixed() + 'm'
                      }

                      return (
                        <li>
                          {dateFns.format(el.date, 'ddd D MMMM YYYY')}
                          <span>{ this.state.project.estimated_time_unit === 'days' ? el.unit_value_formatted : time_formatted}</span>
                        </li>
                      )
                    })}
                    </ul>
                  </div>
                </div>
                <div className="actions">
                  <div>
                    <button
                      disabled={this.state.loading}
                      className="btn btn__outline" onClick={(e) => this.closeAvailability(e, this.state.project)}>
                      Skip
                    </button>
                  </div>
                  <button
                    className={this.state.loading ? 'btn btn__loading' : 'btn'}
                    disabled={this.state.loading}
                    onClick={(e) => this.autoPopulateSchedule(e, this.state.project, this.state.dates)}>
                      <div className="loader loader__button"></div>
                      <span>Add to my Schedule</span>
                    </button>
                </div>
                </React.Fragment>
              )}
            </div>
          )}

          {this.state.overlay_history.includes('delete') && (
            <div className="overlay__block delete">
              <div className="header">
                <h2>Delete {this.state.project.title}</h2>
                <button
                  onClick={(e) => this.setOverlay(e, 'project', 'back')}>
                  {close}
                </button>
              </div>
              <div className="content">
                <div className="padding">
                  <div className="option delete">
                    <p>Deleting this project cannot be undone. This will delete everything related to this project, include any work added to it. There is no going back.</p>
                    <div className="field">
                      <label>
                        <span className="simple">Please type <b>{this.state.project.title}</b> to confirm.</span>
                        <input
                          onKeyDown={(e) => this.keyPress(e)}
                          onKeyUp={(e) => this.checkProjectName(e, e.target.value)}
                          className="form-control"
                          type="text" />
                      </label>
                    </div>
                    <button
                      ref={this.deleteProjectButtonRef}
                      disabled={!this.state.can_delete || this.props.common.loading}
                      className={this.props.common.loading ? 'btn btn__loading' : 'btn btn__delete'}
                      onClick={(e) => this.confirmDeleteProject(e, this.state.project)}>
                      <div className="loader loader__button"></div>
                      <span>Delete {this.state.project.title} (cannot be undone)</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {this.state.overlay_history.includes('invoice') && (
            <div className="overlay__block delete">
              <div className="header">
                <h2>That's a wrap</h2>
                <button
                  onClick={(e) => this.props.closeOverlay(e) }>
                  {close}
                </button>
              </div>
              <div className="content">
                <div className="padding">
                  <div className="option delete">
                    <p>Great work on finishing this project! Don't forget to create an invoice for it so you can 
                      get paid as soon as possible.</p>
                    <Link
                      className="btn btn__inline"
                      to={`/invoices/new?project_id=${this.state.project.id}&client_id=${this.state.project.client_id}&currency=${this.state.project.currency}`}>
                      <span>Create Invoice</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}

          </div>

          <div
            className="overlay__bg"
            disabled={this.state.loading}
            onClick={(e) => this.props.closeOverlay(e) }></div>

          <ListErrors errors={this.props.errors} />

        </div>
        </FocusTrap>,
        document.querySelector('.overlay')
      )
    )


  }

}

export default connect(mapStateToProps, mapDispatchToProps)(Overlay);
