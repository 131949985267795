export const APP_LOAD = 'APP_LOAD';
export const ASYNC_END = 'ASYNC_END';
export const ASYNC_START = 'ASYNC_START';
export const CHANGE_NEXT_MONTH = 'CHANGE_NEXT_MONTH';
export const CHANGE_PREV_MONTH = 'CHANGE_PREV_MONTH';
export const CLIENT_CREATE = 'CLIENT_CREATE';
export const CLIENT_IN_FOCUS = 'CLIENT_IN_FOCUS';
export const CLIENT_REMOVE = 'CLIENT_REMOVE';
export const CLIENT_UPDATE = 'CLIENT_UPDATE';
export const CLIENT_LOADED = 'CLIENT_LOADED';
export const CLIENT_UNLOADED = 'CLIENT_UNLOADED';
export const CLIENT_SCHEDULE_LOADED = 'CLIENTS_SCHEDULE_LOADED';
export const CLIENTS_PAGE_LOADED = 'CLIENTS_PAGE_LOADED';
export const CLIENTS_PAGE_UNLOADED = 'CLIENTS_PAGE_UNLOADED';
export const PROJECT_CREATE = 'PROJECT_CREATE';
export const PROJECT_REMOVE = 'PROJECT_REMOVE';
export const PROJECT_UPDATE = 'PROJECT_UPDATE';
export const PROJECT_LOADED = 'PROJECT_LOADED';
export const PROJECT_UNLOADED = 'PROJECT_UNLOADED';
export const PROJECTS_PAGE_LOADED = 'PROJECTS_PAGE_LOADED';
export const PROJECTS_PAGE_UNLOADED = 'PROJECTS_PAGE_UNLOADED';
export const HOME_PAGE_LOADED = 'HOME_PAGE_LOADED';
export const HOME_PAGE_UNLOADED = 'HOME_PAGE_UNLOADED';
export const LOAD_DAYS = 'LOAD_DAYS';
export const LOGIN = 'LOGIN';
export const LOGIN_PAGE_UNLOADED = 'LOGIN_PAGE_UNLOADED';
export const LOGOUT = 'LOGOUT';
export const DEMO_LOGOUT = 'DEMO_LOGOUT';
export const MONTH_CACHED = 'MONTH_CACHED';
export const MONTH_LOADED = 'MONTH_LOADED';
export const MONTH_UNLOADED = 'MONTH_UNLOADED';
export const REDIRECT = 'REDIRECT';
export const DEMO_REGISTER = 'DEMO_REGISTER';
export const REGISTER = 'REGISTER';
export const REGISTER_PAGE_UNLOADED = 'REGISTER_PAGE_UNLOADED';
export const REQUEST_CLIENTS = 'REQUEST_CLIENTS';
export const REQUEST_PROJECTS = 'REQUEST_PROJECTS';
export const REQUEST_YEAR = 'REQUEST_YEAR';
export const SCHEDULE_BACK = 'SCHEDULE_BACK';
export const SCHEDULE_CREATE = 'SCHEDULE_CREATE';
export const SCHEDULE_FORWARD = 'SCHEDULE_FORWARD';
export const SCHEDULE_LOADED = 'SCHEDULE_LOADED';
export const SCHEDULE_MONTH = 'SCHEDULE_MONTH';
export const SCHEDULE_SUMMARY = 'SCHEDULE_SUMMARY';
export const DESTROY_SCHEDULE_SUMMARY = 'DESTROY_SCHEDULE_SUMMARY';
export const SCHEDULE_AVAILABILITY = 'SCHEDULE_AVAILABILITY';
export const DESTROY_SCHEDULE_AVAILABILITY = 'DESTROY_SCHEDULE_AVAILABILITY';
export const SCHEDULE_REMOVE = 'SCHEDULE_REMOVE';
export const SCHEDULE_RESET = 'SCHEDULE_RESET';
export const SCHEDULE_UNLOADED = 'SCHEDULE_UNLOADED';
export const SCHEDULE_UPDATE = 'SCHEDULE_UPDATE';
export const SCHEDULE_INVOICE = 'SCHEDULE_INVOICE';
export const SCHEDULE_UNDO_INVOICE = 'SCHEDULE_UNDO_INVOICE';
export const SET_PAGE = 'SET_PAGE';
export const SETTINGS_PAGE_UNLOADED = 'SETTINGS_PAGE_UNLOADED';
export const SETTINGS_UPDATE = 'SETTINGS_UPDATE';
export const SETTINGS_SAVED = 'SETTINGS_SAVED';
export const COUNTRY_SAVED = 'COUNTRY_SAVED';
export const SWITCH_MONTH = 'SWITCH_MONTH';
export const TASK_COMPLETE = 'TASK_COMPLETE';
export const TASK_CREATE = 'TASK_CREATE';
export const TASK_UPDATE = 'TASK_UPDATE';
export const TASKS_PAGE_LOADED = 'TASKS_PAGE_LOADED';
export const TASKS_PAGE_UNLOADED = 'TASKS_PAGE_UNLOADED';
export const UPDATE_FIELD_AUTH = 'UPDATE_FIELD_AUTH';
export const YEAR_LOADED = 'YEAR_LOADED';
export const YEAR_UNLOADED = 'YEAR_UNLOADED';
export const PASSWORD_RESET = 'PASSWORD_RESET';
export const PASSWORD_RESET_PAGE_UNLOADED = 'PASSWORD_RESET_PAGE_UNLOADED';
export const PASSWORD_FORGET = 'PASSWORD_FORGET';
export const PASSWORD_FORGET_PAGE_UNLOADED = 'PASSWORD_FORGET_PAGE_UNLOADED';
export const SUBSCRIPTION_CREATE = 'SUBSCRIPTION_CREATE';
export const SUBSCRIPTION_CANCEL = 'SUBSCRIPTION_CANCEL';
export const SUBSCRIPTION_REQUIRED = 'SUBSCRIPTION_REQUIRED';
export const SUBSCRIPTION_EXTEND = 'SUBSCRIPTION_EXTEND';
export const PAYMENT_METHOD_CREATE = 'PAYMENT_METHOD_CREATE';
export const PAYMENT_METHOD_UPDATE = 'PAYMENT_METHOD_UPDATE';
export const UNSUBSCRIBE = 'UNSUBSCRIBE';
export const UNSUBSCRIBE_UNLOADED = 'UNSUBSCRIBE_UNLOADED';
export const CHECK_UNSUBSCRIBE_TOKEN = 'CHECK_UNSUBSCRIBE_TOKEN';
export const REQUEST_ADMIN_USERS = 'REQUEST_ADMIN_USERS';
export const ADMIN_USERS_PAGE_LOADED = 'ADMIN_USERS_PAGE_LOADED';
export const ADMIN_USERS_PAGE_UNLOADED = 'ADMIN_USERS_PAGE_UNLOADED';
export const FORBIDDEN = 'FORBIDDEN'
export const ADMIN_USER_LOADED = 'ADMIN_USER_LOADED';
export const ADMIN_USER_UNLOADED = 'ADMIN_USER_UNLOADED';
export const SET_ERRORS = 'SET_ERRORS';
export const OPEN_PROJECTS_OVERLAY = 'OPEN_PROJECTS_OVERLAY';
export const CLOSE_PROJECTS_OVERLAY = 'CLOSE_PROJECTS_OVERLAY';
export const OPEN_SCHEDULE_OVERLAY = 'OPEN_SCHEDULE_OVERLAY';
export const CLOSE_SCHEDULE_OVERLAY = 'CLOSE_SCHEDULE_OVERLAY';
export const OPEN_CLIENTS_OVERLAY = 'OPEN_CLIENTS_OVERLAY';
export const CLOSE_CLIENTS_OVERLAY = 'CLOSE_CLIENTS_OVERLAY';
export const OPEN_SETTINGS_OVERLAY = 'OPEN_SETTINGS_OVERLAY';
export const CLOSE_SETTINGS_OVERLAY = 'CLOSE_SETTINGS_OVERLAY';
export const MASQUERADE='MASQUERADE'
export const MASQUERADE_REVOKE='MASQUERADE_REVOKE'
export const REQUEST_INVOICES = 'REQUEST_INVOICES'
export const INVOICE_CREATE = 'INVOICE_CREATE'
export const INVOICE_LOADED = 'INVOICE_LOADED'
export const INVOICE_UNLOADED = 'INVOICE_UNLOADED'
export const INVOICE_UPDATE = 'INVOICE_UPDATE'
export const INVOICE_REMOVE = 'INVOICE_REMOVE'
export const INVOICE_EMAIL = 'INVOICE_EMAIL'
export const INVOICES_PAGE_LOADED = 'INVOICES_PAGE_LOADED'
export const INVOICES_PAGE_UNLOADED = 'INVOICES_PAGE_UNLOADED'
export const INVOICE_PAYMENT_CREATE = 'INVOICE_PAYMENT_CREATE'
export const OPEN_INVOICES_OVERLAY = 'OPEN_INVOICES_OVERLAY'
export const CLOSE_INVOICES_OVERLAY = 'CLOSE_INVOICES_OVERLAY'
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION'
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION'
export const OPEN_OVERLAY = 'OPEN_OVERLAY';
export const CLOSE_OVERLAY = 'CLOSE_OVERLAY';
export const TIME_SUMMARY = 'TIME_SUMMARY';
export const DESTROY_TIME_SUMMARY = 'DESTROY_TIME_SUMMARY';
export const PAYMENT_SUMMARY = 'PAYMENT_SUMMARY';
export const DESTROY_PAYMENT_SUMMARY = 'DESTROY_PAYMENT_SUMMARY';