import React from 'react';
import ReactDOM from 'react-dom';
import Tooltip from '../Tooltip';
import { Info } from '../Info';
import ListErrors from '../ListErrors';
import ContentEditable from 'react-contenteditable';
import FocusTrap from 'focus-trap-react';
import { connect } from 'react-redux';
import dateFns from 'date-fns';
import _, { isEmpty } from 'lodash';
import { TwitterPicker } from 'react-color';
import update from 'immutability-helper';
import agent from '../../agent';
import { Dropdown } from './Dropdown';
import { OverlayItem } from './OverlayItem';
import {ReactComponent as NoteIcon} from '../../images/note.svg';
import {ReactComponent as PriceIcon} from '../../images/price_small.svg';
import {ReactComponent as TimeIcon} from '../../images/time_small.svg';
import getSymbolFromCurrency from 'currency-symbol-map';
import { components } from 'react-select';
import Select from 'react-select';
import { currencies } from '../../currencies';
import { validateEmail } from '../../components/Validate';
import {ReactComponent as LockIcon} from '../../images/lock.svg';

import {
  SCHEDULE_CREATE,
  SCHEDULE_UPDATE,
  SCHEDULE_REMOVE,
  CLIENT_CREATE,
  PROJECT_CREATE,
  MONTH_LOADED
} from '../../constants/actionTypes';

const Input = ({ autoComplete, ...props }) => <components.Input {...props} autoComplete="new-password" />;
const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <svg width="9" height="12" xmlns="http://www.w3.org/2000/svg"><g transform="translate(-70 -18)" fill="#1282FF" fill-rule="evenodd"><path fill="currentColor" d="M78.3013153 26.17460431c.15974318.18636704.15830762.45647696.00876349.64041738l-.0629961.06460661-3.5 3c-.16383926.1404336-.39586261.1579879-.57674789.0526626l-.07404349-.0526626-3.5-3c-.20966292-.17971107-.23394369-.49536107-.05423261-.70502399.15974318-.18636704.42689488-.22625929.63154276-.10660658l.07348123.05237397L74.42167794 28.841l3.17461337-2.7206283c.20966292-.17971108.52531292-.15543031.70502399.05423261zm-3.6282761-8.10689523l.07404349.05266262 3.5 3c.20966292.17971107.23394369.49536107.05423261.70502399-.15974318.18636704-.42689488.22625929-.63154276.10660658l-.07348123-.05237397L74.42167794 19.158l-3.17459525 2.7216283c-.18636704.15974318-.45647696.15830762-.64041739.00876349l-.0646066-.0629961c-.15974318-.18636704-.15830762-.45647696-.0087635-.64041738l.06299611-.06460661 3.5-3c.16383926-.14043365.39586261-.15798786.57674789-.05266262z" opacity=".5" fill-rule="nonzero"></path></g></svg>
    </components.DropdownIndicator>
  )
}

export let scrollPosition = 0;

const mapStateToProps = (state, ownProps) => ({
  ...state,
  activeMonth: state.common.activeMonth,
  errors: state.projects.errors || state.clients.errors
});

const mapDispatchToProps = dispatch => ({

  newClient: client => {
    dispatch({ type: CLIENT_CREATE, payload: agent.Clients.create(client) })
  },

  newProject: project => {
    dispatch({ type: PROJECT_CREATE, payload: agent.Projects.create(project) })
  },

  updateSchedule: (type, activeMonth, dates, work) => {

    if (type === 'CREATE' || type === 'HOLIDAY') {

      var schedule = [];

      dates.forEach(function(item, i){

        if (type === 'HOLIDAY') {

          schedule = update(schedule, {$push: [
            update(item, {
              holiday: {$set: true}
            })
          ]})

        } else {

          schedule = update(schedule, {$push: [
            update(item, {
              client_id: {$set: work.client_id},
              project_id: {$set: work.project_id},
              note: {$set: work.note}
            })
          ]})

        }

      })

      dispatch({
        type: SCHEDULE_CREATE,
        payload: agent.Schedule.create_multiple(schedule).then((res) => {
          if(!res.body.errors) {
            let month_name = dateFns.format(activeMonth, 'MMMM').toLowerCase()
            let year = dateFns.format(activeMonth, 'YYYY').toLowerCase()
            dispatch({ type: MONTH_LOADED, month: activeMonth, month_string: month_name + '-' + year, payload: agent.Month.show(month_name, year) })
          }
          return res;
        })
      })

      // cleanup
      schedule = null;

    }

    if (type === 'UPDATE') {

      dates.forEach(function(item, i){

        let schedule = update(item, {
          note: {$set: work.note}
        })

        dispatch({
          type: SCHEDULE_UPDATE,
          payload: agent.Schedule.update(schedule).then((res) => {
            if(!res.body.errors) {
              let month_name = dateFns.format(activeMonth, 'MMMM').toLowerCase()
              let year = dateFns.format(activeMonth, 'YYYY').toLowerCase()
              dispatch({ type: MONTH_LOADED, month: activeMonth, month_string: month_name + '-' + year, payload: agent.Month.show(month_name, year) })
            }
            return res;
          })
        })

        // cleanup
        schedule = null;

      })

    }

    if (type === 'REMOVE') {

      dates.forEach(function(item, i){

        dispatch({
          type: SCHEDULE_REMOVE,
          payload: agent.Schedule.remove_multiple([{id:parseInt(item.id)}]).then((res) => {
            if(!res.body.errors) {
              let month_name = dateFns.format(activeMonth, 'MMMM').toLowerCase()
              let year = dateFns.format(activeMonth, 'YYYY').toLowerCase()
              dispatch({ type: MONTH_LOADED, month: activeMonth, month_string: month_name + '-' + year, payload: agent.Month.show(month_name, year) })
            }
            return res;
          })
        })

      })

    }

  }
})

class Overlay extends React.Component {

  constructor(props) {
    super(props);

    this.searchClientsRef = React.createRef();
    this.clientTitleRef = React.createRef();
    this.searchProjectsRef = React.createRef();
    this.projectTitleRef = React.createRef();
    this.clientButtonRef = React.createRef();
    this.projectButtonRef = React.createRef();
    this.scheduleButtonRef = React.createRef();
    this.fixedPriceRef = React.createRef();

    let client;
    let project;
    let dates = [];

    if (this.props.work) {

      client = this.props.clients.clients.filter((el) => {
        return el.id === this.props.work.client
      });

      project = this.props.projects.projects.filter((el) => {
        return el.id === this.props.work.project_id
      });

      this.state = {
        initialLoad: true,
        counter: 1,
        overlay: this.props.overlay,
        overlay_history: ['work'],
        client: client[0],
        project: project[0],
        dates: [{...this.props.work}],
        note: this.props.work.note,
        new: false,
        loading: false
      }

    } else {

      this.state = {
        initialLoad: true,
        counter: 1,
        overlay: this.props.overlay,
        overlay_history: ['client'],
        clients: this.props.clients.clients.filter((el) => {
          return el.state === 'active'
        }),
        projects: this.props.projects,
        keyword: false,
        dates: dates,
        new: true,
        loading: false
      }

    }

  }

  componentDidMount(){
    document.addEventListener("keydown", this.keyPress.bind(this), false);

    if(this.state.overlay === 'client') {
      this.searchClientsRef.current.focus()
    }

  }

  componentWillUnmount(){
    document.removeEventListener("keydown", this.keyPress.bind(this), false);
    scrollPosition = 0
  }

  componentDidUpdate(prevProps, prevState) {

    if(prevState.loading && this.props.errors) {

      this.setState({
        ...this.state,
        loading: false
      })

    }

    if(prevProps.projects !== this.props.projects) {

      let client_id;

      if (this.state.client.id) {
        client_id = this.state.client.id;
      }

      this.setState({
        ...this.state, // needed?
        projects: this.state.client ? this.props.projects.projects.filter(function (el) {
          return el.client_id === client_id
        }) : this.props.projects.projects
      })

    }

    if(prevState.overlay !== 'new_project' && this.state.overlay === 'new_project' && this.state.project.billing_type === 'fixed_price') {
      this.fixedPriceRef.current.focus()
    }

    if(this.state.overlay === 'new_project' && prevState.project !== this.state.project) {

      let estimated = this.state.project.amount / this.state.project.estimated_time_value

      this.setState({
        estimated: estimated
      })

    }

    if(this.state.overlay === 'client') {
      this.searchClientsRef.current.focus()
    }

    if(this.state.overlay === 'new_client' && prevState.overlay !== 'new_client') {
      this.clientTitleRef.current.focus()
    }

    if(this.state.overlay === 'project') {
      this.searchProjectsRef.current.focus()
    }

    if(this.state.overlay === 'new_project' && prevState.overlay !== 'new_project') {
      this.projectTitleRef.current.focus()
    }

  }

  handleScheduleScroll(e) {
    scrollPosition = e.target.scrollTop;
  }

  keyPress(e, field=false){

    // up/down arrow
    if ( field && [38,40].includes( e.keyCode ) ) {

      let value = parseInt(e.target.innerText)

      // down
      if( e.keyCode === 40 ) {

        if (value === 0) {
          return false
        }

        --value

      }

      // up
      if( e.keyCode === 38 ) {
        ++value
      }

      const [split_field, parent_key] = field.split('.').reverse()
      let newData;

      if (parent_key === undefined) {

        newData = update(this.state, {
          [split_field]: {$set: value}
        })

      } else {

        newData = update(this.state, {
          [parent_key]: {
            [split_field]: {$set: value}
          }
        })

      }

      this.setState(newData);

      // cleanup
      newData = null;

    }

    // escape
    // if(e.keyCode === 27) {
    //   this.props.closeOverlay(e)
    // }

    // enter
    if(e.keyCode === 13) {

      const overlay = this.state.overlay;

      if (e.target.nodeName === 'TEXTAREA') {
        return false;
      }

      if (overlay === 'new_client' && this.clientButtonRef.current) {
        this.clientButtonRef.current.click()
      }

      if ((overlay === 'new_project') && this.projectButtonRef.current) {
        this.projectButtonRef.current.click()
      }

      if (overlay === 'schedule' && this.scheduleButtonRef.current) {
        this.scheduleButtonRef.current.click()
      }

    }

  }

  formatPrice(price, currency=undefined) {

    return new Intl.NumberFormat(this.props.common.locale, {
      style: 'currency',
      currency: currency || this.props.common.currentUser.currency
    }).format(price)

  }

  numericOnly(value){
    return value.replace(/[^0-9.]/g, '')
  }

  calculatePrice(unit, unit_value, rate){
    return (unit === 'days') ? parseFloat(rate).toFixed(2) : (parseFloat(unit_value) * rate).toFixed(2)
  }

  setOverlay(e, overlay, direction) {

    e.stopPropagation();

    let overlay_history;

    if (direction === 'back') {
      let array = this.state.overlay_history
      overlay_history = array.splice(0, array.length-1)
    } else {
      overlay_history = this.state.overlay_history.concat(overlay);
    }

    if(overlay === 'client') {
      this.searchClientsRef.current.value = ''
    }

    if(overlay === 'project') {
      this.searchProjectsRef.current.value = ''
    }

    this.setState({
      ...this.state, // needed?
      initialLoad: false,
      overlay: overlay,
      overlay_history: overlay_history,
      counter: overlay === 'client' ? 1 : (direction === 'forward' ? this.state.counter + 1 : this.state.counter - 1),
      //keyword: (overlay === 'client' || overlay === 'project') ? false : this.state.keyword,
      project: overlay === 'project' ? [] : this.state.project,
      project_id: overlay === 'project' ? false : this.state.project_id,
      projects: overlay === 'client' ? [] : this.state.projects,
      price: overlay === 'schedule'? true : false,
      results: false,
      keyword: false
    });

  }

  search(e, type) {

    let search = e.currentTarget.value;
    let array = {
      'clients': this.props.clients.clients,
      'projects': this.state.client ? this.state.projects : []
    }

    if (search.length > 1) {

      let list = _.filter(array[type], _.flow(
        _.values,
        _.partialRight(_.some, _.method('match', new RegExp(search, 'i')))
      ));

      if (type === 'clients') {

        this.setState({
          ...this.state, // needed?
          results: list,
          keyword: search
        })

        if (list.length === 0 && e.keyCode === 13) {
          this.setupNewClient()
        }

      } else if (type === 'projects') {

        this.setState({
          ...this.state, // needed?
          results: list,
          keyword: search
        })

        if (list.length === 0 && e.keyCode === 13) {
          this.setupNewProject()
        }

      }

    }

    if (search.length === 0) {

      this.setState({
        ...this.state, // needed?
        results: false,
        keyword: false
      })

    }

  }

  updateField(field, value) {

    const [split_field, parent_key] = field.split('.').reverse()
    let newData;

    if (parent_key === undefined) {

      newData = update(this.state, {
        [split_field]: {$set: value}
      })

    } else {

      newData = update(this.state, {
        [parent_key]: {
          [split_field]: {$set: value}
        }
      })

    }

    // get correct rate for new projects
    if(this.state.new && this.state.client && field === 'project.project_rate_unit') {

      let rate;

      if (value === 'hours') {
        rate = this.state.client.hourly_rate ? this.state.client.hourly_rate : this.props.common.currentUser.standard_hourly_rate;
      }

      if (value === 'days') {
        rate = this.state.client.day_rate ? this.state.client.day_rate : this.props.common.currentUser.standard_day_rate;
      }

      newData = update(this.state, {
        'project': {
          'project_rate_value': {$set: rate},
          'project_rate_unit': {$set: value}
        }
      })

    }

    this.setState(newData);

    // cleanup
    newData = null;

  }

  updateColour(color) {
    this.updateField('client.hex_colour', color.hex)

    this.setState({
      displayColorPicker: false
    })

  }

  setClient(e, client) {

    e.stopPropagation();

    let dates = [];
    this.props.dates.map((date) => {
      return dates.push({
        date: date,
        price: (client.day_rate !== null) ? client.day_rate : this.props.common.currentUser.standard_day_rate,
        unit: 'days',
        unit_value: 1,
        unit_value_in_time: '01:00',
        unit_value_formatted: 'All-day'
      })
    })

    let total_price = 0;
    dates.map((date) => {
      return total_price += parseFloat(date.price)
    })

    let total_time = this.props.dates.length + (this.props.dates.length === 1 ? ' Day': ' Days');
    let projects = this.props.projects.projects.filter(function (el) {
      return el.client_id === client.id && el.state === 'active'
    })

    this.setState({
      ...this.state,
      overlay: 'project',
      overlay_history: this.state.overlay_history.concat('project'),
      counter: this.state.counter + 1,
      client: client,
      projects: projects,
      client_id: client.id,
      dates: dates,
      total_price: total_price,
      total_time: total_time,
      results: false,
      keyword: false
    });

  }

  setupNewClient(e) {

    var r = ((Math.floor(Math.random() * 255) + 255) / 2);
    var g = ((Math.floor(Math.random() * 255) + 255) / 2);
    var b = ((Math.floor(Math.random() * 255) + 255) / 2);
    var random_colour = 'rgb(' + r + ', ' + g + ', ' + b + ')';

    this.setState({
      ...this.state,
      overlay: 'new_client',
      overlay_history: this.state.overlay_history.concat('new_client'),
      counter: this.state.counter + 1,
      clients: this.props.clients.clients.filter((el) => {
        return el.state === 'active'
      }),
      client: {
        name: this.state.keyword ? this.state.keyword : '',
        hex_colour: random_colour,
        day_rate: this.props.common.currentUser.standard_day_rate,
        hourly_rate: this.props.common.currentUser.standard_hourly_rate,
        preferred_billing_unit: 'days',
        address_line_1: '',
        address_line_2: '',
        city: '',
        country: '',
        postal_code: '',
        payment_terms: 30,
        invoice_schedule: 'end_of_month',
        currency: this.props.common.currentUser.currency
      },
      projects: [],
      results: false,
      keyword: false
    })

    this.searchClientsRef.current.value = ''
    this.searchClientsRef.current.blur()

  }

  createClient(e, clientName) {

    // validate email if provided
    if( this.state.client.email && !validateEmail(this.state.client.email) ) {
      return false;
    }

    let newData;

    // pro-rata day rate
    if (this.state.client.preferred_billing_unit === 'hours') {

      newData = update(this.state.client, {
        day_rate: {$set: this.state.client.hourly_rate * this.props.common.currentUser.hours_per_day }
      })

    }

    // pro-rata hourly rate
    if (this.state.client.preferred_billing_unit === 'days') {
      newData = update(this.state.client, {
        hourly_rate: {$set: this.state.client.day_rate / this.props.common.currentUser.hours_per_day }
      })

    }

    // create the client
    this.props.newClient( newData );

    this.setState({
      newData,
      loading: true
    })

    let that = this;
    let timer = setInterval(function() {

      // get client using a dispatch event instead (LOAD_CLIENT_IN_SCHEDULE)

      let new_client = that.props.clients.clients.filter(function (el) {
        return el.name === clientName
      });

      if (new_client[0]) {

        clearInterval(timer);

        let client = new_client[0]

        let dates = [];
        that.props.dates.map((date) => {
          return dates.push({
            date: date,
            price: (client.day_rate !== null) ? client.day_rate : that.props.common.currentUser.standard_day_rate,
            unit: 'days',
            unit_value: 1,
            unit_value_in_time: '01:00',
            unit_value_formatted: 'All-day'
          })
        })

        let total_price = 0;
        dates.map((date) => {
          return total_price += parseFloat(date.price)
        })

        let total_time = dates.length + ' Days';

        let overlay_history = that.state.overlay_history;

        let newState = {
          ...that.state,
          keyword: false,
          overlay: 'project',
          overlay_history: overlay_history.splice(0, overlay_history.length-1).concat('project'),
          dates: dates,
          client: client,
          client_id: client.id,
          price: client.day_rate,
          total_price: total_price,
          total_time: total_time,
          clients: that.props.clients.clients.filter((el) => {
            return el.state === 'active'
          }),
          loading: false
        }

        that.setState(newState);

      }

    }, 100);

    // cleanup
    newData = null;

  }

  setProject(e, project) {

    e.stopPropagation();

    let dates = [];
    let total_price = 0;
    let total_time = '';

    this.props.dates.map((date) => {
      return dates.push({
        date: date,
        price: project.project_rate_value,
        unit: project.project_rate_unit,
        unit_value: 1,
        unit_value_in_time: project.project_rate_unit === 'days' ? '24:00' : '01:00',
        unit_value_formatted: project.project_rate_unit === 'days' ? 'All-day' : '1h 00m'
      })

    })

    dates.map((date) => {
      return total_price += parseFloat(date.price)
    })

    if ( project.project_rate_unit === 'days' ) {
      total_time = this.props.dates.length + (this.props.dates.length > 1 ? ' Days' : ' Day');
    } else {
      total_time = this.props.dates.length + 'h 0m';
    }

    this.setState({
      overlay: 'schedule',
      overlay_history: this.state.overlay_history.concat('schedule'),
      counter: this.state.counter + 1,
      project: project,
      project_id: project.id,
      results: false,
      keyword: false,
      total_price: total_price,
      total_time: total_time
    });

  }

  setupNewProject(e) {

    this.setState({
      ...this.state,
      overlay: 'new_project',
      overlay_history: this.state.overlay_history.concat('new_project'),
      counter: this.state.counter + 1,
      projects: this.state.projects,
      project: {
        title: this.state.keyword ? this.state.keyword : '',
        billing_type: 'fixed_price',
        client_id: this.state.client.id,
        amount: '',
        estimated_time_unit: 'days',
        estimated_time_value: 1,
        project_rate_unit: 'days',
        project_rate_value: this.state.client.day_rate,
        currency: this.state.client.currency
      },
      estimated: 0,
      results: false,
      keyword: false
    })

    this.searchProjectsRef.current.value = ''
    this.searchProjectsRef.current.blur()

  }

  createProject(e, client_id, project_name) {

    this.setState({
      loading: true
    })

    // create the project
    this.props.newProject( this.state.project );

    let that = this;
    let timer = setInterval(function() {

      let new_project = that.props.projects.projects.filter(function (el) {
        return (el.title === project_name && el.client_id === client_id)
      });

      if (new_project[0]) {

        clearInterval(timer);

        let project = new_project[0]

        let dates = [];
        that.props.dates.map((date) => {
          return dates.push({
            date: date,
            price: (project.project_rate_value !== null) ? project.project_rate_value : that.state.client.day_rate,
            unit: 'days',
            unit_value: 1,
            unit_value_in_time: '01:00',
            unit_value_formatted: 'All-day',
            project_id: project.id
          })
        })

        let total_price = 0;
        dates.map((date) => {
          return total_price += parseFloat(date.price)
        })

        let total_time = dates.length + ' Days';

        let overlay_history = that.state.overlay_history;

        let newState = {
          ...that.state,
          keyword: false,
          overlay: 'schedule',
          overlay_history: overlay_history.splice(0, overlay_history.length-1).concat('schedule'),
          dates: dates,
          project: project,
          project_id: project.id,
          client_id: project.client_id,
          price: project.project_rate_value,
          total_price: total_price,
          total_time: total_time,
          loading: false
        }

        that.setState(newState);

      }

    }, 100);

  }

  updateInput(input) {

    let field = input.target.name;
    let newState = {
      ...this.state,
      [input.target.name]: input.target.value
    }

    this.setState(newState);

  }

  updateAllDates(override) {

    delete override.date

    let newState = []
    let total_price = 0;
    let total_time = '';
    let total_days = 0;
    let total_hours = 0;
    let calc;
    let calc_hours = 0;
    let calc_minutes = 0;

    // update all dates
    this.state.dates.forEach(function(item, i){

      newState = update(newState, {$push: [
        update(item, {
          price: {$set: override.price},
          unit: {$set: override.unit},
          unit_value: {$set: override.unit_value},
          unit_value_in_time: {$set: override.unit_value_in_time},
          unit_value_formatted: {$set: override.unit_value_formatted},
        })
      ]})

      // calculate total price and total time

      total_price += parseFloat(override.price)
      total_days += (override.unit === 'days' ? 1 : 0)
      total_hours = (override.unit === 'hours' ? parseFloat(override.unit_value) : 0)

      if(total_hours > 0) {
        calc = String(total_hours).split('.')
        calc_hours += parseFloat(calc[0])
        calc_minutes += parseFloat('0.'+calc[1]) * 60
      }

      if(total_days > 0) {
        total_time = total_days + (total_days === 1 ? ' Day': ' Days')
      }

      if(calc_hours > 0 || calc_minutes > 0 ) {
        total_time = calc_hours + 'h ' + calc_minutes.toFixed() + 'm'
      }

    });

    this.setState({
      ...this.state,
      dates: newState,
      total_price: total_price,
      total_time: total_time
    })

  }

  updateDate(date, data) {

    let newState;
    let index = this.state.dates.findIndex(function(el) {
      return el.date === date
    });

    let newData = Object.assign({}, this.state.dates[index], data);
    let unit_value = newData.unit === 'days' ? newData.unit_value : String(newData.unit_value).split(':');

    newData.date = date;

    // update unit_value of date being edited

    if(newData.unit === 'hours') {

      let hours = unit_value[0];
      let minutes = ((unit_value[1] * 60) / 3600).toString();

      if (minutes === '0') {
        minutes = '00'
      } else {
        minutes = minutes.split('.')[1]
      }

      newData.unit_value = hours + '.' + minutes;

    }

    // update price of date being edited

    let client = this.state.client;
    let project = this.state.project;
    let price;

    if(project) {
      price = this.calculatePrice(newData.unit, newData.unit_value,
        (newData.unit === 'days') ? (
          project.project_rate_unit === 'days' ? project.project_rate_value : project.project_rate_value * parseFloat(this.props.common.currentUser.hours_per_day)
        ) : (
          project.project_rate_unit === 'days' ? project.project_rate_value / parseFloat(this.props.common.currentUser.hours_per_day) : project.project_rate_value
        )
      )
    } else if (client) {
      price = this.calculatePrice(newData.unit, newData.unit_value,
        (newData.unit === 'days') ? (
          // day rate
          client.day_rate ? client.day_rate : this.props.common.currentUser.standard_day_rate
        ) : (
          // hourly rate
          client.hourly_rate ? client.hourly_rate : client.day_rate / this.props.common.currentUser.hours_per_day
        )
      )
    } else {
      price = this.calculatePrice(newData.unit, newData.unit_value,
        (newData.unit === 'days') ? (
          this.props.common.currentUser.standard_day_rate
        ) : (
          this.props.common.currentUser.standard_hourly_rate
        )
      )
    }

    newData.price = price

    // new work
    if (this.state.new) {

      newState = Object.assign([], this.state.dates, {
        [index]: newData
      });

    // existing work
    } else {

      newState = Object.assign([], this.state.dates, {
        0: newData
      });

    }

    // calculate total price and total time

    let total_days = 0;
    let total_hours = 0;
    let total_price = 0;
    let calc;
    let calc_hours = 0;
    let calc_minutes = 0;
    let total_time = '';

    newState.map((date) => {
      total_price += parseFloat(date.price)
      total_days += (date.unit === 'days' ? 1 : 0)
      total_hours = (date.unit === 'hours' ? parseFloat(date.unit_value) : 0)

      if(total_hours > 0) {
        calc = String(total_hours).split('.')
        calc_hours += parseFloat(calc[0])
        calc_minutes += parseFloat('0.'+calc[1]) * 60
      }

      return;

    })

    if(total_days > 0) {
      total_time += total_days + (total_days === 1 ? ' Day': ' Days')
    }

    if(calc_hours > 0 || calc_minutes > 0 ) {
      if(total_days > 0) {
        total_time += ', '
      }
      total_time += calc_hours + 'h ' + calc_minutes.toFixed() + 'm'
    }

    // update state

    this.setState({
      ...this.state,
      dates: newState,
      total_price: total_price,
      total_time: total_time
    });

  }

  saveHoliday(e, activeMonth) {

    e.stopPropagation();

    let dates = [];

    this.props.dates.map((date) => {
      return dates.push({
        date: date,
        holiday: true
      })
    })

    this.props.updateSchedule(
      'HOLIDAY', //type
      activeMonth, // activeMonth
      dates, // dates,
      false // work
    )

    this.props.closeOverlay(e)

  }

  saveWork(e, type, activeMonth, dates, work) {

    e.stopPropagation();

    const client = this.state.client;

    work = Object.assign({}, this.state, {holiday: false, updated: dateFns.parse(Date.now())});

    this.props.updateSchedule(
      type, //type
      activeMonth, // activeMonth
      dates, // dates,
      work // work
    )

    this.props.closeOverlay(e)

  }

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false })
  };

  render() {

    const close =
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
        <g strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" fill="none" stroke="currentColor" strokeMiterlimit="10">
          <path d="M19 5L5 19M19 19L5 5"/>
        </g>
      </svg>

    const price =
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
        <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
          <circle cx="11" cy="10" r="1.5"/><path d="M.5 2.5v11a2 2 0 002 2h13v-11h-13a2 2 0 01-2-2h0a2 2 0 012-2h9v2"/>
        </g>
      </svg>

    const time =
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
        <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10">
          <circle cx="8.5" cy="8.5" r="7"/><path d="M8.5 4.5v4h4"/>
        </g>
      </svg>

    const holiday =
      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
        <path fill="#EA9860" d="M24 9c-.55273 0-1-.44775-1-1V2c0-.55225.44727-1 1-1s1 .44775 1 1v6c0 .55225-.44727 1-1 1zM13.3934 13.3934c-.39084.39084-1.02372.3905-1.41421 0L7.73654 9.15076c-.3905-.3905-.39084-1.02337 0-1.41421.39084-.39084 1.02372-.3905 1.41421 0l4.24264 4.24264c.39051.39049.39085 1.02337.00001 1.41421zM9 24c0 .55273-.44775 1-1 1H2c-.55225 0-1-.44727-1-1s.44775-1 1-1h6c.55225 0 1 .44727 1 1zM13.3934 34.6066c.39084.39084.3905 1.02372 0 1.41422l-4.24264 4.24264c-.3905.3905-1.02337.39084-1.41421 0-.39084-.39084-.3905-1.02372 0-1.41421l4.24264-4.24264c.39049-.3905 1.02337-.39085 1.41421-.00001zM24 39c.55273 0 1 .44775 1 1v6c0 .55225-.44727 1-1 1s-1-.44775-1-1v-6c0-.55225.44727-1 1-1zM34.6066 34.6066c.39084-.39084 1.02372-.3905 1.41422 0l4.24264 4.24264c.3905.3905.39084 1.02337 0 1.41421s-1.02372.3905-1.41421 0l-4.24264-4.24264c-.3905-.39049-.39085-1.02337-.00001-1.41421zM39 24c0-.55273.44775-1 1-1h6c.55225 0 1 .44727 1 1s-.44775 1-1 1h-6c-.55225 0-1-.44727-1-1zM34.6066 13.3934c-.39084-.39084-.3905-1.02372 0-1.41421l4.24264-4.24264c.3905-.3905 1.02337-.39084 1.41421 0 .39084.39084.3905 1.02372 0 1.41421l-4.24264 4.24264c-.39049.3905-1.02337.39084-1.41421 0z"/>
        <path fill="#EFD358" d="M24 37c-7.16797 0-13-5.83154-13-13s5.83203-13 13-13 13 5.83154 13 13-5.83203 13-13 13z"/>
      </svg>

    const holiday__sm =
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
        <g strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" fill="none" stroke="currentColor" strokeMiterlimit="10">
          <path d="M1 12h1M4.2 4.2l.7.7M12 1v1M19.8 4.2l-.7.7M23 12h-1M19.8 19.8l-.7-.7M12 23v-1M4.2 19.8l.7-.7"/>
          <circle cx="12" cy="12" r="6"/>
        </g>
      </svg>

    const arrow =
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
        <g strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" fill="none" stroke="currentColor" strokeMiterlimit="10">
          <path d="M2 12h20M15 5l7 7-7 7"/>
        </g>
      </svg>

    const help =
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
        <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M14.5 15.5h-12c-.552 0-1-.448-1-1v-12c0-.552.448-1 1-1h12c.552 0 1 .448 1 1v12c0 .552-.448 1-1 1z"/>
        <circle fill="currentColor" data-stroke="none" cx="8.5" cy="5" r="0.5" stroke="none"/>
        <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M8.5 12.5v-4"/>
      </svg>

    const client =
      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48">
        <g strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" fill="currentColor" stroke="currentColor">
        <path fill="none" strokeMiterlimit="10" d="M24 2C11.85 2 2 11.85 2 24v18s6 4 22 4 22-4 22-4V24c0-12.15-9.85-22-22-22z"/>
        <path fill="none" strokeMiterlimit="10" d="M28.577 8.673c-5.07 5.049-11.822 8.409-19.344 9.164C8.441 19.734 8 21.815 8 24c0 8.837 7.163 16 16 16s16-7.163 16-16c0-7.245-4.818-13.357-11.423-15.327z"/>
        <circle fill="none" strokeMiterlimit="10" cx="17" cy="25" r="1"/>
        <circle fill="none" strokeMiterlimit="10" cx="31" cy="25" r="1"/>
        <circle data-stroke="none" cx="17" cy="25" r="1" stroke="none"/>
        <circle data-stroke="none" cx="31" cy="25" r="1" stroke="none"/>
        </g>
      </svg>

    return (
      ReactDOM.createPortal(

      <FocusTrap>
      <div className="focus">
        <div className={`overlay__blocks counter__${this.state.counter} ${this.state.initialLoad && 'initialLoad'}`}>

          {this.state.overlay_history.includes('client') && (
            <div className={this.props.clients.clients.length === 0 ? 'overlay__block empty' : 'overlay__block client '}>
              {this.props.clients.clients.length === 0 && (
                <div className="empty">
                  { client }
                  <span>Add your first client.</span>
                </div>
              )}
              <div className="header">
                <input
                  autoFocus={true}
                  ref={this.searchClientsRef}
                  onKeyUp={(e) => this.search(e, 'clients')}
                  type="text"
                  placeholder={ this.props.clients.clients.length === 0 ? 'Client Name' : 'Search Clients...'} />
                <button
                  onClick={(e) => this.props.closeOverlay(e)}>
                  {close}
                </button>
              </div>
              <div className="content">
                {(this.state.results ? this.state.results : this.state.clients).map((el,key) => {
                  return (
                    <div
                      key={key}
                      className="item padding border rounded"
                      onClick={(e) => this.setClient(e, el)}>{el.name}</div>
                  )
                })}
                {!this.state.results && _.size(this.state.clients) > 0 && (
                  <div
                  className="new padding border rounded"
                  onClick={(e) => this.setupNewClient(e, '')}>New Client...</div>
                )}
                <div className="divider padding"></div>
                {this.state.keyword.length > 1 ? (
                  <div
                    className="new padding border rounded"
                    onClick={(e) => this.setupNewClient(e)}>Create a client called &shy;<b>{this.state.keyword}</b> { arrow }</div>
                ) : (
                  <div
                    className="new padding border rounded"
                    onClick={(e) => { this.saveHoliday(
                      e,
                      this.props.activeMonth // activeMonth
                    ) }}>Mark these days as holiday { holiday__sm }</div>
                )}
              </div>
            </div>
          )}

          {this.state.overlay_history.includes('new_client') && (
            <div className="overlay__block new_client overflow">
              <div className="header">
                <input
                  type="text"
                  ref={this.clientTitleRef}
                  className="form-control"
                  type="text"
                  placeholder="Client Name..."
                  value={this.state.client.name}
                  onChange={(e) => this.updateField('client.name', e.target.value)} />
                <button
                  onClick={(e) => this.setOverlay(e, 'client', 'back')}>
                  {close}
                </button>
              </div>
              <div className="content">
                <div className="flex">
                  <textarea
                    type="text"
                    placeholder="Address..."
                    value={this.state.client.address}
                    onKeyDown={(e) => this.keyPress(e)}
                    onChange={(e) => this.updateField('client.address', e.target.value)} />
                </div>
                <label className="flex padding">
                  <div className="date">Contact Name</div>
                  <div
                    className="input text">
                      <input
                        type="text"
                        placeholder="Optional"
                        value={this.state.client.contact_name}
                        onKeyDown={(e) => this.keyPress(e)}
                        onChange={(e) => this.updateField('client.contact_name', e.target.value)} />
                  </div>
                </label>
                <label className="flex padding border">
                  <div className="date">Email Address</div>
                  <div
                    className="input text">
                      <input
                        type="text"
                        placeholder="Optional"
                        value={this.state.client.email}
                        onKeyDown={(e) => this.keyPress(e)}
                        onChange={(e) => this.updateField('client.email', e.target.value)} />
                  </div>
                </label>
                <label className="flex padding border">
                  <div className="date">Colour</div>
                  <div className="picker">
                    <div
                      className="preview"
                      style={{backgroundColor: this.state.client.hex_colour}}
                      onClick={ this.handleClick }>
                    </div>
                    { this.state.displayColorPicker && <div className="popover">
                      <div className="popover__cover" onClick={ this.handleClose }/>
                      <TwitterPicker
                        color={ this.state.client.hex_colour }
                        onChangeComplete={this.updateColour.bind(this)}/>
                      </div>}
                  </div>
                </label>
                <div className="flex padding border">
                  <div className="date">Currency</div>
                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={currencies}
                    defaultValue={currencies.filter((el) => { return el.value === this.state.client.currency})}
                    menuPlacement="auto"
                    onChange={(e) => this.updateField('client.currency', e.value)}
                    components={{ Input, DropdownIndicator }}
                  />
                </div>
                <div className="flex padding border">
                  <div className="date">How do you want to bill {this.state.client.name}?</div>
                  <Dropdown
                    client={this.state.client}
                    content="preferred_billing_unit"
                    onChange={this.updateField.bind(this)} />
                </div>
                {this.state.client.preferred_billing_unit !== 'projects' && (
                  this.state.client.preferred_billing_unit === 'hours' ? (
                    <div className="flex padding border">
                      <div className="date">How much do you charge per hour?</div>
                      <div
                        className={`input currency`}
                        onClick={(e) => e.currentTarget.querySelector('[contentEditable]').focus() }>
                        <span className="helper">{getSymbolFromCurrency( this.state.client ? this.state.client.currency : this.props.common.currentUser.currency )}</span>
                        <ContentEditable
                          innerRef={ React.createRef() }
                          html={this.state.client.hourly_rate.toString()}
                          onKeyDown={(e) => this.keyPress(e)}
                          onChange={(e) => this.updateField('client.hourly_rate', this.numericOnly(e.target.value))} />
                      </div>
                    </div>
                  ) : (
                    <div className="flex padding border">
                      <div className="date">How much do you charge per day?</div>
                      <div
                        className={`input currency`}
                        onClick={(e) => e.currentTarget.querySelector('[contentEditable]').focus() }>
                        <span className="helper">{getSymbolFromCurrency( this.state.client ? this.state.client.currency : this.props.common.currentUser.currency )}</span>
                        <ContentEditable
                          innerRef={ React.createRef() }
                          html={this.state.client.day_rate.toString()}
                          onKeyDown={(e) => this.keyPress(e)}
                          onChange={(e) => this.updateField('client.day_rate', this.numericOnly(e.target.value))} />
                      </div>
                    </div>
                  )
                )}
              </div>
              <div className="actions">
                <button
                  ref={this.clientButtonRef}
                  className={this.state.loading ? 'btn btn__loading' : 'btn'}
                  disabled={this.state.loading || isEmpty(this.state.client.name)}
                  onClick={(e) => this.createClient(e, this.state.client.name)}>
                  <div className="loader loader__button"></div>
                  <span>Save & Continue</span>
                </button>
              </div>
            </div>
          )}

          {this.state.overlay_history.includes('project') && (
            <div className={this.state.projects.length === 0 ? 'overlay__block empty' : 'overlay__block project'}>
              {this.state.projects.length === 0 ? (
              <React.Fragment>
                <div className="empty">
                  { client }
                  <span>Add your first project for { this.state.client.name }.</span>
                </div>
                <div className="header">
                  <input
                    autoFocus={true}
                    ref={this.searchProjectsRef}
                    onKeyUp={(e) => this.search(e, 'projects')}
                    type="text" placeholder={`Project name...`}/>
                  <button
                    onClick={(e) => this.setOverlay(e, 'client', 'back')}>
                    {close}
                  </button>
                </div>
              </React.Fragment>
              ) : (
                <div className="header">
                  <input
                    autoFocus={true}
                    ref={this.searchProjectsRef}
                    onKeyUp={(e) => this.search(e, 'projects')}
                    type="text" placeholder={`Search projects for ${this.state.client.name}...`}/>
                  <button
                    onClick={(e) => this.setOverlay(e, 'client', 'back')}>
                    {close}
                  </button>
                </div>
              )}
              <div className="content">
                {(this.state.results ? this.state.results : this.state.projects).map((el, key) => {
                  return (
                    <div
                      key={key}
                      className="item padding border rounded"
                      onClick={(e) => this.setProject(e, el)}>
                      {el.title}
                      <span>{el.billing_type_formatted}</span>
                    </div>
                  )
                })}
                {_.size(this.state.projects) > 0 && !this.state.results && (
                  <div
                    className="new padding border rounded"
                    onClick={(e) => this.setupNewProject(e)}>New Project...</div>
                )}
                <div className="divider padding"></div>
                {this.state.keyword.length > 1 ? (
                  <div
                    className="new padding border rounded"
                    onClick={(e) => this.setupNewProject(e)}>Create a project called &shy;<b>{this.state.keyword}</b> { arrow }</div>
                ) : (
                  <div
                    className="new padding border rounded"
                            onClick={(e) => this.setOverlay(e, 'schedule', 'forward')}>This work isn't for a project {arrow}</div>
                )}
              </div>
            </div>
          )}

          {this.state.overlay_history.includes('new_project') && (
            <div className="overlay__block new_project overflow">
              <div className="header">
                <input
                  autoFocus={true}
                  ref={this.projectTitleRef}
                  className="form-control"
                  type="text"
                  placeholder="Project Name..."
                  value={this.state.project.title}
                  onChange={(e) => this.updateField('project.title', e.target.value)} />
                <button
                  onClick={(e) => this.setOverlay(e, 'project', 'back')}>
                  {close}
                </button>
              </div>
              <div className="content">
                <div className="flex">
                  <textarea
                    className="form-control"
                    type="text"
                    placeholder="Description..."
                    value={this.state.project.description}
                    onChange={(e) => this.updateField('project.description', e.target.value)}></textarea>
                </div>

                <div className="tabs">

                  <div className="title">I want to bill this project by...</div>

                  <div className="options tabs">
                    <button
                      className={this.state.project.billing_type === 'time_spent' ? 'selected' : ''}
                      onClick={(e) => this.updateField('project.billing_type', 'time_spent')}>Time Spent</button>
                    <button
                      className={this.state.project.billing_type === 'fixed_price' ? 'selected' : ''}
                      onClick={(e) => this.updateField('project.billing_type', 'fixed_price')}>Fixed Price</button>
                  </div>

                </div>

                {this.state.project.billing_type === 'time_spent' ? (
                <React.Fragment>
                  <div className="flex padding border">
                    <div className="date">How do you want to bill this project?</div>
                    <Dropdown
                      project={this.state.project}
                      content="project_rate_unit"
                      onChange={this.updateField.bind(this)} />
                  </div>
                  <div className="flex padding border">
                    <div className="date">How much do you charge per {this.state.project.project_rate_unit === 'hours' ? 'hour' : 'day'}?</div>
                    <div
                      className={`input current`}
                      onClick={(e) => e.currentTarget.querySelector('[contentEditable]').focus() }>
                      <span className="helper">{getSymbolFromCurrency( this.state.client ? this.state.client.currency : this.props.common.currentUser.currency )}</span>
                      <ContentEditable
                        innerRef={ React.createRef() }
                        html={this.state.project.project_rate_value.toString()}
                        onKeyDown={(e) => this.keyPress(e)}
                        onChange={(e) => this.updateField('project.project_rate_value', this.numericOnly(e.target.value))} />
                    </div>
                  </div>
                </React.Fragment>
                ) : (
                <React.Fragment>
                  <div className="flex padding border">
                    <div className="date">What is your fixed price?</div>
                    <div
                      className={`input currency`}
                      onClick={(e) => e.currentTarget.querySelector('[contentEditable]').focus() }>
                      <span className="helper">{getSymbolFromCurrency( this.state.client ? this.state.client.currency : this.props.common.currentUser.currency )}</span>
                      <ContentEditable
                        className="fixed_price"
                        innerRef={this.fixedPriceRef}
                        html={this.state.project.amount.toString()}
                        onKeyDown={(e) => this.keyPress(e)}
                        onChange={(e) => this.updateField('project.amount', this.numericOnly(e.target.value))} />
                    </div>
                  </div>
                  <div className="flex padding border">
                    <div className="date">How long do you think this will take?</div>
                    <div className="double">
                      <div
                        className="input number"
                        onClick={(e) => e.currentTarget.querySelector('[contentEditable]').focus() }>
                        <ContentEditable
                          innerRef={ React.createRef() }
                          html={this.state.project.estimated_time_value.toString()}
                          onKeyDown={(e) => this.keyPress(e, 'project.estimated_time_value')}
                          onChange={(e) => this.updateField('project.estimated_time_value', this.numericOnly(e.target.value))} />
                      </div>
                      <Dropdown
                        project={this.state.project}
                        content="estimated_time_unit"
                        onChange={this.updateField.bind(this)} />
                    </div>
                  </div>
                </React.Fragment>
                )}
                <div className="flex padding border">
                  <div className="date">Do you have a deadline?</div>
                  <Dropdown
                    project={this.state.project}
                    content="deadline"
                    onChange={this.updateField.bind(this)} />
                </div>
              </div>
              <div className="actions">
                { this.state.project.billing_type === 'fixed_price' && (
                  <div className="totals">
                    <div>
                      <PriceIcon />
                      { this.formatPrice(this.state.estimated, this.state.client ? this.state.client.currency : this.props.common.currentUser.currency) }/{this.state.project.estimated_time_unit.slice(0, -1)} <Tooltip message={`This is an estimate based on your fixed price and the length of time you expect this project to take.`} position={'top'} size={'large'}><Info /></Tooltip>
                    </div>
                  </div>
                )}
                <button
                  ref={this.projectButtonRef}
                  className={this.state.loading ? 'btn btn__loading' : 'btn'} disabled={this.state.loading}
                  onClick={(e) => this.createProject(e, this.state.client_id, this.state.project.title)}>
                  <div className="loader loader__button"></div>
                  <span>Save & Continue</span>
                </button>
              </div>
            </div>
          )}

          {this.state.overlay_history.includes('schedule') && (
            <div className="overlay__block schedule">
              <div className="header">
                <h2>Schedule { this.state.total_time } { this.state.project && `for ${this.state.project.title}`}</h2>
                <button
                  onClick={(e) => this.setOverlay(e, 'project', 'back')}>
                  {close}
                </button>
              </div>
              <div className="content" onScroll={this.handleScheduleScroll.bind(this)}>
                {this.state.dates.map((date, key) => {
                  return (
                    <OverlayItem
                      key={key}
                      updateDate={this.updateDate.bind(this)}
                      updateAllDates={this.updateAllDates.bind(this)}
                      date={date.date}
                      price={date.price}
                      unit={date.unit}
                      unit_value={date.unit_value}
                      unit_value_in_time={date.unit_value_in_time}
                      unit_value_formatted={date.unit_value_formatted}
                      client={this.state.client}
                      project={this.state.project}/>
                  )
                })}
                <div className="flex padding border note">
                  <NoteIcon />
                  <input
                    name="note"
                    type="text"
                    autoComplete="off"
                    placeholder="Add a note..."
                    maxLength="50"
                    value={this.state.note ? this.state.note : ''}
                    onChange={this.updateInput.bind(this)} />
                </div>
              </div>
              <div className="actions">
                <div className="totals">
                  { (this.state.price || (this.state.project && this.state.project.billing_type === 'time_spent')) && (
                    <div>
                      <PriceIcon /> { this.formatPrice(this.state.total_price, 
                        (this.state.client.currency ? this.state.client.currency : this.props.common.currentUser.currency)
                      ) }
                      <Tooltip
                        message={
                          this.state.total_time.includes('Day') ?
                            `${this.state.client.day_rate_formatted} (Day Rate) <br />x<br /> ${this.state.total_time} (Time)`
                          :
                            `${this.state.client.hourly_rate_formatted} (Hourly Rate) <br />x<br /> ${this.state.total_time} (Time)`
                        }
                        position={'top'} size={'large'}><Info /></Tooltip>
                    </div>
                  )}
                  <div>
                    <TimeIcon /> { this.state.total_time }
                  </div>
                </div>
                <button
                  ref={this.scheduleButtonRef}
                  className="btn"
                  onClick={(e) => { this.saveWork(
                    e,
                    'CREATE', //type
                    this.props.activeMonth, // activeMonth
                    this.state.dates, // dates
                    false // work
                  ) }}>Add to my Schedule</button>
              </div>
              <div className="tip">You can change the time for each day using the <em>↑</em> and <em>↓</em> arrow keys.</div>
            </div>
          )}

          {this.state.client && this.state.overlay === 'work' && (
            <div className="overlay__block work">
              <div className="header">
                  {this.state.project ? (
                    <h2>Work for {this.state.project.title} ({this.state.client.name})</h2>
                  ) : (
                    <h2>Work for {this.state.client.name}</h2>
                  )}
                <button
                  onClick={(e) => this.props.closeOverlay(e)}>
                  {close}
                </button>
              </div>
              <div className="content">
                {this.state.dates.map((date) => {
                  return (
                    <OverlayItem
                      key={date}
                      updateDate={this.updateDate.bind(this)}
                      date={date.date}
                      client={this.state.client}
                      project={this.state.project}
                      id={date.id}
                      price={date.price_formatted}
                      unit={date.unit}
                      unit_value={date.unit_value}
                      unit_value_in_time={date.unit_value_in_time}
                      unit_value_formatted={date.unit_value_formatted}
                      state={date.state} />
                  )
                })}
                <div className="flex padding border note">
                  <NoteIcon />
                  <input
                    name="note"
                    type="text"
                    autoComplete="off"
                    placeholder="Add a note..."
                    maxLength="50"
                    value={this.state.note ? this.state.note : ''}
                    onChange={this.updateInput.bind(this)}
                    disabled={ (this.state.dates[0].state === 'invoiced' || this.state.dates[0].state === 'paid') ? true : false } />
                </div>
              </div>
              <div className="actions">
                <div className="totals">
                  { ((!this.state.project) || (this.state.project && this.state.project.billing_type === 'time_spent')) && (
                    <div>
                      <PriceIcon /> { this.state.total_price ? this.formatPrice(this.state.total_price,
                        (this.state.client.currency ? this.state.client.currency : this.props.common.currentUser.currency)
                      ) : this.state.dates[0].price_formatted }
                      <Tooltip
                        message={
                          ( this.state.dates[0].unit === 'days' ) ?
                          `${this.state.client.day_rate_formatted} (Day Rate) <br />x<br /> ${ this.state.total_time ? this.state.total_time : this.state.dates[0].unit_value_formatted } (Time)`
                          :
                            `${this.state.client.hourly_rate_formatted} (Hourly Rate) <br />x<br /> ${ this.state.total_time ? this.state.total_time : this.state.dates[0].unit_value_formatted } (Time)`
                        }
                        position={'top'} size={'large'}><Info /></Tooltip>
                    </div>
                  )}
                  <div><TimeIcon /> { this.state.total_time ? this.state.total_time : this.state.dates[0].unit_value_formatted }</div>
                </div>
                <div className="buttons">
                  {(this.state.dates[0].state !== 'invoiced' && this.state.dates[0].state !== 'paid') && (
                    <button
                      className="btn btn__secondary"
                      onClick={(e) => { this.saveWork(
                        e,
                        'REMOVE', //type
                        this.props.activeMonth, // activeMonth
                        this.state.dates, // dates
                        false // work
                      ) }}>Delete</button>
                  )}
                  <button
                    disabled={ (this.state.dates[0].state === 'invoiced' || this.state.dates[0].state === 'paid') ? true : false }
                    className="btn"
                    onClick={(e) => { this.saveWork(
                      e,
                      'UPDATE', //type
                      this.props.activeMonth, // activeMonth
                      this.state.dates, // dates
                      false // work
                    ) }}>
                      {(this.state.dates[0].state !== 'invoiced' && this.state.dates[0].state !== 'paid') ? (
                        <span>Update Work</span>
                      ) : (
                        <>
                        <Tooltip message={`This item is now locked as it has been marked as ${this.state.dates[0].state}.`} position={'top'} size={'large'}>
                          <LockIcon />
                        </Tooltip>
                        <span>Update Work</span>
                        </>
                      )}</button>
                </div>
              </div>
            </div>
          )}

          {this.state.overlay === 'holiday' && (
            <div className="overlay__block holiday">
              <div className="header">
                <h2>{dateFns.format(this.props.dates[0], 'ddd Do MMMM YYYY')}</h2>
                <button
                  onClick={(e) => this.props.closeOverlay(e)}>
                  {close}
                </button>
              </div>
              <div className="content">

                { holiday }

                <div>You've marked {dateFns.format(this.props.dates[0], 'ddd Do MMMM YYYY')} as a holiday.</div>

                <button
                  className="btn btn__secondary"
                  onClick={(e) => { this.saveWork(
                    e,
                    'REMOVE', //type
                    this.props.activeMonth, // activeMonth
                    this.state.dates, // dates
                    false // work
                  ) }}>Delete Holiday</button>

              </div>
            </div>
          )}

          </div>

          <div
            className="overlay__bg"
            onClick={(e) => this.props.closeOverlay(e) }></div>

          <ListErrors errors={this.props.errors} />

        </div>
        </FocusTrap>,
        document.querySelector('.overlay')
      )
    )


  }

}

export default connect(mapStateToProps, mapDispatchToProps)(Overlay);
