import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { history } from '../../configureStore';
import { Item } from './Item';
import { Project } from './Project';
import {ReactComponent as ErrorIcon} from '../../images/error.svg';
import {ReactComponent as PriceIcon} from '../../images/icons/price.svg';
import {ReactComponent as ToggleIcon} from '../../images/icons/toggle.svg';
import {ReactComponent as CalendarIcon} from '../../images/icons/calendar.svg';

import agent from '../../agent';
import {
  REQUEST_CLIENTS,
  CLIENT_LOADED,
  PROJECTS_PAGE_LOADED
} from '../../constants/actionTypes';

const mapStateToProps = (state, ownProps) => (
  {
    loading: state.common.loading
  }
);

const mapDispatchToProps = dispatch => ({

  onLoad: (slug) => {
    dispatch({ type: REQUEST_CLIENTS })
    dispatch({
      type: CLIENT_LOADED,
      payload: agent.Clients.show(slug)
      .then((res) => {
        if(!res.body.errors) {
          // need a way to query by client_id
          dispatch({
            type: PROJECTS_PAGE_LOADED, payload: agent.Projects.all()
          });
        }
        return res;
      })
    })
  },

});

class Client extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      tab: 'schedule'
    }

  }

  componentDidMount(){

    this.props.onLoad(this.props.match.params.slug)

  }

  componentDidUpdate(prevProps,prevState){

    if(prevProps.match.params.slug !== this.props.match.params.slug) {

      this.props.onLoad(this.props.match.params.slug);

    }

  }

  toggleMonth(e, month) {
    e.stopPropagation()
    document.querySelector(`[data-month='${month}']`).classList.toggle('collapsed')
  }

  updateClient(e, id, state) {
    this.props.transitionState(id,state)
  }

  formatPrice(price) {

    return new Intl.NumberFormat(this.props.common.locale, {
      style: 'currency',
      currency: this.props.common.currentUser.currency
    }).format(price)

  }

  switchTabs(e, tab) {

    e.stopPropagation();

    this.setState({
      tab: tab
    });

  }

  render() {

    if (this.props.isFetching || !this.props.client) {

      return (
        <div className="loading"><div className="loader"></div></div>
      )

    }

    const active =
      <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
        <g stroke-width="2" strokeLinecap="round" strokeLinejoin="round" transform="translate(.5 .5)" fill="none" stroke="currentColor" strokeMiterlimit="10">
          <path d="M18 34l8 8 20-20"/>
          <circle cx="32" cy="32" r="29"/>
        </g>
      </svg>

    const archived =
      <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
        <g stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill="none" stroke="currentColor" stroke-miterlimit="10">
          <path d="M6.5 16.5h52v44h-52zM14.5 9.5h36M22.5 2.5h20"/>
          <path d="M43.5 28.5v6h-22v-6"/>
        </g>
      </svg>

    // redirect if client not found
    if(!this.props.client) {
      history.push('/clients');
      return false
    }

    var project_total = 0;
    let projects = false;

    // get client projects
    if(this.props.projects) {

      projects = this.props.projects.reduce((result, option) => {
        if (option.client_id === this.props.client.id) {

          if(option.state === 'pending') {
            project_total += parseInt(option.amount)
          }
          if(option.state === 'active') {
            project_total += parseInt(option.remaining)
          }

          return result.concat(option);
        }
        return result;
      }, []);

    }
  
    const work = this.props.client.months_worked.map(hash => {

      var work = _.orderBy(_.values(_.pickBy(this.props.client.work_days,function(value, key){
        return value.month_name === hash.month_name;
      })), ['date'],['desc']);

      if(!work.length){
        return '';
      }

      return (
      <div
        className="block"
        key={hash.month_name}
        data-month={hash.month_name}>
        <header
          onClick={(e) => this.toggleMonth(e, hash.month_name)}>
          <h3>
          <Link to={{
            pathname: `/month/${hash.month}/${hash.year}`,
            state: {
              trigger: 'click'
            }
          }}>
            { hash.month_name}
          </Link>
          </h3>
          <div>
            <span>{hash.amount_worked_string} ({ hash.revenue})</span>
            <button>
              <ToggleIcon />
            </button>
          </div>
        </header>
        <div className="list">
        {work.map(day =>
          <Item
            key={day.id}
            day={day}
            client={this.props.client}
            updateWorkState={this.props.updateWorkState}
            rollbackInvoiceWork={this.props.rollbackInvoiceWork} />
        )}
        </div>
      </div>
      )

    })

    return (

      <React.Fragment>
        <div className="summary">

          <div className="data">

            <header>
              <span>{ this.props.client.client_name }</span>
              <h2>{ this.props.client.name }</h2>
            </header>

            <div className="row">
              <div className="title">
                <PriceIcon />
                <h4>You've earned {this.props.client.total_revenue} in total</h4>
              </div>
            </div>

            <div className="row">
              <div className="title">
                <PriceIcon />
                <h4>You've earned {this.props.client.total_revenue_last_3_months} over the last 3 months</h4>
              </div>
            </div>

            <div className="row">
              <div className="title">
                <PriceIcon />
                { this.props.client.billing_type === 'hours' ? (
                  <h4>Your hourly rate is {this.props.client.hourly_rate_formatted}</h4>
                ) : (
                  <h4>Your day rate is {this.props.client.day_rate_formatted}</h4>
                )}
              </div>
            </div>

            { this.props.client.next_work_at && (
              <div className="row">
                <div className="title">
                  <CalendarIcon />
                  <h4>Next working on {this.props.client.next_work_at}</h4>
                </div>
              </div>
            )}

          </div>
          <div className="status">
          { this.props.client.state === 'active' && (
          <React.Fragment>
            <div>
              { active }
              <span>This client is active.</span>
            </div>

            <button
              ref={this.clientButtonRef}
              className={this.props.loading ? 'btn btn__loading' : 'btn'} disabled={this.props.loading}
              onClick={(e) => this.updateClient(e, this.props.client.id, 'archived')}>
              <div className="loader loader__button"></div>
              <span>Archive Client</span>
            </button>

            <button
              className="btn btn__outline"
              onClick={(e) => this.props.openOverlay(e, 'client', this.props.client)}>Edit Client</button>
          </React.Fragment>
          )}
          { this.props.client.state === 'archived' && (
          <React.Fragment>
            <div>
              { archived }
              <span>This client has been archived.</span>
            </div>
            
            <button
              ref={this.clientButtonRef}
              className={this.state.loading ? 'btn btn__loading' : 'btn'} disabled={this.state.loading}
              onClick={(e) => this.updateClient(e, this.props.client.id, 'active')}>
              <div className="loader loader__button"></div>
              <span>Restart Client</span>
            </button>

            <button
              className="btn btn__outline"
              onClick={(e) => this.props.openOverlay(e, 'client', this.props.client)}>Edit Client</button>
          </React.Fragment>
          )}
          </div>
        </div>

        <div className="options inset">
          <div className="list">
            <button
              className={this.state.tab === 'schedule' ? 'selected' : ''}
              onClick={(e) => this.switchTabs(e, 'schedule')}>Schedule</button>
            <button
              className={this.state.tab === 'projects' ? 'selected' : ''}
              onClick={(e) => this.switchTabs(e, 'projects')}>Projects</button>
            <button
              className={this.state.tab === 'issues' ? 'selected' : ''}
              data-count={this.props.client.issues.length}
              onClick={(e) => this.switchTabs(e, 'issues')}>Issues</button>
          </div>
        </div>
        
        <div className="analysis">

          {(this.state.tab === 'issues') && (
            this.props.client.issues.length > 0 ? (
              <div className="block">
                <header>
                  <h3>We've spotted a few things...</h3>
                </header>
                <div className="list">
                  {this.props.client.issues.map(issue => {
                    return (
                    <div className="item">
                      <span className="icon"><ErrorIcon /></span>
                      {issue.description}
                    </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div className="block">
                <header>
                  <h3>No issues with this client, all good :)</h3>
                </header>
              </div>
            )
          )}

          {(this.state.tab === 'projects') && (
            projects.length > 0 ? (
              <div className="block">
                <header>
                  <h3>You've got { this.formatPrice(project_total) } agreed in pending projects</h3>
                </header>

                <hr />

                <div className="list columns">
                {projects.map(project =>
                  <Project
                    key={project.id}
                    project={project} />
                )}
                </div>
              </div>
            ) : (
              <div className="block">
                <header>
                  <h3>You haven't created any projects for this client yet.</h3>
                </header>
              </div>
            )
          )}

          {(this.state.tab === 'schedule') && (
            this.props.client.work_days.length === 0 ? (
              <div className="block">
                <header>
                  <h3>You haven't scheduled any work yet for this client.</h3>
                </header>
              </div>
            ) : (
              work
            )
          )}

        </div>

      </React.Fragment>

    )

  }

}

export default connect(mapStateToProps, mapDispatchToProps)(Client);