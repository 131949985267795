import React from 'react';
import {Elements, StripeProvider} from 'react-stripe-elements';
import CheckoutForm from './CheckoutForm';
import agent from '../agent';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  SUBSCRIPTION_CREATE
} from '../constants/actionTypes';

const mapStateToProps = state => (
  {
    ...state.settings,
    currentUser: state.common.currentUser,
    subscription: state.common.subscription
  }
);

const mapDispatchToProps = dispatch => ({
  createSubscription: stripe_token_id => {
    dispatch({ type: SUBSCRIPTION_CREATE, payload: agent.Subscription.create(stripe_token_id, true) })
  }
});

class PaymentMethod extends React.Component {
  render() {
    return (
      <section className="component__route component__route__billings">
        <header>
          <h2>Billing / Payment details</h2>
        </header>
        <section>
          <div className="group">
            <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}>
              <div className="example">
                {this.props.subscription && this.props.subscription.payment_method &&
                  <p>
                    <b>{this.props.subscription.payment_method.brand}</b> ending **** **** **** {this.props.subscription.payment_method.last4} expiring on <b>{this.props.subscription.payment_method.exp_month}/{this.props.subscription.payment_method.exp_year}</b>
                  </p>
                }
                <Elements>
                  <CheckoutForm currentUser={this.props.currentUser} submit={this.props.createSubscription}/>
                </Elements>
              </div>
            </StripeProvider>
            <p>
              <Link to="/settings/billing">Back to billing settings</Link>
            </p>
          </div>
        </section>
      </section>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethod);
