import {
  SCHEDULE_RESET,
  SCHEDULE_LOADED,
  SCHEDULE_UNLOADED,
  SCHEDULE_FORWARD,
  SCHEDULE_BACK,
  SCHEDULE_CREATE,
  SCHEDULE_REMOVE,
  SCHEDULE_UPDATE,
  SCHEDULE_MONTH,
  SCHEDULE_SUMMARY,
  DESTROY_SCHEDULE_SUMMARY,
  SCHEDULE_AVAILABILITY,
  DESTROY_SCHEDULE_AVAILABILITY,
  SCHEDULE_INVOICE,
  SCHEDULE_UNDO_INVOICE,
  CLIENT_SCHEDULE_LOADED,
  LOAD_DAYS,
  OPEN_SCHEDULE_OVERLAY,
  CLOSE_SCHEDULE_OVERLAY
} from '../constants/actionTypes';
import dateFns from 'date-fns';
import update from 'immutability-helper';

const defaultState = {
  loading: false
};

export default (state = defaultState, action) => {

  const arrayToObject = (array) =>
     array.reduce((obj, item) => {
       obj[item.id] = item
       return obj
     }, {})

  switch (action.type) {
    case LOAD_DAYS: {
      //console.log(action)
      let currentMonth = dateFns.startOfMonth(dateFns.parse(action.month));
      let nextMonth = dateFns.startOfMonth(dateFns.addMonths(currentMonth, 1));
      let days = [];
      let day = dateFns.startOfMonth(currentMonth);
      while (day <= dateFns.endOfMonth(nextMonth)) {
        days.push({
          key: dateFns.format(day, 'DDMMYY'),
          day: day
        });
        day = dateFns.addDays(day, 1);
      }
      return {
        days: days
      };
    }
    case SCHEDULE_LOADED: {
      if (action.payload.body === undefined){
        return {...state}
      }
      let currentMonth = dateFns.startOfMonth(dateFns.parse(action.month));
      let nextMonth = dateFns.startOfMonth(dateFns.addMonths(currentMonth, 1));
    
      let days = [];
      let day = dateFns.startOfMonth(currentMonth);
      while (day <= dateFns.endOfMonth(nextMonth)) {
        days.push({
          key: dateFns.format(day, 'DDMMYY'),
          day: day
        });
        day = dateFns.addDays(day, 1);
      }
      return {
        ...state,
        work: arrayToObject(action.payload.body.works),
        blackouts: action.payload.body.blackouts
      }
    }
    case CLIENT_SCHEDULE_LOADED:
      //console.log(action)
      return {
        ...state,
        work: arrayToObject(action.payload.body)
      };
    case SCHEDULE_SUMMARY:
      //console.log(action)
      return {
        ...state,
        summary: action.payload.body
      };
    case DESTROY_SCHEDULE_SUMMARY:
      //console.log(action)
      return {
        ...state,
        summary: false
      };
    case SCHEDULE_AVAILABILITY:
      //console.log(action)
      return {
        ...state,
        availability: action.payload.body
      };
    case DESTROY_SCHEDULE_AVAILABILITY:
      //console.log(action)
      return {
        ...state,
        availability: false
      };
    case SCHEDULE_UNLOADED:
      return {
        ...state
      };
    case SCHEDULE_RESET: {
      // console.log(action)
      let currentMonth = dateFns.startOfMonth(dateFns.parse(action.month));
      let nextMonth = dateFns.startOfMonth(dateFns.addMonths(currentMonth, 1));
      let prevMonth = dateFns.startOfMonth(dateFns.subMonths(currentMonth, 1));
      let days = [];
      let day = dateFns.startOfMonth(prevMonth);
      while (day <= dateFns.endOfMonth(nextMonth)) {
        days.push({
          key: dateFns.format(day, 'DDMMYY'),
          day: day
        });
        day = dateFns.addDays(day, 1);
      }
      return {
        work: arrayToObject(action.payload.body.works),
        blackouts: action.payload.body.blackouts,
        days: days,
        currentMonth: currentMonth,
        nextMonth: nextMonth,
        prevMonth: prevMonth
      };
    }
    case SCHEDULE_FORWARD: {
      //console.log(action)
      let next_days = [];
      let day = action.month;
      while (day <= dateFns.endOfMonth(action.month)) {
        next_days.push({
          key: dateFns.format(day, 'DDMMYY'),
          day: day
        });
        day = dateFns.addDays(day, 1);
      }
      let newHash = arrayToObject(action.payload.body.works)
      return {
        ...state,
        work: Object.assign({}, state.work, newHash),
        blackouts: [
          ...state.blackouts,
          ...action.payload.body.blackouts
        ],
        days: [...state.days, next_days].flat(2)
      };
    }
    case SCHEDULE_BACK: {
      //console.log(action)
      let previous_days = [];
      let day = action.month;
      while (day <= dateFns.endOfMonth(action.month)) {
        previous_days.push({
          key: dateFns.format(day, 'DDMMYY'),
          day: day
        });
        day = dateFns.addDays(day, 1);
      }
      let newHash = arrayToObject(action.payload.body.works)
      return {
        ...state,
        work: Object.assign({}, state.work, newHash),
        blackouts: [
          ...state.blackouts,
          ...action.payload.body.blackouts
        ],
        days: [previous_days, ...state.days].flat(2)
      };
    }
    case SCHEDULE_CREATE: {
      //console.log(action)
      if(action.error){
        return {
          ...state,
          errors: action.payload.errors
        };
      } else {

        let newData = state.work;

        if (newData) {

          // add the new days to the work state
          action.payload.body.works.map((item) => {
            return newData = update(newData, {
              [item.id]: {$set: item }
            })
          })

          // update related days
          action.payload.body.related_work_day_prices.map(item => {

            // work item in view
            try {
              return newData = update(newData, {
                [item.id]: {
                  'price': {$set: item.price},
                  'price_formatted': {$set: item.price_formatted}
                }
              })
            }

            // work item not in view
            catch(error) {
              return false
            }

          });

          return {
            ...state,
            work: newData,
            availability: false
          };

        } else {

          return {
            ...state,
            availability: false
          };

        }

      };
    }
    case SCHEDULE_UPDATE: {
      //console.log(action)
      if(action.error){
        return {
          ...state,
          errors: action.payload.errors
        };
      }else{

        let newData;

        // work item loaded
        try {
          newData = update(state.work, {
            [action.payload.body.id]: {$set: action.payload.body}
          })
        }

        // work item not loaded
        catch(error) {
          newData = {
            [action.payload.body.id]: action.payload.body
          }
        }

        return {
          ...state,
          work: newData
        };

      };
    }
    case SCHEDULE_INVOICE: {
      //console.log(action)
      if(action.error){
        return {
          ...state,
          errors: action.payload.errors
        };
      }else{
        
        let newData;

        // work item loaded
        try {
          newData = update(state.work, {
            [action.payload.body.id]: {$set: action.payload.body}
          })
        }

        // work item not loaded
        catch(error) {
          newData = {
            [action.payload.body.id]: action.payload.body
          }
        }

        return {
          ...state,
          work: newData
        };

      };
    }
    case SCHEDULE_UNDO_INVOICE: {
      //console.log(action)
      if(action.error){
        return {
          ...state,
          errors: action.payload.errors
        };
      }else{
        
        let newData;

        // work item loaded
        try {
          newData = update(state.work, {
            [action.payload.body.id]: {$set: action.payload.body}
          })
        }

        // work item not loaded
        catch(error) {
          newData = {
            [action.payload.body.id]: action.payload.body
          }
        }

        return {
          ...state,
          work: newData
        };

      };
    }
    case SCHEDULE_REMOVE:
      //console.log(action);
      if(action.error){
        return {
          ...state,
          errors: action.payload.errors
        };
      }else{

        let newData = state.work;

        // remove days from the work state
        // https://stackoverflow.com/questions/55436821/using-immutability-helper-with-array-object-map
        action.payload.body.works.map((item) => {
          return newData = update(newData, {$unset: [item.id] })
        })

        // update related days:
        action.payload.body.related_work_day_prices.map(item => {

          // work item in view
          try {
            return newData = update(newData, {
              [item.id]: {
                'price': {$set: item.price},
                'price_formatted': {$set: item.price_formatted}
              }
            })
          }

          // work item not in view
          catch(error) {
            return false
          }

        });

        return {
          ...state,
          work: newData
        };
      };
    case SCHEDULE_MONTH: {
      //console.log(action);
      let nextMonth = dateFns.startOfMonth(dateFns.addMonths(action.month, 1));
      let prevMonth = dateFns.startOfMonth(dateFns.subMonths(action.month, 1));
      return {
        ...state,
        activeMonth: action.month,
        nextMonth: nextMonth,
        prevMonth: prevMonth
      };
    }
    case OPEN_SCHEDULE_OVERLAY:
      //console.log(action);
      return {
        ...state,
        overlay: true
      }
    case CLOSE_SCHEDULE_OVERLAY:
      //console.log(action);
      return {
        ...state,
        overlay: false,
        errors: false
      }
    default: {
      //console.log(action);
      let currentMonth = new Date();
      return {
        ...state,
        currentMonth: currentMonth
      };
    }
  }
};
