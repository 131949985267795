import {
  REQUEST_ADMIN_USERS,
  ADMIN_USERS_PAGE_LOADED,
  ADMIN_USERS_PAGE_UNLOADED,
  ADMIN_USER_LOADED,
  ADMIN_USER_UNLOADED,
} from '../constants/actionTypes';

export default (state = {}, action) => {

  switch (action.type) {
    case REQUEST_ADMIN_USERS:
      //console.log(action);
      return {
        ...state,
        isFetching: true
      }
    case ADMIN_USERS_PAGE_LOADED:
      //console.log(action);
      return {
        ...state,
        users: action.payload.body,
        isFetching: false
      };
    case ADMIN_USERS_PAGE_UNLOADED:
      //console.log(action);
      return {
        ...state
      };
    case ADMIN_USER_LOADED:
      //console.log(action);
      return {
        ...state,
        user: action.payload.body,
        isFetching: false
      };
    case ADMIN_USER_UNLOADED:
      //console.log(action);
      return {
        ...state,
        user: null
      };
    default:
      //console.log(action);
      return state;
  }
};
