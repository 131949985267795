import React from 'react';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import dateFns from 'date-fns';

import agent from '../../agent';
import {
  TIME_SUMMARY,
  DESTROY_TIME_SUMMARY
} from '../../constants/actionTypes';

const mapStateToProps = state => (
  {
    ...state,
    summary: state.invoices.summary
  }
);

const mapDispatchToProps = dispatch => ({
  getSummary: (client_id,project_id,currency) => {
    dispatch({
      type: TIME_SUMMARY,
      payload: agent.Invoices.time(client_id,project_id,currency)
    })
  },
  destroySummary: () => {
    dispatch({ type: DESTROY_TIME_SUMMARY })
  }
});

class InvoiceTime extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      displayTooltip: false
    }
    this.hideTooltip = this.hideTooltip.bind(this)
    this.showTooltip = this.showTooltip.bind(this)
  }

  hideTooltip (event) {

    this.props.destroySummary();

    this.setState({
      displayTooltip: false,
      top: null,
      left: null
    })

  }

  showTooltip (event) {

    this.props.getSummary(
      this.props.client_id,
      this.props.project_id,
      this.props.currency
    );

    var bounds = event.currentTarget.getBoundingClientRect();
    var y = bounds.top + bounds.height + 8;
    var x = bounds.left;

    this.setState({
      displayTooltip: true,
      top: y,
      left: x
    })

  }

  render() {
    let message = this.props.message
    let position = this.props.position
    let size = this.props.size

    return (
      <>
        {this.state.displayTooltip &&
          (
            ReactDOM.createPortal(
              <div 
                className={`invoice_summary`}
                style={{top: this.state.top, left: this.state.left}}>
                  {this.props.summary ? (
                    <>
                    {this.props.summary.slice(0, 6).map((date) => {
                      return(
                        <div className="invoice_summary__item">
                          {date.description}
                        </div>
                      )
                    })}
                    {this.props.summary.length > 6 && (
                      <div className="schedule_summary__footer">
                        <div className="empty">{`+ ${this.props.summary.length - 6} more`}</div>
                      </div>
                    )}
                    {this.props.summary.length === 0 && (
                      <div className="invoice_summary__item">
                        <div className="empty">No work added</div>
                      </div>
                    )}
                    </>
                  ) : (
                    <div className="loader loader__small"></div>
                  )}
              </div>,
              document.querySelector('main')
            )
          )
        }
        <span
          className="cursor-default"
          onMouseOver={this.showTooltip}
          onMouseLeave={this.hideTooltip}>
            { this.props.time_formatted }
        </span>
      </>
    )

  }

}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceTime);
