export const AvailabilityDateBuilder = (project, availableDates, currentUser, clientDayRate) => {
        let time_outstanding = (project.estimated_time_unit === 'hours' ? project.estimated_time_value : project.estimated_time_value * currentUser.hours_per_day) - project.amount_scheduled_time;
        let dates = [];

        availableDates.map((date) => {
          if(time_outstanding <= 0 ){
            return
          }
          var remaining_hours = parseFloat(date.remaining_hours)
          if(project.estimated_time_unit === 'days' && remaining_hours >= currentUser.hours_per_day){
                dates.push({
                  date: date.date,
                  price: (project.project_rate_value !== null) ? project.project_rate_value : clientDayRate,
                  unit: 'days',
                  unit_value: 1,
                  unit_value_formatted: 'All-day',
                  project_id: project.id,
                  client_id: project.client_id
                })
                time_outstanding -= remaining_hours;
          }else if(project.estimated_time_unit === 'hours'){
                let hoursToUse = (remaining_hours > time_outstanding) ?  time_outstanding : remaining_hours
                dates.push({
                  date: date.date,
                  price: (project.project_rate_value !== null) ? project.project_rate_value : clientDayRate,
                  unit: 'hours',
                  unit_value: hoursToUse,
                  unit_value_formatted: hoursToUse + ' hour(s)',
                  project_id: project.id,
                  client_id: project.client_id
                })
                time_outstanding -= hoursToUse;
              }

        })

        return dates;
}
