import {
  TASK_CREATE,
  TASK_UPDATE,
  TASK_COMPLETE,
  TASKS_PAGE_LOADED,
  TASKS_PAGE_UNLOADED,
} from '../constants/actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case TASKS_PAGE_LOADED:
      // console.log(action)
      return {
        ...state,
        tasks: action.payload.body,
      };
    case TASKS_PAGE_UNLOADED:
      // console.log(action)
      return {};
    case TASK_CREATE:
      // console.log(action)
      if(action.error){
        return {
          ...state,
          errors: action.payload.errors
        };
      }else{
        return {
          ...state,
          task: action.payload.body,
          tasks: [...state.tasks, action.payload.body],
        };
      };
    case TASK_COMPLETE:
      // console.log(action)
      if(action.error){
        return {
          ...state,
          errors: action.payload.errors
        };
      }else{
        return {
          ...state,
          task: action.payload.body,
          tasks: [...state.tasks].filter(function(value, index, arr){
                   return value.id !== action.payload.body.id;
                 })
        };
      };
    case TASK_UPDATE:
      // console.log(action)
      if(action.error){
        return {
          ...state,
          errors: action.payload.errors
        };
      }else{
        return {
          ...state,
         tasks: state.tasks.map(function(value, index, arr){
            return value.id === action.payload.body.id ? action.payload.body : value
          })
        };
      };
    default:
      // console.log(action)
      return state;
  }
};
