import React from 'react';
import { Switch, Route } from 'react-router';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import Overlay from './Overlay';
import Filter from './Filter';
import agent from '../../agent';
import { Landing } from './Landing';
import Client from './Client';

import {
  CLIENT_UPDATE,
  CLIENT_UNLOADED,
  SCHEDULE_INVOICE,
  SCHEDULE_UNDO_INVOICE,
  OPEN_CLIENTS_OVERLAY,
  CLOSE_CLIENTS_OVERLAY,
  CLIENTS_PAGE_LOADED
} from '../../constants/actionTypes';

const mapStateToProps = state => (
  {
    ...state.clients,
    token: state.common.token,
    common: state.common,
    overlay: state.clients.overlay,
    schedule: state.schedule.work,
    projects: state.projects.projects
  }
);

const mapDispatchToProps = dispatch => ({

  transitionState: (client, state) => {
    dispatch({ type: CLIENT_UPDATE, payload: agent.Clients.transition_state(client, state ) })
  },

  updateWorkState: (client, day, state) => {
    dispatch({
      type: SCHEDULE_INVOICE,
      payload: agent.Schedule.transition_state(day, state )
      .then((res) => {
        if(!res.body.errors) {
          dispatch({
            type: CLIENT_UPDATE, payload: agent.Clients.update(client)
          });
        }
        return res;
      })
    })

  },

  rollbackInvoiceWork: (client, day) => {
    dispatch({
      type: SCHEDULE_UNDO_INVOICE,
      payload: agent.Schedule.rollback_transition_state(day)
      .then((res) => {
        if(!res.body.errors) {
          dispatch({
            type: CLIENT_UPDATE, payload: agent.Clients.update(client)
          });
        }
        return res;
      })
    })

  },

  openOverlay: () => {
    dispatch({ type: OPEN_CLIENTS_OVERLAY });
  },

  closeOverlay: () => {
    dispatch({ type: CLOSE_CLIENTS_OVERLAY });
  },

  unsetClient: () => {
    dispatch({ type: CLIENT_UNLOADED });
  },

  loadClients: () => {
    dispatch({ type: CLIENTS_PAGE_LOADED, payload: agent.Clients.all() })
  }

});

class Clients extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      client: false,
      overlay: false,
      client_state: 'All',
      initialLoad: true
    }

    this.clientKeyPress = this.clientKeyPress.bind(this);
    this.newClientButtonRef = React.createRef();

  }

  componentDidMount(){
    document.addEventListener("keydown", this.clientKeyPress);

    this.props.loadClients();

    if (this.state.initialLoad && this.props.clients && this.props.clients.length === 0) {
      this.newClientButtonRef.current.click()
      this.setState({
        initialLoad: false
      });
    }

  }

  componentWillUnmount() {
    this.props.unsetClient();
    document.removeEventListener("keydown", this.clientKeyPress);
  }

  clientKeyPress(e){

    // new client shortcut
    if ( e.keyCode === 78 && !this.state.overlay ) {
      this.openOverlay(e, 'new_client')
    }

  }

  openOverlay(e, overlay, client=false) {

    e.preventDefault();
    e.stopPropagation();

    this.setState({
      overlay: overlay,
      client: client
    });

    this.props.openOverlay()

  }

  closeOverlay(e=false) {

    this.setState({
      overlay: false,
      client: false
    });

    this.props.closeOverlay()

  }

  updateFilter(filter, value) {

    this.setState({
      [filter]: value
    });

  }

  render() {

    if (typeof this.props.clients === 'undefined') {

      return (
        <div className="loading"><div className="loader"></div></div>
      )

    } else {

      let filtered_clients;

      const grouped_clients = _(this.props.clients)
        .groupBy(x => x.state)
        .map((value, key) => ({
          state: key,
          clients: _.orderBy(value, ['id'],['desc'])
        }))
        .sortBy(this.props.clients, function(element){
          var rank = {
            "active": 1,
            "archived": 2
          };
          return rank[element.state];
        })
        .value();

      if(this.state.client_state === 'All') {

        filtered_clients = grouped_clients;

      } else {

        filtered_clients = _.orderBy(this.props.clients.filter((el) => {
          return el.state === this.state.client_state.toLowerCase()
        }), ['id'],['desc']);

      }


      const plus =
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
          <g fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M6 1v10M1 6h10"/>
          </g>
        </svg>

      return (
        <React.Fragment>
        <section className="component__projects">
          <header className="filter">
            <h2>Clients</h2>
            <Filter
              disabled={this.props.clients.length === 0 && true}
              filter={this.state.client_state}
              filters={['All','Active','Archived']}
              onChange={this.updateFilter.bind(this)} />
          </header>
          <ul>
            <li className="new">
              <a
                ref={this.newClientButtonRef}
                className="option"
                onClick={(e) => this.openOverlay(e, 'new_client')}>
                  <span>New Client</span>
                  { plus }
              </a>
            </li>
            <li className="divider"></li>
            {this.props.clients.length === 0 && (
              <li className="note">Create your first client above.</li>
            )}
          </ul>

          {this.state.client_state === 'All' ? (

            grouped_clients.map(group => {
              return (
              <React.Fragment key={group.state}>
                <h3 className="title">{ group.state }</h3>
                <ul>
                {group.clients.map(client => {
                  return (
                    <li
                      key={client.id}
                      className={client.slug === this.props.match.params.slug ? 'selected' : ''}>
                      <Link to={`/clients/${client.slug}`} className="option">
                        <div className="title">
                          { client.name }
                          { client.issues.length > 0 && (<span className="issue"></span>) }
                        </div>
                      </Link>
                    </li>
                  )
                })}
                </ul>
              </React.Fragment>
              )
            })

          ) : (

            <React.Fragment>

            <h3 className="title">{ this.state.client_state }</h3>

            <ul>
            {filtered_clients.length === 0 ? (
              <li className="empty">Not clients found...</li>
            ) : (
              filtered_clients.map(client => {

                return (
                  <li
                    key={client.id}
                    className={client.slug === this.props.match.params.slug ? 'selected' : ''}>
                    <Link to={`/clients/${client.slug}`} className="option">
                      <div className="title">
                        { client.name }
                        { client.issues.length > 0 && (<span className="issue"></span>) }
                      </div>
                    </Link>
                  </li>
                )
              })
            )}
            </ul>

            </React.Fragment>

          )}

          {this.props.overlay && (
            <Overlay
              overlay={this.state.overlay}
              client={this.state.client}
              closeOverlay={(e) => this.closeOverlay(e)}></Overlay>
          )}
        </section>
        <section className="component__project">
          <Switch>
            <Route
              exact
              path={`/clients/`}
              render={(props) => <Landing {...this.props} />} />
            <Route
              exact
              path={`/clients/:slug`}
              render={(props) => <Client {...this.props}  openOverlay={this.openOverlay.bind(this)} />} />
          </Switch>
        </section>
        </React.Fragment>
      )

    }

  }

}

export default connect(mapStateToProps, mapDispatchToProps)(Clients);
