import React from 'react';
import { connect } from 'react-redux';
import dateFns from 'date-fns';
import ScheduleItem from './ScheduleItem';
import ScheduleWeek from './ScheduleWeek';
import 'intersection-observer';
import Sticky from 'react-sticky-el';
import { createSelectable } from 'react-selectable-fast';
import _ from 'lodash';
import classNames from 'classnames/bind';
import convertToHHMM from '../../support/time';

const mapStateToProps = state => (
  {
    ...state
  }
);

const mapDispatchToProps = dispatch => ({
});

const SelectableComponent = createSelectable(ScheduleItem);

class ScheduleMonth extends React.Component {

  constructor(props){
    super(props);

    this.todayRef = React.createRef();
    this.state = {
      overlay: false
    }

  }

  mouseEnter() {
    this.setState({overlay:true})
  }

  mouseLeave() {
    this.setState({overlay:false})
  }

  render() {

    let booked;
    let consecutive_client;
    let previous;
    let previous_project;
    let previous_length;
    let holiday;
    let blackout;

    return (
      <React.Fragment>
      <div className="schedule__calendar__month" data-month={dateFns.format(this.props.month, 'MMMM YYYY')}>
        <Sticky hideOnBoundaryHit={true} boundaryElement=".schedule__calendar__month" scrollElement=".schedule__calendar" topOffset={0} bottomOffset={0}>
        <div className="month" key={dateFns.format(this.props.day, 'x')} data-month={this.props.month}>
          <h3>{dateFns.format(this.props.month, 'MMMM YYYY')} Schedule</h3>
          <span className="quick__note__inline">
            <span className="actions"></span>
            <span className="helper">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                <path fill="#FFF" d="M5,12.5857864 L5,5 C5,2.23871525 7.23871525,0 10,0 L14,0 C14.5522847,0 15,0.44771525 15,1 C15,1.55228475 14.5522847,2 14,2 L10,2 C8.34328475,2 7,3.34328475 7,5 L7,12.5857864 L10.2928932,9.29289322 C10.6834175,8.90236893 11.3165825,8.90236893 11.7071068,9.29289322 C12.0976311,9.68341751 12.0976311,10.3165825 11.7071068,10.7071068 L6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 L0.292893219,10.7071068 C-0.0976310729,10.3165825 -0.0976310729,9.68341751 0.292893219,9.29289322 C0.683417511,8.90236893 1.31658249,8.90236893 1.70710678,9.29289322 L5,12.5857864 Z"/>
              </svg>
              <span>Click on a day to schedule some work</span>
            </span>
          </span>
        </div>
        </Sticky>
        {this.props.dates.map((date, index) => {
          let day = date.day;
          let top = dateFns.isSameDay(day, this.props.schedule.activeMonth);
          let work = [];
          let hours_per_day = parseFloat(this.props.common.currentUser.hours_per_day);
          let hours = hours_per_day;

          if ( this.props.schedule.work ) {

            // calculate the work booked for this day
            work = _.values(_.pickBy(this.props.schedule.work,function(value, key){
              return dateFns.isSameDay(day, dateFns.parse(value.date))
            }));

            // calculate how many hours left in the day (7 available in total)
            if (!_.isEmpty(work)) {
              hours = _.sumBy(work, function(o) {
                if (o.unit === 'hours') {
                  return parseFloat(o.unit_value);
                } else {
                  return 24;
                }
              })
              if (hours > 0) {
                hours = hours_per_day - hours
              }
              if (hours < 0) {
                hours = 0
              }
            }

          }

          if ( this.props.schedule.blackouts ) {

            blackout = this.props.schedule.blackouts.find(blackout => {
              return dateFns.isSameDay(day, dateFns.parse(blackout.date))
            })

          }

          if ( work.length >= 1 ) {

            booked = true;
            consecutive_client = false;

            if ( work.length === 1 && work[0].client === previous && work[0].project_id === previous_project && previous_length === 1 ) {
              consecutive_client = true;
            }

            previous = work[0].client;
            previous_project = work[0].project_id;
            holiday = work[0].holiday;
            previous_length = work.length;


          } else {

            booked = false;
            consecutive_client = false;
            previous = '';
            previous_project = undefined;
            previous_length = 0;
            holiday = false;

          }

          let selected = this.props.selectedKeys.indexOf(dateFns.format(date.day, 'YYYY-MM-DD')) > -1 || this.props.multipleDates.indexOf(dateFns.format(date.day, 'YYYY-MM-DD')) > -1;
          let first = this.props.selectedKeys.indexOf(dateFns.format(date.day, 'YYYY-MM-DD')) === 0;
          let last = _.size(this.props.selectedKeys) > 0 ? this.props.selectedKeys.indexOf(dateFns.format(date.day, 'YYYY-MM-DD')) === this.props.selectedKeys.length-1 : false;
          let after = dateFns.format(date.day, 'YYYY-MM-DD') === dateFns.format(dateFns.addDays(dateFns.parse(this.props.selectedKeys[this.props.selectedKeys.length-1]), 1), 'YYYY-MM-DD');

          return (
            <>
            {dateFns.isMonday(date.day) &&
              <ScheduleWeek date={date.day}/>
            }
            <SelectableComponent
              key={index}
              selectableKey={dateFns.format(date.day, 'YYYY-MM-DD')}
              day={day}
              top={top}
              work={work}
              holiday={holiday}
              booked={booked}
              blackout={blackout}
              trigger={this.props.trigger}
              activeMonth={this.props.activeMonth}
              consecutive_client={consecutive_client}
              addWork={this.props.addWork}
              deleteWork={this.props.deleteWork}
              deleteKeys={this.props.deleteKeys}
              multipleDates={this.props.multipleDates}
              hours={convertToHHMM(hours)}
              todayRef={this.props.todayRef}
              classNames={
                classNames({
                  day: true,
                  highlighted: top,
                  selected: dateFns.isToday(day),
                  weekend: dateFns.isWeekend(day) || _.size(blackout) >= 1,
                  weekStart: dateFns.isMonday(day),
                  weekFinish: dateFns.isSunday(day),
                  booked: _.size(work) >= 1,
                  holiday: holiday,
                  consecutive_client: (hours === 0 && ((consecutive_client && !dateFns.isFirstDayOfMonth(day)) || selected)),
                  shift: selected,
                  first: first,
                  last: last,
                  after: after,
                  monthStart: dateFns.isFirstDayOfMonth(day),
                  monthEnd: dateFns.isLastDayOfMonth(day),
                  hoursFree: hours >= 1,
                  invoiced: !_.isEmpty(work[0]) && (work[0].state === 'invoiced'),
                  paid: !_.isEmpty(work[0]) && (work[0].state === 'paid')
                })
              } />
            </>
          )

        })}
        <div className="boundary"></div>
      </div>
      </React.Fragment>
    )

  }

}

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleMonth);
