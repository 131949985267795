import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import ListErrors from '../ListErrors';
import ContentEditable from 'react-contenteditable';
import FocusTrap from 'focus-trap-react';
import { connect } from 'react-redux';
import { _ , isEmpty } from 'lodash';
import { TwitterPicker } from 'react-color';
import { history } from '../../configureStore';
import update from 'immutability-helper';
import { Dropdown } from './Dropdown';
import agent from '../../agent';
import getSymbolFromCurrency from 'currency-symbol-map';
import { components } from 'react-select';
import Select from 'react-select';
import { currencies } from '../../currencies';
import { validateEmail } from '../../components/Validate';
import Tooltip from '../../components/Tooltip';
import ClientOptions from './ClientOptions';

import {
  CLIENT_CREATE,
  CLIENT_UPDATE,
  CLIENT_REMOVE,
  PROJECTS_PAGE_LOADED,
  CLOSE_CLIENTS_OVERLAY
} from '../../constants/actionTypes';

const Input = ({ autoComplete, ...props }) => <components.Input {...props} autoComplete="new-password" />;
const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <svg width="9" height="12" xmlns="http://www.w3.org/2000/svg"><g transform="translate(-70 -18)" fill="#1282FF" fill-rule="evenodd"><path fill="currentColor" d="M78.3013153 26.17460431c.15974318.18636704.15830762.45647696.00876349.64041738l-.0629961.06460661-3.5 3c-.16383926.1404336-.39586261.1579879-.57674789.0526626l-.07404349-.0526626-3.5-3c-.20966292-.17971107-.23394369-.49536107-.05423261-.70502399.15974318-.18636704.42689488-.22625929.63154276-.10660658l.07348123.05237397L74.42167794 28.841l3.17461337-2.7206283c.20966292-.17971108.52531292-.15543031.70502399.05423261zm-3.6282761-8.10689523l.07404349.05266262 3.5 3c.20966292.17971107.23394369.49536107.05423261.70502399-.15974318.18636704-.42689488.22625929-.63154276.10660658l-.07348123-.05237397L74.42167794 19.158l-3.17459525 2.7216283c-.18636704.15974318-.45647696.15830762-.64041739.00876349l-.0646066-.0629961c-.15974318-.18636704-.15830762-.45647696-.0087635-.64041738l.06299611-.06460661 3.5-3c.16383926-.14043365.39586261-.15798786.57674789-.05266262z" opacity=".5" fill-rule="nonzero"></path></g></svg>
    </components.DropdownIndicator>
  )
}

const mapStateToProps = (state, ownProps) => ({
  ...state,
  errors: state.clients.errors
});

const mapDispatchToProps = dispatch => ({

  newClient: client => {
    dispatch({ type: CLIENT_CREATE, payload: agent.Clients.create(client) })
  },

  updateClient: client => {
    dispatch({
      type: CLIENT_UPDATE,
      payload: agent.Clients.update(client).then((res) => {
        if(!res.body.errors) {
          dispatch({ type: CLOSE_CLIENTS_OVERLAY })
          // don't do anything if we're on invoices
          if (!history.location.pathname.startsWith('/invoices')) {
            // check if client name has changed
            if (history.location.pathname !== '/clients/' + res.body.slug) {
              history.push('/clients/')
            }
          }
        }
        return res;
      })
    })
  },

  transitionState: (client, state) => {
    dispatch({
      type: CLIENT_UPDATE,
      payload: agent.Clients.transition_state(client.id, state).then((res) => {
        if(!res.body.errors) {
          dispatch({ type: CLOSE_CLIENTS_OVERLAY })
        }
        return res;
      })
    })
  },

  deleteClient: (client, prompt) => {
    dispatch({
      type: CLIENT_REMOVE,
      payload: agent.Clients.remove(client, prompt).then((res) => {
        if(!res.body.errors) {
          history.push('/clients/')
          dispatch({
            type: PROJECTS_PAGE_LOADED, payload: agent.Projects.all()
          });
        }
        return res;
      })
    })
  },

})

export let scrollPosition = 0;

class Overlay extends React.Component {

  constructor(props) {
    super(props);

    this.clientNameRef = React.createRef();
    this.clientButtonRef = React.createRef();
    this.deleteClientButtonRef = React.createRef();

    let client;

    if (this.props.overlay === 'new_client') {

      var r = ((Math.floor(Math.random() * 255) + 255) / 2);
      var g = ((Math.floor(Math.random() * 255) + 255) / 2);
      var b = ((Math.floor(Math.random() * 255) + 255) / 2);
      var random_colour = 'rgb(' + r + ', ' + g + ', ' + b + ')';

      this.state = {
        initialLoad: true,
        counter: 1,
        overlay: 'new_client',
        overlay_history: ['new_client'],
        new: true,
        loading: false,
        client: {
          name: '',
          contact_name: '',
          email: '',
          hex_colour: random_colour,
          day_rate: this.props.common.currentUser.standard_day_rate,
          hourly_rate: this.props.common.currentUser.standard_hourly_rate,
          preferred_billing_unit: 'days',
          address: '',
          payment_terms: 30,
          invoice_schedule: 'end_of_month',
          currency: this.props.common.currentUser.currency,
          can_be_deleted: false
        }
      }

    } else {
      this.state = {
        initialLoad: true,
        counter: 1,
        overlay: this.props.overlay,
        overlay_history: ['client'],
        new: false,
        loading: false,
        client: this.props.client
      }

    }

  }

  componentDidMount(){
    document.addEventListener("keydown", this.keyPress.bind(this), false);

    if(this.state.overlay === 'new_client') {
      this.clientNameRef.current.focus()
    }

  }

  componentWillUnmount(){
    document.removeEventListener("keydown", this.keyPress.bind(this), false);
    scrollPosition = 0
  }

  componentDidUpdate(prevProps, prevState) {

    if(prevState.loading && this.props.clients.errors) {

      this.setState({
        loading: false
      })

    }

  }

  keyPress(e, field=false){

    // up/down arrow
    if ( field && [38,40].includes( e.keyCode ) ) {

      let value = parseInt(e.target.innerText)

      // down
      if( e.keyCode === 40 ) {

        if (value === 0) {
          return false
        }

        --value

      }

      // up
      if( e.keyCode === 38 ) {
        ++value
      }

      const [split_field, parent_key] = field.split('.').reverse()
      let newData;

      if (parent_key === undefined) {

        newData = update(this.state, {
          [split_field]: {$set: value}
        })

      } else {

        newData = update(this.state, {
          [parent_key]: {
            [split_field]: {$set: value}
          }
        })

      }

      this.setState(newData);

      // cleanup
      newData = null;

    }

    // enter
    if(e.keyCode === 13) {

      const overlay = this.state.overlay;

      if (e.target.nodeName === 'TEXTAREA') {
        return false;
      }

      if ((overlay === 'client' || overlay === 'new_client') && this.clientButtonRef.current) {
        this.clientButtonRef.current.click()
      }

      if (overlay === 'delete' && this.deleteClientButtonRef.current) {
        this.deleteClientButtonRef.current.click()
      }

    }

  }

  formatPrice(price, currency=undefined) {

    return new Intl.NumberFormat(this.props.common.locale, {
      style: 'currency',
      currency: currency || this.props.common.currentUser.currency
    }).format(price)

  }

  numericOnly(value){
    return value.replace(/[^0-9.]/g, '')
  }

  setOverlay(e, overlay, direction) {

    e.stopPropagation();

    let overlay_history;

    if (direction === 'back') {
      let array = this.state.overlay_history
      overlay_history = array.splice(0, array.length-1)
    } else {
      overlay_history = this.state.overlay_history.concat(overlay);
    }

    this.setState({
      ...this.state, // needed?
      initialLoad: false,
      overlay: overlay,
      overlay_history: overlay_history,
      counter: overlay === 'client' ? 1 : (direction === 'forward' ? this.state.counter + 1 : this.state.counter - 1)
    });

  }

  updateField(field, value) {

    const [split_field, parent_key] = field.split('.').reverse()
    let newData;

    if (parent_key === undefined) {

      newData = update(this.state, {
        [split_field]: {$set: value}
      })

    } else {

      newData = update(this.state, {
        [parent_key]: {
          [split_field]: {$set: value}
        }
      })

    }

    this.setState(newData);

    // cleanup
    newData = null;

  }

  updateColour(color) {
    this.updateField('client.hex_colour', color.hex)

    this.setState({
      displayColorPicker: false
    })

  }

  createClient(e, clientName) {

    let newData;

    // pro-rata day / hourly rate
    if (this.state.client.preferred_billing_unit === 'projects') {

      newData = update(this.state.client, {
        day_rate: {$set: this.props.common.currentUser.standard_day_rate },
        hourly_rate: {$set: this.props.common.currentUser.standard_hourly_rate }
      })

    }

    // pro-rata day rate
    if (this.state.client.preferred_billing_unit === 'hours') {

      newData = update(this.state.client, {
        day_rate: {$set: this.state.client.hourly_rate * this.props.common.currentUser.hours_per_day }
      })

    }

    // pro-rata hourly rate
    if (this.state.client.preferred_billing_unit === 'days') {
      newData = update(this.state.client, {
        hourly_rate: {$set: this.state.client.day_rate / this.props.common.currentUser.hours_per_day }
      })

    }

    // create the client
    this.props.newClient( newData );

    this.setState({
      newData,
      loading: true
    });

    let that = this;
    let timer = setInterval(function() {

      let new_client = that.props.clients.clients.filter(function (el) {
        return el.name === clientName
      });

      if (new_client[0]) {

        clearInterval(timer);

        history.push('/clients/' + new_client[0].slug);

        that.props.closeOverlay(e)

      }

    }, 100);

    // cleanup
    newData = null;

  }

  updateClient(e, client) {

    // validate email if provided
    if( client.email && !validateEmail(client.email) ) {
      return false;
    }

    let newData;

    // pro-rata day / hourly rate
    if (this.state.client.preferred_billing_unit === 'projects') {

      newData = update(this.state.client, {
        day_rate: {$set: this.props.common.currentUser.standard_day_rate },
        hourly_rate: {$set: this.props.common.currentUser.standard_hourly_rate }
      })

    }

    // pro-rata day rate
    if (this.state.client.preferred_billing_unit === 'hours') {

      newData = update(this.state.client, {
        day_rate: {$set: this.state.client.hourly_rate * this.props.common.currentUser.hours_per_day }
      })

    }

    // pro-rata hourly rate
    if (this.state.client.preferred_billing_unit === 'days') {
      newData = update(this.state.client, {
        hourly_rate: {$set: this.state.client.day_rate / this.props.common.currentUser.hours_per_day }
      })

    }

    this.props.updateClient( newData );

    this.setState(newData);

    // cleanup
    newData = null;

  }

  deleteClient = () => {

    this.setState({
      overlay: 'delete',
      overlay_history: this.state.overlay_history.concat('delete'),
      counter: this.state.counter + 1,
    });

  }

  confirmDeleteClient(e, client) {

    this.props.deleteClient( this.state.client, this.state.prompt );

  }

  checkClientName(e, name) {

    e.stopPropagation();

    if (name === this.state.client.name) {

      this.setState({
        can_delete: true,
        prompt: name
      });

    } else {

      this.setState({
        can_delete: false,
        prompt: name
      });

    }

  }

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false })
  };

  render() {

    const close =
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
        <g strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" fill="none" stroke="currentColor" strokeMiterlimit="10">
          <path d="M19 5L5 19M19 19L5 5"/>
        </g>
      </svg>

    const price =
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
        <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
          <circle cx="11" cy="10" r="1.5"/><path d="M.5 2.5v11a2 2 0 002 2h13v-11h-13a2 2 0 01-2-2h0a2 2 0 012-2h9v2"/>
        </g>
      </svg>

    const arrow =
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
        <g strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" fill="none" stroke="currentColor" strokeMiterlimit="10">
          <path d="M2 12h20M15 5l7 7-7 7"/>
        </g>
      </svg>

    const client =
      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48">
        <g strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" fill="currentColor" stroke="currentColor">
        <path fill="none" strokeMiterlimit="10" d="M24 2C11.85 2 2 11.85 2 24v18s6 4 22 4 22-4 22-4V24c0-12.15-9.85-22-22-22z"/>
        <path fill="none" strokeMiterlimit="10" d="M28.577 8.673c-5.07 5.049-11.822 8.409-19.344 9.164C8.441 19.734 8 21.815 8 24c0 8.837 7.163 16 16 16s16-7.163 16-16c0-7.245-4.818-13.357-11.423-15.327z"/>
        <circle fill="none" strokeMiterlimit="10" cx="17" cy="25" r="1"/>
        <circle fill="none" strokeMiterlimit="10" cx="31" cy="25" r="1"/>
        <circle data-stroke="none" cx="17" cy="25" r="1" stroke="none"/>
        <circle data-stroke="none" cx="31" cy="25" r="1" stroke="none"/>
        </g>
      </svg>

    const archive =
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10">
          <path d="M2.5.5h11M11.5 6.5v2h-7v-2"/>
          <path d="M.5 3.5h15v11H.5z"/>
        </g>
      </svg>

    const icon_delete =
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10">
          <path d="M2.5 6.5v7c0 1.105.895 2 2 2h7c1.105 0 2-.895 2-2v-7M.5 3.5h15M5.5 3.5v-3h5v3"/>
        </g>
      </svg>

    return (
      ReactDOM.createPortal(

      <FocusTrap>
      <div className="focus">
        <div className={`overlay__blocks counter__${this.state.counter} ${this.state.initialLoad && 'initialLoad'}`}>

          {(this.state.overlay_history.includes('client') || this.state.overlay_history.includes('new_client')) && (
            <div className="overlay__block new_client overflow">
              <div className="header">
                <input
                  autoFocus={this.state.new ? true : false}
                  ref={this.clientNameRef}
                  type="text"
                  value={this.state.client.name}
                  placeholder="Client Name..."
                  onChange={(e) => this.updateField('client.name', e.target.value)} />
                <button
                  onClick={(e) => this.props.closeOverlay(e)}>
                  {close}
                </button>
              </div>

              <div className="content" tabIndex="1">
                <div className="flex">
                  <textarea
                    type="text"
                    placeholder="Address..."
                    value={this.state.client.address}
                    onKeyDown={(e) => this.keyPress(e)}
                    onChange={(e) => this.updateField('client.address', e.target.value)} />
                </div>
                <label className="flex padding">
                  <div className="date">Contact Name</div>
                  <div
                    className="input text">
                      <input
                        type="text"
                        placeholder="Optional"
                        value={this.state.client.contact_name}
                        onKeyDown={(e) => this.keyPress(e)}
                        onChange={(e) => this.updateField('client.contact_name', e.target.value)} />
                  </div>
                </label>
                <label className="flex padding border">
                  <div className="date">Email Address</div>
                  <div
                    className="input text">
                      <input
                        type="text"
                        placeholder="Optional"
                        value={this.state.client.email}
                        onKeyDown={(e) => this.keyPress(e)}
                        onChange={(e) => this.updateField('client.email', e.target.value)} />
                  </div>
                </label>
                <label className="flex padding border">
                  <div className="date">Colour</div>
                  <div className="picker">
                    <div
                      className="preview"
                      style={{backgroundColor: this.state.client.hex_colour}}
                      onClick={ this.handleClick }>
                    </div>
                    { this.state.displayColorPicker && <div className="popover">
                      <div className="popover__cover" onClick={ this.handleClose }/>
                      <TwitterPicker
                        color={ this.state.client.hex_colour }
                        onChangeComplete={this.updateColour.bind(this)}/>
                      </div>}
                  </div>
                </label>

                <div className="flex padding border">
                  <div className="date">Currency</div>
                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={currencies}
                    defaultValue={currencies.filter((el) => { return el.value === this.state.client.currency})}
                    menuPlacement="auto"
                    onChange={(e) => this.updateField('client.currency', e.value)}
                    components={{ Input, DropdownIndicator }}
                  />
                </div>

                <div className="flex padding border">
                  <div className="date">How do you want to bill {this.state.client.name}?</div>
                  <Dropdown
                    client={this.state.client}
                    content="preferred_billing_unit"
                    onChange={this.updateField.bind(this)} />
                </div>

                {this.state.client.preferred_billing_unit !== 'projects' && (
                  this.state.client.preferred_billing_unit === 'hours' ? (
                    <div className="flex padding border">
                      <div className="date">How much do you charge per hour?</div>
                      <div
                        className={`input currency`}
                        onClick={(e) => e.currentTarget.querySelector('[contentEditable]').focus() }>
                        <span className="helper">{getSymbolFromCurrency(this.state.client.currency)}</span>
                        <ContentEditable
                          innerRef={ React.createRef() }
                          html={this.state.client.hourly_rate.toString()}
                          onKeyDown={(e) => this.keyPress(e)}
                          onChange={(e) => this.updateField('client.hourly_rate', this.numericOnly(e.target.value))} />
                      </div>
                    </div>
                  ) : (
                    <div className="flex padding border">
                      <div className="date">How much do you charge per day?</div>
                      <div
                        className={`input currency`}
                        onClick={(e) => e.currentTarget.querySelector('[contentEditable]').focus() }>
                        <span className="helper">{getSymbolFromCurrency(this.state.client.currency)}</span>
                        <ContentEditable
                          innerRef={ React.createRef() }
                          html={this.state.client.day_rate.toString()}
                          onKeyDown={(e) => this.keyPress(e)}
                          onChange={(e) => this.updateField('client.day_rate', this.numericOnly(e.target.value))} />
                      </div>
                    </div>
                  )
                )}
              </div>
              <div className="actions">
                {this.state.new ? (
                  <button
                    ref={this.clientButtonRef}
                    disabled={this.props.common.loading || isEmpty(this.state.client.name)}
                    className={this.props.common.loading ? 'btn btn__loading' : 'btn'}
                    onClick={(e) => this.createClient(e, this.state.client.name)}>
                    <div className="loader loader__button"></div>
                    <span>Create Client</span>
                  </button>
                ) : (
                  <div className="buttons">
                    <ClientOptions
                      content="small"
                      client={this.state.client}
                      deleteClient={this.deleteClient} />
                    <button
                      ref={this.clientButtonRef}
                      disabled={this.props.common.loading}
                      className={this.props.common.loading ? 'btn btn__loading' : 'btn'}
                      onClick={(e) => this.updateClient(e, this.state.client)}>
                      <div className="loader loader__button"></div>
                      <span>Update</span>
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}

          {this.state.overlay_history.includes('delete') && (
            <div className="overlay__block delete">
              <div className="header">
                <h2>Delete {this.state.client.name}</h2>
                <button
                  onClick={(e) => this.setOverlay(e, 'client', 'back')}>
                  {close}
                </button>
              </div>
              <div className="content">
                <div className="padding">
                  <div className="option delete">
                    <p>Deleting this client cannot be undone. This will delete everything related to this client, include any work added to it. There is no going back.</p>
                    <div className="field">
                      <label>
                        <span className="simple">Please type <b>{this.state.client.name}</b> to confirm.</span>
                        <input
                          onKeyDown={(e) => this.keyPress(e)}
                          onKeyUp={(e) => this.checkClientName(e, e.target.value)}
                          className="form-control"
                          type="text" />
                      </label>
                    </div>
                    <button
                      ref={this.deleteClientButtonRef}
                      disabled={!this.state.can_delete || this.props.common.loading}
                      className={this.props.common.loading ? 'btn btn__loading' : 'btn btn__delete'}
                      onClick={(e) => this.confirmDeleteClient(e, this.state.client)}>
                      <div className="loader loader__button"></div>
                      <span>Delete {this.state.client.name} (cannot be undone)</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          </div>

          <div
            className="overlay__bg"
            onClick={(e) => this.props.closeOverlay(e) }></div>

          <ListErrors errors={this.props.errors} />

        </div>
        </FocusTrap>,
        document.querySelector('.overlay')
      )
    )


  }

}

export default connect(mapStateToProps, mapDispatchToProps)(Overlay);
