import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import TimeField from 'react-simple-timefield';
import DayPicker from 'react-day-picker';
import dateFns from 'date-fns';
import Tooltip from '../Tooltip';
import { scrollPosition } from './Overlay';

export const Dropdown = (props) => {
  const node = useRef();

  const [open, setOpen] = useState(false);

  const handleScroll = e => {
    setOpen(false);
  }

  const handleChange = (field, value, persist) => {
    props.onChange(field,value)
    if (!persist) {
      setOpen(false);
    }
  };

  const handleClickOutside = e => {

    e.stopImmediatePropagation()

    let dropdown = e.composedPath().filter(function (el) {
      return el.id === 'dropdown__menu'
    })

    // inside click
    if (dropdown.length >= 1 || node.current.contains(e.target)) {
      return;
    }

    // outside click
    setOpen(false);

  };

  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
      node.current.offsetParent.addEventListener("scroll", handleScroll);

      const dropdown_menu = document.querySelector('#dropdown__menu');
      const position_top = ((node.current.offsetParent.offsetTop + node.current.offsetTop + node.current.offsetHeight + 8) - scrollPosition);

      // set default position
      dropdown_menu.style.top = position_top + 'px';

      // get bounds now dropdown has default position
      const dropdown_menu_bounds = dropdown_menu.getBoundingClientRect();

      // dropdown outside window, recalculate position
      if ( dropdown_menu_bounds.bottom > window.innerHeight ) {
        const position_recalc = ((node.current.offsetParent.offsetTop + node.current.offsetTop - 8) - scrollPosition) - dropdown_menu.offsetHeight;
        dropdown_menu.style.top = position_recalc + 'px';
      }

      // finally, show dropdown (to stop position popping effect)
      dropdown_menu.style.visibility = 'visible';

    } else {
      document.removeEventListener("mousedown", handleClickOutside);
      node.current.offsetParent.removeEventListener("scroll", handleScroll);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      node.current.offsetParent.removeEventListener("scroll", handleScroll);
    };
  }, [open]);

  return (
    <div ref={node} className="dropdown">
      <button disabled={props.disabled} className="dropdown-toggler" onClick={e => setOpen(!open)}>
        <span>
        {((props.content === 'project_rate_unit') && (
            (props.project.project_rate_unit === 'days' && 'By the day') ||
            (props.project.project_rate_unit === 'hours' && 'By the hour')
        )) || ((props.content === 'estimated_time_unit') && (
          (props.project.estimated_time_unit === 'hours' && 'Hours') ||
          (props.project.estimated_time_unit === 'days' && 'Days')
        )) || ((props.content === 'preferred_billing_unit') && (
          (props.client.preferred_billing_unit === 'days' && 'By the day') ||
          (props.client.preferred_billing_unit === 'hours' && 'By the hour') ||
          (props.client.preferred_billing_unit === 'projects' && 'By the project')
        )) || ((props.content === 'rates') && (
          (((props.client.preferred_billing_unit === 'days') || (props.client.preferred_billing_unit === 'projects')) && (
            <span><span className={`currency-${props.currency.toLowerCase()}`}></span>{props.client.day_rate}/day</span>
          )) || ((props.client.preferred_billing_unit === 'hours') && (
            <span><span className={`currency-${props.currency.toLowerCase()}`}></span>{props.client.hourly_rate}/hour</span>
          ))
        )) || ((props.content === 'date') && (
          props.unit_value_formatted
        )) || ((props.content === 'deadline') && (
          props.project.deadline ? dateFns.format(props.project.deadline, 'D MMMM YYYY') : 'No'
        ))}
        </span>
        <svg width="9" height="12" xmlns="http://www.w3.org/2000/svg">
          <g transform="translate(-70 -18)" fill="#1282FF" fillRule="evenodd">
            <path fill="currentColor" d="M78.3013153 26.17460431c.15974318.18636704.15830762.45647696.00876349.64041738l-.0629961.06460661-3.5 3c-.16383926.1404336-.39586261.1579879-.57674789.0526626l-.07404349-.0526626-3.5-3c-.20966292-.17971107-.23394369-.49536107-.05423261-.70502399.15974318-.18636704.42689488-.22625929.63154276-.10660658l.07348123.05237397L74.42167794 28.841l3.17461337-2.7206283c.20966292-.17971108.52531292-.15543031.70502399.05423261zm-3.6282761-8.10689523l.07404349.05266262 3.5 3c.20966292.17971107.23394369.49536107.05423261.70502399-.15974318.18636704-.42689488.22625929-.63154276.10660658l-.07348123-.05237397L74.42167794 19.158l-3.17459525 2.7216283c-.18636704.15974318-.45647696.15830762-.64041739.00876349l-.0646066-.0629961c-.15974318-.18636704-.15830762-.45647696-.0087635-.64041738l.06299611-.06460661 3.5-3c.16383926-.14043365.39586261-.15798786.57674789-.05266262z" opacity=".5" fillRule="nonzero"/>
          </g>
        </svg>
      </button>
      {open && (
        ReactDOM.createPortal(
          <div
            style={{position: 'absolute', visibility: 'hidden', right: '32px' }}
            id="dropdown__menu" className={`dropdown__menu ` + props.content}>
            {((props.content === 'project_rate_unit') && ( // project.project_rate_unit
              <div className="list">
                <div
                  className={props.project.project_rate_unit === 'days' ? 'item selected' : 'item'}
                  onClick={e => handleChange('project.project_rate_unit','days')}>By the day</div>
                <div
                  className={props.project.project_rate_unit === 'hours' ? 'item selected' : 'item'}
                  onClick={(e) => handleChange('project.project_rate_unit','hours')}>By the hour</div>
              </div>
            )) || ((props.content === 'estimated_time_unit') && ( // project.estimated_time_unit
              <div className="list">
                <div
                  className={props.project.estimated_time_unit === 'hours' ? 'item selected' : 'item'}
                  onClick={e => handleChange('project.estimated_time_unit','hours')}>Hours</div>
                <div
                  className={props.project.estimated_time_unit === 'days' ? 'item selected' : 'item'}
                  onClick={(e) => handleChange('project.estimated_time_unit','days')}>Days</div>
              </div>
            )) || ((props.content === 'preferred_billing_unit') && ( // client.preferred_billing_unit
              <div className="list">
                <div
                  className={props.client.preferred_billing_unit === 'days' ? 'item selected' : 'item'}
                  onClick={e => handleChange('client.preferred_billing_unit','days')}>By the day</div>
                <div
                  className={props.client.preferred_billing_unit === 'hours' ? 'item selected' : 'item'}
                  onClick={(e) => handleChange('client.preferred_billing_unit','hours')}>By the hour</div>
                <div
                  className={props.client.preferred_billing_unit === 'projects' ? 'item selected' : 'item'}
                  onClick={(e) => handleChange('client.preferred_billing_unit','projects')}>By the project</div>
              </div>
            )) || ((props.content === 'rates') && ( // client.day_rate & client.hourly_rate
              <div className="list">
                {(((props.client.preferred_billing_unit === 'days') && (
                  <div>
                    <div className={`currency-${props.currency.toLowerCase()}`}></div>
                    <input
                      className="mini"
                      type="text"
                      value={props.client.day_rate}
                      onChange={(e) => handleChange('client.day_rate', e.target.value, true)} />
                  </div>
                )) || ((props.client.preferred_billing_unit === 'hours') && (
                  <div>
                    <div className={`currency-${props.currency.toLowerCase()}`}></div>
                    <input
                      className="mini"
                      type="text"
                      value={props.client.hourly_rate}
                      onChange={(e) => handleChange('client.hourly_rate', e.target.value, true)} />
                  </div>
                )))}
              </div>
            )) || ((props.content === 'date') && (
              <React.Fragment>
              <div
                className={ props.unit === 'days' ? 'label' : 'label disabled' }
                onClick={props.toggleUnit}>
                <span>All-day</span>
                <Tooltip message={ props.unit === 'days' ? 'Switch to hours' : 'Switch to all-day' } position={'top'} size={'large'}>
                <span className={ props.unit === 'days' ? 'checkbox checked' : 'checkbox' }>
                  <input
                    checked={props.unit === 'days'}
                    type="checkbox" />
                  <span></span>
                </span>
                </Tooltip>
              </div>
              <div
                className={ props.unit === 'days' ? 'time hidden' : 'time' }>
                <TimeField
                  colon=":"
                  value={props.unit_value_in_time}
                  onKeyDown={props.onKeyDown}
                  onChange={props.onChange}
                  style={{width: '100%'}}
                />
              </div>
              <div class="apply" onClick={props.allDates}>Apply to all days</div>
              </React.Fragment>
            )) || ((props.content === 'deadline') && (
              <DayPicker
                selectedDays={dateFns.parse(props.project.deadline)}
                disabledDays={{ before: new Date() }}
                onDayClick={(e) => handleChange('project.deadline',e)}
                onTodayButtonClick={(e) => handleChange('project.deadline',false)}
                fromMonth={new Date()}
                todayButton={'Remove Deadline'}
                initialMonth={props.project.deadline ? dateFns.parse(props.project.deadline) : new Date()} />
            ))}
          </div>, document.querySelector('.overlay__block:last-child')
        )
      )}
    </div>
  );
};
